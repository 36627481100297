import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useStore } from "../../../hooks";

function PrivateRoute({ user, component: Component, ...rest }) {
    const { isAuth, isAdminAuth } = useStore();
    
    return (<>
        <Route {...rest} render={(props) => (
            (isAuth || isAdminAuth) && user
                ? <Component {...props} />
                : <Redirect to={{
                    pathname:'/login',
                    state: {
                        from: props.location
                    }
                }} />
        )} />
    </>
    );
}
export default PrivateRoute;