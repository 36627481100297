import { useState, useEffect } from 'react';

export const useDebounce = (value, delay) => { 
  const [debouncedValues, setDebouncedValues] = useState(value);
  const [searching, setSearching] = useState(false)

  useEffect(
    () => {
      const handler = setTimeout(() => {
        setDebouncedValues(value);
        setSearching(false);
      }, delay);


      return () => {
        clearTimeout(handler);
        setSearching(true);
      };
    },
    [value, delay] 
  );

  return {debouncedValues, searching};
}