import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Signup from './Screens/Signup/index';
import VerifyEmail from './Screens/VerifyEmail'
import Brand from './Screens/Brand';
import Blocked from './Screens/Blocked'
import Complete from './Screens/Complete';
import ReactGA from 'react-ga';
//import LinkAccounts from './Screens/LinkAccounts';
//import Categories from './Screens/Categories';
//import SubscriptionType from './Screens/SubscriptionType';
//import Loading from "../Global/Loading/Icon";
//import { useTranslation } from 'react-i18next'

const rootclass = 'onboarding';

const Onboarding = ({ me, isAuth, windowWidth }) => {
    //const { t } = useTranslation();
    const [values, setValues] = useState({
        name: '',
        websiteUrl: '',
        description: '',
    })
    const history = useHistory();
    const [step, setStep] = useState(1);
    const [image, setImage] = useState();
    const [logo, setLogo] = useState();
    const [blocked, setBlocked]=useState(true);
    //const { createBrand, loading: creatingBrand } = useBrandCreate();

    useEffect(() => {
        if(step>=0){
            history.push(`/signup?=${step}`)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [step]);

    useEffect(() => {
        if(!blocked && step === 3){
            setStep(4);
        }
    }, [blocked, step])

    useEffect(() => {
        if(step === 1){
            ReactGA.event({
                category: `Brand User Onboarding`,
                action: `Signup`,
                label: 'N/A'
            });
        }
        if(step === 2){
            ReactGA.event({
                category: `Brand User Onboarding`,
                action: `Verify Email (from sign up)`,
                label: 'N/A'
            });
        }
        if(step === 3){
            ReactGA.event({
                category: `Brand User Onboarding`,
                action: `Blocked (from sign up)`,
                label: 'N/A'
            });
        }
        if(step === 4){
            ReactGA.event({
                category: `Brand User Onboarding`,
                action: `Create Brand (from sign up)`,
                label: 'N/A'
            });
        }
        if(step === 5 && windowWidth>769){
            ReactGA.event({
                category: `Brand User Onboarding`,
                action: `Completed`,
                label: 'N/A'
            });
        }
        if(step === 5 && windowWidth<769){
            ReactGA.event({
                category: `Brand User Onboarding`,
                action: `Completed on Mobile`,
                label: 'N/A'
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [step]);

    return ( 
        <>
            {step <= 4 ?
                    <section className={rootclass}>
                    {step === 0 && history.push('/')}
                    {step === 1 && <Signup me={me} isAuth={isAuth} step={step} setStep={setStep} setBlocked={setBlocked}/>}
                    {step === 2 && <Brand blocked={blocked} windowWidth={windowWidth} values={values} setValues={setValues} step={step} setStep={setStep} image={image} setImage={setImage} logo={logo} setLogo={setLogo}/>}
                    {step === 3 && <VerifyEmail step={step} setStep={setStep} />}
                    {step === 4 && <Blocked step={step} setStep={setStep} rootclass={rootclass}/>}
                    {/* {step === 3 && <LinkAccounts step={step} setStep={setStep} />} */}
                </section>
                : 
                <section>
                    <Complete rootclass={rootclass} step={step} setStep={setStep} windowWidth={windowWidth} values={values} image={image} />
                </section>}

                {/* {step === 3 && 
                        <section className={`${rootclass}-wide`}>
                            <Categories updateBrand={organizationUpdate} step={step} setStep={setStep} />  
                        </section>
                } */}

               {/* {step === 4 && 
                    <>
                    <section className={`${rootclass}-xtrawide`}>
                        <SubscriptionType updateBrand={organizationUpdate} step={step} setStep={setStep} rootclass={rootclass} />
                    </section>
                    <span className={`${rootclass}-xtrawide-disclaim`}>{t("onboarding.screen.6.feeDisclaimer")}</span>
                    </>
                } */}
        </>
    )
}
export default Onboarding