import create from 'zustand';

export const useOnboardingStore = create(set => ({
    signUp:{
        stored: false,
        logo: null,
        token: '',
        requirements: [],
        values: {
            email: '',
            name: '',
            websiteUrl: '',
            description: '',
        }
    },
    signIn:{
        stored: false,
        email:'',
        token: ''
    },
    setSignUp: (signUp) => set({ signUp }),
    setSignIn: (signIn)=> set({ signIn }),
}));