export const advancedOptions = [
    {
        label: "modal.offer.advanced.ex",
        tooltip: "modal.offer.advanced.ext",
        length: 6,
        id: "ex"
    },
    {
        label: "modal.offer.advanced.ur",
        tooltip: "modal.offer.advanced.urt",
        length: 6,
        id: "ur"
    }
]