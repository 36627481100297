import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom';

const rootclass = 'onboarding';

const Complete = ({ windowWidth, setRecoverPassword }) => {
    const { t } = useTranslation();
    const history = useHistory();
    localStorage.removeItem('onboarding')


    if (windowWidth < 769){
        return(
            <div className={`${rootclass}-complete-mobile`}>
                <span>{t("onboarding.screen.7.mobile")}</span>
                <span><FontAwesomeIcon icon="check-circle" className={`${rootclass}-complete-icon`} /></span>
            </div>
        )
    } else {
        window.setTimeout(function () {
            setRecoverPassword(false);
            history.push('/login');
        }, 3000);

        return (
            <div className={`${rootclass}-complete`}>
                <span>{t("onboarding.screen.recover-password.complete")}</span>
                <span><FontAwesomeIcon icon="check-circle" className={`${rootclass}-complete-icon`} /></span>
            </div>
        )
    }
}

export default Complete
