import React from "react"
import { NotificationType } from "../../../types/withhooks";

export function NotificationImageAlt({ notification }) {
    const { deliverable } = notification

    let imageUrl;
    let alt;

    switch (notification.type) {
        case NotificationType.ContentSubmitted:
        case NotificationType.ContentUpdated:
            imageUrl = deliverable.mediaUrls[0]
            alt = 'Content image'
            break
        default:
            imageUrl = null
            alt= null
            break
    }

    if (notification.type.toLowerCase().startsWith('content')) {
        return (
            <div className={"notification-image-alt-container"}>
                <img
                    src={imageUrl}
                    alt={alt}
                />
            </div>
        )
    } else {
        return null;
    }
}