import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from '@material-ui/core/Tooltip';
// import Tooltip from '@material-ui/core/Tooltip';
// import Loading from '../../../Global/Loading/Icon'
import Loading from '../../../Global/Loading/Icon'
import ImageCropper from '../../../Global/Modals/ImageCropperModal';
import { useTranslation } from 'react-i18next'
import { useOrganizationCreateMutation } from '../../../../types/withhooks'

const rootclass = "onboarding";

const Brand = ({ values, setValues, step, setStep, image, setImage, setLogo, logo}) => {
    const { t } = useTranslation();
    const [errors, setErrors]=useState({})
    const {name, description, websiteUrl } = values;
    const [ organizationCreate, organizationCreateData ]=useOrganizationCreateMutation();

    let textareaCount = values.description ? values.description.length : 0;

    const handleSave = async () => {
        let response = await organizationCreate({
            variables: {
                input:  {
                    name: name,
                    about: description,
                    websiteUrl: websiteUrl,
                    photo: logo
                }
            }
        });
        if(response?.data?.organizationCreate?.errors?.length === 0){
            setStep(step + 1);
        } else {
            setErrors({
                brand: response?.data?.organizationCreate?.errors[0]
            })
        }
    }

    return (
        <>
            <h1>{t("onboarding.screen.3.title")}</h1>
            <ImageCropper
                rootclass={rootclass}
                title={t("onboarding.screen.3.image")}
                image={image}
                setImage={setImage}
                setFile={setLogo}
                aspectRatio={1}
                className="form-input-file-upload"
                placeholder={<p>{t("onboarding.screen.3.yourLogo")}</p> }
            />
            <label>
                <Tooltip arrow open placement="left" title={errors && errors.brand ? errors.brand.message : ""} >
                    <div className={errors && errors.brand ? "form-input form-input-error" : "form-input"}>
                        <FontAwesomeIcon icon="user" />
                        <input
                            maxLength="100"
                            id="orgName"
                            type="text"
                            name="orgName"
                            placeholder={t("onboarding.screen.3.organization")}
                            value={values.name}
                            onChange={e => {
                                setValues({ ...values, name: e.target.value });
                                setErrors({});
                            }}
                            required />
                    </div>
                </Tooltip>
            </label>

            <label>
                <div className="form-input">
                    <FontAwesomeIcon icon="link" />
                    <input
                        maxLength="100"
                        id="website"
                        type="text"
                        name="website"
                        placeholder={t("onboarding.screen.3.website")}
                        value={values.websiteUrl}
                        onChange={e =>{
                            setValues({ ...values, websiteUrl: e.target.value })
                            setErrors({})
                        }}
                        required />
                </div>
            </label>
            <label>
                <textarea
                    name='description'
                    value={values.description}
                    type="text"
                    placeholder={t("onboarding.screen.3.description")}
                    maxLength="280"
                    onChange={e => {
                        setValues({ ...values, description: e.target.value });
                        setErrors({});
                    }}
                />
                <p className='textarea-count'>{textareaCount}/280</p>
            </label>

            {/* {!loading ?
                <div>
                    {values.confirmPassword === "" || values.password === "" || values.fullname === ""  || values.email === "" || values.acceptedTerms === false ? <button className="btn-primary" disabled onClick={handleSubmit}>{t("onboarding.screen.2.create")}</button> : <button className="btn-primary" onClick={handleSubmit}>{t("onboarding.screen.2.create")}</button>}
                </div>
                :
                <Loading rootclass={rootclass} />
            } */}


            <div className={`${rootclass}-skip`}>
                {!organizationCreateData.loading ?
                    <button
                        onClick={handleSave}
                        disabled={name === ''}
                        className="btn-primary">
                        {t("onboarding.screen.3.next")}
                    </button> 
                :
                    <Loading rootclass={rootclass} />
                    
                }
            </div>
        </>

    )
}
export default Brand;