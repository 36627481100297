import React, { useEffect, useState, useMemo } from "react"
import { useNotificationStore } from "../../hooks"
import { Waypoint } from 'react-waypoint';
import shallow from "zustand/shallow"
import { NotificationItem } from "./NotificationItem/"
import { useUserNotificationsQuery, useUserUnreadNotificationsQuery } from "../../types/withhooks";

export function NotificationList() {
    // These will trigger notification list processing
    const [notificationEndCursor, setNotificationEndCursor]=useState('')
    const [notifications] = useNotificationStore(state => [state.notifications], shallow)
    const addNotifications = useNotificationStore(state => state.addNotifications)
    const openDrawer = useNotificationStore(state => state.openDrawer)

    const { data: userNotificationsData } = useUserNotificationsQuery({ 
        variables: {
            after: notificationEndCursor
        }
    })
    const { data: userUnreadNotificationsData } = useUserUnreadNotificationsQuery({ fetchPolicy: 'network-only'})

    useEffect(() => {
        if (userNotificationsData?.currentUser?.notifications?.nodes) {
            addNotifications(userNotificationsData.currentUser.notifications.nodes)
        }
        if (userUnreadNotificationsData?.currentUser?.unreadNotifications?.nodes) {
            addNotifications(userUnreadNotificationsData.currentUser.unreadNotifications.nodes)
        }
    }, [userNotificationsData, userUnreadNotificationsData, addNotifications])

    // We will only recompute the list of items if the notification map
    // has been modified
    const { readNotificationsItems, unreadNotificationsItems } = useMemo(() => {

        const readNotificationsItems = []
        const unreadNotificationsItems = []

        for (const n of notifications.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)).values()) {
            if (n.readAt) {
                readNotificationsItems.push(<NotificationItem key={n.id} notificationId={n.id} />)
            } else {
                unreadNotificationsItems.push(<NotificationItem key={n.id} notificationId={n.id} />)
            }
        }

        return {
            readNotificationsItems,
            unreadNotificationsItems
        }

    }, [notifications])


    if (!openDrawer) {
        return null
    }
    
    return (
        <div className={"notification-container"} >
            <div id={"triangle-up"}></div>
            <div className={"notification-list"}>
                {
                    !unreadNotificationsItems.length && !readNotificationsItems.length
                        ?
                        <div className="no-notifications">
                            No notifications
                        </div>
                        :
                        <React.Fragment>
                            {
                                unreadNotificationsItems
                            }
                            {
                                readNotificationsItems
                            }
                            <Waypoint
                                topOffset={"95%"}
                                onEnter={() => {
                                    if (userNotificationsData?.currentUser?.notifications?.pageInfo?.endCursor) {
                                        setNotificationEndCursor(userNotificationsData.currentUser.notifications.pageInfo.endCursor)
                                    }
                                }}
                            />
                        </React.Fragment>
                }
            </div>


        </div>
    )
}
