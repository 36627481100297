import React from "react"

/**
 * This component takes the component it wraps and 
 * decorates the onClick callback. This in order to avoid
 * adding divs and breaking defined styles.
 * This component must wrap a real HTML element
 */
export function AbstractController({children, fun}) {

    children = React.Children.only(children)
    
    const onClick = (...args) => {
        console.log('On click wrapped')
        fun()
        if ( children.props.onClick ) {
            children.props.onClick(...args)
        }
    }
    
    // Return the component with the badge added
    return React.cloneElement(
        children, 
        {onClick},  // Props are merged
        ...React.Children.toArray(children.props.children)
    )

    // return (
    //     <div onClick={fun}>
    //         {children}
    //     </div>
    // )
}
