import { useEffect } from 'react';
import { useNotificationStore } from "../../../hooks"

export function IsChatMounted(props) {
    const { children } = props;
    const setChatOpen = useNotificationStore(state => state.setChatOpen)

    useEffect(() => {
        // console.log('Mounted')
        setChatOpen(true);
        return () => {
            // console.log('Unmounted')
            setChatOpen(false);
        }
    })

    return children
}