import React from "react";
import { useNotificationStore } from "../../../hooks";
import { AbstractNotificationBadge } from "./AbstractNotificationBadge";
import { useTranslation } from 'react-i18next'
import { ApplicationState, NotificationType } from "../../../types/withhooks";

export function ApplicationNotificationBadge(props) {
    const { t } = useTranslation();
    const { applicationId } = props;

    const APPLICATION_BADGES = {
        [NotificationType.ApplicationCreated]: t("notifications.badges.new")
    }

    const unreadNotifications = useNotificationStore(state => state.unreadNotifications, (a, b) => a.size === b.size);

    let associatedNotification;

    // 1. Check for event match
    // 2. Check that the entity still exists
    // 3. Match for the id
    for (let notification of unreadNotifications.values()) {
        if (notification.type in APPLICATION_BADGES) {
            if (applicationId === notification.application?.id) {
                if (notification.type === NotificationType.ApplicationCreated && notification.application.state !== ApplicationState.Applied) {
                    continue;
                }

                associatedNotification = notification;
            }
        }

        // Only one associated notification
        if (associatedNotification) {
            break;
        }
    }

    if (associatedNotification) {
        return <AbstractNotificationBadge className={"badge-component-avatar"} message={APPLICATION_BADGES[associatedNotification.type]} />;
    } else {
        return null;
    }

}
