import React from "react"
import moment from 'moment';
import mergeWith from "lodash.mergewith"
import { useNotificationStore } from "../../../hooks";
import { useTranslation } from 'react-i18next'
import { useNotificationMarkAsReadMutation } from "../../../types/withhooks";

import { NotificationLink } from "./NotificationLink"
import { NotificationMessage } from "./NotificationMessage"
import { NotificationImage } from "./NotificationImage"
import { NotificationImageAlt } from "./NotificationImageAlt"

export function NotificationItem({ notificationId }) {
    const { t } = useTranslation();
    const originalNotification = useNotificationStore(state => state.notifications.get(notificationId))
    const setOpenDrawer = useNotificationStore(state => state.setOpenDrawer)
    const markAsRead = useNotificationStore(state => state.markAsRead)

    const [notificationMarkAsRead] = useNotificationMarkAsReadMutation({
        onCompleted: (data) => {
            if (data?.notificationMarkAsRead?.notification?.id) {
                markAsRead(data?.notificationMarkAsRead?.notification?.id, data?.notificationMarkAsRead?.notification?.readAt)
            }
        }
    })

    /**
     * Notification object cannot be expected to contain all of it's properties (the entity it points to 
     * may have been deleted in the database), so this object will contain all the missing properties 
     * and their associated values
     */
    const NOTIFICATION_DEFAULTS = {
        campaign: {
            name: t("notifications.miscellaneous.deleted.campaign"),
            photoUrl: 'https://res.cloudinary.com/dhxv2kj02/image/upload/v1610213248/atnmy-dev/defaults/Placeholder_Mastheading_ipvgdg.png'
        },
        profile: {
            fullname: t("notifications.miscellaneous.deleted.creator"),
            instagramAccount: {
                profilePictureUrl: '/placeholder-profile.png'
            }
        },
        deliverable: {
            mediaUrls: ['/placeholder-post.png']
        }
    }


    // We fill the object with the required data to display it
    const notification = {}
    mergeWith(notification, NOTIFICATION_DEFAULTS, originalNotification, (objValue, srcValue) => srcValue === null ? objValue : undefined)

    return (
        <NotificationLink notification={notification}>
            <div
                className = {"notification-item"}
                onClick={() => {
                    notificationMarkAsRead({
                        variables: {
                            notificationId: notification.id
                        }
                    })
                    setOpenDrawer(false)
                }}
            >
                {notification.readAt ? null : <ItemNotificationBadge />}
                
                    <NotificationImage notification={notification} />
                        <div className={"notification-info"}>
                        <NotificationMessage notification={notification} />
                        <span>{moment(notification.createdAt).fromNow()}</span>
                        </div>
                        <NotificationImageAlt notification={notification} />
            </div>
        </NotificationLink>
    )
}

/* Internal components of the notification item component */

function ItemNotificationBadge() {
    return (
        <div className={"badge-notification-item"}></div>
    );
}
