import React from 'react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import ReCAPTCHA from "react-google-recaptcha";
// import validator from 'validator';
// import { useHistory } from 'react-router-dom';
// import LinkAccounts from './Screens/LinkAccounts';
// import Categories from './Screens/Categories';
// import { useBrandCreate, useBrandUpdate } from '../../hooks';
import { useTranslation } from 'react-i18next'
// import SubscriptionType from './Screens/SubscriptionType';
// import Loading from "../Global/Loading/Icon";
// import { Tooltip } from '@material-ui/core';


const LinkSent = ({ values }) => {
    const { t } = useTranslation();

    return ( 
        <>
            <h1>{t("onboarding.screen.recover-password.link-sent.title")}</h1>
            <p>{t("onboarding.screen.recover-password.link-sent.content1")}{values?.email}{t("onboarding.screen.recover-password.link-sent.content2")}</p>
        </>
    )
}
export default LinkSent