import React, { useEffect, useRef } from "react"
import { useNotificationStore, useStore } from "../../hooks"
import { NotificationList } from "./NotificationList";
import shallow from "zustand/shallow"
import { useUserNotificationSubscription, useUserNotificationReadSubscription } from "../../types/withhooks"
import { useTranslation } from 'react-i18next'

export function NotificationComponent() {
    const userId = useStore(state => state.userId)
    return userId ? <InnerNotificationComponent userId={userId}/> : null
}

const hiddeListCallback = () => {
    useNotificationStore.setState({ openDrawer: false })
}

function InnerNotificationComponent({ userId }) {
    const { t } = useTranslation();

    const [openDrawer, setOpenDrawer] = useNotificationStore(state => [state.openDrawer, state.setOpenDrawer], shallow);

    useEffect(() => {
        if (openDrawer) {
            window.addEventListener('click', hiddeListCallback)
        } else {
            window.removeEventListener('click', hiddeListCallback)
        }
    }, [openDrawer])

    const addNotifications = useNotificationStore(state => state.addNotifications);
    const markAsRead = useNotificationStore(state => state.markAsRead);

    useUserNotificationSubscription({
      variables: {
         userId
      },
      shouldResubscribe: true,
      onSubscriptionData: ({ subscriptionData }) => {
        if (subscriptionData?.data?.userNotifications?.newNotification?.id) {
            addNotifications([subscriptionData.data.userNotifications.newNotification])
        }
      }
    });

    useUserNotificationReadSubscription({
        variables: {
            userId
        },
        shouldResubscribe: true,
        onSubscriptionData: ({ subscriptionData }) => {
            if (subscriptionData?.data?.userNotificationRead?.readNotification?.id) {
                const { id, readAt } = subscriptionData.data.userNotificationRead.readNotification
                markAsRead(id, readAt)
            }
        }
    })

    const notificationList = useRef(<NotificationList />)
    const badge = useRef(<DrawerNotificationBadge />)

    return (
        <div className={"notification-component"}>
            <div onClick={
                () => setOpenDrawer(!openDrawer)
            }>
                    {t("nav.notifications")}
            </div>

            {notificationList.current}
            {!openDrawer ? badge.current : null}

        </div>
    )
}

function DrawerNotificationBadge() {
    const unreadNotifications = useNotificationStore(state => state.unreadNotifications, (a, b) => a.size === b.size);

    if (!unreadNotifications.size) {
        return null;
    } else {
        return <div className={"badge-notification"}></div>
    }
}