import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReCAPTCHA from "react-google-recaptcha";
import validator from 'validator';
import { useHistory } from 'react-router-dom';
// import LinkAccounts from './Screens/LinkAccounts';
// import Categories from './Screens/Categories';
// import { useBrandCreate, useBrandUpdate } from '../../hooks';
// import SubscriptionType from './Screens/SubscriptionType';
// import Loading from "../Global/Loading/Icon";
import { useTranslation } from 'react-i18next'
import { Tooltip } from '@material-ui/core';
import Loading from '../../../Global/Loading/Icon';


const Email = ({ rootclass, values, setValues, setStep, setRecoverPassword }) => {
    const { t } = useTranslation();
    const [submitting, setSubmitting]=useState(false);
    const [recaptchaKey]=useState(process.env.REACT_APP_RECAPTCHA_SITE_KEY);
    const [recaptchaValue,setRecaptchaValue]=useState(null);
    const [validated, setValidated]=useState(false);
    const [errors, setErrors]=useState({})
    const [submit, setSubmit]=useState(false)
    const history = useHistory();


    useEffect(() => {
        if(submit && validated && errors.email === null){
            submitEmail();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [submit, errors]);

    const submitEmail = async () => {
        setSubmitting(true);
        const { email } = values
        let submitEmailFormData = new FormData();;
        submitEmailFormData.append("email", email);

        let response = await new Promise(resolve => {
            let request = new XMLHttpRequest();
            request.open("POST", process.env.REACT_APP_REQUEST_RESET_PASSWORD_ENDPOINT);
            request.onload =  () => {
                resolve(JSON.parse(request.response))
            }
            request.onerror = () => {
                resolve(undefined);
                // setErrors({email});
            }
            request.send(submitEmailFormData);
        })

        if(response.message){
            setErrors({
                email: response.message
            });
            setSubmitting(false);
        } else{
            setStep(2);
        }

    }

    const Validate = (values) => {

        let errors = { email: null }

        if (!validator.isEmail(values.email)) {
            errors.email = t("onboarding.screen.1.errors.email");
        } else {
            setValidated(true);
        }
        return errors
    }

    const handleChange = (e) =>{
        setValues({
            ...values,
            email: e.target.value
        })
    }

    const captchaChange =(value) => {
        setRecaptchaValue(value);
    }

    const handleValidate = () => {
        setErrors(Validate(values));
        setSubmit(true);
    }

    const handleSignIn = () => {
        setRecoverPassword(false);
        history.push('/');
    }

    return ( 
        <>
            <h1>{t("onboarding.screen.recover-password.email.title")}</h1>
            <label>
                <Tooltip arrow open placement="left" title={errors && errors.email ? errors.email : ""} >
                    <div className={errors && errors.email ? "form-input form-input-error" : "form-input"}>
                        <FontAwesomeIcon icon="envelope" />
                        <input
                            id="email"
                            type="email"
                            name="email"
                            placeholder={t("onboarding.screen.1.email")}
                            value={values.email}
                            onChange={handleChange}
                            required />
                         {errors && errors.email && <FontAwesomeIcon icon="times-circle" className="form-error-icon" />}
                    </div>
                    </Tooltip>
            </label>
            <div className={`${rootclass}-captcha`}>
                <ReCAPTCHA sitekey={recaptchaKey} onChange={captchaChange} theme="dark"/>
            </div>
            
            {submitting ? 
                <Loading rootclass={rootclass} />
                :
                <button className="btn-primary" disabled={!recaptchaValue} onClick={handleValidate}>{t("onboarding.screen.recover-password.email.send")}</button>
            }
            <button className="btn-secondary" onClick={handleSignIn}>{t("onboarding.screen.recover-password.email.back")}</button>
        </>
    )
}
export default Email