import React, { useState, useEffect } from 'react';
import Email from './Screens/Email';
import LinkSent from './Screens/LinkSent';
import Complete from './Screens/Complete';
import Password from './Screens/Password'
// import { useHistory } from 'react-router-dom';
// import LinkAccounts from './Screens/LinkAccounts';
//import Categories from './Screens/Categories';
//import { useBrandCreate, useBrandUpdate } from '../../hooks';
//import SubscriptionType from './Screens/SubscriptionType';
// import Loading from "../Global/Loading/Icon";
// import { useTranslation } from 'react-i18next'

const rootclass = 'onboarding';

const PasswordRecovery = ({ setRecoverPassword }) => {
    // const { t } = useTranslation();
    const [values, setValues] = useState({
        email: '',
        newPassword: '',
    })
    // const history = useHistory();
    const [step, setStep] = useState(1);

    useEffect(() => {
        if(window.location.search.length > 0){
            setStep(3);
        }
    }, [])

    useEffect(() => {
        setRecoverPassword(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return ( 
        <>
            {step <= 3 ?
                <section className={rootclass}>
                    {step === 1 && <Email rootclass={rootclass} values={values} setValues={setValues} setStep={setStep} setRecoverPassword={setRecoverPassword} />}
                    {step === 2 && <LinkSent values={values}/>}
                    {step === 3 && <Password rootclass={rootclass} values={values} setValues={setValues} step={step} setStep={setStep} />}
                </section>
                : 
                <section>
                    <Complete rootclass={rootclass} setRecoverPassword={setRecoverPassword}/>
                </section>
            }
        </>
    )
}
export default PasswordRecovery