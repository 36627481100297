import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom';
import { useOrganizationCreateMutation } from '../../../../types/withhooks'

const rootclass = 'onboarding';

const Complete = ({ windowWidth, values, logo }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const [ organizationCreate ]=useOrganizationCreateMutation();
    const { name, description, websiteUrl } = values;

    localStorage.removeItem('onboarding');
    
    const createOrg = async () => {
        try{
            await organizationCreate({
                variables: {
                    input:  {
                        name: name,
                        about: description,
                        websiteUrl: websiteUrl,
                        photo: logo
                    }
                }
            });
            //console.log(response);
        }catch (error){
            console.log(error);
        } finally {
            
        }
    }

    if (windowWidth < 769){
        return(
            <div className={`${rootclass}-complete-mobile`}>
                <span>{t("onboarding.screen.7.mobile")}</span>
                <span><FontAwesomeIcon icon="check-circle" className={`${rootclass}-complete-icon`} /></span>
            </div>
        );
    } else {
        window.setTimeout(function () {
            createOrg();
            history.push('/loading');
            window.location.reload();
        }, 5000);

        return (
            <div className={`${rootclass}-complete`}>
                <span>{t("onboarding.screen.7.web")}</span>
                <span><FontAwesomeIcon icon="check-circle" className={`${rootclass}-complete-icon`} /></span>
            </div>
        );
    };
};

export default Complete
