import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useOnboardingStore } from '../../../../hooks';
// import Tooltip from '@material-ui/core/Tooltip';
import ImageCropper from '../../../Global/Modals/ImageCropperModal';
import { useTranslation } from 'react-i18next'

const rootclass = "onboarding";

const Brand = ({ blocked, values, setValues, step, setStep, image, setImage, logo, setLogo}) => {
    const { t } = useTranslation();
    const yourSignupStore = useOnboardingStore(state => state.signUp);
    const setYourSignupStore = useOnboardingStore(state => state.setSignUp);

    const { name } = values;

    let textareaCount = values.description ? values.description.length : 0;

    const handleSave = async () => {
        if(step === 2 && !yourSignupStore?.stored){
            setYourSignupStore({
                ...yourSignupStore,
                stored: true,
                logo: logo,
                values:values
            });
            setStep(step+1);
        } else {
            return
        }

    }

    return (
        <>
            <h1>{t("onboarding.screen.3.title")}</h1>
            <ImageCropper
                rootclass={rootclass}
                title={t("onboarding.screen.3.image")}
                image={image}
                setImage={setImage}
                setFile={setLogo}
                aspectRatio={1}
                className="form-input-file-upload"
                placeholder={<p>{t("onboarding.screen.3.yourLogo")}</p> }
            />
            <label>
                <div className="form-input">
                    <FontAwesomeIcon icon="user" />
                    <input
                        maxLength="100"
                        id="orgName"
                        type="text"
                        name="orgName"
                        placeholder={t("onboarding.screen.3.organization")}
                        value={values.orgName}
                        onChange={e => setValues({ ...values, name: e.target.value })}
                        required />
                </div>
            </label>

            <label>
                <div className="form-input">
                    <FontAwesomeIcon icon="link" />
                    <input
                        maxLength="100"
                        id="website"
                        type="text"
                        name="website"
                        placeholder={t("onboarding.screen.3.website")}
                        value={values.websiteUrl}
                        onChange={e => setValues({ ...values, websiteUrl: e.target.value })}
                        required />
                </div>
            </label>
            <label>
                <textarea
                    name='description'
                    value={values.description}
                    type="text"
                    placeholder={t("onboarding.screen.3.description")}
                    maxLength="280"
                    onChange={e => setValues({ ...values, description: e.target.value })}
                />
                <p className='textarea-count'>{textareaCount}/280</p>
            </label>

            {/* {!loading ?
                <div>
                    {values.confirmPassword === "" || values.password === "" || values.fullname === ""  || values.email === "" || values.acceptedTerms === false ? <button className="btn-primary" disabled onClick={handleSubmit}>{t("onboarding.screen.2.create")}</button> : <button className="btn-primary" onClick={handleSubmit}>{t("onboarding.screen.2.create")}</button>}
                </div>
                :
                <Loading rootclass={rootclass} />
            } */}


            <div className={`${rootclass}-skip`}>
                <button
                    onClick={handleSave}
                    disabled={name === ''}
                    className="btn-primary">
                    {t("onboarding.screen.3.next")}
                </button> 
            </div>
        </>

    )
}
export default Brand;