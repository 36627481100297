import React from "react"
import moment from "moment"
import { useTranslation } from 'react-i18next'
import { NotificationType } from '../../../types/withhooks'

export function NotificationMessage({ notification }) {
    const { t } = useTranslation();

    const TEMPLATES = {
        [NotificationType.CampaignCreated]: "notifications.templates.campaignCreated",
        [NotificationType.CampaignStarted]: "notifications.templates.campaignStarted",
        [NotificationType.CampaignHalfway]: "notifications.templates.campaignHalfway",
        [NotificationType.CampaignOneDayLeft]: "notifications.templates.campaignOneDayLeft",
        [NotificationType.CampaignEnded]: "notifications.templates.campaignEnded",
    
        [NotificationType.ApplicationCreated]: "notifications.templates.applicationCreated",
    
        [NotificationType.OfferAccepted]: "notifications.templates.offerAccepted",
    
        [NotificationType.ContentSubmitted]: "notifications.templates.contentSubmitted",
        [NotificationType.ContentUpdated]: "notifications.templates.contentUpdated",
    
        [NotificationType.DummyAction]: "notifications.templates.dummyAction"
    }

    const template = TEMPLATES[notification.type] ? TEMPLATES[notification.type] : "notifications.miscellaneous.noTemplate"

    if (notification.type === NotificationType.CampaignHalfway) {
        if (notification.campaign) {
            notification.campaign = {
                ...notification.campaign, 
                daysLeft: Math.floor(moment(notification.campaign.dateEnd).diff(moment(notification.campaign.dateStart), 'days') / 2)
            }
        }
    }

    return <p dangerouslySetInnerHTML={{__html: t(template, notification)}} />
}
