import React from 'react';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next'

const customStyles = {
    overlay: {
        background: 'rgb(26,26,26,0.75)',
        zIndex: 9999
    },
    content: {
        backgroundColor: '#2E2E2E',
        border: 'none',
        borderRadius: '8px',
        color: '#F1F1F1',
        margin: 'auto',
        width: '50%',
        height: '75%',
    }
};

const TermsConditionsModal = ({ modal, setModal }) => {
    const { t } = useTranslation();

    return (
        <Modal
            isOpen={modal}
            style={customStyles}
        >
            <div className="modal-offer-close">
                <button type="button" className="btn-tertiary" onClick={() => setModal(false)}>
                    <FontAwesomeIcon icon="times" />
                </button>
            </div>
            <div className="modal-terms-header">
                <div>
                    <span>{t("modal.privacy.title")}</span>
                </div>
            </div>
            <div className="modal-terms-content">
                <div>
                    <strong>{t("modal.privacy.updated")}</strong>
                </div>
                <div>
                    {t("modal.privacy.content.1")}
                </div>
                <div>
                    {t("modal.privacy.content.2")}
                </div>
                <div>
                    <strong>{t("modal.privacy.content.3")}</strong>
                </div>
                <div>
                    {t("modal.privacy.content.4")}
                </div>
                <div>
                    {t("modal.privacy.content.5")}
                </div>
                <div>
                    {t("modal.privacy.content.6")}
                </div>
                <div>
                {t("modal.privacy.content.7")}
                    <br></br>{t("modal.privacy.content.8")}<br></br>
                    <br></br>{t("modal.privacy.content.9")}<br></br>
                </div>
                <div>
                    <strong>{t("modal.privacy.content.10")}</strong>
                </div>
                <div>
                    {t("modal.privacy.content.11")}<br></br>
                    <br></br>{t("modal.privacy.content.12")}<br></br>
                    <br></br>{t("modal.privacy.content.13")}<br></br>
                    <br></br>{t("modal.privacy.content.14")}<br></br>
                    <br></br>{t("modal.privacy.content.15")}<br></br>
                    <br></br>{t("modal.privacy.content.16")}<br></br>
                    <br></br>{t("modal.privacy.content.17")}<br></br>
                    <br></br>{t("modal.privacy.content.18")}<br></br>
                </div>
                <div>
                    {t("modal.privacy.content.19")}
                </div>
                <div>
                    {t("modal.privacy.content.20")}
                </div>
                <div>
                    {t("modal.privacy.content.21")}
                </div>
                <div>
                    {t("modal.privacy.content.22")}
                </div>
                <div>
                    {t("modal.privacy.content.23")}
                </div>
                <div>
                    <strong>{t("modal.privacy.content.54")}</strong>
                </div>
                <div>
                    {t("modal.privacy.content.24")}
                </div>
                <div>
                    <strong>{t("modal.privacy.content.25")}</strong>
                </div>
                <div>
                    {t("modal.privacy.content.26")}
                </div>
                <div>
                    <strong>{t("modal.privacy.content.27")}</strong>
                </div>
                <div>
                    {t("modal.privacy.content.28")}
                </div>
                <div>
                    <strong>{t("modal.privacy.content.29")}</strong>
                </div>
                <div>
                    {t("modal.privacy.content.30")}
                </div>
                <div>
                    <strong>{t("modal.privacy.content.31")}</strong>
                </div>
                <div>
                    {t("modal.privacy.content.32")}
                </div>
                <div>
                    <strong>{t("modal.privacy.content.33")}</strong>
                </div>
                <div>
                    {t("modal.privacy.content.34")}
                </div>
                <div>
                    {t("modal.privacy.content.35")}
                </div>
                <div>
                    <strong>{t("modal.privacy.content.36")}</strong>
                </div>
                <div>
                    {t("modal.privacy.content.37")}
                </div>
                <div>
                    <strong>{t("modal.privacy.content.38")}</strong>
                </div>
                <div>
                    {t("modal.privacy.content.39")}
                </div>
                <div>
                    <strong>{t("modal.privacy.content.40")}</strong>
                </div>
                <div>
                    {t("modal.privacy.content.41")}
                </div>
                <div>
                    <strong>{t("modal.privacy.content.42")}</strong>
                </div>
                <div>
                    {t("modal.privacy.content.43")}
                </div>
                <div>
                    <strong>{t("modal.privacy.content.44")}</strong>
                </div>
                <div>
                    {t("modal.privacy.content.45")}
                </div>
                <div>
                    <strong>{t("modal.privacy.content.46")}</strong>
                </div>
                <div>
                    {t("modal.privacy.content.47")}
                </div>
                <div>
                    <strong>{t("modal.privacy.content.48")}</strong>
                </div>
                <div>
                    {t("modal.privacy.content.49")}
                </div>
                <div>
                    <strong>{t("modal.privacy.content.50")}</strong>
                </div>
                <div>
                    {t("modal.privacy.content.51")}
                </div>
                <div>
                    <strong>{t("modal.privacy.content.52")}</strong>
                </div>
                <div>
                    {t("modal.privacy.content.53")}
                </div>
                <div>
                    <strong>{t("modal.privacy.content.54")}</strong>
                </div>
                <div>
                    {t("modal.privacy.content.55")}
                </div>
                <div>
                    {t("modal.privacy.content.56")}
                </div>
                <div>
                <strong>{t("modal.privacy.content.57")}</strong>
                </div>
                <div>
                    {t("modal.privacy.content.58")}
                </div>
                <div>
                    {t("modal.privacy.content.59")}
                </div>
                <div>
                    <strong>{t("modal.privacy.content.60")}</strong>
                </div>
                <div>
                    {t("modal.privacy.content.61")}
                </div>
                <div>
                    <strong>{t("modal.privacy.content.62")}</strong>
                </div>
                <div>
                    {t("modal.privacy.content.63")}
                </div>
                <div>
                    {t("modal.privacy.content.64")}
                </div>
                <div>
                    {t("modal.privacy.content.65")}
                </div>
                <div>
                    {t("modal.privacy.content.65")}
                </div>
                <div>
                    <strong>{t("modal.privacy.content.66")}</strong>
                </div>
                <div>
                    {t("modal.privacy.content.67")}
                </div>
            </div>
        </Modal>
    )
}

export default TermsConditionsModal;
