import create from 'zustand';

export const useCampaignManageStore = create(set => ({
    deliverables:{
        contentModal:{
            creator:{
                name: '',
                photoUrl: '',
            },
            src: '',
            caption:''
        }
    },
    setDeliverables: (deliverables) => set({ deliverables }),
}));