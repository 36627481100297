export const timeOptions = [
    {
        value:1,
        text:"modal.offer.advanced.one",
    },
    {
        value:3,
        text:"modal.offer.advanced.thr",
    },
    {
        value:6,
        text:"modal.offer.advanced.six",
    },
    {
        value:12,
        text:"modal.offer.advanced.twel",
    },
    // {
    //     value:618,
    //     text:"modal.offer.advanced.eig",
    // },
    {
        value:24,
        text:"modal.offer.advanced.twen",
    },
    {
        value:1200,
        text:"modal.offer.advanced.perp",
    }
]