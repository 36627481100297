import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next'
import validator from 'validator';
import Tooltip from '@material-ui/core/Tooltip';
import Loading from '../../../Global/Loading/Icon';
import { useOnboardingStore } from '../../../../hooks';

const rootclass = 'onboarding';

const Login = ({ me, isAuth, windowWidth, setStep }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const setYourSigninStore = useOnboardingStore(state => state.setSignIn);
    const [loading, setLoading] = useState(false);
    const [values, setValues] = useState({
        email: '',
        password: ''
    });
    const [validated, setValidated] = useState(false);
    const [errors, setErrors] = useState();
    const [submitted, setSubmitted] = useState(false);
    const [serverError, setServerError] = useState('');

    useEffect(() => {
        function checkLogin() {
            if (isAuth && me !== null) {
                if (windowWidth <= 767) {
                    history.push('/messages');
                } else {
                    //history.push(localStorage.getItem('currentPath'));
                }
            }
        }
        checkLogin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuth, me, windowWidth])

    useEffect(() => {
        if (submitted) {
            setErrors(Validate(values))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [submitted, values])

    useEffect(() => {
        submitSignIn();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [submitted, validated])

    useEffect(() => {
        if (serverError.length > 0) {
            setErrors({
                email: serverError,
                password: null
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [serverError])

    useEffect(()=>{
        setValidated(false);
        setSubmitted(false);
    }, [values])

    const handleSignin = (e) => {       
        e.preventDefault();  
        setSubmitted(true);
        setLoading(true);
    }

    const handleSignup = () => {
        history.push('/signup');
    }

    const handleChange = (e) => {
        setLoading(false);
        const { name, value } = e.target
        setValues({
            ...values,
            [name]: value,
        });
        setServerError('')
        if (validated) {
            setErrors(Validate(values))
        }
    }

    const Validate = (values) => {

        let errors = { email: null, password: null }

        if (!validator.isEmail(values.email)) {
            errors.email = t("onboarding.screen.1.errors.email");
            setLoading(false);
        } else if (!values.password) {
            errors.password = t("onboarding.screen.1.errors.password");
            setLoading(false);
        } else {
            setValidated(true);
        }
        return errors
    }

    const submitSignIn = async () => {
        const { email, password } = values
        let signInFormData = new FormData();
        signInFormData.append("email", email);
        signInFormData.append("password", password);

        if (submitted && validated) {
            let response = await new Promise(resolve => {
                let request = new XMLHttpRequest();
                request.open("POST", process.env.REACT_APP_SIGN_IN_ENDPOINT);
                request.onload =  () => {
                    resolve(JSON.parse(request.response))
                }
                request.onerror = () => {
                    resolve(undefined);
                    setServerError(t("onboarding.screen.1.errors.invalid"));
                }
                request.send(signInFormData);
            });

            //console.log(response);
            
            if(response.message){
                setServerError(response.message);
                setLoading(false);
            } else if(response?.requirements?.includes('verify_email')){
                setYourSigninStore({
                    stored:true,
                    token: response?.token,
                    email: email
                });
                setStep(2);
            } else if (response?.requirements?.includes('wait_for_approval')){
                setStep(3);
            } else if (response?.requirements?.includes('create_organization')){
                setStep(4);
                localStorage.setItem('token', response?.token);
            } else {
                localStorage.setItem('token', response?.token);
                window.location = '/'
            }
        }
    }       
    
    return (
        <>
            <h1>{t("onboarding.screen.1.title")}</h1>
            <label>
                <Tooltip arrow open placement="left" title={errors && errors.email ? errors.email : ""} >
                    <div className={errors && errors.email ? "form-input form-input-error" : "form-input"}>
                        <FontAwesomeIcon icon="envelope" />
                        <input
                            id="email"
                            type="email"
                            name="email"
                            placeholder={t("onboarding.screen.1.email")}
                            value={values.email}
                            onChange={handleChange}
                            required />
                        {errors && errors.email && <FontAwesomeIcon icon="times-circle" className="form-error-icon" />}
                    </div>
                </Tooltip>
            </label>
            <label>
                <Tooltip arrow open placement="left" title={errors && errors.password ? errors.password : ""} >
                    <div className={errors && errors.password ? "form-input form-input-error" : "form-input"}>
                        <FontAwesomeIcon icon="ellipsis-h" />
                        <input
                            id="password"
                            type="password"
                            name="password"
                            placeholder={t("onboarding.screen.1.password")}
                            value={values.password}
                            onChange={handleChange}
                            required />
                        {errors && errors.password && <FontAwesomeIcon icon="times-circle" className="form-error-icon" />}
                    </div>
                </Tooltip>
            </label>

            {!loading ?
                <div>
                    <button className="btn-primary" onClick={handleSignin}>{t("onboarding.screen.1.signIn")}</button>
                </div> :
                <div className={`${rootclass}-signInBtn`}>
                    <Loading rootclass={rootclass} />
                </div>
            }
            <Link to={`/password-recover`}>
                <p className={`${rootclass}-forgot`}>{t("onboarding.screen.1.forgot")}</p>
            </Link>

            <hr></hr>

            {/* ***** ENHANCEMENT ***** */}
            {/* <div className={`${rootclass}-linebreak`}><span>OR</span></div>
            <button className="btn-fb">Sign in with Facebook <FontAwesomeIcon icon={['fab', 'facebook-square']} className="icon-social" /></button>
            <button className="btn-primary"><FontAwesomeIcon icon={['fab', 'google']} /> Sign in with Google</button> */}

            <div className={`${rootclass}-txt`}>
                {t("onboarding.screen.1.member")} {t("onboarding.screen.1.signUpAtnmy")}
            </div>
            <button onClick={handleSignup} className="btn-secondary">{t("onboarding.screen.1.signUp")}</button>
        </>
    )
}

export default Login