import create from 'zustand';

export const useStore = create(set => ({
    isAuth: (localStorage.getItem('token') ? true : false),
    isAdminAuth: (sessionStorage.getItem('adminToken') ? true : false),
    userId: null,
    cometChatUserLoggedIn: false,
    setIsAuth: (isAuth) => set({ isAuth }),
    setIsAdminAuth: (isAdminAuth) => set({ isAdminAuth }),
    setUserId: (userId) => set({ userId }),
    setCometChatUserLoggedIn: (cometChatUserLoggedIn)=> set({ cometChatUserLoggedIn }),
    imgError: (image)=> {image.target.src = '/placeholder-profile.png'},
    instaImgError: (image)=> {image.target.src = '/placeholder-post.png'}
}))