import React, { useState, useEffect } from 'react';
import Dropzone from 'react-dropzone';
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next'

Modal.setAppElement(document.getElementById('root'))

const imageMaxSize = 100000000 //bytes
const acceptedFileTypes = 'image/x-png, image/png, image/jpg, image/jpeg, image/gif'
const acceptedFileTypesArray = acceptedFileTypes.split(",").map((item) => { return item.trim() })

const ImageCropper = ({ rootclass, image, setImage, setFile, aspectRatio, className, placeholder, title }) => {
    const { t } = useTranslation();
    const [cropData, setCropData] = useState(image);
    const [cropper, setCropper] = useState();
    const [modal, setModal] = useState(false);
    const [customStyles, setCustomStyles ]= useState()
    const [cropperSize, setCropperSize] = useState()
    const [ windowWidth ] = useState(window.innerWidth)

    useEffect(() => {
        if (windowWidth >= 426){
            setCustomStyles({
                overlay: {
                    background: 'rgb(26,26,26,0.75)',
                },
                content: {
                    width: '50%',
                    height: '75%',
                    margin: 'auto',
                    backgroundColor: '#2E2E2E'
                }
            })
            setCropperSize({ height: 400, width: "100%" })
        }

        if (425 >= windowWidth){
            setCustomStyles({
                overlay: {
                    background: 'rgb(26,26,26,0.75)',
                },
                content: {
                    position: 'absolute',
                    inset: '1px',
                    width: '90%',
                    height: '60%',
                    margin: '140px 20px 20px 20px',
                    padding: '0px',
                    backgroundColor: '#2E2E2E'
                }
            })
            setCropperSize({ height: 300, width: "100%" })
        }

    }, [windowWidth])

    const verifyFile = (files) => {
        if (files && files.length > 0) {
            const currentFile = files[0]
            const currentFileType = currentFile.type
            const currentFileSize = currentFile.size
            if (currentFileSize > imageMaxSize) {
                alert(t("modal.image.size.1") + currentFileSize + t("modal.image.size.2"))
                return false
            }
            if (!acceptedFileTypesArray.includes(currentFileType)) {
                alert(t("modal.image.type"))
                return false
            }
            return true
        }
    }

    const handleOnDrop = (files, rejectedFiles) => {
        if (rejectedFiles && rejectedFiles.length > 0) {
            verifyFile(rejectedFiles)
        }
        if (files && files.length > 0) {
            const isVerified = verifyFile(files)
            if (isVerified) {
                const currentFile = files[0];
                setFile(currentFile);
                const reader = new FileReader();
                reader.addEventListener("load", () => {
                    setImage(reader.result)
                }, false)

                reader.readAsDataURL(currentFile)
                setModal(true)
            }
        }
    }

    const getCropData = () => {
        if (typeof cropper !== "undefined") {
            setCropData(cropper.getCroppedCanvas().toDataURL());
            setImage(cropper.getCroppedCanvas().toDataURL())
            setModal(false)
        }
    };
    
    return (
        <>
            <Dropzone onDrop={handleOnDrop} multiple={false}>
                {({ getRootProps, getInputProps }) => (
                    <section >
                        <div {...getRootProps()} className={className}>
                            <input {...getInputProps()} />
                            {
                                className === "form-input-file-upload"
                                    ? (image !== null && cropData
                                        ? <div>
                                            <img src={cropData} alt="uploaded logo" />
                                        </div>
                                        : <div className="upload-placeholder">{t("modal.image.upload")}</div>)
                                    : (image !== null && cropData
                                        ? <div>
                                            <img src={image} alt="uploaded logo" />
                                        </div>
                                        : placeholder)
                            }
                        </div>
                    </section>
                )}
            </Dropzone>
            <Modal
                isOpen={modal}
                style={customStyles}
            >
                <div className="modal-upload-header">
                    <div>
                        <button type="button" className="btn-tertiary" onClick={() => setModal(false)}>
                            <FontAwesomeIcon icon="times" />
                        </button>
                        <span>{title}</span>
                    </div>
                    <button className="btn-primary" onClick={getCropData}>{t("modal.image.apply")}</button>
                </div>
                <div className={`${rootclass}-cropper`}>   
                    <Cropper
                        aspectRatio={aspectRatio}
                        style={cropperSize}
                        initialAspectRatio={1}
                        src={image}
                        viewMode={1}
                        guides={false}
                        background={false}
                        responsive={true}
                        checkOrientation={false}
                        onInitialized={(instance) => {
                            setCropper(instance);
                        }}
                    />
                </div>

            </Modal>
        </>
    )
}

export default ImageCropper
