import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';
import Modal from 'react-modal';
import { useCampaignsOfferModalQuery } from '../../../../types/withhooks'
import { useCampaignCreate } from '../../../../hooks';
import Navigation from './Navigation'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { customStyles } from './customStyles';
import { advancedOptions } from './options';
import { timeOptions } from './timeOptions'
import { useTranslation } from 'react-i18next'
import Loading from '../../Loading/Icon'

const rootclass='modal-offer';

const CampaignOfferModal = ({ influencer, campaign, setUpdate, update, modal, setModal }) => {
    const { t } = useTranslation();
    const bottomOfferModal = useRef(null);
    const history = useHistory();
    const [step, setStep] = useState(0);
    const [advanced, setAdvanced] = useState(false);
    const [application, setApplication]=useState([]);
    const [offer, setOffer]=useState([]);
    const [liveCampaign, setLiveCampaign]= useState({})
    const [campaigns, setCampaigns]=useState([]);
    const [deliverables, setDeliverables]=useState();
    const [storyContent,setStoryContent]=useState({});
    const [postContent,setPostContent]=useState({});
    const [videoContent,setVideoContent]=useState({});
    const [deliverablesData, setDeliverablesData]=useState([]);
    const { createCampaign, campaignId } = useCampaignCreate();
    const { data: campaignOfferModalData, loading: loadingCampaignOfferModalData } = useCampaignsOfferModalQuery({
        variables:{
            appProfileId:{
                profileId: influencer?.id
            },
            offProfileId:{
                profileId: influencer?.id
            }
        }
    });

    const [values, setValues] = useState({
        productPaymentOfferCents: 0,
        storeCreditPaymentOfferCents: 0,
        cashPaymentOfferCents: 0,
        instaPhotoDeliverable: 0,
        instaVideoDeliverable: 0,
        instaStoryDeliverable: 0,
        generalRequest: '',
        advanced:[]
    });

    const payment = [
        application[0]?.acceptsPayByProduct && t("modal.pitch.product"),
        application[0]?.acceptsPayByStoreCredit && t("modal.pitch.credit"),
        application[0]?.acceptsPayByCash && t("modal.pitch.cash")
    ].filter(x => x !== false);

    useEffect(() => {
        if(campaignOfferModalData?.currentUser?.brands?.nodes[0]?.campaigns?.nodes){
            setCampaigns(campaignOfferModalData.currentUser.brands.nodes[0].campaigns.nodes)
        }
    }, [campaignOfferModalData])

    useEffect(() => {
        let camp;
        if(campaigns){
            camp = campaigns.filter(filteredCampaign=>{
                 return filteredCampaign.id === campaign?.id
            });
        }
        setLiveCampaign(camp[0])
    }, [campaign, campaigns, modal]);

    useEffect(() => {
        if(liveCampaign?.applications?.nodes){
            setApplication(liveCampaign.applications.nodes);
        }
        if(liveCampaign?.offers?.nodes){
            setOffer(liveCampaign.offers.nodes);
        }
    }, [liveCampaign, modal]);

    useEffect(() => {
        if (liveCampaign?.offers?.nodes?.length >0){
            setDeliverablesData(liveCampaign.offers.nodes[0].deliverableDescriptions)
        } else if (liveCampaign?.applications?.nodes?.length >0){
            setDeliverablesData(liveCampaign.applications.nodes[0].campaign.deliverableDescriptions)
        } else {
            setDeliverablesData(liveCampaign?.deliverableDescriptions)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [liveCampaign]);

    useEffect( () => {
        if(deliverablesData){
            let selectedAges;
            deliverablesData?.map((deliverable,i)=>{
                if(deliverable.type === "post"){
                    setPostContent ({
                        hashtags: deliverable.metadata?.hashtags || null,
                        linkedAccounts:  deliverable.metadata?.linkedAccounts || null
                    })
                }
                if(deliverable.type === "video_post"){
                    setVideoContent({
                        hashtags: deliverable.metadata?.hashtags || null,
                        linkedAccounts:  deliverable.metadata?.linkedAccounts || null
                    })
    
                }
                if(deliverable.type === "story"){
                    setStoryContent({
                            hashtags: deliverable.metadata.hashtags || null ,
                            linkedAccounts: deliverable.metadata.linkedAccounts || null,
                            swipeUpLinks: deliverable.metadata.swipeUpLinks || null,
                            pollQuestions: deliverable.metadata.pollQuestions || null,
                            pollAnswers: deliverable.metadata.pollAnswers ||  null,
                            askAQuestion: deliverable.metadata.askAQuestion || null
                    })
                }
                return null;
            }); 
    
            setDeliverables({
                targetAgeGroups: selectedAges,
                instaPhotoDeliverable: {
                    quantity: Number(deliverablesData?.filter(del => del.type === "post").length),
                    hashtags: postContent.hashtags || [],
                    linkedAccounts: postContent.linkedAccounts || []
                },
                instaVideoDeliverable: {
                    quantity: Number(deliverablesData?.filter(del => del.type === "video_post").length),
                    hashtags: videoContent.hashtags || [],
                    linkedAccounts: videoContent.linkedAccounts || []
                },
                instaStoryDeliverable: {
                    quantity: Number(deliverablesData?.filter(del => del.type === "story").length),
                    hashtags: storyContent.hashtags || [],
                    linkedAccounts: storyContent.linkedAccounts || [],
                    swipeUpLinks: storyContent.swipeUpLinks || [],
                    pollQuestions: storyContent.pollQuestions || [],
                    pollAnswers: storyContent.pollAnswers || [],
                },
                generalRequest: {
                    text: deliverablesData.filter(del => del.type === "general")[0]?.metadata?.text || ''
                },
                dueDate: null
            })
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deliverablesData])

    useEffect(() => {
        if(liveCampaign && deliverables && offer.length>0){
            setValues({
                productPaymentOfferCents: Number(offer[0]?.paymentProductCents/100),
                storeCreditPaymentOfferCents: Number(offer[0]?.paymentStoreCreditCents/100),
                cashPaymentOfferCents: Number(offer[0]?.paymentCashCents/100),
                instaPhotoDeliverable: deliverables.instaPhotoDeliverable.quantity,
                instaVideoDeliverable: deliverables.instaVideoDeliverable.quantity,
                instaStoryDeliverable: deliverables.instaStoryDeliverable.quantity,
                generalRequest: deliverables.generalRequest.text,
                advanced: []
            })

        } else if (liveCampaign && deliverables && offer.length === 0 && application.length >0 ){
            setValues({
                productPaymentOfferCents: 0,
                storeCreditPaymentOfferCents: 0,
                cashPaymentOfferCents: 0,
                instaPhotoDeliverable: deliverables.instaPhotoDeliverable.quantity,
                instaVideoDeliverable: deliverables.instaVideoDeliverable.quantity,
                instaStoryDeliverable: deliverables.instaStoryDeliverable.quantity,
                generalRequest: deliverables.generalRequest.text,
                advanced: []
            })

        } else if (liveCampaign && deliverables && offer.length === 0 && application.length === 0) {
            setValues({
                productPaymentOfferCents: 0,
                storeCreditPaymentOfferCents: 0,
                cashPaymentOfferCents: 0,
                instaPhotoDeliverable: deliverables.instaPhotoDeliverable.quantity,
                instaVideoDeliverable: deliverables.instaVideoDeliverable.quantity,
                instaStoryDeliverable: deliverables.instaStoryDeliverable.quantity,
                generalRequest: deliverables.generalRequest.text,
                advanced: []
            })
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [application, offer, liveCampaign, deliverables]);

    useEffect(() => {
        if(offer.length >0 && offer[0]?.exclusivityMonths !==0 ){
            values.advanced.push(
                {
                label: "Exclusivity",
                tooltip: "The amount of time after your business has concluded with this influencer, that the influencer may not partner or produce content for a competitor brand in your immediate area of competition",
                length: offer[0]?.exclusivityMonths,
                id: "ex"
            })
        }
        if(offer.length >0 && offer[0]?.usageRightsMonths !==0 ){
            values.advanced.push(
                {
                    label: "Usage Rights",
                    tooltip: "The amount of time after your business has concluded with this influencer, that you as a business are able to use their content in your own posts on social media or other marketing materials.",
                    length:offer[0]?.usageRightsMonths,
                    id: "ur"
                })
        }
        if(values?.advanced?.length>0){
            setAdvanced(true);
        } else {
            return
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values])

    useEffect(() => {
        if (campaignId) {
            history.push('/edit-campaign/' + campaignId);
        }
    }, [campaignId, history])

    useEffect(() => {
        scrollToBottom();
      }, [advanced]);

    const postAdCampaign = async () => {
        await createCampaign()
    }

    const handleSelectAdvanced =(option)=> {
        if(values.advanced.length>0 && values?.advanced.some(selected => option.id === selected.id)){
            let newOptions = values.advanced.filter(oldOption => oldOption.id !== option.id);
            setValues({ ...values, advanced: newOptions })
        } else {
            setValues({...values})
            values.advanced.push(option)
        }
    }

    const handleTimeSelect=(e, option)=>{
        let newOption = values.advanced.filter(oldOption => oldOption.id !== option.id);
        newOption.push({
            label: option.label,
            tooltip: option.tooltip,
            length: Number(e.target.value),
            id: option.id
        })
        setValues({
            ...values,
            advanced:newOption
        })
    }

    const handleAdvancedToggle = () =>{
        setAdvanced(!advanced)
    }

    const scrollToBottom = () => {
        bottomOfferModal.current?.scrollIntoView({ behavior: "smooth" })
    }

    const closeModal = () => {
        setModal(false);
        setStep(0);
        setOffer([]);
        setApplication([]);
    }

    return (
        <Modal isOpen={modal} style={customStyles} >
            <div className={`${rootclass}-close`}>
                <button type="button" className="btn-tertiary" onClick={()=>setModal(false)}> <FontAwesomeIcon icon="times" /></button>
            </div>
            <div className={`${rootclass}-header`}>
                <img src={influencer?.profilePictureUrl ? influencer?.profilePictureUrl : '/placeholder-profile.png'} alt={`${influencer?.profile?.displayName} profile`} />
                <div>
                    <span>{t("modal.offer.offer")} {influencer?.fullName || influencer?.displayName ? influencer?.fullName || influencer?.displayName : '[No name]'}</span>
                    {application.length>0 && step === 1 && <>{t("modal.offer.fee")}{' '}<strong>${application[0]?.paymentEstimateCents/100}</strong> {' '}| {t("modal.offer.payment")} {' '} <strong>{payment.filter(i => i !== false).join(', ')}</strong></>}
                    {application.length === 0 && step === 1 && <strong>{t("modal.offer.noAppNoOff")}</strong>}
                    {/* {noApplication && !noOffer && step === 1 && <strong>{t("modal.offer.noAppUpdateOff")}</strong>} */}
                </div>
            </div>
            <div className={`${rootclass}-breadcrumbs`}>
                {step === 0 && <> <span>{t("modal.offer.campSelect")} </span> <FontAwesomeIcon icon="chevron-right" /> {t("modal.offer.infOff")}</>}
                {step === 1 && <> {t("modal.offer.campSelect")} <FontAwesomeIcon icon="chevron-right" /> <span>{t("modal.offer.infOff")}</span></>}
            </div>
            {step === 0 &&
                <>
                    {!loadingCampaignOfferModalData ?
                    <div className={`${rootclass}-selection`}>
                        {campaigns?.filter(campaign=> campaign.isPublished || campaign.applications.nodes.length>0 || campaign.offers.nodes.length>0)?.map((c, i) =>
                            <div
                                key={i}
                                tabIndex="0"
                                data-value={c}
                                onClick={() =>{ 
                                    setLiveCampaign(c)
                                }}
                                className={liveCampaign?.name === c.name && liveCampaign?.id === c.id ? 'modal-offer-selection-selected' : 'modal-offer-selection-tile'}
                            >
                                <span>{c.name}<FontAwesomeIcon icon='check-circle' className={liveCampaign?.name === c.name && liveCampaign?.id === c.id  ? 'check-selected' : 'hidden'} /></span>
                                {t("modal.offer.starting")} {moment(c.dateStart).format("MMM DD, YYYY")} {t("modal.offer.to")} {moment(c.dateEnd).format("MMM DD, YYYY")}
                            </div>
                        )}
                        {campaigns.length ===0 &&
                            <div className={`${rootclass}-selection-message`}>
                                <strong>{t("modal.offer.noCamp")}</strong>
                                <button className="btn-primary" onClick={postAdCampaign}>{t("modal.offer.post")}</button>
                            </div>
                        }
                    </div>
                    :
                        <Loading rootclass={rootclass} />
                    }
                </>}

            {step === 1 &&
                <>
                    <div className={`${rootclass}-selection`}>
                        <strong className={`${rootclass}-selection-title`}>{t("modal.offer.paymentOptions")}</strong>
                        
                        {/* Pay with cash amount */}
                        <label>
                            <p>{t("modal.offer.cash")}</p>
                            <div className="form-input" id="offer-form">
                                <FontAwesomeIcon icon="money-bill" />
                                <input 
                                    type='number' 
                                    id="paymentPerPostCents" 
                                    value={values?.cashPaymentOfferCents === 0 ? '' : values?.cashPaymentOfferCents} 
                                    onChange={e => setValues({ ...values, cashPaymentOfferCents: Number(e.target.value) })} 
                                    placeholder="Direct Payment" 
                                    min="0"
                                    max="999999999" />
                            </div>
                        </label>

                        {/* Pay with product amount*/}
                        <label>
                            <p>{t("modal.offer.product")}</p>
                            <div className="form-input" id="offer-form">
                                <FontAwesomeIcon icon="gift" />
                                <input 
                                    type='number' 
                                    id="paymentByProductCents" 
                                    value={values?.productPaymentOfferCents === 0 ? '' : values?.productPaymentOfferCents} 
                                    onChange={e => setValues({ ...values, productPaymentOfferCents: Number(e.target.value) })} 
                                    placeholder="Payment by product" 
                                    min="0" 
                                    max="999999999" 
                                />
                            </div>
                        </label>

                        {/* Pay with credit amount */}
                        <label>
                            <p>{t("modal.offer.credit")}</p>
                            <div className="form-input" id="offer-form">
                                <FontAwesomeIcon icon="credit-card" />
                                <input 
                                    type='number' 
                                    id="storeCreditCents" 
                                    value={values?.storeCreditPaymentOfferCents === 0 ? '' : values?.storeCreditPaymentOfferCents} 
                                    onChange={e => setValues({ ...values, storeCreditPaymentOfferCents: Number(e.target.value) })} 
                                    placeholder="Store Credit" 
                                    min="0" 
                                    max="999999999"
                                />
                            </div>
                        </label>

                        {/* Number of insta posts */}
                        <label>
                            <strong>{t("modal.offer.instaPost")}</strong>
                            <div className="form-input counter-container">
                                <div> {values?.instaPhotoDeliverable} </div>
                                <div className="counter-btn-container">
                                    <button
                                        onClick={() => setValues({ ...values, instaPhotoDeliverable: values?.instaPhotoDeliverable - 1 })}
                                        disabled={values?.instaPhotoDeliverable === 0}
                                        className="btn-tertiary">
                                        <FontAwesomeIcon icon="minus" />
                                    </button>
                                    <button
                                        onClick={() => setValues({ ...values, instaPhotoDeliverable: values?.instaPhotoDeliverable + 1 })}
                                        className="btn-tertiary">
                                        <FontAwesomeIcon icon="plus" />
                                    </button>
                                </div>
                            </div>
                        </label>

                        {/* Number of insta videos */}
                        <label>
                            <strong>{t("modal.offer.instaVideo")}</strong>
                            <div className="form-input counter-container">
                                <div>{values?.instaVideoDeliverable} </div>
                                <div className="counter-btn-container">
                                    <button
                                        onClick={() => setValues({ ...values, instaVideoDeliverable: values?.instaVideoDeliverable - 1 })}
                                        disabled={values?.instaVideoDeliverable === 0}
                                        className="btn-tertiary">
                                        <FontAwesomeIcon icon="minus" />
                                    </button>
                                    <button
                                        onClick={() => setValues({ ...values, instaVideoDeliverable: values?.instaVideoDeliverable + 1 })}
                                        className="btn-tertiary">
                                        <FontAwesomeIcon icon="plus" />
                                    </button>
                                </div>
                            </div>
                        </label>

                        {/* Number of insta stories */}
                        <label>
                            <strong>{t("modal.offer.instaStory")}</strong>
                            <div className="form-input counter-container">
                                <div> {values?.instaStoryDeliverable}</div>
                                <div className="counter-btn-container">
                                    <button
                                        onClick={() => setValues({ ...values, instaStoryDeliverable: values?.instaStoryDeliverable - 1 })}
                                        disabled={values?.instaStoryDeliverable === 0}
                                        className="btn-tertiary">
                                        <FontAwesomeIcon icon="minus" />
                                    </button>
                                    <button
                                        onClick={() => setValues({ ...values, instaStoryDeliverable: values?.instaStoryDeliverable + 1 })}
                                        className="btn-tertiary">
                                        <FontAwesomeIcon icon="plus" />
                                    </button>
                                </div>
                            </div>
                        </label>

                        {/* General Request */}
                        <div className={`${rootclass}-selection-general`}>
                            <strong>{t('campaignLaunch.step5.general.title')}</strong>
                            <label>
                                <textarea
                                    name='generalRequest'
                                    value={values?.generalRequest}
                                    onChange={e => setValues({ ...values, generalRequest: e.target.value })}
                                    type="text"
                                    placeholder={t('campaignLaunch.step5.general.example')}
                                    maxLength="1000"
                                />
                                <p className='textarea-count'>{values?.generalRequest?.length || 0}/1000</p>
                            </label>
                        </div>
                        
                        <div className={`${rootclass}-advanced`}>
                            <div className={`${rootclass}-advanced-toggle`} onClick={offer.length === 0 && handleAdvancedToggle}>
                                <FontAwesomeIcon icon="angle-down" />
                                <p>{t('modal.offer.advanced.title')}</p>
                            </div>
                            
                            {advanced && <div className={`${rootclass}-advanced-options`}>
                                {advancedOptions.map((advancedOption, i)=>{
                                    return(
                                        <div className={`${rootclass}-advanced-options-item`}>
                                            <div className={`${rootclass}-advanced-options-content`} onClick={() => offer.length === 0 && handleSelectAdvanced(advancedOption)}  key={i}>
                                                {values?.advanced.some(selected => advancedOption.id === selected.id) ?
                                                    <>
                                                        <span ><FontAwesomeIcon icon={"square"} /> {t(advancedOption.label)}</span>
                                                    </>
                                                    :
                                                    <>
                                                        <span><FontAwesomeIcon icon={["far", "square"]} /> {t(advancedOption.label)}</span>

                                                    </>
                                                }
                                            </div>
                                            {values?.advanced.some(selected => advancedOption.id === selected.id) &&
                                                <select name="select" disabled={offer.length > 0} value={
                                                    values.advanced.map(selected => {
                                                        let value;
                                                        if (selected.id === advancedOption.id){
                                                            value = selected.length
                                                        }
                                                        //console.log(value)
                                                        return value
                                                    }).filter( Number )[0]
                                                } className={`${rootclass}-advanced-options-select`} onChange={(e) =>  handleTimeSelect(e, advancedOption)}>
                                                
                                                {timeOptions.map((option,i) => {
                                                    return <option key={i} value={option.value} className={`${rootclass}-title-sort-option`}>{t(option.text)}</option>
                                                })}
                                                
                                            </select>}
                                            <Tooltip arrow placement="right" title={t(advancedOption.tooltip)} >
                                                <img src='/info_icon.png' alt="more info if you hover mouse here"></img>
                                            </Tooltip>
                                        </div>
                                    )
                                })}
                            </div>}
                        </div>
                        <div ref={bottomOfferModal} />
                    </div>
                </>}
                
                <Navigation 
                    rootclass={rootclass}
                    campaigns={campaigns} 
                    campaign={liveCampaign} 
                    application={application} 
                    offer={offer} 
                    values={values} 
                    step={step} 
                    influencer={influencer}
                    setStep={setStep} 
                    setAdvanced={setAdvanced}
                    closeModal={closeModal} 
                    setUpdate={setUpdate}
                    deliverables={deliverables}
                />       
        </Modal>
    )
}

export default CampaignOfferModal;
