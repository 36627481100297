let range = [
    {
        highValue: 9999,
        lowValue: 1601,
        label: '<1600px'
    },
    {
        highValue: 1600,
        lowValue: 1501,
        label: '1500<=1600px'
    },
    {
        highValue: 1500,
        lowValue: 1401,
        label: '1400<=1500px'
    },
    {
        highValue: 1400,
        lowValue: 1301,
        label: '1300<=1400px'
    },
    {
        highValue: 1300,
        lowValue: 1201,
        label: '1200<=1300px'
    },
    {
        highValue: 1200,
        lowValue: 1101,
        label: '1100<=1200px'
    },
    {
        highValue: 1100,
        lowValue: 1001,
        label: '1000<=1100px'
    },
    {
        highValue: 1000,
        lowValue: 901,
        label: '900<=1000px'
    },
    {
        highValue: 900,
        lowValue: 801,
        label: '800<=900px'
    },
    {
        highValue: 800,
        lowValue: 701,
        label: '700<=800px'
    },
    {
        highValue: 700,
        lowValue: 601,
        label: '600<=700px'
    },
    {
        highValue: 600,
        lowValue: 501,
        label: '500<=600px'
    },
    {
        highValue: 500,
        lowValue: 401,
        label: '400<=500px'
    },
    {
        highValue: 400,
        lowValue: 301,
        label: '300<=400px'
    },
    {
        highValue: 300,
        lowValue: 201,
        label: '200<=300px'
    },
    {
        highValue: 200,
        lowValue: 101,
        label: '100<=200px'
    },
    {
        highValue: 100,
        lowValue: 0,
        label: '<=100px'
    },
]

export default range