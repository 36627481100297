import React from "react";

export function AbstractNotificationBadge({ message, ...props }) {
    props.className = props.className ? props.className : 'badge-component';
    return (
        <b {...props}>
            {message}
        </b>
    );
}
