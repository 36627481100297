export const customStyles = {
    overlay: {
        background: 'rgb(26,26,26,0.75)',
        zIndex: 9999
    },
    content: {
        backgroundColor: '#2E2E2E',
        border: 'none',
        borderRadius: '8px',
        color: '#F1F1F1',
        margin: '120px auto',
        width: '60%',
        height: '35%',
    }
};