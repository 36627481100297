import create from 'zustand';

export const useSettingsStore = create(set => ({
    yourInfo:{
        true: false,
        image: null,
        brandName: null,
        brandId: null,
        websiteUrl: null,
        about: null,
        email: null,
    },
    yourPaymentInfo:{
        paymentMethodStored: false,
        paymentMethods: [],
        invoicesStored: false,
        invoices: []
    },
    setYourPaymentInfo: (yourPaymentInfo) => set({ yourPaymentInfo }),
    setYourInfo: (yourInfo)=> set({ yourInfo }),
}));