import React, { useState, useEffect, lazy, Suspense  } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { CometChat } from '@cometchat-pro/chat';
import { useStore, useNotificationStore } from './hooks';
import { useCurrentUserQuery } from './types/withhooks';
import { useTranslation } from 'react-i18next';
import { useIdleTimer } from 'react-idle-timer';
import ReactGA from 'react-ga';

// Components import
import PrivateRoute from './components/Global/PrivateRoute';
import UserSignin from './components/UserSignin';
import UserSignup from './components/UserSignup/index.jsx';
import UserPasswordRecovery from './components/UserPasswordRecovery';
import Header from './components/Global/Header';
import Footer from './components/Global/Footer';
import UserHome from './components/UserHome';
import Loading from './components/Global/Loading/Icon';
import SignUpLoading from './components/Global/Loading/SignUp'
import PageNotFound from './components/404Page';
import ErrorBoundary from './components/Global/ErrorBoundary';
import RouteChangeTracker from './components/Analytics/RouteChangeTracker';
import WindowWidthTracker from './components/Analytics/WindowWidthTracker';
import { CometChatUnified } from './components/Messages/CometChatComponents';
import { IsChatMounted } from './components/Notifications/utils/IsChatMounted';

// import SponsorshipCreate from './components/Sponsorship/Create'
// import SponsorshipManage from './components/Sponsorship/Manage'

// Styles and icons
import './styles/main.scss';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';

library.add(fab, fas, far);

var apiKey = process.env.REACT_APP_CHAT_KEY;
var appID = process.env.REACT_APP_CHAT_APPID;
var region = process.env.REACT_APP_CHAT_REGION;
var adminUID = process.env.REACT_APP_COMETCHAT_ADMIN_ID
var appSetting = new CometChat.AppSettingsBuilder().subscribePresenceForAllUsers().setRegion(region).build();

const TRACKING_ID = process.env.REACT_APP_GOOGLE_TRACKING_ID;
ReactGA.initialize(TRACKING_ID);

const App = () => {
	const { i18n, t } = useTranslation();
	const [me, setMe]=useState(undefined);
	const [update, setUpdate]=useState(false);
	const [recoverPassword, setRecoverPassword]=useState(false);
	const isAuth = useStore(state => state.isAuth);
	const isAdminAuth = useStore(state => state.isAdminAuth);
	const setIsAuth = useStore(state => state.setIsAuth);
	const setIsAdminAuth = useStore(state => state.setIsAdminAuth);
	const setUserId = useStore(state => state.setUserId);
	const setCometChatUserLoggedIn = useStore(state => state.setCometChatUserLoggedIn);
	const [windowWidth] = useState(window.innerWidth);
    const { data, loading } = useCurrentUserQuery();

	//Lazy Load Components to reduce bundle size
	const CampaignCreate = lazy(() => import('./components/Campaign/Create'));
	const CampaignManage = lazy(() => import('./components/Campaign/Manage'));
	const Messages = lazy(() => import('./components/Messages'));
	const InfluencerList = lazy(() => import('./components/InfluencerList'));
	const Influencer = lazy(() => import('./components/InfluencerProfile'));
	const UserSettings = lazy(() => import('./components/UserSettings'));
	const AdminSignin = lazy(() => import('./components/AdminSignin'));
	const AdminHome = lazy(() => import('./components/AdminHome'));
	const AdminStats = lazy(() => import('./components/AdminStats'));
	const BrandUpdate = lazy(() => import('./components/UserHome/LinkBrand'));
	const InfluencerUserRedirect = lazy(() => import('./components/InfluencerUserRedirect'));
	
	// Notifications related
	const setHasNewMessages = useNotificationStore(state => state.setHasNewMessages);

	useEffect(()=> {
		let onboarding = localStorage.getItem('onboarding');

		if(data && !onboarding){
			setMe(data?.currentUser);
			setUserId(data?.currentUser?.id);
		};

		return () => {
			setUserId(null);
		};
	}, [data, setUserId]);

	useEffect(() => {
		if(isAuth && !data && !loading){
			setIsAuth(false);
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data, loading, isAuth]);

	useEffect(() => {
        if(update){
            setUpdate(false);
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [update]);

	useEffect(() => {
		if(isAdminAuth) {
			async function adminCometChatLogin () {

				let initialized = new Promise ((resolve, reject) => {
                    CometChat.init(appID, appSetting).then(
                        () => {
                            resolve("CometChat initialization completed successfully");
                        },
                        error => {
                            reject("Initialization failed with error:", error)
                        }
                    );
                });

				initialized.then(result => {
					console.log(result);
					CometChat.login(adminUID, apiKey).then(
						user => {
							console.log("CometChat Login successful:", { user });

						},
						error => {
							console.log("CometChat Login failed with exception:", { error });
						}
					);
				});
			};
			adminCometChatLogin();
		}
	// eslint-disable-next-line
	},[isAdminAuth]);

	useEffect(() => {
		if (me) {
			if (me?.organization?.brands) {
				setCometChatUserLoggedIn(false);
				let initialized = new Promise ((resolve, reject) => {
                    CometChat.init(appID, appSetting).then(
                        () => {
                            resolve("CometChat initialization completed successfully");
                        },
                        error => {
                            reject("Initialization failed with error:", error);
                        }
                    );
                });

                initialized.then( result => {
                    console.log(result);
                    let loggedIn = new Promise((resolve, reject) => {
                        CometChat.login(me.organization.brands.nodes[0].id, apiKey).then(
                            user => {
                                resolve(user);
    
                                CometChat.getUnreadMessageCount().then(
                                    ({users, groups}) => {
                                        if (Object.keys(users).length || Object.keys(groups).length) {
                                            setHasNewMessages(true);
                                        };
                                    },
                                    (error) => {
                                    	reject(error);
                                    }
                                );
                            },
                            error => {
                                reject(error)
                                
                            }
                        );
                    });
    
                    loggedIn.then(user => {
                        console.log("CometChat Login Successful:", { user });
						setCometChatUserLoggedIn(true);
                        // Notifications related
                        const NOTIFICATION_LISTENER = 'NOTIFICATION_LISTENER';
        
                        CometChat.addMessageListener(
                            NOTIFICATION_LISTENER,
                            new CometChat.MessageListener({
                                onTextMessageReceived: textMessage => {
                                    // TODO: Check the sender
                                    console.log('Test', textMessage.sender.uid !== me.brand.cometchatID, textMessage)
                                    
                                    if (textMessage.sender.uid !== me.brand.cometchatID) {
                                        setHasNewMessages(true);
                                    };
                                },
                                onMediaMessageReceived: mediaMessage => {
                                    console.log('Test', mediaMessage.sender.uid !== me.brand.cometchatID, mediaMessage)
                                    
                                    if (mediaMessage.sender.uid !== me.brand.cometchatID) {
                                        setHasNewMessages(true);
                                    };
                                },
                                onCustomMessageReceived: customMessage => {
                                    console.log('Test', customMessage.sender.uid !== me.brand.cometchatID, customMessage)
                                    if (customMessage.sender.uid !== me.brand.cometchatID) {
                                        setHasNewMessages(true);
                                    };
                                }
                            })
                        );
                    }, err => {
                        console.log("CometChat Login failed with exception:", { err });
                    });
    
                }, err => {
                    console.log("Initialization failed with error:", err);
                });
			};
		};

	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [me, setIsAuth, setHasNewMessages]);

	const changeLanguage = (lang) => {
		i18n.changeLanguage(lang);
		ReactGA.event({
			category: `Brand User`,
			action: `Switched Language to ${lang}`,
			label: me.id
		});
	};

	const handleOnIdle = event => {
		console.log('user is idle', event);
		console.log('last active', getLastActiveTime());
		localStorage.removeItem('token');
		window.location = '/';
	};

	//remove token if user inactive for 30 mins
	const { getLastActiveTime } = useIdleTimer({
		//30 minutes
		timeout: 1800*1000,
		onIdle: handleOnIdle,
		debounce: 500
	});

	return (		
		<Router>
			<div className="container">
				<Header me={me} isAuth={isAuth} isAdminAuth={isAdminAuth} windowWidth={windowWidth} setRecoverPassword={setRecoverPassword} recoverPassword={recoverPassword}/>
				<RouteChangeTracker />
				<WindowWidthTracker />
				{!loading
					?
					<ErrorBoundary t={t}>
						<Suspense fallback={<Loading />}>
							<main>
								<Switch>
									<PrivateRoute exact path="/" user={data} component={() => <UserHome me={me} windowWidth={windowWidth} update={update} setUpdate={setUpdate}/>} />
									<PrivateRoute exact path="/edit-campaign/:id" user={data} component={() => <CampaignCreate me={me} update={update} setUpdate={setUpdate} />} />
									<PrivateRoute exact path="/campaign/:id" user={data} component={() => <CampaignManage me={me} update={update} setUpdate={setUpdate}/>} />
									{/* <Route path ="/edit-sponsorship" component ={SponsorshipCreate} />
									<Route path ="/sponsorship" component ={SponsorshipManage} /> */}
									<Route exact path="/login" component={() => <UserSignin isAuth={isAuth} windowWidth={windowWidth}  />} />
									<Route exact path="/signup" component={() => <UserSignup isAuth={isAuth} setIsAuth={setIsAuth} windowWidth={windowWidth} />} />
									<PrivateRoute exact path="/influencer" user={data} component={() => <InfluencerList me={me} />} />
									<PrivateRoute exact path='/influencer/:id' user={data} component={() => <Influencer update={update} setUpdate={setUpdate} />} />
									<PrivateRoute exact path="/messages" user={data} component={() => <Messages me={me} />} />
									<PrivateRoute exact path="/settings" user={data} component={() => <UserSettings me={me} update={update} setUpdate={setUpdate} />} />
									<PrivateRoute exact path='/link-brand' user={data} component={() => <BrandUpdate />} />
									<Route exact path='/loading' component={() => <SignUpLoading />} />
									<Route exact path='/download-app' component={() => <InfluencerUserRedirect />} />
									<Route exact path='/password-recover' component={() => <UserPasswordRecovery setRecoverPassword={setRecoverPassword} />} />
									<Route exact path="/admin/login" component={() => <AdminSignin windowWidth={windowWidth} isAdminAuth={isAdminAuth} setIsAdminAuth={setIsAdminAuth}/>} />
									<PrivateRoute exact path="/admin" user={true} component={() => <AdminHome setUpdate={setUpdate} isAdminAuth={isAdminAuth} />} />
									<PrivateRoute exact path="/admin/messages" user={true} component={() => <IsChatMounted> <CometChatUnified /> </IsChatMounted>} />
									<PrivateRoute exact path='/admin/influencer/:id' user={true} component={() => <Influencer update={update} setUpdate={setUpdate} />} />
									<PrivateRoute exact path='/admin/stats' user={true} component ={()=> <AdminStats />} />
									<Route component={PageNotFound} />
								</Switch>
							</main>
						</Suspense>
					</ErrorBoundary>
					: 
					<Loading />}

				<Footer me={me} changeLanguage={changeLanguage} />
			</div>
		</Router>
	);
}

export default App;