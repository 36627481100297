import React from 'react';
import { useTranslation } from 'react-i18next'
//import { useOrganizationCreateMutation } from '../../../../types/withhooks'
import { useOnboardingStore } from '../../../../hooks';

const Blocked = ({ rootclass, setStep }) => {
    const { t } = useTranslation();
    const setYourSigninStore = useOnboardingStore(state => state.setSignIn);
    //const [response, setResponse]=useState(null);
    //const yourSigninStore = useOnboardingStore(state => state.signUp);
    //const [organizationCreate, organizationCreateData]=useOrganizationCreateMutation();

    // useEffect(()=> {
    //     if(response){
    //         if(response.message){ 
    //             console.log('something went wrong');
    //         } else {
    //             return
    //         }
    //     }
    // // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [response]);

    // useEffect(() => {
    //     if(yourSignupStore.requirements.includes("wait_for_approval")){
    //         return
    //     } else {
    //         //saveOrg();
    //     }
    // });

    // const saveOrg = async () => {
    //     localStorage.setItem('onboardingDone', true);
    //     let response = await organizationCreate({
    //         variables: {
    //             input:  {
    //                 name: yourSignupStore.values.name,
    //                 about: yourSignupStore.values.description,
    //                 websiteUrl: yourSignupStore.values.websiteUrl,
    //                 photo: yourSignupStore.logo
    //             }
    //         }
    //     });
    //     setResponse(response);
    // }

    const handleSignInButton = ()=> {
        //history.push('/login');
        setYourSigninStore({
            stored: false,
            email:'',
            token: ''
        })
        setStep(1);
    }

    return (
        <div className={`${rootclass}-signUp-blocked`}>
            <img className={`${rootclass}-signUp-img`} src="signup_img.png" alt={t("onboarding.screen.verification.alt")} ></img>
            <h1>{t("onboarding.screen.3.blocked.title")}</h1>
            <p>{t("onboarding.screen.3.blocked.description")}</p>
            <button className="btn-secondary" onClick={()=>handleSignInButton()}>{t('onboarding.screen.3.blocked.signout')}</button>
            <div className={`${rootclass}-signUp-blocked-contact`}>
                <p>{t("onboarding.screen.3.blocked.contact")}</p>
                <a target='_blank' href='mailto:Contact@thefirework.app' rel="noreferrer">
                    <button className='btn-quaternary'>{t("onboarding.screen.3.blocked.btn")}</button>
                </a>
            </div>
           
        </div>
    )
}
export default Blocked;