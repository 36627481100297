import React, { useEffect, useState } from 'react'
import ReactGA from 'react-ga';
import range from './range'

const WindowWidthTracker = () => {
    const [windowWidth] = useState(window.innerWidth);
    const [widthRange, setWidthRange]=useState(null);

    useEffect(() => {
        if(windowWidth){
            setWidthRange(range.filter(range => {
                return range.lowValue <= windowWidth && range.highValue >= windowWidth
            }));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [windowWidth]);

    useEffect(() => {
        if(widthRange){
            ReactGA.event({
                category: `Brand User`,
                action: `Screen Window width used: ${windowWidth}`,
                label: widthRange[0]?.label
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [widthRange])

    return <div></div>;
};

export default WindowWidthTracker;