import React from "react";
import { useNotificationStore } from "../../../hooks";
import { AbstractNotificationBadge } from "./AbstractNotificationBadge";

export function MessageNotificationBadge() {
    const hasNewMessages = useNotificationStore(state => state.hasNewMessages);

    if (hasNewMessages) {
        return <AbstractNotificationBadge className={"badge-notification"} message={null} />;
    } else {
        return null;
    }
}
