import { gql, useApolloClient } from "@apollo/client";
import { useState } from "react";

const UPDATE_OFFERS = gql`
query Campaign($id: String!) {
    campaign(id: $id) {
        createdAt
        updatedAt
        id
        name
        offers{
            nodes{
                state
                paymentCash
                paymentProduct
                paymentStoreCredit
                groupchatId
                id
                pitch
                profile{
                    bio
                    displayName
                    minimumFee
                    profilePictureUrl
                    isLinkedToAgency
                    id
                }
            }
        }
    }
}
  
`

export function useOffersQuery(campaignId) {
    const client = useApolloClient();
    const [offers, setOffers] = useState([])
    const [loading, setLoading] = useState(false);

    const OffersQuery = async (campaignId) => {
        try {
            setLoading(true)
            const { data } = await client.query({
                query: UPDATE_OFFERS,
                variables: {
                    id: campaignId
                }
            })
            if (data) {
                setOffers(data?.offers?.nodes)
            }
        } catch (errors) {
            //console.log("useOffers", JSON.stringify(errors))
        } finally {
            setLoading(false)
        }
    }
    return { OffersQuery, offers, loading };
}