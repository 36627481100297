import React from 'react';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next'

const customStyles = {
    overlay: {
        background: 'rgb(26,26,26,0.75)',
        zIndex: 9999
    },
    content: {
        backgroundColor: '#2E2E2E',
        border: 'none',
        borderRadius: '8px',
        color: '#F1F1F1',
        margin: 'auto',
        width: '50%',
        height: '75%',
    }
};

const TermsConditionsModal = ({ modal, setModal }) => {
    const { t } = useTranslation();

    return (
        <Modal
            isOpen={modal}
            style={customStyles}
        >
            <div className="modal-offer-close">
                <button type="button" className="btn-tertiary" onClick={() => setModal(false)}>
                    <FontAwesomeIcon icon="times" />
                </button>
            </div>
            <div className="modal-terms-header">
                <div>
                    <span>{t("modal.tnc.title")}</span>
                </div>
            </div>
            <div className="modal-terms-content">
                <div>
                    <strong>{t("modal.tnc.updated")}</strong>
                </div>
                <div>
                    <strong>{t("modal.tnc.content.1")}</strong>
                </div>
                <div>
                    {t("modal.tnc.content.2")}
                </div>
                <div>
                    <strong>{t("modal.tnc.content.3")}</strong>
                </div>
                <div>
                    {t("modal.tnc.content.4")}
                </div>
                <div>
                    <strong>{t("modal.tnc.content.5")}</strong>
                </div>
                <div>
                    {t("modal.tnc.content.6")}
                </div>
                <div>
                <strong>{t("modal.tnc.content.7")}</strong>
                    <br></br>{t("modal.tnc.content.8")}<br></br>
                    <br></br>{t("modal.tnc.content.9")}<br></br>
                </div>
                <div>
                    <strong>{t("modal.tnc.content.10")}</strong>
                </div>
                <div>
                    {t("modal.tnc.content.11")}<br></br>
                    <br></br>{t("modal.tnc.content.12")}<br></br>
                    <br></br>{t("modal.tnc.content.13")}<br></br>
                    <br></br>{t("modal.tnc.content.14")}<br></br>
                    <br></br>{t("modal.tnc.content.15")}<br></br>
                    <br></br>{t("modal.tnc.content.16")}<br></br>
                    <br></br>{t("modal.tnc.content.17")}<br></br>
                    <br></br>{t("modal.tnc.content.18")}<br></br>
                    <br></br>{t("modal.tnc.content.19")}<br></br>
                    <br></br>{t("modal.tnc.content.20")}<br></br>
                    <br></br>{t("modal.tnc.content.21")}<br></br>
                    <br></br>{t("modal.tnc.content.22")}<br></br>
                    <br></br>{t("modal.tnc.content.23")}<br></br>
                    <br></br>{t("modal.tnc.content.54")}<br></br>
                </div>
                <div>
                    {t("modal.tnc.content.24")}
                </div>
                <div>
                    {t("modal.tnc.content.25")}
                </div>
                <div>
                    <strong>{t("modal.tnc.content.26")}</strong>
                </div>
                <div>
                    {t("modal.tnc.content.27")}
                </div>
                <div>
                    <strong>{t("modal.tnc.content.28")}</strong>
                </div>
                <div>
                    {t("modal.tnc.content.29")}
                </div>
                <div>
                    <strong>{t("modal.tnc.content.30")}</strong>
                </div>
                <div>
                    {t("modal.tnc.content.31")}
                </div>
                <div>
                    <strong>{t("modal.tnc.content.32")}</strong>
                </div>
                <div>
                    {t("modal.tnc.content.33")}
                </div>
                <div>
                    <strong>{t("modal.tnc.content.34")}</strong>
                </div>
                <div>
                    {t("modal.tnc.content.35")}
                </div>
                <div>
                    <strong>{t("modal.tnc.content.36")}</strong>
                </div>
                <div>
                    {t("modal.tnc.content.37")}
                </div>
                <div>
                    <strong>{t("modal.tnc.content.38")}</strong>
                </div>
                <div>
                    {t("modal.tnc.content.39")}
                </div>
                <div>
                    <strong>{t("modal.tnc.content.40")}</strong>
                </div>
                <div>
                    {t("modal.tnc.content.41")}
                </div>
                <div>
                    {t("modal.tnc.content.42")}
                </div>
                <div>
                    <strong>{t("modal.tnc.content.43")}</strong>
                </div>
                <div>
                    {t("modal.tnc.content.44")}
                </div>
                <div>
                    <strong>{t("modal.tnc.content.45")}</strong>
                </div>
                <div>
                    {t("modal.tnc.content.46")}
                </div>
                <div>
                    {t("modal.tnc.content.47")}
                </div>
                <div>
                    <strong>{t("modal.tnc.content.48")}</strong>
                </div>
                <div>
                    {t("modal.tnc.content.49")}
                </div>
                <div>
                    <strong>{t("modal.tnc.content.50")}</strong>
                </div>
                <div>
                    {t("modal.tnc.content.51")}
                </div>
                <div>
                    <strong>{t("modal.tnc.content.52")}</strong>
                </div>
                <div>
                    {t("modal.tnc.content.53")}
                </div>
            </div>
        </Modal>
    )
}

export default TermsConditionsModal;
