import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next'
import moment from 'moment';
import ReactGA from 'react-ga';
import { CampaignNotificationBadge} from '../Notifications/badges';
import { NotificationBadgeWrapper } from '../Notifications/NotificationBadgeWrapper';
import { useOrganizationQuery, useCampaignCreateMutation, useCurrentUserHomeQuery } from '../../types/withhooks'
import { useSettingsStore } from '../../hooks';
import CampaignDeleteModal from '../Global/Modals/CampaignDeleteModal';
import CampaignHideModal from '../Global/Modals/CampaignHideModal'
import Loading from '../Global/Loading/Icon';
import Overlay from './Overlay';
import SidebarTile from '../Global/SidebarTile';

const rootclass = 'home';

const Home = ({ me, windowWidth, setUpdate }) => {
    let brand = me && me.organization;
    const { t } = useTranslation();
    const history = useHistory();
    const [applications, setApplications] = useState([]);
    const [campaign, setCampaign] = useState();
    const [campaignId, setCampaignId]=useState();
    const [draftCampaigns, setDraftCampaigns] = useState();
    const [publishedCampaigns, setPublishedCampaigns] = useState();
    const [organizationId, setOrganizationId]=useState();
    const [brandId, setBrandId]=useState();
    const [deleteModal, setDeleteModal] = useState(false);
    const [hideModal, setHideModal] = useState(false);
    const [overlay, setOverlay] = useState(false);
    const yourInfoStore = useSettingsStore(state => state.yourInfo);
    const { data:currentUserHomeData,loading: currentUserHomeLoading } = useCurrentUserHomeQuery({
        variables: {
            filter: {
                states: "applied",
                campaignId: null,
                profileId: null            
            },
        },
    });
    const { data: orgData } = useOrganizationQuery({
        variables:{
            id: organizationId
        },
    });
    const [campaignCreate, createCampaignData] = useCampaignCreateMutation({
        variables:{
            id: {
                brandId:brandId
            },
        },
    });
    
    localStorage.removeItem('onboardingPath');
    localStorage.removeItem('onboardingDone');
    localStorage.removeItem('onboarding');

    useEffect(() => {
        if(me?.__typename === 'Influencer'){
            history.push('/download-app');
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [me])

	useEffect(() => {
		if (campaignId) {
			history.push('/edit-campaign/' + campaignId);
		}
	}, [campaignId, history]);

    useEffect(() => {
        if (currentUserHomeData) {
            setDraftCampaigns(currentUserHomeData?.currentUser?.brands?.edges[0]?.node?.campaigns?.edges.filter(campaign => {
                return !campaign?.node?.isPublished && campaign?.node?.applications?.edges?.length===0 && campaign?.node?.offers?.nodes?.length===0
            }));
            setPublishedCampaigns(currentUserHomeData?.currentUser?.brands?.edges[0]?.node?.campaigns?.edges.filter(campaign => {
                return campaign?.node?.isPublished || campaign?.node?.applications?.edges?.length>0 || campaign?.node?.offers?.nodes?.length >0
            }));

            let apps = [];
            currentUserHomeData?.currentUser?.brands?.edges[0]?.node?.campaigns?.edges.map((campaign,i) => {
                return campaign?.node?.applications?.edges.map(app => {
                    return apps.push(app.node);
                })
            });
            setApplications(apps); 

            if(currentUserHomeData?.currentUser?.brands?.edges[0]?.node?.campaigns?.edges.length===0){
                setOverlay(true);
            }
        }
    
    }, [currentUserHomeData]);


    useEffect(() => {
        if(brand){
            setOrganizationId(brand.id);
        }
    }, [brand]);

    useEffect(() => {
        if(orgData){
            setBrandId(orgData?.organization?.brands?.nodes[0]?.id);
        }
    }, [orgData]);

    useEffect(()=> {
        if(createCampaignData?.data){
            setCampaignId(createCampaignData?.data?.campaignCreate?.campaign?.id);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [createCampaignData]);

	const postAdCampaign = async () => {
		await campaignCreate();

	};

    // const handleDelete = (campaign) => {
    //     alert("sorry Josh this doesn't work yet");
    //     // setCampaign(campaign);
    //     // setDeleteModal(true);
    // }

    const handleHide = (campaign) => {
        //alert("sorry Josh this doesn't work yet")
        ReactGA.modalview(`/home/${campaign.id}/unpublish`);
        setCampaign(campaign);
        setHideModal(true);
    };

    const handleMessageRedirect = () => {
        history.push('/messages');
    };

    return (
        me && !currentUserHomeLoading
            ? 
            <>
                {/* Desktop and Tablet */}
                {windowWidth>500 &&
                <>
                    <div className={rootclass}>
                        {campaign && <CampaignDeleteModal campaign={campaign} modal={deleteModal} setModal={setDeleteModal} setUpdate={setUpdate}/>}
                        {campaign && <CampaignHideModal campaign={campaign} modal={hideModal} setModal={setHideModal} setUpdate={setUpdate}/>}
                        <h1>{yourInfoStore?.brandName ? yourInfoStore.brandName : brand?.name ? brand.name : t('home.campaigns.noName')}</h1>
                        <div className={`${rootclass}-content`}>
                            <div className={`${rootclass}-inline`}>
                                {overlay && <Overlay rootclass={rootclass} overlay={overlay} setOverlay={setOverlay} />}
                                <div className={overlay ? `${rootclass}-campaigns primary-card` : `${rootclass}-campaigns primary-card no-overlay`}>
                                    <h2>{t("home.campaigns.title")}</h2>
                                    <div className={`${rootclass}-container`}>
                                        {publishedCampaigns && publishedCampaigns.map((campaign, i) => {
                                            return (
                                                <NotificationBadgeWrapper key={campaign?.node?.id} badge={<CampaignNotificationBadge campaignId={campaign?.node?.id}/>}>
                                                    <div className={`${rootclass}-campaign`} key={i}>
                                                        <Link to={`/campaign/${campaign?.node?.id}`}>
                                                            <strong> {campaign?.node?.name ? campaign?.node?.name : t("home.campaigns.noName")}</strong>
                                                            <div className={`${rootclass}-campaign-date`}>
                                                            {t("home.campaigns.starting")} {moment(campaign?.node?.dateStart).format("MMM DD, YYYY")} {t("home.campaigns.to")} {moment(campaign?.node?.dateEnd).format("MMM DD, YYYY")}
                                                            </div>
                                                        </Link>
                                                        <div className={`${rootclass}-campaign-actions`}>
                                                            <Link to={`/edit-campaign/${campaign?.node?.id}`}>
                                                                <button className='btn-tertiary'>
                                                                    <FontAwesomeIcon icon="pen" /><span>{t("home.campaigns.edit")}</span>
                                                                </button>
                                                            </Link>
                                                            <button className='btn-tertiary' onClick={() => handleHide(campaign.node)} >
                                                                {campaign.node.isPublished ? 
                                                                    <>
                                                                        <FontAwesomeIcon icon="eye-slash" /><span>{t("home.campaigns.unpublish")}</span>
                                                                    </>
                                                                :
                                                                    <>
                                                                        <FontAwesomeIcon icon="eye" /><span>{t("home.campaigns.publish")}</span>
                                                                    </>}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </NotificationBadgeWrapper>
                                            )
                                        })}
                                        {draftCampaigns && draftCampaigns.map((campaign, i) => {
                                            return (
                                                <div className={`${rootclass}-campaign`} key={i}>
                                                    <Link to={`/edit-campaign/${campaign?.node?.id}`}>
                                                        <strong> {campaign?.node?.name ? campaign?.node?.name : t("home.campaigns.noName")}</strong>
                                                        <div className={`${rootclass}-campaign-date`}>{t("home.campaigns.draft")}</div>
                                                    </Link>
                                                    <div className={`${rootclass}-campaign-actions`}>
                                                        <Link to={`/edit-campaign/${campaign?.node?.id}`}>
                                                            <button className='btn-tertiary'>
                                                                <FontAwesomeIcon icon="pen" /><span>{t("home.campaigns.edit")}</span>
                                                            </button>
                                                        </Link>
                                                        {/* <button className='btn-tertiary' disabled onClick={() => handleDelete(campaign?.node)}>
                                                            <FontAwesomeIcon icon="trash" /><span>{t("home.campaigns.delete")}</span>
                                                        </button> */}
                                                    </div>
                                                </div>
                                            )
                                        })}
                                        {currentUserHomeData?.currentUser?.brands?.edges[0]?.node?.campaigns?.edges.length===0 && brand && 
                                            <div className={`${rootclass}-container-noCampaigns`}>
                                                <p>{t('home.campaigns.noCampaigns')}</p>
                                            </div>}

                                        <div className={`${rootclass}-btn-container`}>
                                            {brand ?
                                                <>
                                                    <button className="btn-primary" onClick={postAdCampaign}>{t("home.campaigns.post")}</button>
                                                    {overlay && 
                                                        <>
                                                            <div className={`${rootclass}-btn-label`}>
                                                                <img src='/v7.png' alt='up-arrow'></img>
                                                                <div className={`${rootclass}-btn-label-txt`}>
                                                                    <h4>Welcome to FireWork!</h4>
                                                                    <p>Select “Post an Ad Campaign” to get started!</p>
                                                                </div>
                                                            </div>
                                                        </>}
                                                </>
                                            : 
                                                <div>
                                                    <p>{t("home.campaigns.noBrand.1")}</p> 
                                                    <button className="btn-primary" onClick= {() => history.push('/link-brand')}>{t("home.campaigns.noBrand.2")}</button>
                                                </div>}
                                        </div>
                                    </div>
                                </div>

                                {/* Sponsorship Section */}
                                {/* <div className={`${rootclass}-sponsorships primary-card`}>
                                    <h2>{t("home.sponsorships.title")}</h2>
                                    <div className={`${rootclass}-container`} >
                                        <div className={`${rootclass}-btn-container`} >
                                            {brand  &&   
                                                    <>
                                                        <button className="btn-primary" >{t("home.sponsorships.manage")}</button> 
                                                        <button className="btn-secondary" >{t("home.sponsorships.send")}</button>
                                                    </>
                                            }
                                        </div>
                                    </div>
                                </div> */}

                            </div>
                            {applications && publishedCampaigns?.length !== 0 &&
                            <>
                                <div className={`${rootclass}-inbound primary-card`}>
                                    <SidebarTile
                                        rootclass={rootclass}
                                        campaigns={publishedCampaigns}
                                        applications={applications.filter(application => application.state === 'applied')}
                                        title={t("home.campaigns.inbound")}
                                        tooltip={t("home.campaigns.tooltip")}
                                        primaryBtn={t("campaignManage.inbound.3")}
                                        secondaryBtn={t("campaignManage.inbound.4")}
                                        setUpdate={setUpdate}
                                    />
                                </div>
                            </>}
                        </div>
                    </div>
                </>}      
                {windowWidth<500 &&
                <>
                    <div className={`${rootclass}-mobile`}>
                        <h1>{t("home.mobile.redirect.title")}</h1>

                        <p>{t("home.mobile.redirect.sub")}</p>

                        <div className={`${rootclass}-mobile-btn`}>
                            <button className="btn-primary" onClick={handleMessageRedirect}>{t("home.mobile.redirect.btn")}</button>
                        </div>
                    </div>
                </>}      
            </>
            : <Loading />
    )
}

export default Home;