import React from "react"
import { useNotificationStore } from "../../../hooks";
import { AbstractController } from "./AbstractController"

export function MessageController({ children }) {
    const setHasNewMessages = useNotificationStore(state => state.setHasNewMessages);
    
    return (
        <AbstractController fun={() => setHasNewMessages(false)}>
            {children}
        </AbstractController>
    )
}