import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next'
import { useOnboardingStore } from '../../../../hooks';
import ReactGA from 'react-ga';
import validator from 'validator';
import Tooltip from '@material-ui/core/Tooltip';
import TermsConditionsModal from '../../../Global/Modals/TermsConditionsModal';
import Loading from '../../../Global/Loading/Icon'

const rootclass = 'onboarding';

const Signup = ({ step, setStep, setBlocked }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const yourSignupStore = useOnboardingStore(state => state.signUp);
    const setYourSignupStore = useOnboardingStore(state => state.setSignUp);
    const [values, setValues] = useState({
        fullName: '',
        email: '',
        password: '',
        accountType: 'personnel',
        confirmPassword: '',
        acceptedTerms: false,
    })
    const [validated, setValidated] = useState(false);
    const [errors, setErrors] = useState({
        state:false
    });
    const [emailTaken, setEmailTaken] = useState(false)
    const [submitted, setSubmitted] = useState(false);
    const [modal, setModal] = useState(false);
    const [loading, setLoading]=useState(false);
    localStorage.setItem('onboarding', 'true')
    localStorage.setItem('onboardingPath', '/signup')

    useEffect(() => {
        if (values.fullname !== '' && values.email !== '' && values.password !== '' && values.confirmPassword !== '' && values.acceptedTerms === true) {
            setErrors(validate(values));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [submitted, values, emailTaken]);

    const handleSubmit = async (e) => {
        const { fullName, email, password, accountType } = values;
        e.preventDefault();
        setLoading(true);
        setEmailTaken(false);
        setSubmitted(true);
        setErrors(validate(values));

        let signUpFormData = new FormData();
        signUpFormData.append("full_name", fullName);
        signUpFormData.append("email", email);
        signUpFormData.append("password", password);
        signUpFormData.append("account_type", accountType);

        if (validated) {
            setYourSignupStore({
                ...yourSignupStore,
                values:{
                    ...yourSignupStore.values,
                    email: email
                }
            });
            try {
                let request = new XMLHttpRequest();
                request.open("POST", process.env.REACT_APP_SIGN_UP_ENDPOINT);
                request.send(signUpFormData);
                request.onload = () => {
                    setLoading(false);
                    let response = JSON.parse(request.response);
                    if (response?.errors && response?.errors?.length > 0) {
                        setEmailTaken(true);
                    } else {
                        if(!response?.requirements.includes("wait_for_approval")){
                            setBlocked(false);
                        } else {
                            setBlocked(true);
                        }
                        setYourSignupStore({
                            ...yourSignupStore,
                            stored:true,
                            requirements:response?.requirements,
                            token:response?.token
                        });
                        
                        if(response?.requirements.includes("wait_for_approval")){
                            setStep(3);
                        } else {
                            setStep(step+1);
                        }
                        
                    }
                }
            } catch (error) {
                console.log(error);
                ReactGA.event({
                    category: `Brand User Error`,
                    action: `Onboarding TypeError: ${error}`,
                    label: 'N/A'
                });
            }

        } else {
            return
        }
    }

    const handleTermsModal = () => {
        setModal(true)
    }

    const handleSignin = () => {
        history.push('/login')
    }

    const handleChange = (e) => {
        setEmailTaken(false)
        setValidated(false);
        const { name, value } = e.target
        setValues({
            ...values,
            [name]: value,
        });
        if (e.target.name === 'acceptedTerms') {
            setValues({
                ...values,
                acceptedTerms: !values.acceptedTerms,
            });
        }
        if (submitted) {
            setErrors(validate(values))
        }
    }

    const validate = (values) => {
        let errors = {}

        if (!values.fullName) {
            errors.fullname = t("onboarding.screen.2.errors.name");
            errors.state=true
        }

        if (!validator.isEmail(values.email)) {
            errors.email = t("onboarding.screen.2.errors.email");
            errors.state=true
        }

        if (emailTaken) {
            errors.email = t("onboarding.screen.2.errors.exists");
            errors.state=true
        }

        if (values.password.length<8) {
            errors.password = t("onboarding.screen.2.errors.reg");
            errors.state=true

        } else {
            if(values.password === ""){
                errors.password = t("onboarding.screen.2.errors.password");
                errors.state=true
            }

            if (values.password !== "") {
                if (!values.confirmPassword || values.confirmPassword !== values.password) {
                    errors.password = t("onboarding.screen.2.errors.noMatch");
                    errors.confirmPassword = t("onboarding.screen.2.errors.noMatch");
                    errors.state=true
                }
            }
        }

        // if (signUpHookErrors === "Failed to fetch") {
        //     errors.email = 'Server error or issue with your Network'
        // }

        if (!errors.password && !errors.confirmPassword && !errors.email && !errors.fullname) {
            setValidated(true);
            setErrors({});
        } else {
            setValidated(false);
        }
        return errors
    }
    
    return (    
        <div>
            {<TermsConditionsModal modal={modal} setModal={setModal} />}
            <h1>{t("onboarding.screen.2.title")}</h1>
            <form>
                <label>
                    <Tooltip arrow open={!modal} placement="left" title={errors.fullname ? errors.fullname : ""} >
                        <div className={errors.fullname ? "form-input form-input-error" : "form-input"}>
                            <FontAwesomeIcon icon="user" />
                            <input
                                maxLength={100}
                                id="fullName"
                                type="text"
                                name="fullName"
                                placeholder={t("onboarding.screen.2.name")}
                                autoComplete="name"
                                value={values.fullName}
                                onChange={handleChange}
                                required />
                            {errors.fullname && <FontAwesomeIcon icon="times-circle" className="form-error-icon" />}
                        </div>
                    </Tooltip>
                </label>
                <label>
                    <Tooltip arrow open={!modal} placement="left" title={errors.email ? errors.email : ""} >
                        <div className={errors.email ? "form-input form-input-error" : "form-input"}>
                            <FontAwesomeIcon icon="envelope" />
                            <input
                                maxLength={100}
                                id="email"
                                type="email"
                                name="email"
                                placeholder={t("onboarding.screen.2.email")}
                                autoComplete="email"
                                value={values.email}
                                onChange={handleChange}
                                required />
                            {errors.email && <FontAwesomeIcon icon="times-circle" className="form-error-icon" />}
                        </div>
                    </Tooltip>
                </label>
                <label>
                    <Tooltip arrow open={!modal} placement="left" title={errors.password ? errors.password : ""} >
                        <div className={errors.password ? "form-input form-input-error" : "form-input"}>
                            <FontAwesomeIcon icon="ellipsis-h" />
                            <input
                                maxLength={100}
                                id="password"
                                type="password"
                                name="password"
                                placeholder={t("onboarding.screen.2.password")}
                                autoComplete="new-password"
                                value={values.password}
                                onChange={handleChange}
                                required />
                            {/* <FontAwesomeIcon onClick={showPassword} icon="eye" /> */}
                            {errors.password && <FontAwesomeIcon icon="times-circle" className="form-error-icon" />}
                        </div>
                    </Tooltip>
                </label>
                <label>
                    {/* <p>Confirm password</p> */}
                    <Tooltip arrow open={!modal} placement="left" title={errors.confirmPassword ? errors.confirmPassword : ""} >
                        <div className={errors.confirmPassword ? "form-input form-input-error" : "form-input"}>
                            <FontAwesomeIcon icon="ellipsis-h" />
                            <input
                                maxLength={100}
                                id="confirmPassword"
                                type="password"
                                name="confirmPassword"
                                placeholder={t("onboarding.screen.2.confirm")}
                                autoComplete="new-password"
                                value={values.confirmPassword}
                                onChange={handleChange}
                                required />
                            {errors.confirmPassword && <FontAwesomeIcon icon="times-circle" className="form-error-icon" />}
                        </div>
                    </Tooltip>
                </label>
                <label className="label-option">
                    <Tooltip arrow open={!modal} placement="left" title={errors.acceptedTerms ? errors.acceptedTerms : ""} >
                        <div className={errors.acceptedTerms ? "form-checkbox-error" : ""}>
                            <input type="checkbox"
                                name="acceptedTerms"
                                onChange={handleChange}
                            />
                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                            <span>{t("onboarding.screen.2.tnc.1")} <a className= 'btn-quaternary' onClick={handleTermsModal}>{t("onboarding.screen.2.tnc.2")}</a></span>
                        </div>
                    </Tooltip>
                </label>
                {/* <label className="label-option">
                    <Tooltip arrow open={!modal} placement="left" title={errors.acceptedEmailNotifications ? errors.acceptedEmailNotifications : ""} >
                        <div
                            className={errors.acceptedEmailNotifications ? "form-checkbox-error" : ""}
                        >
                            <input type="checkbox"
                                name="acceptedEmailNotifications"
                                value={values.acceptedEmailNotifications}
                                onChange={handleChange}
                            />
                            <span>Yes, I understand and agree to the <div onClick={handleTermsModal}>ATNMY Terms & Conditions.</div></span>
                        </div>
                    </Tooltip>
                </label> */}
            </form>

            {!loading ?
                <div>
                    {values.confirmPassword === "" || values.password === "" || values.fullname === ""  || values.email === "" || values.acceptedTerms === false || errors.state === true ? <button className="btn-primary" disabled onClick={handleSubmit}>{t("onboarding.screen.2.create")}</button> : <button className="btn-primary" onClick={handleSubmit}>{t("onboarding.screen.2.create")}</button>}
                </div>
                :
                <Loading rootclass={rootclass} />
            }


            <div className={`${rootclass}-txt`}>
                {t("onboarding.screen.2.have")}
            </div>
            <button className="btn-secondary" onClick={handleSignin}>{t("onboarding.screen.2.signIn")}</button>
            {/*************************ENHANCEMENT******************************** */}
            {/* <div className="onboarding-linebreak"><span>OR</span></div>
            <button className="btn-fb">Sign up with Facebook <FontAwesomeIcon icon={['fab', 'facebook-square']} className="icon-social" /> </button>
            <button className="btn-primary"><FontAwesomeIcon icon={['fab', 'google']} /> Sign up with Google</button> */}
        </div>
    )
}

export default Signup