import { gql, useApolloClient } from "@apollo/client";
import { useState } from "react";

const CAMPAIGN_APPLICATIONS = gql`
query CampaignApplications($id: String!) {
    campaign(id: $id) {
      createdAt
      updatedAt
      id
      name
      applications{
        nodes {
            applicant{
                fullName
                id
                email
            }
            profile{
                bio
                displayName
                minimumFee
                profilePictureUrl
                isLinkedToAgency
                id
            }
            id
            pitch
            state
            acceptsPayByCash
            acceptsPayByProduct
            acceptsPayByStoreCredit
        }
      }
    }
  }
`

export const useCampaignApplicationsQuery = () => {
    const client = useApolloClient();
    const [applications, setApplications] = useState()
    const [loading, setLoading] = useState(false);

    const campaignApplicationsQuery = async (campaignId) => {
        try {
            setLoading(true)
            const { data } = await client.query({
                query: CAMPAIGN_APPLICATIONS,
                    id:campaignId
            })
            if (data !== null) {
                setApplications(data.campaign.applications.nodes)
            }
        } catch (errors) {
            //console.log("useCampaignApplications", JSON.stringify(errors))
        } finally {
            setLoading(false)
        }
    }

    return { campaignApplicationsQuery, applications, loading }
}