export const customStyles = {
    overlay: {
        background: 'rgb(26,26,26,0.75)',
    },
    content: {
        backgroundColor: '#2E2E2E',
        border: 'none',
        borderRadius: '8px',
        color: '#F1F1F1',
        margin: 'auto',
        width: '65%',
        height: '75%',
    }
};