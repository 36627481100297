import React from "react";
import { useNotificationStore } from "../../../hooks";
import { AbstractNotificationBadge } from "./AbstractNotificationBadge";
import { useTranslation } from 'react-i18next'
import { ApplicationState, DeliverableState, NotificationType } from "../../../types/withhooks";

export function CampaignNotificationBadge(props) {
    const { t } = useTranslation();
    const { campaignId } = props;

    const CAMPAIGN_BADGES = {
        [NotificationType.CampaignCreated]: t("notifications.badges.newCampaign"),
        [NotificationType.CampaignStarted]: t("notifications.badges.campaignStart"),
        [NotificationType.CampaignEnded]: t("notifications.badges.campaignHalf"),
        [NotificationType.CampaignOneDayLeft]: t("notifications.badges.almostOver"),
    
        [NotificationType.ApplicationCreated]: t("notifications.badges.newInbound"),
    
        [NotificationType.ContentSubmitted]: t("notifications.badges.newContent"),
        [NotificationType.ContentUpdated]: t("notifications.badges.updatedContent"),
    
        [NotificationType.OfferAccepted]: t("notifications.badges.newOffer"),
    
        "_MANY": t("notifications.badges.lotsNews")
    }
    const unreadNotifications = useNotificationStore(state => state.unreadNotifications, (a, b) => a.size === b.size);
    let associatedNotifications = [];

    // 1. Check for event match
    // 2. Check that the entity still exists
    // 3. Match for the id
    for (let notification of unreadNotifications.values()) {
        if (notification.type in CAMPAIGN_BADGES) {
            if (campaignId === notification.campaign?.id) {
                if (notification.type === NotificationType.ApplicationCreated && notification.application.state !== ApplicationState.Applied) {
                    continue;
                }

                if (
                    (notification.type === NotificationType.ContentSubmitted || notification.type === NotificationType.ContentUpdated)
                    &&
                    notification.deliverable.state !== DeliverableState.AwaitingReview
                ) {
                    continue
                }

                associatedNotifications.push(notification);
            }
        }
    }

    // Pick the message
    let message;
    
    if (associatedNotifications.length === 1) {
        const notification = associatedNotifications[0];
        message = CAMPAIGN_BADGES[notification.type]
    } else {
        message = CAMPAIGN_BADGES["_MANY"]
    }

    if (associatedNotifications.length) {
        return <AbstractNotificationBadge className={"badge-component-campaign"} message={message} />;
    } else {
        return null;
    }

}
