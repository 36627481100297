import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import validator from 'validator';
// import { useHistory } from 'react-router-dom';
// import LinkAccounts from './Screens/LinkAccounts';
// import Categories from './Screens/Categories';
// import { useBrandCreate, useBrandUpdate } from '../../hooks';
// import SubscriptionType from './Screens/SubscriptionType';
import Loading from '../../../Global/Loading/Icon'
import { useTranslation } from 'react-i18next'
import { Tooltip } from '@material-ui/core';

const Email = ({ rootclass, values, setValues, setStep }) => {
    const { t } = useTranslation();
    const [hidden, setHidden]=useState(true);
    const [errors, setErrors]=useState({});
    const [submit, setSubmit]=useState(false);
    const [token]=useState(window.location.search.slice(7))
    // const history = useHistory();    
    
    useEffect(() => {
        if(submit && errors.newPassword === null){
            submitPassword();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [submit, errors]);

    const submitPassword = async () => {
        setSubmit(true);
        const { newPassword } = values
        
        let newPasswordFormData = new FormData();
        newPasswordFormData.append("password", newPassword);
        newPasswordFormData.append("token", token);

        let response = await new Promise(resolve => {
            let request = new XMLHttpRequest();
            request.open("POST", process.env.REACT_APP_RESET_PASSWORD_ENDPOINT);
            request.onload =  () => {
                resolve(request.response)
            }
            request.onerror = () => {
                resolve(request.response);
            }
            request.send(newPasswordFormData);
        });

        let parsed;
        if(response){
            parsed = JSON.parse(response)
        }
        if(parsed.message){
            setErrors({
                newPassword: parsed.message
            });
            setSubmit(false);
        } else{
            setStep(4);
        }
    }

    const Validate = (values) => {
        let errors = { newPassword: null }
        if (values.newPassword.length<8) {
            errors.newPassword = t("onboarding.screen.2.errors.reg")
        } else {
            errors.newPassword = null
        }
        return errors
    }

    const handleChange = (e) =>{
        setErrors({});
        setValues({
            ...values,
            newPassword: e.target.value
        })
    }

    const handleViewPassword = () => {
        setHidden(!hidden);
    }

    const handleValidate = () => {
        setErrors(Validate(values));
        setSubmit(true);
    }

    return ( 
        <>
            <h1>{t("onboarding.screen.recover-password.password.title")}</h1>
            <p>
                {t("onboarding.screen.recover-password.password.content")}
            </p>
            <label>
                <Tooltip arrow open placement="left" title={errors?.newPassword ? errors.newPassword : ""} >
                    <div className={errors?.newPassword ? "form-input form-input-error" : "form-input"}>
                        <FontAwesomeIcon icon="envelope" />
                        <input
                            id="newPassword"
                            type={hidden ? "password" : "text"}
                            name="newPassword"
                            placeholder={t("onboarding.screen.1.password")}
                            value={values.newPassword}
                            onChange={handleChange}
                            required />
                        <FontAwesomeIcon icon={hidden? "eye": "eye-slash"} onClick={handleViewPassword}/>
                        {errors?.newPassword && <FontAwesomeIcon icon="times-circle" className="form-error-icon" />}
                    </div>
                    </Tooltip>
            </label>
            
            {submit ? 
                <Loading rootclass={rootclass} />
                :
                 <button className="btn-primary" disabled={values?.newPassword?.length <8} onClick={handleValidate}>{t("onboarding.screen.recover-password.password.set")}</button>
            }
            
        </>
    )
}
export default Email