import React from "react"
import { NotificationType } from "../../../types/withhooks";
import { useStore } from '../../../hooks/useStore'

export function NotificationImage({ notification }) {
    const { campaign, profile } = notification
    const imgError = useStore(state => state.imgError);

    let imageUrl;
    let alt;

    switch (notification.type) {
        case NotificationType.CampaignCreated:
        case NotificationType.CampaignStarted:
        case NotificationType.CampaignHalfway:
        case NotificationType.CampaignOneDayLeft:
        case NotificationType.CampaignEnded:
            imageUrl = campaign?.photoUrl
            alt = 'Campaign header image'
            break

        case NotificationType.ApplicationCreated:
        case NotificationType.OfferAccepted:
        case NotificationType.ContentSubmitted:
        case NotificationType.ContentUpdated:
            imageUrl = null
            if (profile?.profilePictureUrl) {
                imageUrl = profile?.profilePictureUrl
            } else {
                imageUrl = profile?.instagramAccount?.profilePictureUrl
            }
            alt = 'Profile image'
            break
        default:
            imageUrl = null
            alt= null
            break
    }

    return (
        <div className={"notification-image-container"}>
            <img
                onError={(e)=>imgError(e)}
                src={imageUrl}
                alt={alt}
            />
        </div>
    )
}