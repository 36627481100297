import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withTranslation } from 'react-i18next';
import ReactGA from 'react-ga';

const rootclass = 'error';

export default class ErrorBoundary extends Component {
  state = {
    error: '',
    errorInfo: '',
    hasError: false,
  };
  
  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  };

  componentDidCatch(error, errorInfo) {
    // eslint-disable-next-line no-console
    console.log({ error, errorInfo });
    ReactGA.event({
        category: `Brand User Error`,
        action: error,
        label: `Location: ${errorInfo.componentStack}`
    });
    this.setState({ errorInfo });
  }
  
  render() {
    const { hasError } = this.state;
    const { t } = this.props;

    if (hasError) {
        return (
            <div className={rootclass}>
                  <div className={`${rootclass}-card`}>
                      <p> {t('error.text')} <FontAwesomeIcon icon="bug" />!{' '}</p>
                      <div className={`${rootclass}-card-btn-container`}>
                          <button className='btn-primary' onClick={() => window.location.assign('/settings?=4') }>{t('error.report')}</button>
                          <button className='btn-secondary' onClick={() => window.location.assign('/')}>{t('error.home')}</button>  
                          <button className='btn-tertiary' onClick={() => window.location.reload()}>{t('error.reload')}</button>      
                      </div>
                      
                  </div>
                {/* <div className="card-body">
                    <details className="error-details">
                    <summary>Click for error details</summary>
                    {errorInfo && errorInfo.componentStack.toString()}
                    </details>
                </div> */}
            </div>
        );
        }
    return this.props.children;
  };
};

withTranslation('ns1')(ErrorBoundary);

ErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([ PropTypes.object, PropTypes.array ]).isRequired,
};