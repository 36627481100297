import React from "react"
import {
    ApolloClient,
    ApolloProvider,
    InMemoryCache,
    ApolloLink
} from '@apollo/client'
import { createConsumer } from '@rails/actioncable';
import { createUploadLink } from 'apollo-upload-client';
import { setContext } from '@apollo/client/link/context';
import ActionCableLink from 'graphql-ruby-client/subscriptions/ActionCableLink';

let API_URL;

switch (process.env.REACT_APP_DEPLOYMENT) {
    case 'production':
        API_URL = process.env.REACT_APP_DEPLOYMENT_URL_PROD;
        console.log(`Using API: Production`);
        break;
    default:
        API_URL = process.env.REACT_APP_DEPLOYMENT_URL_DEV;
        console.log(`Using API: ${API_URL}`);
        break;
}

const cable = createConsumer(`${API_URL}/cable?token=${localStorage.getItem('token')}`)

const httpLink = createUploadLink({
    uri: `${API_URL}/graphql`,
})

const authLink = setContext((_, { headers }) => {

    const adminToken=sessionStorage.getItem('adminToken');
    const token=localStorage.getItem('token');

    if (!token && !adminToken){
        const lang = localStorage.getItem('i18nextLng');
        if(lang){
            localStorage.setItem('i18nextLng', lang);
        }
    } else {
        return {
            headers: {
                ...headers,
                authorization: `Bearer ${adminToken || token}`
            }
        }
    } 
})

//@ts-ignore
const hasSubscriptionOperation = ({ query: { definitions } }) => {
    return definitions.some(
        //@ts-ignore
        ({ kind, operation }) => kind === 'OperationDefinition' && operation === 'subscription'
    )
}
  
const link = ApolloLink.split(
    hasSubscriptionOperation,
    new ActionCableLink({
        cable,
    }),
    authLink.concat(httpLink)
);

// Create the client as outlined in the setup guide
const client = new ApolloClient({
    // By default, this client will send queries to the
    //  `/graphql` endpoint on the same host
    link: link,
    cache: new InMemoryCache({
        dataIdFromObject: o => (o._id ? `${o.__typename}:${o._id}`: undefined),
    }),
    defaultOptions: {
        watchQuery: {
            fetchPolicy: 'no-cache',
            errorPolicy: 'ignore',
          },
          query: {
            fetchPolicy: 'no-cache',
            errorPolicy: 'all',
          },
  },
})

interface Props {
    children: React.ReactNode
}

export function GraphqlProvider({ children }: Props) {
    return (
        <ApolloProvider client={client}>
            {children}
        </ApolloProvider>
    )
}