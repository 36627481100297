  import { gql, useApolloClient } from "@apollo/client";
import { useState } from "react";

const CREATE_CAMPAIGN = gql`
mutation createDraft{
    createDraft
}`

export const useCampaignCreate = () => {
    const client = useApolloClient();
    const [loading, setLoading] = useState(false);
    const [campaignId, setCampaignId] = useState()

    const createCampaign = async (args) => {
        try {
            setLoading(true)
            const res = await client.mutate({
                mutation: CREATE_CAMPAIGN,
            })
            if (res) {
                console.log('res', res)
                setCampaignId(res.data?.createDraft)
            } else {
                //console.log("createCampaign", JSON.stringify(res.errors))
            }
        } catch (errors) {
            //console.log("createCampaign", JSON.stringify(errors))
        } finally {
            setLoading(false)
        }
    }

    return { createCampaign, campaignId, loading };
}