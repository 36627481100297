import { gql, useApolloClient } from "@apollo/client";
import { useState } from "react";

const BRAND_USER_UPDATE = gql`
mutation BrandUserUpdate($brandUser: UpdateBrandUserInput!){
    brandUserUpdate(brandUser: $brandUser){
        id
        email
        photoUrl
        fullname
    }
}
`

export function useBrandUserUpdate() {
    const client = useApolloClient();
    const [loading, setLoading] = useState(false)

    const updateBrandUser = async (brandUser) => {
        try {
            setLoading(true)
            const res = await client.mutate({
                mutation: BRAND_USER_UPDATE,
                variables: { brandUser },
            })
            if (res.data?.brandUserUpdate) {
                //console.log('res', res)
                return res.data.brandUserUpdate;
            } else {
                //console.log("useBrandUserUpdate", JSON.stringify(res.errors))
            }
        } catch (errors) {
            //console.log("useBrandUserUpdate", JSON.stringify(errors))
        } finally {
            setLoading(false);
            window.location.reload();
        }
    }

    return { updateBrandUser, loading };
}