/* eslint-disable array-callback-return */
import React, { useState, useEffect } from 'react';
import Loading from '../../../Loading/Icon'
import { useTranslation } from 'react-i18next'
import { useOfferCreateMutation } from '../../../../../types/withhooks'
// const { makeOffer } = useOfferMake();
// const { updateOffer } = useOfferUpdate();
// const { makeStandaloneOffer } = useOfferMakeStandalone();

const Buttons =({
    campaigns, 
    rootclass,
    campaign,
    application, 
    offer, 
    step, 
    closeModal, 
    setStep, 
    values, 
    influencer, 
    deliverables,
    setUpdate,
    setAdvanced
    })=>{
        const { t } = useTranslation();
        const [deliverableDescriptionsInput, setDeliverableDescriptionsInput] = useState({});
        const [exclusivityMonths, setExclusivityMonths] = useState(0);
        const [usageRightsMonths, setUsageRightsMonths] = useState(0);

        const [offerCreate, offerCreateData] = useOfferCreateMutation({
            variables:{
                input: {
                    campaignId:campaign?.id,
                    profileId: influencer?.id,
                    paymentCashCents: Number(values?.cashPaymentOfferCents*100),
                    paymentProductCents: Number(values?.productPaymentOfferCents*100),
                    paymentStoreCreditCents: Number(values?.storeCreditPaymentOfferCents*100),
                    deliverableDescriptions:deliverableDescriptionsInput,
                    exclusivityMonths: exclusivityMonths,
                    usageRightsMonths: usageRightsMonths
                }
            },
        });

        useEffect(() => {
            if(values){
                let deliverablesArray =[]

                for(let i=0;i<values.instaPhotoDeliverable;i++){
                    deliverablesArray.push({
                        description:"",
                        dueDate:null,
                        metadata:{
                            hashtags: deliverables.instaPhotoDeliverable.hashtags,
                            linkedAccounts: deliverables.instaPhotoDeliverable.linkedAccounts
                        },
                        type:"post"
                    })
                }

                for(let j=0;j<values.instaVideoDeliverable;j++){
                    deliverablesArray.push({
                        description:"",
                        dueDate:null,
                        metadata:{
                            hashtags: deliverables.instaVideoDeliverable.hashtags,
                            linkedAccounts: deliverables.instaVideoDeliverable.linkedAccounts
                        },
                        type:"video_post"
                    })
                }

                for(let k=0;k<values.instaStoryDeliverable;k++){
                    deliverablesArray.push({
                        description:"",
                        dueDate:null,
                        metadata:{
                            hashtags: deliverables.instaStoryDeliverable.hashtags,
                            linkedAccounts: deliverables.instaStoryDeliverable.linkedAccounts,
                            pollAnswers: deliverables.instaStoryDeliverable.pollAnswers,
                            pollQuestions: deliverables.instaStoryDeliverable.pollQuestions,
                            swipeUpLinks: deliverables.instaStoryDeliverable.swipeUpLinks,
                        },
                        type:"story"
                    })
                }

                if(values.generalRequest.length > 0){
                    deliverablesArray.push({
                        description:"",
                        dueDate:null,
                        metadata:{
                            text:values.generalRequest
                        },
                        type:"general"
                    })
                }

                if(values?.advanced?.length>0){
                    values.advanced.map(option => {
                        if (option.id === 'ex'){
                            setExclusivityMonths(option.length)
                        }
                        if (option.id === 'ur'){
                            setUsageRightsMonths(option.length)
                        }
                    })
                }
                
                setDeliverableDescriptionsInput(deliverablesArray)
            }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [values]);

        const handleSubmit = async () => {
            if (application) {
                try{    
                    await offerCreate();
                } catch(err) {
                    console.log(err)
                } finally {
                    closeModal();
                    setUpdate(true);
                }
            } 
        }

        return(
            <div>
                {campaigns && 
                    <div className="modal-offer-footer">
                        <hr />
                        <div className="modal-offer-btn">
                            {step === 0 &&
                                <>
                                    <button className="btn-secondary" onClick={() => closeModal()}>{t("modal.offer.buttons.cancel")}</button>
                                    <button className="btn-primary" disabled={!campaign} onClick={() => setStep(step + 1)}>{t("modal.offer.buttons.next")}</button>
                                </>
                            }
                            {step === 1 &&
                                <>
                                    <button className="btn-secondary" onClick={() => {
                                        setStep(step - 1);
                                        setAdvanced(false);
                                        }}>{t("modal.offer.buttons.back")}
                                    </button>

                                    {((application[0]?.state === 'applied') || (application.length === 0 && offer.length ===0)) &&
                                        <>
                                            <button 
                                                className="btn-primary"
                                                disabled={
                                                    (values.productPaymentOfferCents === 0 && values.storeCreditPaymentOfferCents === 0 && values.cashPaymentOfferCents === 0 ) ||
                                                    (values.instaPhotoDeliverable === 0 && values.instaVideoDeliverable === 0 && values.instaStoryDeliverable === 0 && values.generalRequest.length === 0)
                                                }
                                                onClick={handleSubmit}
                                            >
                                                {offerCreateData.loading ? <Loading rootclass={`${rootclass}-btn`} /> : t("modal.offer.buttons.submitOff") }
                                            </button>
                                        </>
                                    }
                                    {application[0]?.state === 'offered' && offer.length===0 &&
                                        <button className="btn-primary" disabled onClick={handleSubmit}>
                                            {t("modal.offer.buttons.offConfirmed")}
                                        </button>
                                    }
                                    {offer.length>0 &&
                                        <button className="btn-primary" disabled onClick={handleSubmit}>
                                            {offer[0]?.state === "for_influencer" && t("modal.offer.buttons.offUpdate")}
                                            {offer[0]?.state === "accepted" && t("modal.offer.buttons.offConfirmed")}
                                        </button>
                                    }
                                </>
                            }
                        </div>
                    </div>
                }
            </div>
        )
}

export default Buttons;