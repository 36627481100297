import React, { useState, useEffect } from 'react'
import moment from 'moment';
import PayInvoice from './PayInvoice';
import Loading from '../../../../Loading/Icon';
import AddingPaymentMethod from './Adding';
import { usePaymentSetupIntentCreateMutation, usePaymentPayInvoiceMutation } from '../../../../../../types/withhooks'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next'
import { ELEMENTS_OPTIONS } from './elementsOptions';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useHistory } from 'react-router-dom';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API);

const PayOffer = ({
        invoice, 
        step, 
        setStep, 
        setCardError, 
        values, 
        setValues, 
        cardError, 
        setModal, 
        closeModal, 
        rootclass,
        inputError,
        setUpdate,
        me,
		loadingUser
    }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const [submit, setSubmit] = useState(false);
    const [addingPaymentMethod, setAddingPaymentMethod] = useState(true);
    const [paying,setPaying]=useState(false);
    const [paymentMethods, setPaymentMethods]=useState([]);
    const [paymentSetupIntentClientSecret, setPaymentSetupIntentClientSecret]=useState(null);
    const [paymentSetupIntentCreate, paymentIntentSetupData]= usePaymentSetupIntentCreateMutation();
    const [paymentPayInvoice, paymentPayInvoiceData] = usePaymentPayInvoiceMutation();

    useEffect(() => {
        if(me?.organization?.paymentMethods.length>0){
            setAddingPaymentMethod(false);
            setPaymentMethods(me?.organization?.paymentMethods);
            setValues({
                name: '',
                company: '',
                address: '',
                apartment: '',
                city: '',
                country: '',
                province: '',
                postalCode: '',
                email: '',
                number: '',
                creditName: '',
                creditNumber: '',
                expDate: '',
                cvc: '',
                ccId: '',
                invoiceId: invoice.id
            })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [me]);

    useEffect(() => {
        if(paymentIntentSetupData?.data){
            setPaymentSetupIntentClientSecret(paymentIntentSetupData.data.paymentSetupIntentCreate.clientSecret)
        }
    }, [paymentIntentSetupData]);

    console.log(values)
	const handleNext = async () => {
        const { ccId, invoiceId } = values;
        if(addingPaymentMethod){
            await paymentSetupIntentCreate({
                variables: {
                    input: {
                    }
                }
            });
            setStep(1);
        } else if (values.invoiceId !=='' && values.ccId !==''){
            let response = await paymentPayInvoice({
                variables: {
                    input:{
                        invoiceId: invoiceId,
                        paymentMethodId: ccId
                    }
                }
            })
            console.log(response)
            if(response?.data?.paymentPayInvoice?.errors?.length===0){
                setStep(1);
            } else {
                setCardError({
                    errorState: true,
                    errorMessage: response.data.errors.message
                })
            }

        } else {
            setStep(1);
            setCardError({
                state: false,
                code: '',
                decline_code: ''
            })
        }
    }

    const handleSubmit = () => {
		setSubmit(true);
	}

    const handleRedirect = () => {
        history.push('/settings?=2');
    }

    let cashFee = Number((invoice.product.paymentCashCents/100*0.095).toFixed(2))
    let creditFee = Number((invoice.product.paymentStoreCreditCents/100*0.095).toFixed(2))
    let productFee = Number((invoice.product.paymentProductCents/100*0.095).toFixed(2))
    let total = ((cashFee+creditFee+productFee+invoice.product.paymentCashCents/100)).toFixed(2)
    
    console.log(invoice);

    if (step <2){
        return (
        <>
            <div className={`${rootclass}-header`}>
                <img
                    src={
                        invoice?.product?.profile?.profilePictureUrl
                            ? invoice.product.profile.profilePictureUrl
                            : '/placeholder-profile.png'
                    }
                    alt={`${invoice?.product?.profile?.displayName} profile`}
                />
                <div>
                    <span>
                        {t("modal.pay.payOffer.pay")}{' '}
                        {invoice?.product?.profile?.displayName
                            ? invoice.product.profile.displayName
                            : t("modal.pay.release.noName")}
                    </span>
                    <div className={`${rootclass}-header-fee`}>
                        <p>{t("modal.pay.payOffer.fee")}</p> 
                        {invoice.product.paymentCashCents !== 0 && <span> <FontAwesomeIcon icon='money-bill' /> ${invoice.product.paymentCashCents/100} </span> }
                        {invoice.product.paymentStoreCreditCents !== 0 && <span> <FontAwesomeIcon icon='credit-card' /> ${invoice.product.paymentStoreCreditCents/100} </span> }
                        {invoice.product.paymentProductCents !== 0 && <span> <FontAwesomeIcon icon='gift' /> ${invoice.product.paymentProductCents/100} </span> }
                    </div>
                </div>
            </div>
                <div className='modal-offer-breadcrumbs'>
                    {!addingPaymentMethod ?
                        <>
                            {step===0 && 
                                <>
                                    <span>{t("modal.pay.payOffer.billing.title")}</span>{' '}
                                    <FontAwesomeIcon icon='chevron-right' /> {t("modal.pay.payOffer.card")}
                                </>
                            }
                            {step===1 && 
                                <>
                                    {t("modal.pay.payOffer.billing.title")} <FontAwesomeIcon icon='chevron-right' />{' '}
                                    <span>{t("modal.pay.payOffer.card")}</span>
                                </>
                            }
                        </>
                        :
                        <>
                            {step===0 && 
                                <>
                                    <span>{t("modal.pay.payOffer.billing.title")}</span>{' '}
                                    <FontAwesomeIcon icon='chevron-right' /> {t("modal.pay.payOffer.payment")}
                                </>
                            }
                            {step===1 && 
                                <>
                                    {t("modal.pay.payOffer.billing.title")} <FontAwesomeIcon icon='chevron-right' />{' '}
                                    <span>{t("modal.pay.payOffer.payment")}</span>
                                </>
                            }
                        </>

                    }
                </div>
            <div className='modal-offer-selection'>
                <div className='modal-offer-selection-2col'>
                    <div className='modal-offer-selection-2col-col' id="modal-offer-selection-2col-col-step1">
                        <strong>{t("modal.pay.payOffer.infDeposit.title")}</strong>
                        <span>
                        {t("modal.pay.payOffer.infDeposit.label.1")}{' '}
                            {invoice?.product?.profile?.displayName
                                ? invoice.product.profile.displayName
                                : t("modal.pay.release.noName")}
                            . {t("modal.pay.payOffer.infDeposit.label.2")}{' '}
                            {invoice.product.profile.displayName
                                ? invoice.product.profile.displayName
                                : t("modal.pay.release.noName")}{' '}
                            {t("modal.pay.payOffer.infDeposit.label.3")}{' '}
                            {moment(invoice.product.campaign.dateEnd).format('MMM DD, YYYY')} . 
                            {t("modal.pay.payOffer.infDeposit.label.4")}{' '}
                            {invoice?.product?.profile?.displayName
                                ? invoice.product.profile.displayName
                                : t("modal.pay.release.noName")}{' '}
                            {t("modal.pay.payOffer.infDeposit.label.5")}
                        </span>
                        
                        {!loadingUser && !paymentIntentSetupData.loading && !paymentPayInvoiceData.loading ? 
                            <Elements stripe={stripePromise} options={ELEMENTS_OPTIONS}> 
                                {addingPaymentMethod ?
                                    <AddingPaymentMethod 
                                        step={step}
                                        values={values}
                                        submit={submit}
                                        setSubmit={setSubmit}
                                        setStep={setStep}
                                        inputError={inputError}
                                        setValues={setValues}
                                        cardError={cardError}
                                        rootclass={rootclass}
                                        setCardError={setCardError}
                                        clientSecret={paymentSetupIntentClientSecret}
                                        setAddingPaymentMethod={setAddingPaymentMethod}
                                        paymentPayInvoice={paymentPayInvoice}
                                        paymentPayInvoiceData={paymentPayInvoiceData}
                                        setUpdate={setUpdate}
                                    />
                                    :
                                    <PayInvoice
                                        values={values}
                                        paying={paying}
                                        step={step}
                                        setStep={setStep}
                                        setCardError={setCardError}
                                        setModal={setModal}
                                        setValues={setValues}
                                        setUpdate={setUpdate}
                                        handleSubmit={handleSubmit}
                                        setPaying={setPaying}
                                        rootclass={rootclass}
                                        paymentMethods={paymentMethods}
                                        paymentIntentClientSecret={paymentPayInvoiceData?.data?.paymentPayInvoice?.clientSecret}
                                        paymentSetupIntentClientSecret={paymentIntentSetupData?.data?.paymentSetupIntentCreate?.clientSecret}
                                    /> 
                                }

                                {/* {(step === 0 && !paymentIntentSetupData.loading && !addingPaymentMethod) &&
                                <div className={`${rootclass}-cards-row`}>  
                                    {me?.organization?.paymentMethods.map((card,i)=> {
                                        switch (card.brand) {
                                            case 'mastercard':
                                                return(
                                                    <div key={i} className={`${rootclass}-cards-row-item`}>
                                                        <img src="/mc.png" alt="MasterCard Png - Transparent Background Mastercard Logo"></img>
                                                        <p>{card.lastFour}</p>
                                                    </div>
                                                )
                                            default:
                                                return(
                                                    <div key={i} className={`${rootclass}-cards-row-item`}>
                                                        <img src="/visa.png" alt="Visa Png - Transparent Background Visa Logo"></img>
                                                        <p>{card.lastFour}</p>
                                                    </div>
                                                );
                                        };
                                    })}
                                </div>
                                } */}
                            </Elements>
                            :
                            <>    
                                <Loading rootclass={rootclass} />
                            </>
                        }
                    </div>

                    <div className={`${rootclass}-pricing-breakdown`} id='fixed'>
                        <strong>{t("modal.pay.payOffer.pricing.title")}</strong>

                        {invoice.product.paymentProductCents !==0 &&
                        <>
                        <div className={`${rootclass}-pricing-breakdown-fee`}>
                            <div>
                                <div>{t("modal.pay.payOffer.pricing.prodFee.title")}</div>
                                <span className={`${rootclass}-pricing-breakdown-fee-calculation`} >${invoice.product.paymentProductCents/100} x 9.5%</span>
                            </div>
                            <div>${((invoice.product.paymentProductCents/100)*0.095).toFixed(2)}</div>
                        </div>
                        <span>{t("modal.pay.payOffer.pricing.prodFee.label")}</span>
                        </>}

                        {invoice.product.paymentStoreCreditCents !==0 &&
                        <>
                        <div className={`${rootclass}-pricing-breakdown-fee`}>
                            <div>
                                <div>{t("modal.pay.payOffer.pricing.credFee.title")}</div>
                                <span className={`${rootclass}-pricing-breakdown-fee-calculation`} >${invoice.product.paymentStoreCreditCents/100} x 9.5%</span>
                            </div>
                            <div>${((invoice.product.paymentStoreCreditCents/100)*0.095).toFixed(2)}</div>
                        </div>
                        <span>{t("modal.pay.payOffer.pricing.credFee.label")}</span>
                        </>}

                        {invoice.product.paymentCashCents !==0 &&
                        <>
                        <div className={`${rootclass}-pricing-breakdown-item`}>
                            <div>{t("modal.pay.payOffer.pricing.cash.title")}</div>
                            <div>${((invoice.product.paymentCashCents/100).toFixed(2))}</div>
                        </div>
                        
                        <div className={`${rootclass}-pricing-breakdown-item`}>
                            <div>{t("modal.pay.payOffer.pricing.cash.fee")}</div>
                            <div>${((invoice.product.paymentCashCents/100) * 0.095).toFixed(2)}</div>
                        </div>
                        </>}

                        <hr />
                        <div className={`${rootclass}-pricing-breakdown-item`}>
                            <div>{t("modal.pay.payOffer.pricing.total")}</div>
                            <div className='modal-offer-selection-total'>${total}</div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='modal-offer-footer'>
            <hr />
            <div className='modal-offer-btn'>
                {step === 0 && (
                    <>
                        <button className='btn-secondary' onClick={() => closeModal()}>
                            {t("modal.pay.payOffer.pricing.cancel")}
                        </button>
                        {!addingPaymentMethod ?
                            <button className='btn-primary' disabled ={((values.address.length === 0 || values.name.length === 0 || values.city.length === 0 || values.country.length === 0 || values.province.length === 0 || values.postalCode.length === 0) && addingPaymentMethod) || (!addingPaymentMethod && values.ccId.length ===0) || paying} onClick={() => handleNext()}>
                                {!addingPaymentMethod ? `${t("modal.pay.payOffer.pricing.payInf")} $${total}` : t("modal.pay.payOffer.pricing.next")}
                            </button>
                            :
                            <button className='btn-primary' onClick={handleRedirect}>
                                {t("modal.pay.payOffer.pricing.add")}
                            </button>
                        }
                    </>
                )}
                {step === 1 && !submit && (
                    <>
                        <button className='btn-secondary' onClick={() => {
                            setStep(step - 1)
                        }}>
                            {t("modal.pay.payOffer.pricing.back")}
                        </button>

                        <button type='submit' disabled={total === 0.00 || (!paymentSetupIntentClientSecret && !paymentPayInvoiceData?.data?.paymentPayInvoice?.clientSecret) || (addingPaymentMethod && (values?.email?.length ===0 || values?.number?.length ===0))} onClick={handleSubmit}>
                            {t("modal.pay.payOffer.pricing.payInf") + '$' + total}
                        </button>


                        
                    </>
                )}
                {/* {step === 1 && cardError.state === true && !submit && (
                    <>
                        <button className='btn-secondary' onClick={() => {
                            setStep(step - 1);
                            setValues({
                                ...values,
                                ccId: '',
                                invoiceId: ''
                            });
                            console.log('here')
                        }}>
                            {t("modal.pay.payOffer.pricing.back")}
                        </button>
                        <button type='submit' onClick={handleRetry}>
                            {t("modal.pay.payOffer.pricing.retry")}
                        </button>
                    </>
                )} */}
                {submit  &&                 
                    <>
                        <Loading rootclass={rootclass} />
                    </>
                }
            </div>
        </div> 
        </>
    )
    } else {
        return (
            <div className={`${rootclass}-offer-complete`}>
                <span className={`${rootclass}-offer-complete-text`}>{t("modal.pay.payOffer.pricing.success.1")} {invoice?.profile?.displayName} {t("modal.pay.payOffer.pricing.success.2")}</span>
                <span><FontAwesomeIcon icon="check-circle" className={`${rootclass}-complete-icon`} /></span>
            </div>
        )
    }

}

export default PayOffer;