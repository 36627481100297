import React, { useState } from 'react';
import TermsConditionsModal from '../Modals/TermsConditionsModal';
import PrivacyPolicyModal from '../Modals/PrivacyPolicyModal';
import ReactGA from 'react-ga';

import { useTranslation } from 'react-i18next'

const Footer = ({ me, changeLanguage }) => {
    const [termsModal, setTermsModal] = useState(false);
    const [privacyModal, setPrivacyModal] = useState(false);

    const { t, i18n } = useTranslation();

    const handleTermsModalClick = () => {
        setTermsModal(true);
        ReactGA.modalview('/t&c');
    }

    const handlePrivacyModalClick = () => {
        setPrivacyModal(true);
        ReactGA.modalview('/privacyPolicy');
    }

    const handleOurStoryClick = () => {
        window.open("https://www.thefirework.app/");
        ReactGA.event({
			category: `Brand User`,
			action: `Outbound Footer Our Story Click`,
			label: me.id
		});
    }

    const handleHelpClick = () => {
        ReactGA.event({
			category: `Brand User`,
			action: `Outbound Footer Help Click`,
			label: me.id
		});
    }

    return (
        <>
            <TermsConditionsModal modal={termsModal} setModal={setTermsModal} />
            <PrivacyPolicyModal modal={privacyModal} setModal={setPrivacyModal} />
            <footer>
                <nav role="navigation">
                    <ul>
                        <li onClick={handleHelpClick}><a href='mailto:contact@thefirework.app' target='_blank' rel="noopener noreferrer">{t('footer.help')}</a></li>
                        <li tabIndex="0" onClick={() => handleTermsModalClick()}>{t('footer.t&c')}</li>
                        <li tabIndex="0" onClick={() => handlePrivacyModalClick()}>{t('footer.privacy')}</li>
                        <li onClick={() => handleOurStoryClick()}>{t('footer.ourStory')}</li>
                        {i18n.language === 'fr' && <li onClick={()=>changeLanguage('en')}>EN</li>}
                        {i18n.language === 'en' && <li onClick={()=>changeLanguage('fr')}>FR</li>}
                    </ul>
                </nav>
                <div>
                    {t('footer.tag')}
                </div>
            </footer >
        </>
    )
}

export default Footer
