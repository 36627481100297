  
import create from 'zustand';
import { Map as ImmutableMap} from 'immutable'

export const useNotificationStore = create(set => ({
    /**
     * This map is used by all the badges and controllers in order
     * to update only when needed
     */
    unreadNotifications: new ImmutableMap(),

    /**
     * This map is used by the NotificationList and NotificationItem
     * components. It contains all notifications.
     */
    notifications: new ImmutableMap(),

    /**
     * Notifications are added in groups
     */
    addNotifications: (newNotifications) => set((state) => {
        const { notifications, unreadNotifications } = state

        return {
            unreadNotifications: unreadNotifications.withMutations((map) => {
                for (const notification of newNotifications) {
                    if (!notification.readAt) {
                        map.set(notification.id, notification)
                    } else {
                        map.remove(notification.id)
                    }
                }
            }),

            notifications: notifications.withMutations((map) => {
                for (const notification of newNotifications) {
                    map.set(notification.id, notification)
                }
            })

        }
    }),

    /**
     * Once the user interacts with a notification it must be marked as read.
     * A newNotification object is created so that the NotificationItem component
     * removes its badge.
     */
    markAsRead: (notificationId, readAt) => set((state) => {
        const { notifications, unreadNotifications } = state

        const oldNotification = notifications.get(notificationId)

        if (!oldNotification) {
            return { notifications, unreadNotifications }
        }

        const newNotification = {...oldNotification, readAt}
        
        return {
            notifications: notifications.set(oldNotification.id, newNotification),
            unreadNotifications: unreadNotifications.remove(newNotification.id)
        }
    }),

    /**
     * About messages
     */
    chatOpen: false,
    setChatOpen: (bool) => set(() => ({chatOpen: bool})),

    hasNewMessages: false,
    // It will not show the badge on new messages 
    // if the chat component is currently mounted
    setHasNewMessages: (bool) => set((state) => ({ hasNewMessages: state.chatOpen ? false : bool })),


    /**
     * Miscellaneous
     */
    openDrawer: false,
    setOpenDrawer: (bool) => set(() => ({openDrawer: bool})),
}))