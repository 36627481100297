import moment from 'moment';
import { useState } from 'react';

export const useReport = () => {
  const [loading, setLoading] = useState(false)

  const sendReport = async ({ creatorUsername, text }) => {
    const time = moment().format("HH:mm:ssa, ddd MM YYYY");

    try {
      setLoading(true);
      const response = await fetch(`https://hooks.slack.com/services/${process.env.REACT_APP_SLACK_SNITCH_ENDPOINT}`,
        {
        method: 'POST',
        headers: {
          Accept: 'application/json'
        },
        body: JSON.stringify({
          text: `
            Our brand user *${creatorUsername}* sent in a new issue.\nI'm snitching:\n\n \`\`\`${text}\`\`\` \n\nSent around \`${time}\`
          `})
        });

      if (response.ok) {
        return null;
      }
    } catch (err) {
      console.log({err})
      return err;
    } finally {
      setLoading(false);
    }
  }

  return {sendReport, loading};
}