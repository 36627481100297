import React, { Suspense } from 'react';
import { GraphqlProvider } from './providers/GraphqlProvider';
import ReactDOM from 'react-dom';
import App from './App.jsx';
import './i18next';

require('dotenv').config();

ReactDOM.render(
  <GraphqlProvider>
    <Suspense fallback={(<div>Loading</div>)} >
      <App />
    </Suspense >
  </GraphqlProvider>,
  document.getElementById('root')
);  


