import React from 'react'
// import { options } from '../../../options';
// import { CARD_OPTIONS  } from './cardOptions';
// import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
// import { useTranslation } from 'react-i18next'

const AddingPaymentMethod =({
        step,
        //values, 
        //inputError, 
        //setValues, 
        //cardError, 
        // setCardError, 
        //rootclass, 
        // submit, 
        // setSubmit, 
        // clientSecret, 
        // setAddingPaymentMethod,
        // paymentPayInvoice,
        // paymentPayInvoiceData,
        // setStep,
        // setUpdate
    })=>{
    // const { t } = useTranslation();
    // const stripe = useStripe();
    // const elements = useElements();
    // const [paying, setPaying]=useState(false);
    // const [paymentIntentClientSecret,setPaymentIntentClientSecret] = useState(paymentPayInvoiceData?.data?.paymentPayInvoice?.clientSecret)

    // const saveCard = async () => {
    //     setPaying(true);
    //     const { name, email, number, country, address, city, province, postal } = values;
    //     // We don't want to let default form submission happen here,
    //     // which would refresh the page.
    //     //e.preventDefault();
    //     setCardError({
    //         code: "",
    //         decline_code: "",
    //         state: false
    //     }); 

    //     if (!stripe || !elements) {
    //         // Stripe.js has not yet loaded.
    //         // Make sure to disable form submission until Stripe.js has loaded.
    //         return;
    //     }

    //     const setupCardResult = await stripe.confirmCardSetup(clientSecret, {
    //         payment_method: {
    //           card: elements.getElement(CardElement),
    //           billing_details: {
    //             address: {
    //                 city: city,
    //                 country: country,
    //                 line1: address,
    //                 line2: '',
    //                 postal_code: postal,
    //                 state: province,
    //             },
    //             email: email,
    //             phone: number,
    //             name: name,
    //           },
    //         }
    //       });

    //     if (setupCardResult.error) {
    //         console.log(setupCardResult);
    //         setCardError({
    //             state: true,
    //             code: setupCardResult.error.code,
    //             decline_code: setupCardResult.error.decline_code
    //         })
    //         setSubmit(false);
    //     } else {
    //         console.log(setupCardResult);
    //         setValues({
    //             ...values,
    //             ccId: setupCardResult.setupIntent.payment_method
    //         })
    //         setSubmit(false);
    //         setAddingPaymentMethod(false);
    //         payInvoiceIntent();
    //     }
    // }

    // const payInvoiceIntent = async () => {
    //     const {invoiceId, ccId}= values
    //     let payInvoiceIntent = await paymentPayInvoice({
    //         variables: {
    //             input:{
    //                 invoiceId: invoiceId,
    //                 paymentMethodId: ccId
    //             }
    //         }
    //     })
    //     console.log(payInvoiceIntent)
    //     payInvoice();
    // }

    // const payInvoice = async () => {
	// 	if (!stripe || !elements) {
	// 		// Stripe.js has not yet loaded.
	// 		// Make sure to disable form submission until Stripe.js has loaded.
	// 		return;
	// 	}

	// 	const result = await stripe.confirmCardPayment(`${paymentIntentClientSecret}`);
		
	// 		if (result.error) {
    //             console.log(result)
	// 			// Show error to your customer (e.g., insufficient funds)
	// 			setPaying(false);
	// 			setCardError({
	// 				errorState: true,
	// 				errorMessage: result.error.message
	// 			})
	// 		} else {
    //             console.log(result);
	// 			// The payment has been processed!
	// 			setPaying(false);
	// 			if (result.paymentIntent.status === 'succeeded') {
	// 				setStep(3);
	// 				window.setTimeout(function () {
	// 				setUpdate(true);
	// 				}, 3000);
	// 			}
	// 		}
	
    // }

    // useEffect(() => {
    //     if(submit){
    //         saveCard();
    //     }
    // // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [submit])

    return (
        <>
        {/* Billing Address */}
        {/* {step === 0 &&
            <>
                <strong>{t("modal.pay.payOffer.billing.title")}</strong>
                <span>{t("modal.pay.payOffer.billing.label")}</span>
                <label>
                    <input
                        maxLength='100'
                        name='name'
                        value={values.name}
                        className={inputError && values.name.length === 0 ? 'form-input form-input-error' : "form-input "}
                        type='text'
                        placeholder={t("modal.pay.payOffer.billing.name")}
                        onChange={(e) => setValues({ ...values, name: e.target.value })} 
                    />
                </label>
                <label>
                    <input
                        maxLength='100'
                        name='address'
                        value={values.address}
                        className={inputError && values.address.length === 0 ? 'form-input form-input-error' : "form-input "}
                        type='text'
                        placeholder={t("modal.pay.payOffer.billing.add")}
                        onChange={(e) => setValues({ ...values, address: e.target.value })} 
                    />
                </label>

                <label>
                    <input
                        maxLength='100'
                        name='city'
                        value={values.city}
                        className={inputError && values.city.length === 0 ? 'form-input form-input-error' : "form-input "}
                        type='text'
                        placeholder={t("modal.pay.payOffer.billing.city")}
                        onChange={(e) => setValues({ ...values, city: e.target.value })} 
                    />
                </label>
                <div>
                    <label>
                        <select
                            name='country'
                            value={values.country}
                            className={inputError && values.country.length === 0 ? 'form-input form-input-error' : "form-input "}
                            onChange={(e) => setValues({ ...values, country: e.target.value })}
                        >
                            <option value='' disabled defaultValue> {t("modal.pay.payOffer.billing.country")}</option>
                            <option value='ca'>{t("modal.pay.payOffer.billing.ca")}</option>
                            <option value='us'>{t("modal.pay.payOffer.billing.us")}</option>
                        </select>
                    </label>
                    <label>
                        <select
                            name='province'
                            value={values.province}
                            className={inputError && values.province.length === 0 ? 'form-input form-input-error' : "form-input "}
                            onChange={(e) => setValues({ ...values, province: e.target.value })}
                        >
                            {values.country === '' && (
                                <option value='' disabled defaultValue>
                                    {t("modal.pay.payOffer.billing.province")}
                                </option>
                            )}
                            {values.country === 'ca' &&
                                options.ca.map((province, i) => (
                                    <option key={i} value={province.abbreviation}>
                                        {province.name}
                                    </option>
                                ))}
                            {values.country === 'us' &&
                                options.us.map((state, i) => (
                                    <option key={i} value={state.abbreviation}>
                                        {state.name}
                                    </option>
                                ))}
                        </select>
                    </label>
                    <label>
                        <input
                            maxLength='6'
                            name='postalCode'
                            value={values.postalCode}
                            className={inputError && values.postalCode.length === 0 ? 'form-input form-input-error' : "form-input "}
                            id='form-input-bottom'
                            type='text'
                            placeholder={t("modal.pay.payOffer.billing.postal")}
                            onChange={(e) => setValues({ ...values, postalCode: e.target.value })} />
                    </label>
                </div>
            </>
            } */}

        {/* Additions
        <label>
            <input
                maxLength='100'
                name='apartment'
                value={values.apartment}
                className='form-input'
                type='text'
                placeholder={t("modal.pay.payOffer.billing.apart")}
                onChange={(e) => setValues({ ...values, apartment: e.target.value })} />
        </label> 
        <label>
        <input
            maxLength='100'
            name='company'
            value={values.company}
            className='form-input'
            type='text'
            placeholder={t("modal.pay.payOffer.billing.company")}
            onChange={(e) => setValues({ ...values, company: e.target.value })} />
        </label>
        
        */}
        
        {/* Card Details */}
        {/* {step ===1 &&
            <>
                <strong>{t("modal.pay.payOffer.card")}</strong>
                <span>{t("settings.payment.modal.name")}</span>
                <label>
                    <input
                        maxLength='100'
                        name='name'
                        value={values.name}
                        className={inputError && values.name.length === 0 ? 'form-input form-input-error' : "form-input "}
                        type='text'
                        placeholder={t("settings.payment.modal.placeholder.1")}
                        onChange={(e) => setValues({ ...values, name: e.target.value })} />
                </label>

                <span>{t("settings.payment.modal.email")}</span>
                <label>
                    <input
                        maxLength='100'
                        name='email'
                        value={values.email}
                        className={inputError && values.email.length === 0 ? 'form-input form-input-error' : "form-input "}
                        type='email'
                        placeholder={t("settings.payment.modal.placeholder.10")}
                        onChange={(e) => setValues({ ...values, email: e.target.value })} />
                </label>

                <span>{t("settings.payment.modal.phone")}</span>
                <label>
                    <input
                        maxLength='10'
                        name='number'
                        value={values.number}
                        className={inputError && values.number.length === 0 ? 'form-input form-input-error' : "form-input "}
                        type='tel'
                        pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                        placeholder={t("settings.payment.modal.placeholder.11")}
                        onChange={(e) => setValues({ ...values, number: e.target.value })} />
                </label>

                <span>{t("settings.payment.modal.number")}</span>

                <div >
                    <CardElement options={ CARD_OPTIONS } />
                </div>
                
                <div className={`${rootclass}-stripe-error-container`}>
                    {cardError.decline_code && <span className ={`${rootclass}-stripe-error-text`}>{t(`stripeErrors.${cardError.decline_code}`)}</span>}
                    {cardError.code && !cardError.decline_code && <span className ={`${rootclass}-stripe-error-text`}>{t(`stripeErrors.${cardError.code}`)}</span>}
                </div>
            </>
        } */}
        </>
    );
}

export default AddingPaymentMethod