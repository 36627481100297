import React, { useState } from 'react';
import Modal from 'react-modal';
import PaymentMethod from './Components/PaymentMethod'
import Complete from './Components/Complete'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { customStyles } from './customStyles';
import Loading from '../../Loading/Icon';
import { useCurrentUserQuery } from '../../../../types/withhooks'

const rootclass = "modal-pay"

const CampaignPayModal = ({ offer, invoice, offers, campaign, modal, setModal, setUpdate }) => {
	const [step, setStep] = useState(0);
	const [inputError, setInputError] = useState(false);
	const {data: currentUserData, loading:loadingCurrentUser} = useCurrentUserQuery();
	const [cardError, setCardError ] = useState({
		state: false,
		influencerStripeError: false,
		errorMessage: ''
	});
	const [values, setValues] = useState({
		name: '',
		company: '',
		address: '',
		apartment: '',
		city: '',
		country: '',
		province: '',
		postalCode: '',
		email: '',
		phone: '',
		creditName: '',
		creditNumber: '',
		expDate: '',
		cvc: '',
		ccId:'',
		invoiceId: invoice.id
	});

	const closeModal = (e) => {
		setModal(false);
		setStep(0);
		setInputError(false);
		setCardError({
			state: false,
			influencerStripeError: false,
			errorMessage: ''
		});
		setValues({
			name: '',
			company: '',
			address: '',
			apartment: '',
			city: '',
			country: '',
			province: '',
			postalCode: '',
			email: '',
			phone: '',
			creditName: '',
			creditNumber: '',
			expDate: '',
			cvc: '',
			ccId:'',
			invoiceId: ''
		});
	};

	return invoice ? (
		<Modal isOpen={modal} style={customStyles}>
			<div className='modal-offer-close'>
				<button type='button' className='btn-tertiary' onClick={closeModal}>
					{' '}
					<FontAwesomeIcon icon='times' />
				</button>
			</div>

		{/* If there is a cash payment to be made, collect payment with stripe checkout */}
		{step <= 2 &&
				<>
				<PaymentMethod
					invoice={invoice}
					rootclass={rootclass}
					step={step} 
					setStep={setStep} 
					values={values} 
					setValues={setValues} 
					setCardError={setCardError}
					campaign={campaign}
					cardError={cardError}
					setModal={setModal}
					closeModal={closeModal}
					inputError={inputError}
					setInputError={setInputError}
					setUpdate={setUpdate}
					me={currentUserData?.currentUser}
					loadingUser={loadingCurrentUser}
				/>
			</>
		}
		{step === 3 && 
			<Complete 
				rootclass={rootclass}
				influencer={offer?.profile?.displayName}
			/>
		}

		</Modal>
	) : (
		<Loading />
	);
};

export default CampaignPayModal;