import React from 'react';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { customStyles } from './customStyles';
import { useTranslation } from 'react-i18next'
// import ReactGA from 'react-ga';

const CampaignDeleteModal = ({ campaign, modal, setModal }) => {
    const { t } = useTranslation();

    return (
        <Modal isOpen={modal} style={customStyles}>
            <div className="modal-offer-close">
                <button type="button" className="btn-tertiary" onClick={() => setModal(false)}>
                    <FontAwesomeIcon icon="times" />
                </button>
            </div>
            <div className="modal-upload-header">
                <div><span>{t("modal.delete.title")}</span></div>
            </div>
            <div className="modal-delete-content">{t("modal.delete.confirmation.1")} <span>{campaign.name ? campaign.name : 'campaign'}</span>{t("modal.delete.confirmation.2")}</div>
            <div className="modal-offer-footer">
                <div className="modal-offer-btn">
                    <button className="btn-secondary" >{t("modal.delete.confirmation.1")} <span>{campaign.name ? campaign.name : 'campaign'}</span>{t("modal.delete.delete")}</button>
                    <button className="btn-primary" onClick={() => setModal(false)}>{t("modal.delete.cancel")}</button>
                </div>
            </div>
        </Modal>
    )
}

export default CampaignDeleteModal;
