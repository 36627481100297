import React, {useState } from 'react';
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
const Loading = ({ rootclass }) => {
    const { t } = useTranslation();
    const [windowWidth] = useState(window.innerWidth);
    const history = useHistory();

    window.setTimeout(function () {
        history.push('/');
    }, 2000);

    if(windowWidth <= 425) {
        return (
            <main className="loading-overlay">
                <h1>{t("loading.icon")}</h1>
                <div>
                    <i className="fas fa-circle-notch fa-spin fa-gradient" aria-hidden="true"></i>
                    {/* <FontAwesomeIcon icon="circle-notch" spin /> */}
                </div>
            </main>
        )
    } else {
        if (rootclass){
            return (
                <div className={`loading-${rootclass}`}>
                    <div>
                        <i className="fas fa-circle-notch fa-spin fa-gradient" aria-hidden="true"></i>
                        {/* <FontAwesomeIcon icon="circle-notch" spin /> */}
                    </div>
                </div>
            )
        }else{
            return (
                <main className="loading">
                    <h1>{t("loading.icon")}</h1>
                    <div>
                        <i className="fas fa-circle-notch fa-spin fa-gradient" aria-hidden="true"></i>
                        {/* <FontAwesomeIcon icon="circle-notch" spin /> */}
                    </div>
                </main>
            )
        }
    }
    
}

export default Loading