import React from "react"

/**
 * This component takes the component it wraps, and adds a CSS property to it.
 * Also, it adds a new children to that component. It adds a badge.
 * This component must wrap a real HTML element
 */
export function NotificationBadgeWrapper(props) {
    const { badge } = props;

    // Update container element styles
    let children = React.Children.only(props.children)

    let initialStyles = children.props.style ? children.props.style : {}

    if (initialStyles.position) {
        console.warn('The CSS "position" property is modified by the NotificationViewWrapper component.')
    }

    // Return the component with the badge added
    return React.cloneElement(
        children, 
        {style: {...initialStyles, position: "relative"}}, 
        badge,
        ...React.Children.toArray(children.props.children)
    )
    
    // This approach breaks easily the existing styles
    // return (
    //     <div style={{position: "relative"}}>
    //         {badge}
    //         {children}
    //     </div>
    // )
}
