import React from 'react';
import { useTranslation } from 'react-i18next'

const Loading = ({ rootclass }) => {
    const { t } = useTranslation();

    if (rootclass){
        return (
            <div className={`loading-${rootclass}`}>
                <div className={`loading-${rootclass}-container`}>
                    <i className="fas fa-circle-notch fa-spin fa-gradient" aria-hidden="true"></i>
                    {/* <FontAwesomeIcon icon="circle-notch" spin /> */}
                </div>
            </div>
        )
    } else {
        return (
            <main className={ window.innerWidth >500 ? "loading": "loading-overlay"}>
                <h1>{t("loading.icon")}</h1>
                <div>
                    <i className="fas fa-circle-notch fa-spin fa-gradient" aria-hidden="true"></i>
                    {/* <FontAwesomeIcon icon="circle-notch" spin /> */}
                </div>
            </main>
        )
    }
    

}

export default Loading
