import React, {useState, useEffect} from 'react';
import { useTranslation } from 'react-i18next'
import { useOnboardingStore } from '../../../../hooks';
import ReactGA from 'react-ga';
import Tooltip from '@material-ui/core/Tooltip';
import ReactCodeInput from 'react-code-input'
import Loading from '../../../Global/Loading/Icon'

const rootclass = "onboarding";

const VerifyEmail = ({ step, setStep }) => {
    const { t } = useTranslation();
    const yourSignupStore = useOnboardingStore(state => state.signUp);
    const [loadingCode, setLoadingCode]=useState(false);
    const [loadingResend, setLoadingResend]=useState(false);
    const [code, setCode]=useState('');
    const [resend, setResend]=useState(false);
    const [errors, setErrors]=useState({});

    useEffect(() => {
        if(code){
            let result = code.match(/[0-9]/g);
            if(!result || (result.length < code.length)){
                setErrors({
                    code: t("onboarding.screen.verification.errors.code")
                })
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [code]);

    const handleSubmit = () => {
        setLoadingCode(true);
        
        if(yourSignupStore.stored){ 
            let authToken = new Headers();
            authToken.append("Authorization", `Bearer ${yourSignupStore.token}`);

            let formdata = new FormData();

            var requestOptions = {
            method: 'POST',
            headers: authToken,
            body: formdata,
            redirect: 'follow'
            };

            fetch(`${process.env.REACT_APP_EMAIL_VERIFY_ENDPOINT}?code=${code}`, requestOptions)

            .then(response => response.text())

            .then(result => {
                let data = JSON.parse(result);
                console.log(data)

                if(data.message){
                    setLoadingCode(false);
                    ReactGA.event({
                        category: `Brand User Onboarding`,
                        action: `Email Verification Unsuccessful`,
                        label: 'N/A'
                    });
                    setErrors({
                        code: data.message
                    });
                    setResend(true);
                }
                if(data.success){
                    setLoadingCode(false);
                    ReactGA.event({
                        category: `Brand User Onboarding`,
                        action: `Email Verified Successfully`,
                        label: 'N/A'
                    });
                    localStorage.setItem('token', yourSignupStore.token)
                    setStep(step+1);
                } 
            })

            .catch(error => {
                setLoadingCode(false);
                //console.log('error', error);
                setErrors({
                    code: t("onboarding.screen.verification.errors.our")
                })
            });
        } else {
            setLoadingCode(false);
            setErrors({
                code: t("onboarding.screen.verification.errors.our")
            })
        }
    }

    const handleResendCode = () => {
        setLoadingResend(true);
        ReactGA.event({
            category: `Brand User Onboarding`,
            action: `Resent Email Code`,
            label: 'N/A'
        });

        if(yourSignupStore.stored){
            let authToken = new Headers();
            authToken.append("Authorization", `Bearer ${yourSignupStore.token}`);

            var requestOptions = {
                method: 'GET',
                headers: authToken,
                redirect: 'follow'
            };

            fetch(`${process.env.REACT_APP_RESEND_EMAIL_CODE}`, requestOptions)

            .then(response => response.text())

            .then(result => {
                let data = JSON.parse(result)

                if(data.success){
                    setLoadingResend(false);
                    setErrors({
                        resend: t("onboarding.screen.verification.errors.resent")
                    })
                } 
            })

            .catch(error => {
                setLoadingResend(false);
                console.log('error', error);
                setErrors({
                    code: t("onboarding.screen.verification.errors.our")
                })
            });
        } else {
            setLoadingResend(false);
            setErrors({
                code: t("onboarding.screen.verification.errors.our")
            })
        }
    }

    return (
        <>
        {step === 3 &&
            <>
                <img className={`${rootclass}-signUp-img`} src="signup_img.png" alt={t("onboarding.screen.verification.alt")} ></img>
                <h1>{t("onboarding.screen.verification.title")}</h1>
                <h5 className={`${rootclass}-signUp-title`}>{t("onboarding.screen.verification.subtitle.1")}<br/><span>{yourSignupStore.values.email}</span><br/>{t("onboarding.screen.verification.subtitle.2")}</h5>
                <label className={`${rootclass}-signUp-code`}>
                    <Tooltip arrow open placement="left" title={errors.code ? errors.code : ""} >
                        <ReactCodeInput 
                            type='number' 
                            fields={6} 
                            value={code}
                            onChange={e => {
                                setErrors({});
                                setCode(e);
                            }}
                            inputStyle={{
                                'backgroundColor': '#474747',
                                'borderRadius': '6px',
                                'width': '40px',
                                'height': '40px',
                                'fontSize': '27px',
                                'lineHeight': '32px',
                                'fontWeight': '700',
                                'color': '#FFFFFF',
                                'margin': '4px',
                                'border': '1px solid #474747',
                                'textAlign': 'center'
                            }}
                        />
                   
                        
                        {/* <div className={errors.code ? "form-input form-input-error" : "form-input"}>
                            <input
                                name='verificationCode'
                                value={code}
                                type="tel"
                                placeholder={t("onboarding.screen.verification.description")}
                                onChange={e => {
                                    setErrors({})
                                    setCode(e.target.value)
                                }}
                                maxLength="6"
                                required
                            />
                            {errors.code && <FontAwesomeIcon icon="times-circle" className="form-error-icon" />}
                        </div> */}
                    </Tooltip>
                </label>

                {/* {!loading ?
                    <div>
                        {values.confirmPassword === "" || values.password === "" || values.fullname === ""  || values.email === "" || values.acceptedTerms === false ? <button className="btn-primary" disabled onClick={handleSubmit}>{t("onboarding.screen.2.create")}</button> : <button className="btn-primary" onClick={handleSubmit}>{t("onboarding.screen.2.create")}</button>}
                    </div>
                    :
                    <Loading rootclass={rootclass} />
                } */}


                <div className={`${rootclass}-skip`}>
                    {!loadingCode ? 
                        <button
                            onClick={handleSubmit}
                            disabled={code?.length <=5 || errors.code}
                            className="btn-primary">{t("onboarding.screen.verification.submit")}
                        </button>
                        :
                        <>
                            <Loading rootclass={rootclass} />
                        </>
                        }
                </div>

                {resend && 
                    <div className={`${rootclass}-resend`}>
                        {!loadingResend ?
                            <button
                                onClick={handleResendCode}
                                className="btn-quaternary">{!errors.resend ? t("onboarding.screen.verification.resend") : errors.resend}
                            </button>
                            :
                            <Loading rootclass={rootclass} /> }
                    </div>}
            </>}
        </>
    )
}
export default VerifyEmail;