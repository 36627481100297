import { gql, useApolloClient } from "@apollo/client";
import { useEffect, useState } from "react";
import { useStore } from "./useStore";

const SIGNIN_MUTATION = gql`
  mutation AdminSignIn($password: String!) {
    adminSignIn(password: $password)
  }
`;

const SIGNEDIN_QUERY = gql`
  query {
    adminSignedIn
  }
`;

const LOGOUT_MUTATION = gql`
  mutation AdminSignOut {
    adminSignOut
  }
`;

const ADMIN_COMET_ID = gql`
  query AdminCometChatId {
      adminCometchatID
  }
`;

export function useAdminSignin() {
  const client = useApolloClient();
  const [loading, setLoading] = useState();
  const [error, setError] = useState('');
  const {isAdminAuth, setIsAdminAuth} = useStore()

  useEffect(() => {
    if(!isAdminAuth) {
      checkAdminSignedIn()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function adminSignIn(password) {
    try {
      setLoading(true);
      const { data } = await client.mutate({
        mutation: SIGNIN_MUTATION,
        variables: { password },
      });
      
      if(data.adminSignIn) {
        setIsAdminAuth(true);
      }
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }

  async function checkAdminSignedIn() {
    try {
      setLoading(true);
      const { data } = await client.query({
        query: SIGNEDIN_QUERY,
        fetchPolicy: 'network-only',
      });

      if(data.adminSignedIn) {
        setIsAdminAuth(true);
      }
    } catch (err) {
    } finally {
      setLoading(false)
    }
  }

  async function getAdminCometchatId() {
    try {
      const { data } = await client.query({
        query: ADMIN_COMET_ID,
      });
      if(data.adminCometchatID){
        return data.adminCometchatID;
      }
    } catch (err) {}
  }

  async function adminSignOut() {
    try {
      setLoading(true);
      const { data } = await client.mutate({
        mutation: LOGOUT_MUTATION,
      });
      if(data.adminSignOut){
        setIsAdminAuth();
      }
    } catch (err) {
    } finally {
      setLoading(false)
    }
  }

  return {
    error,
    loading,
    adminSignIn,
    adminSignOut,
    checkAdminSignedIn,
    getAdminCometchatId,
  }
}