import React from 'react';
import Modal from 'react-modal';
import { useCampaignUpdateIsPublishedMutation } from '../../../../types/withhooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { customStyles } from './customStyles';
import { useTranslation } from 'react-i18next';
import ReactGA from 'react-ga';

const CampaignHideModal = ({ campaign, modal, setModal, setUpdate }) => {
    const { t } = useTranslation();
 const [ campaignUpdateIsPublished ] = useCampaignUpdateIsPublishedMutation();

    const handleUpdateIsPublished = async () => {
        await campaignUpdateIsPublished({
            variables:{
                input: {
                    campaignId: campaign.id,
                    campaignBody: {
                        isPublished: !campaign.isPublished
                    }
                }
            },
        })

        if(campaign.isPublished){
            ReactGA.event({
                category: `Brand User`,
                action: `Campaign Unpublished`,
                label: `Campaign ID: ${campaign.id}`
            });
        } else {
            ReactGA.event({
                category: `Brand User`,
                action: `Campaign Published from modal`,
                label: `Campaign ID: ${campaign.id}`
            });
        }

        setModal(false);
        setUpdate(true);
    }

    return (

        <Modal isOpen={modal} style={customStyles}>
            <div className="modal-offer-close">
                <button type="button" className="btn-tertiary" onClick={() => setModal(false)}>
                    <FontAwesomeIcon icon="times" />
                </button>
            </div>
            <div className="modal-upload-header">
                <div><span>{campaign.isPublished ? t("modal.unpublish.title") : t("modal.publish.title")}</span></div>
            </div>

            <div className="modal-delete-content">
                {campaign.isPublished ? 
                    <>
                        {t("modal.unpublish.content")} <span>{campaign.name ? campaign.name : 'campaign'}?</span>{t("modal.unpublish.content1")}
                    </>
                    :
                    <>
                        {t("modal.publish.content")} <span>{campaign.name ? campaign.name : 'campaign'}?</span>{t("modal.publish.content1")}
                    </>
                }
            </div>

            <div className="modal-offer-footer">
                <div className="modal-offer-btn">
                    <button className="btn-secondary" onClick={() => setModal(false)}>{t("modal.delete.cancel")}</button>
                    {campaign.isPublished ? 
                        <>
                            <button className="btn-primary" onClick={handleUpdateIsPublished}>{t("modal.unpublish.button")}</button>
                        </>
                        :
                        <>
                            <button className="btn-primary" onClick={handleUpdateIsPublished}>{t("modal.publish.button")}</button>
                        </>
                    }
                    
                </div>
            </div>
        </Modal>
    )
}

export default CampaignHideModal;
