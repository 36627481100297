import React, { useState, } from 'react';
import { Link, useHistory } from "react-router-dom";
import Tooltip from '@material-ui/core/Tooltip';
import CampaignPitchModal from '../Modals/CampaignPitchModal';
import CampaignOfferModal from '../Modals/CampaignOfferModal';
import CampaignPayModal from '../Modals/CampaignPayModal';
import { useStore } from '../../../hooks/useStore'
import { OfferNotificationBadge, ApplicationNotificationBadge } from '../../Notifications/badges';
import { NotificationBadgeWrapper } from '../../Notifications/NotificationBadgeWrapper';
import { ApplicationController } from '../../Notifications/controllers';
import { useTranslation } from 'react-i18next'
import ReactGA from 'react-ga';

const rootclass = 'sidebar-tile'

const SidebarTile = ({ applications, campaign, campaigns, sponsorship, sponsorships, offers, invoices, title, tooltip, primaryBtn, secondaryBtn, update, setUpdate }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const imgError = useStore(state => state.imgError);
    const [application, setApplication] = useState();
    const [offer, setOffer] = useState();
    const [invoice, setInvoice] = useState();
    const [pitchModal, setPitchModal] = useState(false);
    const [offerModal, setOfferModal] = useState(false);
    const [payModal, setPayModal] = useState(false);

    const message = () => {
        localStorage.setItem("currentPath", "/messages");
        history.push('/messages');
    }   

    const handleAction = (action, app, id) => {
        switch (action) {
            // View Pitch
            case t("campaignManage.inbound.4"):
                if(window.location.href.includes('campaign')){
                    ReactGA.modalview(`/campaign/${app.campaign.id}/pitch`);
                } else {
                    ReactGA.modalview(`/home/${app.campaign.id}/pitch`);
                }
                setApplication(app)
                return setPitchModal(true);
            // Offer
            case t("campaignManage.inbound.3"):
                if(window.location.href.includes('campaign')){
                    ReactGA.modalview(`/campaign/${app.campaign.id}/offer`);
                } else {
                    ReactGA.modalview(`/home/${app.campaign.id}/offer`);
                }
                setApplication(app)
                return setOfferModal(true);
            // Edit Offer
            case t("campaignManage.offer.4"):
                ReactGA.modalview(`/campaign/${app.campaign.id}/editOffer`);
                setOffer(app);
                return setOfferModal(true);
            // View Offer
            case t("campaignManage.awaiting.4"):
                ReactGA.modalview(`/campaign/${app.campaign.id}/viewOffer`);
                setOffer(app);
                return setOfferModal(true);
            // Pay
            case t("campaignManage.awaiting.3"):
                ReactGA.modalview(`/campaign/${app.product.campaign.id}/pay`);
                setInvoice(app);
                return setPayModal(true);
            // Message
            case t("campaignManage.offer.3"):
                return message(app, id);
            default:
                return t("campaignManage.noAction")
        }
    }

    return (
        <>
            <div className={rootclass}>
                <h2>{title}</h2>
                <Tooltip arrow placement="left" title={tooltip} >
                    <div className={`${rootclass}-tooltip`}>
                        {t("campaignManage.what")}
                        </div>
                </Tooltip>
                {applications && applications.length === 0
                    ? <>
                        <span>{t("campaignManage.noInbound")}</span>
                        <div className={`${rootclass}-btn`}>
                            <Link to='/influencer' className="btn-primary">{t("campaignManage.view")}</Link>
                        </div>
                    </>
                    : <div className={`${rootclass}-content`}> 
                        {application && <CampaignPitchModal application={application} creator={application?.profile} modal={pitchModal} setModal={setPitchModal} setOfferModal={setOfferModal} />}
                        {application && campaigns && <CampaignOfferModal influencer={application?.profile} campaign={campaign || application?.campaign} modal={offerModal} update={update} setUpdate={setUpdate} setModal={setOfferModal}/>}
                        {invoice && invoices && <CampaignPayModal offer={offer} invoice={invoice} offers={offers} campaign ={offer?.campaign} modal={payModal} setModal={setPayModal} setUpdate={setUpdate} />}
                        {offers && offers.length > 0 && offer && campaigns && <CampaignOfferModal influencer={offer?.profile} campaign={campaign || offer?.campaign} modal={offerModal} update={update} setUpdate={setUpdate} setModal={setOfferModal}/>}
                        {applications && applications.map((application, i) => {
                            return (
                                <div key={i} >
                                    <div className={`${rootclass}-influencer`}>
                                        <div>
                                            <Link to={`/influencer/${application?.profile?.id}`}>
                                                <NotificationBadgeWrapper badge={<ApplicationNotificationBadge applicationId={application?.id} />}>
                                                    <figure>
                                                        <ApplicationController applicationId={application?.id}>
                                                            <img src={application?.profile?.profilePictureUrl ? application?.profile?.profilePictureUrl : '/placeholder-profile.png'} alt={`${application?.profile?.displayName} profile`} onError={(e)=>imgError(e)}/>
                                                        </ApplicationController>
                                                    </figure>
                                                </NotificationBadgeWrapper>
                                            </Link>
                                            <div>
                                                <Link to={`/influencer/${application?.profile?.id}`}>
                                                    <h3>{application?.profile?.displayName ? application?.profile?.displayName : t("campaignManage.noName")}</h3>
                                                </Link>

                                                {/* needs to be refactored */}
                                                {/* <div>
                                                    {application.creator && application.creator.instaLink && <a href={application.creator.instaLink}><FontAwesomeIcon icon={['fab', 'instagram']} /></a>}
                                                    {application.creator && application.creator.facebookLink && <a href={application.creator.facebookLink}><FontAwesomeIcon icon={['fab', 'facebook']} /></a>}
                                                    {application.creator && application.creator.twitterLink && <a href={application.creator.twitterLink}><FontAwesomeIcon icon={['fab', 'twitter']} /></a>}
                                                    {application.creator && application.creator.youtubeLink && <a href={application.creator.youtubeLink}><FontAwesomeIcon icon={['fab', 'youtube']} /></a>}
                                                </div> */}
                                            </div>
                                        </div>
                                        <div>
                                            <ApplicationController applicationId={application?.id}>
                                                <button className="btn-secondary" onClick={() => handleAction(secondaryBtn, application)}>{secondaryBtn}</button>
                                            </ApplicationController>
                                            <ApplicationController applicationId={application?.id || application?.node?.id}>
                                                <button className="btn-primary" onClick={() => handleAction(primaryBtn, application)}>{primaryBtn}</button>
                                            </ApplicationController>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                        {offers && !invoices && offers.filter(offer => offer.state === 'for_influencer' || offer.state === 'accepted' || (offer.state === 'fulfilled' && offer.cashPaymentOffer !==0)).map((offer, i) => {
                            return (
                                <div key={i} >
                                    <div className={`${rootclass}-influencer`}>
                                        <div>
                                            <Link to={`/influencer/${offer.profile.id}`}>
                                                <NotificationBadgeWrapper badge={<OfferNotificationBadge offerId={offer.id}/>}>
                                                    <figure>
                                                        <img src={offer.profile.profilePictureUrl ? offer.profile.profilePictureUrl : '/placeholder-profile.png'} alt={`${offer.profile.displayName} profile`} onError={(e)=>imgError(e)}/>
                                                    </figure>
                                                </NotificationBadgeWrapper>
                                            </Link>
                                            <div>
                                                <Link to={`/influencer/${offer.profile.id}`}>
                                                    <h3>{offer.profile.displayName ? offer.profile.displayName : t("campaignManage.noName")}</h3>
                                                </Link>

                                                {/* social links need to be refactored */}
                                                {/* <div>
                                                    {offer.creator && offer.creator.instaLink && <a href={offer.creator.instaLink}><FontAwesomeIcon icon={['fab', 'instagram']} /></a>}
                                                    {offer.creator && offer.creator.facebookLink && <a href={offer.creator.facebookLink}><FontAwesomeIcon icon={['fab', 'facebook']} /></a>}
                                                    {offer.creator && offer.creator.twitterLink && <a href={offer.creator.twitterLink}><FontAwesomeIcon icon={['fab', 'twitter']} /></a>}
                                                    {offer.creator && offer.creator.youtubeLink && <a href={offer.creator.youtubeLink}><FontAwesomeIcon icon={['fab', 'youtube']} /></a>}
                                                </div> */}
                                            </div>
                                        </div>
                                        <div>
                                            {<button className="btn-secondary" onClick={() => handleAction(secondaryBtn, offer)}>{secondaryBtn}</button>}
                                            {primaryBtn===t("campaignManage.awaiting.3") && offer.status === "accepted" ? <button className="btn-primary" onClick={() => handleAction(primaryBtn, offer, offer.groupchatId)}>{t("campaignManage.hire")}</button> 
                                            :
                                            <button className="btn-primary" onClick={() => handleAction(primaryBtn, offer, offer.groupchatId)}>{primaryBtn}</button>}
                                        </div>
                                    </div>
                                </div>
                            )
                        })}

                        {invoices && invoices.filter(invoice => invoice.invoiceStatus !== "paid").map((invoice,i)=>{
                            return (
                                <div key={i} >
                                    <div className={`${rootclass}-influencer`}>
                                        <div>
                                            <Link to={`/influencer/${invoice.product.profile.id}`}>
                                                <NotificationBadgeWrapper badge={<OfferNotificationBadge offerId={invoice.product.id}/>}>
                                                    <figure>
                                                        <img src={invoice.product.profile.profilePictureUrl ? invoice.product.profile.profilePictureUrl : '/placeholder-profile.png'} alt={`${invoice.product.profile.displayName} profile`} onError={(e)=>imgError(e)}/>
                                                    </figure>
                                                </NotificationBadgeWrapper>
                                            </Link>
                                            <div>
                                                <Link to={`/influencer/${invoice.product.profile.id}`}>
                                                    <h3>{invoice.product.profile.displayName ? invoice.product.profile.displayName : t("campaignManage.noName")}</h3>
                                                </Link>

                                                {/* social links need to be refactored */}
                                                {/* <div>
                                                    {offer.creator && offer.creator.instaLink && <a href={offer.creator.instaLink}><FontAwesomeIcon icon={['fab', 'instagram']} /></a>}
                                                    {offer.creator && offer.creator.facebookLink && <a href={offer.creator.facebookLink}><FontAwesomeIcon icon={['fab', 'facebook']} /></a>}
                                                    {offer.creator && offer.creator.twitterLink && <a href={offer.creator.twitterLink}><FontAwesomeIcon icon={['fab', 'twitter']} /></a>}
                                                    {offer.creator && offer.creator.youtubeLink && <a href={offer.creator.youtubeLink}><FontAwesomeIcon icon={['fab', 'youtube']} /></a>}
                                                </div> */}
                                            </div>
                                        </div>
                                        <div>
                                            {<button className="btn-secondary" onClick={() => handleAction(secondaryBtn, invoice.product)}>{secondaryBtn}</button>}
                                            {primaryBtn===t("campaignManage.awaiting.3") && invoice.product.status === "accepted" ? <button className="btn-primary" onClick={() => handleAction(primaryBtn, invoice.product, invoice.product.groupchatId)}>{t("campaignManage.hire")}</button> 
                                            :
                                            <button className="btn-primary" onClick={() => handleAction(primaryBtn, invoice, invoice.product.groupchatId)}>{primaryBtn}</button>}
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>}
            </div >
        </>
    )
}

export default SidebarTile

