import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next'

const Complete =({ rootclass, influencer }) => {
	const { t } = useTranslation();

    return (
        <div className={`${rootclass}-complete`}>
            <p>{t('modal.pay.payOffer.pricing.success.1')} {influencer} {t('modal.pay.payOffer.pricing.success.2')}</p>
            <FontAwesomeIcon icon='check-circle' /> 
        </div>
    )
}

export default Complete