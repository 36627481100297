import React from "react";
import { useNotificationStore } from "../../../hooks";
import { AbstractNotificationBadge } from "./AbstractNotificationBadge";
import { useTranslation } from 'react-i18next'
import { NotificationType } from "../../../types/withhooks";

export function OfferNotificationBadge(props) {
    const { t } = useTranslation();
    const { offerId } = props;
    const OFFER_BADGES = {
        [NotificationType.OfferAccepted]: t("notifications.badges.new")
    }
    const unreadNotifications = useNotificationStore(state => state.unreadNotifications, (a, b) => a.size === b.size);
    
    let associatedNotification;

    // 1. Check for event match
    // 2. Check that the entity still exists
    // 3. Match for the id
    for (let notification of unreadNotifications.values()) {
        if (notification.type in OFFER_BADGES) {
            if (offerId === notification.offer?.id) {
                associatedNotification = notification;
            }
        }

        if (associatedNotification) {
            break;
        }
    }

    if (associatedNotification) {
        return <AbstractNotificationBadge className={"badge-component-avatar"} message={OFFER_BADGES[associatedNotification.type]} />;
    } else {
        return null;
    }
}
