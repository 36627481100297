import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Signin from './Screens/SignIn/index';
import VerifyEmail from './Screens/VerifyEmail'
import Brand from './Screens/Brand';
import Blocked from './Screens/Blocked'
import Complete from './Screens/Complete';
import ReactGA from 'react-ga';

// import LinkAccounts from './Screens/LinkAccounts';
//import Categories from './Screens/Categories';
//import { useBrandCreate, useBrandUpdate } from '../../hooks';

//import SubscriptionType from './Screens/SubscriptionType';
// import Loading from "../Global/Loading/Icon";
//import { useTranslation } from 'react-i18next'

const rootclass = 'onboarding';

const SignIn = ({ me, isAuth, windowWidth }) => {
    //const { t } = useTranslation();
    const [values, setValues] = useState({
        name: '',
        websiteUrl: '',
        description: '',
    })
    const history = useHistory();
    const [step, setStep] = useState(1);
    const [image, setImage] = useState();
    const [logo, setLogo] = useState();
    //const { createBrand, loading: creatingBrand } = useBrandCreate();

    useEffect(() => {
        if(isAuth){
            history.push('/');
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuth]);

    useEffect(() => {
        if(step === 2){
            ReactGA.event({
                category: `Brand User Onboarding`,
                action: `Verify Email (from sign in)`,
                label: 'N/A'
            });
        }
        if(step === 3){
            ReactGA.event({
                category: `Brand User Onboarding`,
                action: `Blocked (from sign in)`,
                label: 'N/A'
            });
        }
        if(step === 4){
            ReactGA.event({
                category: `Brand User Onboarding`,
                action: `Create Brand (from sign in)`,
                label: 'N/A'
            });
        }
        if(step === 5){
            ReactGA.event({
                category: `Brand User Onboarding`,
                action: `Completed`,
                label: 'N/A'
            });
        }
    }, [step]);

    return ( 
        <>
            {step <= 4 ?
                    <section className={rootclass}>
                    {step === 0 && history.push('/')}
                    {step === 1 && <Signin me={me} rootclass={rootclass} isAuth={isAuth} step={step} windowWidth={windowWidth} setStep={setStep} />}
                    {step === 2 && <VerifyEmail rootclass={rootclass} step={step} setStep={setStep} />}
                    {step === 3 && <Blocked rootclass={rootclass} setStep={setStep} />}
                    {step === 4 && <Brand rootclass={rootclass} windowWidth={windowWidth} values={values} setValues={setValues} step={step} setStep={setStep} image={image} setImage={setImage} logo={logo} setLogo={setLogo}/>}
                    
                    {/* {step === 3 && <LinkAccounts step={step} setStep={setStep} />} */}
                </section>
                : 
                <section>
                    <Complete rootclass={rootclass} windowWidth={windowWidth} values={values} image={image} logo={logo}/>
                </section>
            }
        </>
    )
}
export default SignIn