import React from 'react';
import { useHistory } from 'react-router-dom';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next'
import ReactGA from 'react-ga';

const customStyles = {
    overlay: {
        background: 'rgb(26,26,26,0.75)',
    },
    content: {
        backgroundColor: '#2E2E2E',
        border: 'none',
        borderRadius: '8px',
        color: '#F1F1F1',
        margin: 'auto',
        width: '50%',
        height: '65%',
    }
};

const CampaignPitchModal = ({ application, applications, influencer, modal, setModal, setOfferModal }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const payment = [
        application?.acceptsPayByProduct && t("modal.pitch.product"),
        application?.acceptsPayByStoreCredit && t("modal.pitch.credit"),
        application?.acceptsPayByCash && t("modal.pitch.cash")
    ].filter(x => x !== false)

    const handleHire = () => {
        if(window.location.href.includes('campaign') && !influencer){
            ReactGA.modalview(`/campaign/${application.campaign.id}/offer`);
        } else if (!window.location.href.includes('campaign') && !influencer){
            ReactGA.modalview(`/home/${application.campaign.id}/offer`);
        } else {
            ReactGA.modalview(`/influencer/${influencer.id}/offer`);
        }
        setModal(false);
        setOfferModal(true);
    }
    
    return (
        <Modal isOpen={modal} style={customStyles}>
            <div className="modal-offer-close">
                <button type="button" className="btn-tertiary" onClick={() => setModal(false)}>
                    <FontAwesomeIcon icon="times" />
                </button>
            </div>
            {application &&
                <>
                    <div className="modal-offer-header">
                        <img src={application?.profile?.profilePictureUrl ? application?.profile?.profilePictureUrl : '/placeholder-profile.png'} alt={`${application?.profile?.displayName} profile`} />
                        <span>{t("modal.pitch.offer")} {application?.profile?.displayName ? application?.profile?.displayName : t("modal.pitch.noName")}</span>
                    </div>

                    <div className="modal-offer-subheader">
                        <strong>{t("modal.pitch.pitch.1")} {application?.campaign?.name}</strong>
                        {t("modal.pitch.pitch.2")}{' '}<span>${application?.paymentEstimateCents/100}</span> {' '}| {t("modal.pitch.pitch.3")} {' '} <span>{payment.filter(i => i !== false).join(', ')}</span>
                    </div>
                    <div className="modal-offer-selection">
                        {application?.pitch}
                    </div>
                </>
            }
            {applications &&
                <>
                    <div className="modal-offer-header">
                        <img src={influencer?.profilePictureUrl ? influencer.profilePictureUrl : '/placeholder-profile.png'} alt={`${influencer.displayName}'s profile`} />
                        <span>{t("modal.pitch.offer")} {influencer.displayName ? influencer.displayName : t("modal.pitch.noName")}</span>
                    </div>
                    <div className="modal-offer-selection">
                        {applications.map((application, i) => {
                            let appPayment = [
                                application.acceptsPayByProduct && t("modal.pitch.product"),
                                application.acceptsPayByCredit && t("modal.pitch.credit"),
                                application.acceptsPayByCash && t("modal.pitch.cash")
                            ].filter(x => x !== undefined);
                            return <div key={i} className="modal-offer-pitch">
                                <div className="modal-offer-subheader">
                                    <strong>{t("modal.pitch.pitch.1")}{application?.campaign?.name}</strong>
                                    {t("modal.pitch.pitch.2")}{' '}<span>{`$${application?.paymentEstimateCents/100}`}</span> {' '}| {t("modal.pitch.pitch.3")} {' '} <span>{appPayment.filter(i => i !== false).join(', ')}</span>
                                </div>
                                {application?.pitch}
                            </div>
                        })}
                    </div>
                </>
            }
            <div className="modal-offer-footer">
                <hr />
                <div className="modal-offer-btn">
                    {window.location.pathname === '/' && <button className="btn-secondary" onClick={() => history.push(`/influencer/${application?.profile?.id}`)}>{t("modal.pitch.profile")}</button>}
                    <button className="btn-primary" onClick={handleHire}>{t("modal.pitch.offer")}</button>
                </div>
            </div>
        </Modal>
    )
}

export default CampaignPitchModal;
