import React from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next'

const rootclass = 'page-not-found'

const PageNotFound = () =>{
    const { t } = useTranslation();
    const history = useHistory();

    const handleGoHome = () => {
        history.push('/');
    }

    return (
        <div className={rootclass}>
            <h1>404</h1>
            <h2>{t("404.title")}</h2>
            <p>{t("404.desc")}</p>
            <button className={`btn-primary`} onClick={handleGoHome}>{t("404.home")}</button>
        </div>
        
    )
}

export default PageNotFound