import React, { useCallback } from "react"
import { useNotificationStore } from "../../../hooks";
import { NotificationType, useNotificationMarkAsReadMutation } from "../../../types/withhooks";
import { AbstractController } from "./AbstractController"

export function ApplicationController({ children, applicationId }) {

    const [notificationMarkAsRead] = useNotificationMarkAsReadMutation({
        onCompleted: (data) => {
            if (data?.notificationMarkAsRead?.notification?.id) {
                markAsRead(data?.notificationMarkAsRead?.notification?.id, data?.notificationMarkAsRead?.notification?.readAt)
            }
        }
    })
    
    const unreadNotifications = useNotificationStore(state => state.unreadNotifications, (a, b) => a.size === b.size);
    const markAsRead = useNotificationStore(state => state.markAsRead);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const APPLICATION_EVENTS = {
        [NotificationType.ApplicationCreated]: true
    }

    const fun = useCallback(() => {
        // 1. Check for event match
        // 2. Check that the entity still exists
        // 3. Match for the id
        for (const n of unreadNotifications.values()) {
            if (n.type in APPLICATION_EVENTS) {
                if (n.application) {
                    if (n.application.id === applicationId) {
                        notificationMarkAsRead({
                            variables: {
                                notificationId: n.id
                            }
                        })
                    }
                }
            }
        }

    }, [unreadNotifications, notificationMarkAsRead, applicationId, APPLICATION_EVENTS ])
    
    return (
        <AbstractController fun={fun}>
            {children}
        </AbstractController>
    )
}