import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A valid URL, transported as a string */
  File: any;
  /** An ISO 8601-encoded date */
  ISO8601Date: any;
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: any;
  /** Represents untyped JSON */
  JSON: any;
};

export type Auth_Provider_Credentials = {
  email: Scalars['String'];
  password: Scalars['String'];
};

/** An enum to describe what type of account is interacting with the API. */
export enum Account {
  /** Represents influencers and acts on their behalf */
  Agent = 'agent',
  /** An athlete, or anyone who is looking to make money. */
  Influencer = 'influencer',
  /** An employee of one of our organizations */
  Personnel = 'personnel'
}

/** The different actions that need to be taken for user to complete their account. */
export enum AccountAction {
  /** This user needs to add their agency information */
  CreateAgency = 'create_agency',
  /** This user needs to add their organization information */
  CreateOrganization = 'create_organization',
  /** This user needs create link their instagram account. */
  LinkInstagram = 'link_instagram',
  /** Stripe requires information from the user. */
  StripeActionRequired = 'stripe_action_required',
  /** This user needs to submit the invitation code provided by the ATNMY team */
  SubmitInvitationCode = 'submit_invitation_code',
  /** This user needs to verify their email. */
  VerifyEmail = 'verify_email',
  /** This user is pending approval from the admin team. */
  WaitForApproval = 'wait_for_approval'
}

export type AccountCanBeAddedPayload = {
  __typename?: 'AccountCanBeAddedPayload';
  canBeAdded: Scalars['Boolean'];
  /** Why the user cannot be added */
  errorMessage?: Maybe<Scalars['String']>;
  /** Profile is returned if the user is already on FireWork™ */
  profile?: Maybe<Profile>;
};

export type Agency = Node & Payable & {
  __typename?: 'Agency';
  about?: Maybe<Scalars['String']>;
  agents: AgentConnection;
  applications?: Maybe<ApplicationConnection>;
  /** The balance ready to be cashed out. */
  availableBalanceCents: Scalars['Int'];
  /** UUID of the object. */
  id: Scalars['ID'];
  invoices: InvoiceConnection;
  name: Scalars['String'];
  offers?: Maybe<OfferConnection>;
  /** Total amount paid out to the influencer. */
  payoutTotalCents: Scalars['Int'];
  photoUrl?: Maybe<Scalars['String']>;
  profiles: ProfileConnection;
  /** The balance that has been collected but cannot be paid out until the deliverables are met. */
  unavailableBalanceCents: Scalars['Int'];
  /** The balance that has not been collected from the brands yet. */
  uncollectedBalanceCents: Scalars['Int'];
};


export type AgencyAgentsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type AgencyApplicationsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<ApplicationFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type AgencyInvoicesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<InvoiceFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type AgencyOffersArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<OfferFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type AgencyProfilesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** Autogenerated input type of AgencyCreate */
export type AgencyCreateInput = {
  about?: Maybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  photo?: Maybe<Scalars['File']>;
};

/** Autogenerated return type of AgencyCreate */
export type AgencyCreatePayload = {
  __typename?: 'AgencyCreatePayload';
  agency?: Maybe<Agency>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<UserError>;
};

export type AgencyLinkRequest = Node & {
  __typename?: 'AgencyLinkRequest';
  agent: Agent;
  /** UUID of the object. */
  id: Scalars['ID'];
  minimumFeeCents: Scalars['Int'];
  profile: Profile;
  state: AgencyLinkRequestState;
};

/** The connection type for AgencyLinkRequest. */
export type AgencyLinkRequestConnection = {
  __typename?: 'AgencyLinkRequestConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<AgencyLinkRequestEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<AgencyLinkRequest>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type AgencyLinkRequestEdge = {
  __typename?: 'AgencyLinkRequestEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<AgencyLinkRequest>;
};

/** Autogenerated input type of AgencyLinkRequestResolve */
export type AgencyLinkRequestResolveInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The new state of the request */
  newState: AgencyLinkRequestState;
  /** The ID of the request to be resolved. */
  requestId: Scalars['ID'];
};

/** Autogenerated return type of AgencyLinkRequestResolve */
export type AgencyLinkRequestResolvePayload = {
  __typename?: 'AgencyLinkRequestResolvePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<UserError>;
  success: Scalars['Boolean'];
};

/** An enum that contains the list of valid states for a request. */
export enum AgencyLinkRequestState {
  /** Request was approved by the influencer. */
  Approved = 'approved',
  /** Request was declined by the influencer. */
  Declined = 'declined',
  /** Request hasn't been resolved by the influencer. */
  Pending = 'pending'
}

/** Autogenerated input type of AgencyProfileAdd */
export type AgencyProfileAddInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  handlesAndFees: Array<HandleAndFee>;
};

/** Autogenerated return type of AgencyProfileAdd */
export type AgencyProfileAddPayload = {
  __typename?: 'AgencyProfileAddPayload';
  agency?: Maybe<Agency>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<UserError>;
};

/** Autogenerated input type of AgencyProfileRemove */
export type AgencyProfileRemoveInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  profileId: Scalars['ID'];
};

/** Autogenerated return type of AgencyProfileRemove */
export type AgencyProfileRemovePayload = {
  __typename?: 'AgencyProfileRemovePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<UserError>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of AgencyUpdate */
export type AgencyUpdateInput = {
  about?: Maybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['File']>;
};

/** Autogenerated return type of AgencyUpdate */
export type AgencyUpdatePayload = {
  __typename?: 'AgencyUpdatePayload';
  agency?: Maybe<Agency>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<UserError>;
};

export type Agent = Node & User & {
  __typename?: 'Agent';
  agency?: Maybe<Agency>;
  agencyId?: Maybe<Scalars['ID']>;
  agencyLinkRequests?: Maybe<AgencyLinkRequestConnection>;
  applications?: Maybe<ApplicationConnection>;
  approved: Scalars['Boolean'];
  associatedCampaigns: CampaignRelationConnection;
  /** The email of the user. */
  email?: Maybe<Scalars['String']>;
  emailPreference: EmailPreference;
  /** The full name of the user. */
  fullName?: Maybe<Scalars['String']>;
  /** UUID of the object. */
  id: Scalars['ID'];
  notificationPreference: NotificationPreference;
  /** User notifications */
  notifications: NotificationConnection;
  offers?: Maybe<OfferConnection>;
  preferences?: Maybe<UserPreference>;
  profileLists: ProfileListConnection;
  profiles: ProfileConnection;
  /** A list of the actions that need to be taken on the account. */
  requirements?: Maybe<Array<AccountAction>>;
  /** User notifications */
  unreadNotifications: NotificationConnection;
};


export type AgentAgencyLinkRequestsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type AgentApplicationsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<ApplicationFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type AgentAssociatedCampaignsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<CampaignRelationFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type AgentNotificationsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type AgentOffersArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<OfferFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type AgentProfileListsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type AgentProfilesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type AgentUnreadNotificationsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** The connection type for Agent. */
export type AgentConnection = {
  __typename?: 'AgentConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<AgentEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Agent>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type AgentEdge = {
  __typename?: 'AgentEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Agent>;
};

export type Application = Node & {
  __typename?: 'Application';
  acceptsPayByCash: Scalars['Boolean'];
  acceptsPayByProduct: Scalars['Boolean'];
  acceptsPayByStoreCredit: Scalars['Boolean'];
  applicant: User;
  campaign: Campaign;
  /** UUID of the object. */
  id: Scalars['ID'];
  paymentEstimateCents?: Maybe<Scalars['Int']>;
  pitch?: Maybe<Scalars['String']>;
  profile: Profile;
  state: ApplicationState;
};

/** The connection type for Application. */
export type ApplicationConnection = {
  __typename?: 'ApplicationConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ApplicationEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Application>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** Autogenerated input type of ApplicationCreate */
export type ApplicationCreateInput = {
  acceptsPayByCash?: Maybe<Scalars['Boolean']>;
  acceptsPayByProduct?: Maybe<Scalars['Boolean']>;
  acceptsPayByStoreCredit?: Maybe<Scalars['Boolean']>;
  /** The campaign for which you are applying */
  campaignId: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  paymentEstimateCents?: Maybe<Scalars['Int']>;
  pitch?: Maybe<Scalars['String']>;
  /** The profile with which the application is associated to */
  profileId: Scalars['ID'];
};

/** Autogenerated return type of ApplicationCreate */
export type ApplicationCreatePayload = {
  __typename?: 'ApplicationCreatePayload';
  application?: Maybe<Application>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<UserError>;
};

/** An edge in a connection. */
export type ApplicationEdge = {
  __typename?: 'ApplicationEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Application>;
};

export type ApplicationFilter = {
  campaignId?: Maybe<Scalars['ID']>;
  profileId?: Maybe<Scalars['ID']>;
  states?: Maybe<Array<ApplicationState>>;
};

/** The allowed states of an application. */
export enum ApplicationState {
  Applied = 'applied',
  Offered = 'offered',
  Rejected = 'rejected'
}

/** A Brand Object */
export type Brand = Node & {
  __typename?: 'Brand';
  about?: Maybe<Scalars['String']>;
  applications?: Maybe<ApplicationConnection>;
  campaigns: CampaignConnection;
  /** UUID of the object. */
  id: Scalars['ID'];
  invoices: InvoiceConnection;
  name: Scalars['String'];
  offers?: Maybe<OfferConnection>;
  photoUrl?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
};


/** A Brand Object */
export type BrandApplicationsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<ApplicationFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** A Brand Object */
export type BrandCampaignsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** A Brand Object */
export type BrandInvoicesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<InvoiceFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** A Brand Object */
export type BrandOffersArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<OfferFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** The connection type for Brand. */
export type BrandConnection = {
  __typename?: 'BrandConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<BrandEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Brand>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type BrandEdge = {
  __typename?: 'BrandEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Brand>;
};

export type Campaign_Body = {
  ageRangeMax?: Maybe<Scalars['Int']>;
  ageRangeMin?: Maybe<Scalars['Int']>;
  dateEnd?: Maybe<Scalars['ISO8601Date']>;
  dateStart?: Maybe<Scalars['ISO8601Date']>;
  deliverableDescriptions?: Maybe<Array<DeliverableDescriptionInput>>;
  description?: Maybe<Scalars['String']>;
  gender?: Maybe<Gender>;
  isPublished?: Maybe<Scalars['Boolean']>;
  keyMessage?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  paymentCashCents?: Maybe<Scalars['Int']>;
  paymentStoreCreditCents?: Maybe<Scalars['Int']>;
  paymentStoreProductCents?: Maybe<Scalars['Int']>;
  photo?: Maybe<Scalars['File']>;
};

/** A Campaign Object */
export type Campaign = Node & {
  __typename?: 'Campaign';
  ageRangeMax?: Maybe<Scalars['Int']>;
  ageRangeMin?: Maybe<Scalars['Int']>;
  agencyAvailableProfiles?: Maybe<Array<Profile>>;
  applications?: Maybe<ApplicationConnection>;
  brand?: Maybe<Brand>;
  dateEnd?: Maybe<Scalars['ISO8601Date']>;
  dateStart?: Maybe<Scalars['ISO8601Date']>;
  deliverableDescriptions?: Maybe<Array<DeliverableDescription>>;
  description?: Maybe<Scalars['String']>;
  gender?: Maybe<Gender>;
  id: Scalars['ID'];
  invoices: InvoiceConnection;
  isPublished?: Maybe<Scalars['Boolean']>;
  keyMessage?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  offers?: Maybe<OfferConnection>;
  paymentCashCents?: Maybe<Scalars['Int']>;
  paymentStoreCreditCents?: Maybe<Scalars['Int']>;
  paymentStoreProductCents?: Maybe<Scalars['Int']>;
  photoUrl?: Maybe<Scalars['String']>;
};


/** A Campaign Object */
export type CampaignApplicationsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<ApplicationFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** A Campaign Object */
export type CampaignInvoicesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<InvoiceFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** A Campaign Object */
export type CampaignOffersArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<OfferFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** The connection type for Campaign. */
export type CampaignConnection = {
  __typename?: 'CampaignConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<CampaignEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Campaign>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** Autogenerated input type of CampaignCreate */
export type CampaignCreateInput = {
  /** The brand for which this campaign will be created. */
  brandId: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CampaignCreate */
export type CampaignCreatePayload = {
  __typename?: 'CampaignCreatePayload';
  campaign?: Maybe<Campaign>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<UserError>;
};

/** An edge in a connection. */
export type CampaignEdge = {
  __typename?: 'CampaignEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Campaign>;
};

export type CampaignFilter = {
  paymentTypes?: Maybe<Array<CampaignPayment>>;
  /** By default, we only show "new" campaigns that influencers have not applied to or gotten an offer for yet */
  showOldCampaigns?: Maybe<Scalars['Boolean']>;
};

export enum CampaignPayment {
  Cash = 'cash',
  StoreCredit = 'store_credit',
  StoreProduct = 'store_product'
}

/** A composite type describing the relationship between a profile and a campaign */
export type CampaignRelation = {
  __typename?: 'CampaignRelation';
  campaign: Campaign;
  profile: Profile;
  relation: OfferOrApplication;
};

/** The connection type for CampaignRelation. */
export type CampaignRelationConnection = {
  __typename?: 'CampaignRelationConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<CampaignRelationEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<CampaignRelation>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type CampaignRelationEdge = {
  __typename?: 'CampaignRelationEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<CampaignRelation>;
};

export enum CampaignRelationFilter {
  All = 'all',
  PendingApplications = 'pending_applications',
  PendingOffers = 'pending_offers'
}

/** Autogenerated input type of CampaignUpdate */
export type CampaignUpdateInput = {
  /** The updated field data on the campaign. */
  campaignBody: Campaign_Body;
  /** The UUID of the campaign you wish to update. */
  campaignId: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CampaignUpdate */
export type CampaignUpdatePayload = {
  __typename?: 'CampaignUpdatePayload';
  campaign?: Maybe<Campaign>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<UserError>;
};

export type Deliverable = Node & {
  __typename?: 'Deliverable';
  caption?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  mediaUrls?: Maybe<Array<Scalars['String']>>;
  metadata?: Maybe<Scalars['JSON']>;
  offer: Offer;
  state?: Maybe<DeliverableState>;
  type?: Maybe<DeliverableType>;
};

/** Autogenerated input type of DeliverableAccept */
export type DeliverableAcceptInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The deliverable which we are approving */
  deliverableId: Scalars['ID'];
};

/** Autogenerated return type of DeliverableAccept */
export type DeliverableAcceptPayload = {
  __typename?: 'DeliverableAcceptPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  deliverable?: Maybe<Deliverable>;
  errors: Array<UserError>;
};

/** Autogenerated input type of DeliverableComplete */
export type DeliverableCompleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The deliverable which we are marking as complete */
  deliverableId: Scalars['ID'];
  /** The ID of the Instagram post which completes this deliverable. Only applicable to `post` and `video_post` types. */
  postId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of DeliverableComplete */
export type DeliverableCompletePayload = {
  __typename?: 'DeliverableCompletePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  deliverable?: Maybe<Deliverable>;
  errors: Array<UserError>;
};

/** The connection type for Deliverable. */
export type DeliverableConnection = {
  __typename?: 'DeliverableConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<DeliverableEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Deliverable>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type DeliverableDescription = {
  __typename?: 'DeliverableDescription';
  /** General description for what you want out of the deliverable(s) */
  description?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['ISO8601DateTime']>;
  metadata?: Maybe<Scalars['JSON']>;
  type: DeliverableType;
};

/** The options used to configure deliverables */
export type DeliverableDescriptionInput = {
  /** General description for what you want out of the deliverable(s) */
  description?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['ISO8601DateTime']>;
  metadata?: Maybe<Scalars['JSON']>;
  type: DeliverableType;
};

/** An edge in a connection. */
export type DeliverableEdge = {
  __typename?: 'DeliverableEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Deliverable>;
};

/** Autogenerated input type of DeliverableReject */
export type DeliverableRejectInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The deliverable which we are rejecting */
  deliverableId: Scalars['ID'];
};

/** Autogenerated return type of DeliverableReject */
export type DeliverableRejectPayload = {
  __typename?: 'DeliverableRejectPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  deliverable?: Maybe<Deliverable>;
  errors: Array<UserError>;
};

/** The possible state of deliverables */
export enum DeliverableState {
  /** The brand has accepted the content */
  Accepted = 'accepted',
  /** The brand has not reviewed the content yet */
  AwaitingReview = 'awaiting_review',
  /** The content has been posted */
  Delivered = 'delivered',
  /** The influencer/agent needs to revise the content */
  NeedsRevision = 'needs_revision',
  /** The influencer/agent has not submitted anything yet */
  Pending = 'pending'
}

/** Autogenerated input type of DeliverableSubmit */
export type DeliverableSubmitInput = {
  /** The caption to be reviewed */
  caption: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The deliverable which we are submitting for review */
  deliverableId: Scalars['ID'];
  /** The media to be reviewed */
  media?: Maybe<Array<Scalars['File']>>;
};

/** Autogenerated return type of DeliverableSubmit */
export type DeliverableSubmitPayload = {
  __typename?: 'DeliverableSubmitPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  deliverable?: Maybe<Deliverable>;
  errors: Array<UserError>;
};

/** The possible types of deliverables */
export enum DeliverableType {
  /** General requests */
  General = 'general',
  /** A regular instagram post */
  Post = 'post',
  /** An instagram story */
  Story = 'story',
  /** A video instagram post */
  VideoPost = 'video_post'
}

export type EmailPreference = {
  __typename?: 'EmailPreference';
  applicationAccepted: Scalars['Boolean'];
  applicationCreated: Scalars['Boolean'];
  contentApproved: Scalars['Boolean'];
  contentSubmitted: Scalars['Boolean'];
  contentUpdated: Scalars['Boolean'];
  dummyAction: Scalars['Boolean'];
  messageReceived: Scalars['Boolean'];
  passwordReset: Scalars['Boolean'];
  paymentReceived: Scalars['Boolean'];
  paymentSetupNeeded: Scalars['Boolean'];
  user: User;
};

/** Autogenerated input type of EmailPreferencesUpdate */
export type EmailPreferencesUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  emailType: EmailType;
  newValue: Scalars['Boolean'];
};

/** Autogenerated return type of EmailPreferencesUpdate */
export type EmailPreferencesUpdatePayload = {
  __typename?: 'EmailPreferencesUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<UserError>;
  user?: Maybe<User>;
};

export enum EmailType {
  /** An application for a campaign was accepted */
  ApplicationAccepted = 'APPLICATION_ACCEPTED',
  /** A new application for a campaign was created */
  ApplicationCreated = 'APPLICATION_CREATED',
  /** Content for a deliverable has been approved */
  ContentApproved = 'CONTENT_APPROVED',
  /** Content for a deliverable has been submitted */
  ContentSubmitted = 'CONTENT_SUBMITTED',
  /** Content for a deliverable has been updated */
  ContentUpdated = 'CONTENT_UPDATED',
  /** For development purposes */
  DummyAction = 'DUMMY_ACTION',
  /** A new message was received */
  MessageReceived = 'MESSAGE_RECEIVED',
  /** The user password was changed */
  PasswordReset = 'PASSWORD_RESET',
  /** A payment has been received */
  PaymentReceived = 'PAYMENT_RECEIVED',
  /** Stripe account needs to be setup in order to receive payments */
  PaymentSetupNeeded = 'PAYMENT_SETUP_NEEDED'
}

/** Autogenerated input type of ExpoTokenRemove */
export type ExpoTokenRemoveInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The expo token that will be removed */
  expoToken: Scalars['String'];
};

/** Autogenerated return type of ExpoTokenRemove */
export type ExpoTokenRemovePayload = {
  __typename?: 'ExpoTokenRemovePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<UserError>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of ExpoTokenSave */
export type ExpoTokenSaveInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The expo token that will be stored */
  expoToken: Scalars['String'];
};

/** Autogenerated return type of ExpoTokenSave */
export type ExpoTokenSavePayload = {
  __typename?: 'ExpoTokenSavePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<UserError>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of FakeLinkInstagram */
export type FakeLinkInstagramInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  handle: Scalars['String'];
};

/** Autogenerated return type of FakeLinkInstagram */
export type FakeLinkInstagramPayload = {
  __typename?: 'FakeLinkInstagramPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};


/** Controversial. */
export enum Gender {
  Any = 'any',
  Female = 'female',
  Male = 'male'
}

export type HandleAndFee = {
  /** Instagram handle of the influencer being added */
  handle: Scalars['String'];
  minimumFeeCents: Scalars['Int'];
};

export type HypeauditorReport = {
  __typename?: 'HypeauditorReport';
  accountQualityScore?: Maybe<Scalars['Float']>;
  /** e.g. High percent of authentic audience; Excellent likes activity; Excellent comments activity */
  accountQualityScoreDescription?: Maybe<Scalars['String']>;
  /** e.g. Excellent */
  accountQualityScoreName?: Maybe<Scalars['String']>;
  /** Of the form {gender, value, by_age_group: [{group, value}]} */
  audienceAgesFemale?: Maybe<Scalars['JSON']>;
  /** Of the form {gender, value, by_age_group: [{group, value}]} */
  audienceAgesMale?: Maybe<Scalars['JSON']>;
  /** Of the form [{username, prc, merged_accounts: [string], sort_value, image_url}] */
  audienceBrandAffinity?: Maybe<Scalars['JSON']>;
  /** Of the form [{name, value, id (for geoname)}] */
  audienceCities?: Maybe<Scalars['JSON']>;
  /** Of the form [{name, code, value}] */
  audienceCountries?: Maybe<Scalars['JSON']>;
  /** Of the form [{name, value}] */
  audienceEthnicity?: Maybe<Scalars['JSON']>;
  /** Of the form {"0k-5k", "5k-10k", "10k-25k", ...}. */
  audienceIncome?: Maybe<Scalars['JSON']>;
  /** Of the form [(name, weight)] */
  audienceInterests?: Maybe<Scalars['JSON']>;
  /** Of the form [{code (ISO 639-1), value}] */
  audienceLanguages?: Maybe<Scalars['JSON']>;
  /** Of the form {real, susp, infs, mass} */
  audienceType?: Maybe<Scalars['JSON']>;
  /** Of the form [{name, value}] */
  audienceUsStates?: Maybe<Scalars['JSON']>;
  averageComments?: Maybe<Scalars['Float']>;
  averageLikes?: Maybe<Scalars['Float']>;
  /** Of the form [{username, name, avatar, mentions_count, mention_er, category}] */
  brandMentions?: Maybe<Scalars['JSON']>;
  /** e.g. United States (USA), Los Angeles */
  country?: Maybe<Scalars['String']>;
  engagementRate?: Maybe<Scalars['Float']>;
  /** e.g. Excellent, compared to other 1M+ followers accounts */
  engagementRateDescription?: Maybe<Scalars['String']>;
  engagementRateForAds?: Maybe<Scalars['Float']>;
  /** e.g. excellent. Yes, it seems to be all lowercase >:( */
  engagementRateForAdsName?: Maybe<Scalars['Float']>;
  estimatedReachMax?: Maybe<Scalars['Int']>;
  estimatedReachMin?: Maybe<Scalars['Int']>;
  followCount?: Maybe<Scalars['Int']>;
  followerCount?: Maybe<Scalars['Int']>;
  fullName?: Maybe<Scalars['String']>;
  handle?: Maybe<Scalars['String']>;
  instagramAccount: InstagramAccount;
  postFrequency?: Maybe<Scalars['Float']>;
};



export type Influencer = Node & Payable & User & {
  __typename?: 'Influencer';
  applications?: Maybe<ApplicationConnection>;
  approved: Scalars['Boolean'];
  /** The balance ready to be cashed out. */
  availableBalanceCents: Scalars['Int'];
  /** The email of the user. */
  email?: Maybe<Scalars['String']>;
  emailPreference: EmailPreference;
  /** The full name of the user. */
  fullName?: Maybe<Scalars['String']>;
  /** UUID of the object. */
  id: Scalars['ID'];
  invoices: InvoiceConnection;
  notificationPreference: NotificationPreference;
  /** User notifications */
  notifications: NotificationConnection;
  offers?: Maybe<OfferConnection>;
  /** Total amount paid out to the influencer. */
  payoutTotalCents: Scalars['Int'];
  preferences?: Maybe<UserPreference>;
  profile?: Maybe<Profile>;
  profileLists: ProfileListConnection;
  /** A list of the actions that need to be taken on the account. */
  requirements?: Maybe<Array<AccountAction>>;
  /** The balance that has been collected but cannot be paid out until the deliverables are met. */
  unavailableBalanceCents: Scalars['Int'];
  /** The balance that has not been collected from the brands yet. */
  uncollectedBalanceCents: Scalars['Int'];
  /** User notifications */
  unreadNotifications: NotificationConnection;
};


export type InfluencerApplicationsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<ApplicationFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type InfluencerInvoicesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<InvoiceFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type InfluencerNotificationsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type InfluencerOffersArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<OfferFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type InfluencerProfileListsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type InfluencerUnreadNotificationsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** The connection type for Influencer. */
export type InfluencerConnection = {
  __typename?: 'InfluencerConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<InfluencerEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Influencer>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type InfluencerEdge = {
  __typename?: 'InfluencerEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Influencer>;
};

export type InstagramAccount = {
  __typename?: 'InstagramAccount';
  biography?: Maybe<Scalars['String']>;
  followCount?: Maybe<Scalars['Int']>;
  followerCount?: Maybe<Scalars['Int']>;
  handle: Scalars['String'];
  hypeauditorReport?: Maybe<HypeauditorReport>;
  id: Scalars['ID'];
  instagramId: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  postCount?: Maybe<Scalars['Int']>;
  profile: Profile;
  profilePictureUrl?: Maybe<Scalars['String']>;
  /** Of the form [{caption, comments_count, id, like_count, media_type, media_url, permalink}] */
  recentPosts?: Maybe<Scalars['JSON']>;
};

/** Autogenerated return type of InstagramAccountUpdateRequest */
export type InstagramAccountUpdateRequestPayload = {
  __typename?: 'InstagramAccountUpdateRequestPayload';
  errors: Array<UserError>;
  instagramAccount?: Maybe<InstagramAccount>;
};

export type Interest = {
  __typename?: 'Interest';
  /** Unique identifier for interests */
  code: Scalars['String'];
  name: Scalars['String'];
};

/** An Invoice Object */
export type Invoice = Node & {
  __typename?: 'Invoice';
  brand: Brand;
  id: Scalars['ID'];
  invoiceStatus: InvoiceStatus;
  paidAt?: Maybe<Scalars['ISO8601DateTime']>;
  payoutAmountCents: Scalars['Int'];
  product: Offer;
  recipient: Payable;
};

/** The connection type for Invoice. */
export type InvoiceConnection = {
  __typename?: 'InvoiceConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<InvoiceEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Invoice>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type InvoiceEdge = {
  __typename?: 'InvoiceEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Invoice>;
};

export type InvoiceFilter = {
  invoiceStatus?: Maybe<Array<InvoiceStatus>>;
};

/** The allowed states of an invoice. */
export enum InvoiceStatus {
  Open = 'open',
  Paid = 'paid'
}


export type Mutation = {
  __typename?: 'Mutation';
  agencyCreate?: Maybe<AgencyCreatePayload>;
  /** Mutation to resolve requests created by agents. */
  agencyLinkRequestResolve?: Maybe<AgencyLinkRequestResolvePayload>;
  agencyProfileAdd?: Maybe<AgencyProfileAddPayload>;
  agencyProfileRemove?: Maybe<AgencyProfileRemovePayload>;
  agencyUpdate?: Maybe<AgencyUpdatePayload>;
  /** Creates an application by an profile to a campaign */
  applicationCreate?: Maybe<ApplicationCreatePayload>;
  /** Creates an unpublished campaign for a brand. */
  campaignCreate?: Maybe<CampaignCreatePayload>;
  /** Updates a campaign for a brand. */
  campaignUpdate?: Maybe<CampaignUpdatePayload>;
  /** Personnel can mark deliverable content as approved */
  deliverableAccept?: Maybe<DeliverableAcceptPayload>;
  /**
   * Influencer/Agent can mark *approved* deliverable as complete. If it is of
   * `post` or `video_post` type, then you must supply `post_id`. Otherwise, you
   * should omit it.
   */
  deliverableComplete?: Maybe<DeliverableCompletePayload>;
  /** Personnel can mark deliverable content as needing revisions */
  deliverableReject?: Maybe<DeliverableRejectPayload>;
  /** Influencers/agents can submit an deliverable for review */
  deliverableSubmit?: Maybe<DeliverableSubmitPayload>;
  emailPreferencesUpdate?: Maybe<EmailPreferencesUpdatePayload>;
  /** Mutation to remove expo tokens */
  expoTokenRemove?: Maybe<ExpoTokenRemovePayload>;
  /** Mutation to save expo tokens for user mobile devices */
  expoTokenSave?: Maybe<ExpoTokenSavePayload>;
  fakeLinkInstagram?: Maybe<FakeLinkInstagramPayload>;
  /** Users can mark their notifications as read */
  notificationMarkAsRead?: Maybe<NotificationMarkAsReadPayload>;
  notificationPreferencesUpdate?: Maybe<NotificationPreferencesUpdatePayload>;
  /** Personnel and Influencers/agents can accept an offer */
  offerAccept?: Maybe<OfferAcceptPayload>;
  /** Personnel and Influencers/agents can see an offer and create a counteroffer */
  offerCounter?: Maybe<OfferCounterPayload>;
  /** Creates an offer by a brand to a profile. At least one payment method must be non-zero */
  offerCreate?: Maybe<OfferCreatePayload>;
  /** Personnel and Influencers/agents can reject an offer */
  offerReject?: Maybe<OfferRejectPayload>;
  organizationCreate?: Maybe<OrganizationCreatePayload>;
  organizationUpdate?: Maybe<OrganizationUpdatePayload>;
  /** Returns an account link for the user to finish onboarding, or update their details. */
  paymentAccountLinkCreate?: Maybe<PaymentAccountLinkCreatePayload>;
  /** Consumes an invoice and payment method and returns a client secret to allow the frontend to finalize the payment flow. */
  paymentPayInvoice?: Maybe<PaymentPayInvoicePayload>;
  /** Detaches a payment method from an account. */
  paymentPaymentMethodDetach?: Maybe<PaymentPaymentMethodDetachPayload>;
  /** Pays out all available funds to a Payable's bank account */
  paymentPayout?: Maybe<PaymentPayoutPayload>;
  /** Returns a setup intent client secret to be used by the frontend when adding a payment method to a customer. */
  paymentSetupIntentCreate?: Maybe<PaymentSetupIntentCreatePayload>;
  /** Sets the interests of the given profile */
  profileInterestsUpdate?: Maybe<ProfileInterestsUpdatePayload>;
  profileListAdd?: Maybe<ProfileListAddPayload>;
  profileListCreate?: Maybe<ProfileListCreatePayload>;
  profileListDelete?: Maybe<ProfileListDeletePayload>;
  profileListRemove?: Maybe<ProfileListRemovePayload>;
  profileListRename?: Maybe<ProfileListRenamePayload>;
  profileUpdate?: Maybe<ProfileUpdatePayload>;
  /** @deprecated moving auth out of graphql */
  requestEmailVerification?: Maybe<RequestEmailVerificationPayload>;
  /** @deprecated Moving towards token based authentication. */
  signIn?: Maybe<SignInPayload>;
  /** @deprecated Moving towards token based authentication. */
  signOut?: Maybe<SignOutPayload>;
  /** @deprecated Moving towards token based authentication. */
  signUp?: Maybe<SignUpPayload>;
  userApprove?: Maybe<UserApprovePayload>;
  /** Creates an account that has already been approved and has a random password. */
  userCreate?: Maybe<UserCreatePayload>;
  userPasswordChange?: Maybe<UserPasswordChangePayload>;
  /** @deprecated moving auth out of graphql */
  verifyEmail?: Maybe<VerifyEmailPayload>;
};


export type MutationAgencyCreateArgs = {
  input: AgencyCreateInput;
};


export type MutationAgencyLinkRequestResolveArgs = {
  input: AgencyLinkRequestResolveInput;
};


export type MutationAgencyProfileAddArgs = {
  input: AgencyProfileAddInput;
};


export type MutationAgencyProfileRemoveArgs = {
  input: AgencyProfileRemoveInput;
};


export type MutationAgencyUpdateArgs = {
  input: AgencyUpdateInput;
};


export type MutationApplicationCreateArgs = {
  input: ApplicationCreateInput;
};


export type MutationCampaignCreateArgs = {
  input: CampaignCreateInput;
};


export type MutationCampaignUpdateArgs = {
  input: CampaignUpdateInput;
};


export type MutationDeliverableAcceptArgs = {
  input: DeliverableAcceptInput;
};


export type MutationDeliverableCompleteArgs = {
  input: DeliverableCompleteInput;
};


export type MutationDeliverableRejectArgs = {
  input: DeliverableRejectInput;
};


export type MutationDeliverableSubmitArgs = {
  input: DeliverableSubmitInput;
};


export type MutationEmailPreferencesUpdateArgs = {
  input: EmailPreferencesUpdateInput;
};


export type MutationExpoTokenRemoveArgs = {
  input: ExpoTokenRemoveInput;
};


export type MutationExpoTokenSaveArgs = {
  input: ExpoTokenSaveInput;
};


export type MutationFakeLinkInstagramArgs = {
  input: FakeLinkInstagramInput;
};


export type MutationNotificationMarkAsReadArgs = {
  input: NotificationMarkAsReadInput;
};


export type MutationNotificationPreferencesUpdateArgs = {
  input: NotificationPreferencesUpdateInput;
};


export type MutationOfferAcceptArgs = {
  input: OfferAcceptInput;
};


export type MutationOfferCounterArgs = {
  input: OfferCounterInput;
};


export type MutationOfferCreateArgs = {
  input: OfferCreateInput;
};


export type MutationOfferRejectArgs = {
  input: OfferRejectInput;
};


export type MutationOrganizationCreateArgs = {
  input: OrganizationCreateInput;
};


export type MutationOrganizationUpdateArgs = {
  input: OrganizationUpdateInput;
};


export type MutationPaymentAccountLinkCreateArgs = {
  input: PaymentAccountLinkCreateInput;
};


export type MutationPaymentPayInvoiceArgs = {
  input: PaymentPayInvoiceInput;
};


export type MutationPaymentPaymentMethodDetachArgs = {
  input: PaymentPaymentMethodDetachInput;
};


export type MutationPaymentPayoutArgs = {
  input: PaymentPayoutInput;
};


export type MutationPaymentSetupIntentCreateArgs = {
  input: PaymentSetupIntentCreateInput;
};


export type MutationProfileInterestsUpdateArgs = {
  input: ProfileInterestsUpdateInput;
};


export type MutationProfileListAddArgs = {
  input: ProfileListAddInput;
};


export type MutationProfileListCreateArgs = {
  input: ProfileListCreateInput;
};


export type MutationProfileListDeleteArgs = {
  input: ProfileListDeleteInput;
};


export type MutationProfileListRemoveArgs = {
  input: ProfileListRemoveInput;
};


export type MutationProfileListRenameArgs = {
  input: ProfileListRenameInput;
};


export type MutationProfileUpdateArgs = {
  input: ProfileUpdateInput;
};


export type MutationRequestEmailVerificationArgs = {
  input: RequestEmailVerificationInput;
};


export type MutationSignInArgs = {
  input: SignInInput;
};


export type MutationSignOutArgs = {
  input: SignOutInput;
};


export type MutationSignUpArgs = {
  input: SignUpInput;
};


export type MutationUserApproveArgs = {
  input: UserApproveInput;
};


export type MutationUserCreateArgs = {
  input: UserCreateInput;
};


export type MutationUserPasswordChangeArgs = {
  input: UserPasswordChangeInput;
};


export type MutationVerifyEmailArgs = {
  input: VerifyEmailInput;
};

/** An object with an ID. */
export type Node = {
  /** UUID of the object. */
  id: Scalars['ID'];
};

export type Notification = Node & {
  __typename?: 'Notification';
  agent?: Maybe<Agent>;
  application?: Maybe<Application>;
  brand?: Maybe<Brand>;
  campaign?: Maybe<Campaign>;
  createdAt: Scalars['ISO8601DateTime'];
  deliverable?: Maybe<Deliverable>;
  id: Scalars['ID'];
  influencer?: Maybe<Influencer>;
  offer?: Maybe<Offer>;
  profile?: Maybe<Profile>;
  readAt?: Maybe<Scalars['ISO8601DateTime']>;
  type: NotificationType;
  updatedAt: Scalars['ISO8601DateTime'];
  user: User;
};

/** The connection type for Notification. */
export type NotificationConnection = {
  __typename?: 'NotificationConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<NotificationEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Notification>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type NotificationEdge = {
  __typename?: 'NotificationEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Notification>;
};

/** Autogenerated input type of NotificationMarkAsRead */
export type NotificationMarkAsReadInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The notification that will be marked as read */
  notificationId: Scalars['ID'];
};

/** Autogenerated return type of NotificationMarkAsRead */
export type NotificationMarkAsReadPayload = {
  __typename?: 'NotificationMarkAsReadPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<UserError>;
  notification?: Maybe<Notification>;
};

export type NotificationPreference = {
  __typename?: 'NotificationPreference';
  applicationAccepted: Scalars['Boolean'];
  applicationCreated: Scalars['Boolean'];
  applicationRejected: Scalars['Boolean'];
  campaignCreated: Scalars['Boolean'];
  campaignEnded: Scalars['Boolean'];
  campaignHalfway: Scalars['Boolean'];
  campaignOneDayLeft: Scalars['Boolean'];
  campaignStarted: Scalars['Boolean'];
  contentApproved: Scalars['Boolean'];
  contentCompleted: Scalars['Boolean'];
  contentRejected: Scalars['Boolean'];
  contentSubmitted: Scalars['Boolean'];
  contentUpdated: Scalars['Boolean'];
  dummyAction: Scalars['Boolean'];
  linkRequestApproved: Scalars['Boolean'];
  linkRequestCreated: Scalars['Boolean'];
  linkRequestDeclined: Scalars['Boolean'];
  messageReceived: Scalars['Boolean'];
  offerAccepted: Scalars['Boolean'];
  offerCreated: Scalars['Boolean'];
  offerUpdated: Scalars['Boolean'];
  paymentReceived: Scalars['Boolean'];
  paymentSetupNeeded: Scalars['Boolean'];
  reminderTriggered: Scalars['Boolean'];
  user: User;
};

/** Autogenerated input type of NotificationPreferencesUpdate */
export type NotificationPreferencesUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  newValue: Scalars['Boolean'];
  notificationType: NotificationType;
};

/** Autogenerated return type of NotificationPreferencesUpdate */
export type NotificationPreferencesUpdatePayload = {
  __typename?: 'NotificationPreferencesUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<UserError>;
  user?: Maybe<User>;
};

export enum NotificationType {
  /** Profile's application for a campaign was accepted */
  ApplicationAccepted = 'APPLICATION_ACCEPTED',
  /** A new application for a campaign was created */
  ApplicationCreated = 'APPLICATION_CREATED',
  /** Profile's application for a campaign was rejected */
  ApplicationRejected = 'APPLICATION_REJECTED',
  /** A new campaign was created */
  CampaignCreated = 'CAMPAIGN_CREATED',
  /** A campaign has ended */
  CampaignEnded = 'CAMPAIGN_ENDED',
  /** A campaign is halfway */
  CampaignHalfway = 'CAMPAIGN_HALFWAY',
  /** A campaign is only one day left */
  CampaignOneDayLeft = 'CAMPAIGN_ONE_DAY_LEFT',
  /** A campaign has started */
  CampaignStarted = 'CAMPAIGN_STARTED',
  /** A submitted deliverable was approved */
  ContentApproved = 'CONTENT_APPROVED',
  /** An approved deliverable was posted to IG */
  ContentCompleted = 'CONTENT_COMPLETED',
  /** Contet for a deliverable has been rejected */
  ContentRejected = 'CONTENT_REJECTED',
  /** Content for a deliverable has been submitted */
  ContentSubmitted = 'CONTENT_SUBMITTED',
  /** Content for a deliverable has been updated */
  ContentUpdated = 'CONTENT_UPDATED',
  /** Deliverable is overdue */
  DeliverableDueDate = 'DELIVERABLE_DUE_DATE',
  /** A reminder for a deliverable */
  DeliverableReminder = 'DELIVERABLE_REMINDER',
  /** For development purposes */
  DummyAction = 'DUMMY_ACTION',
  /** An agency link request was approved by the influencer */
  LinkRequestApproved = 'LINK_REQUEST_APPROVED',
  /** An agency link request was created */
  LinkRequestCreated = 'LINK_REQUEST_CREATED',
  /** An agency link request was declined by the influencer */
  LinkRequestDeclined = 'LINK_REQUEST_DECLINED',
  /** A new message was received */
  MessageReceived = 'MESSAGE_RECEIVED',
  /** An offer was accepted */
  OfferAccepted = 'OFFER_ACCEPTED',
  /** An offer was created */
  OfferCreated = 'OFFER_CREATED',
  /** An offer was updated */
  OfferUpdated = 'OFFER_UPDATED',
  /** The user received a payment */
  PaymentReceived = 'PAYMENT_RECEIVED',
  /** The user hasn't added payment information to his account */
  PaymentSetupNeeded = 'PAYMENT_SETUP_NEEDED',
  /** A reminder was sent to the user */
  ReminderTriggered = 'REMINDER_TRIGGERED'
}

export type Offer = Node & {
  __typename?: 'Offer';
  agent?: Maybe<Agent>;
  campaign: Campaign;
  deliverableDescriptions: Array<DeliverableDescription>;
  deliverables?: Maybe<DeliverableConnection>;
  exclusivityMonths: Scalars['Int'];
  groupchatId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  offerAcceptDate?: Maybe<Scalars['ISO8601DateTime']>;
  paymentCashCents: Scalars['Int'];
  paymentProductCents: Scalars['Int'];
  paymentStoreCreditCents: Scalars['Int'];
  pitch?: Maybe<Scalars['String']>;
  profile: Profile;
  state: OfferState;
  usageRightsMonths: Scalars['Int'];
};


export type OfferDeliverablesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** Autogenerated input type of OfferAccept */
export type OfferAcceptInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The offer which we are accepting */
  offerId: Scalars['ID'];
};

/** Autogenerated return type of OfferAccept */
export type OfferAcceptPayload = {
  __typename?: 'OfferAcceptPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<UserError>;
  offer?: Maybe<Offer>;
};

/** The connection type for Offer. */
export type OfferConnection = {
  __typename?: 'OfferConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<OfferEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Offer>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** Autogenerated input type of OfferCounter */
export type OfferCounterInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  deliverableDescriptions?: Maybe<Array<DeliverableDescriptionInput>>;
  exclusivityMonths?: Maybe<Scalars['Int']>;
  /** The offer which we are countering */
  offerId: Scalars['ID'];
  paymentCashCents?: Maybe<Scalars['Int']>;
  paymentProductCents?: Maybe<Scalars['Int']>;
  paymentStoreCreditCents?: Maybe<Scalars['Int']>;
  usageRightsMonths?: Maybe<Scalars['Int']>;
};

/** Autogenerated return type of OfferCounter */
export type OfferCounterPayload = {
  __typename?: 'OfferCounterPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<UserError>;
  offer?: Maybe<Offer>;
};

/** Autogenerated input type of OfferCreate */
export type OfferCreateInput = {
  /** The campaign with which the offer is associated to */
  campaignId: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  deliverableDescriptions: Array<DeliverableDescriptionInput>;
  exclusivityMonths?: Maybe<Scalars['Int']>;
  paymentCashCents?: Maybe<Scalars['Int']>;
  paymentProductCents?: Maybe<Scalars['Int']>;
  paymentStoreCreditCents?: Maybe<Scalars['Int']>;
  /** The profile receiving the offer */
  profileId: Scalars['ID'];
  usageRightsMonths?: Maybe<Scalars['Int']>;
};

/** Autogenerated return type of OfferCreate */
export type OfferCreatePayload = {
  __typename?: 'OfferCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<UserError>;
  offer?: Maybe<Offer>;
};

/** An edge in a connection. */
export type OfferEdge = {
  __typename?: 'OfferEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Offer>;
};

export type OfferFilter = {
  campaignId?: Maybe<Scalars['ID']>;
  profileId?: Maybe<Scalars['ID']>;
  states?: Maybe<Array<OfferState>>;
};

/** A union type representing the object that is the relationship between a profile and a campaign */
export type OfferOrApplication = Application | Offer;

/** Autogenerated input type of OfferReject */
export type OfferRejectInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The offer which we are rejecting */
  offerId: Scalars['ID'];
};

/** Autogenerated return type of OfferReject */
export type OfferRejectPayload = {
  __typename?: 'OfferRejectPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<UserError>;
  offer?: Maybe<Offer>;
};

/** Offer states */
export enum OfferState {
  Accepted = 'accepted',
  ForBrand = 'for_brand',
  ForInfluencer = 'for_influencer',
  Fulfilled = 'fulfilled',
  Rejected = 'rejected'
}

/** Represents whether a sorted field should be ascending or descending. */
export enum OrderDirection {
  Asc = 'asc',
  Desc = 'desc'
}

/** An Organization Object */
export type Organization = Node & {
  __typename?: 'Organization';
  about?: Maybe<Scalars['String']>;
  brands: BrandConnection;
  /** UUID of the object. */
  id: Scalars['ID'];
  name: Scalars['String'];
  paymentMethods: Array<PaymentMethod>;
  photoUrl?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
};


/** An Organization Object */
export type OrganizationBrandsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** The connection type for Organization. */
export type OrganizationConnection = {
  __typename?: 'OrganizationConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<OrganizationEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Organization>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** Autogenerated input type of OrganizationCreate */
export type OrganizationCreateInput = {
  about?: Maybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  photo?: Maybe<Scalars['File']>;
  websiteUrl?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of OrganizationCreate */
export type OrganizationCreatePayload = {
  __typename?: 'OrganizationCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<UserError>;
  organization?: Maybe<Organization>;
};

/** An edge in a connection. */
export type OrganizationEdge = {
  __typename?: 'OrganizationEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Organization>;
};

/** Autogenerated input type of OrganizationUpdate */
export type OrganizationUpdateInput = {
  about?: Maybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['File']>;
  websiteUrl?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of OrganizationUpdate */
export type OrganizationUpdatePayload = {
  __typename?: 'OrganizationUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<UserError>;
  organization?: Maybe<Organization>;
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
};

/** An object that carries balances, has invoices, and has payouts. */
export type Payable = {
  /** The balance ready to be cashed out. */
  availableBalanceCents: Scalars['Int'];
  invoices: InvoiceConnection;
  /** Total amount paid out to the influencer. */
  payoutTotalCents: Scalars['Int'];
  /** The balance that has been collected but cannot be paid out until the deliverables are met. */
  unavailableBalanceCents: Scalars['Int'];
  /** The balance that has not been collected from the brands yet. */
  uncollectedBalanceCents: Scalars['Int'];
};


/** An object that carries balances, has invoices, and has payouts. */
export type PayableInvoicesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<InvoiceFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** Autogenerated input type of PaymentAccountLinkCreate */
export type PaymentAccountLinkCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of PaymentAccountLinkCreate */
export type PaymentAccountLinkCreatePayload = {
  __typename?: 'PaymentAccountLinkCreatePayload';
  accountLink?: Maybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<UserError>;
};

/** A payment method. For now, a credit card. */
export type PaymentMethod = Node & {
  __typename?: 'PaymentMethod';
  brand: Scalars['String'];
  id: Scalars['ID'];
  lastFour: Scalars['String'];
};

/** Autogenerated input type of PaymentPayInvoice */
export type PaymentPayInvoiceInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The ID of the invoice that is being paid. */
  invoiceId: Scalars['ID'];
  /** The ID of the payment method that will be charged. */
  paymentMethodId: Scalars['String'];
};

/** Autogenerated return type of PaymentPayInvoice */
export type PaymentPayInvoicePayload = {
  __typename?: 'PaymentPayInvoicePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  clientSecret?: Maybe<Scalars['String']>;
  errors: Array<UserError>;
};

/** Autogenerated input type of PaymentPaymentMethodDetach */
export type PaymentPaymentMethodDetachInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The ID of the payment method that will be charged. */
  paymentMethodId: Scalars['String'];
};

/** Autogenerated return type of PaymentPaymentMethodDetach */
export type PaymentPaymentMethodDetachPayload = {
  __typename?: 'PaymentPaymentMethodDetachPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<UserError>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of PaymentPayout */
export type PaymentPayoutInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of PaymentPayout */
export type PaymentPayoutPayload = {
  __typename?: 'PaymentPayoutPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<UserError>;
  /** Amount on it's way to the user's bank account. */
  paidOut: Scalars['Int'];
};

/** Autogenerated input type of PaymentSetupIntentCreate */
export type PaymentSetupIntentCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of PaymentSetupIntentCreate */
export type PaymentSetupIntentCreatePayload = {
  __typename?: 'PaymentSetupIntentCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  clientSecret?: Maybe<Scalars['String']>;
  errors: Array<UserError>;
};

export type Personnel = Node & User & {
  __typename?: 'Personnel';
  approved: Scalars['Boolean'];
  brands?: Maybe<BrandConnection>;
  /** The email of the user. */
  email?: Maybe<Scalars['String']>;
  emailPreference: EmailPreference;
  /** The full name of the user. */
  fullName?: Maybe<Scalars['String']>;
  /** UUID of the object. */
  id: Scalars['ID'];
  notificationPreference: NotificationPreference;
  /** User notifications */
  notifications: NotificationConnection;
  organization?: Maybe<Organization>;
  preferences?: Maybe<UserPreference>;
  profileLists: ProfileListConnection;
  /** A list of the actions that need to be taken on the account. */
  requirements?: Maybe<Array<AccountAction>>;
  /** User notifications */
  unreadNotifications: NotificationConnection;
};


export type PersonnelBrandsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type PersonnelNotificationsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type PersonnelProfileListsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type PersonnelUnreadNotificationsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** The connection type for Personnel. */
export type PersonnelConnection = {
  __typename?: 'PersonnelConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<PersonnelEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Personnel>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type PersonnelEdge = {
  __typename?: 'PersonnelEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Personnel>;
};

/** A profile object */
export type Profile = Node & {
  __typename?: 'Profile';
  agency?: Maybe<Agency>;
  agencyLinkRequests: AgencyLinkRequestConnection;
  agent?: Maybe<Agent>;
  applications?: Maybe<ApplicationConnection>;
  associatedCampaigns: CampaignRelationConnection;
  bio?: Maybe<Scalars['String']>;
  /** ID of city from countrystatecity.in */
  city?: Maybe<Scalars['Int']>;
  cometchatId?: Maybe<Scalars['ID']>;
  /** An ISO country code. (CISO) */
  country?: Maybe<Scalars['String']>;
  displayName: Scalars['String'];
  id: Scalars['ID'];
  instagramAccount?: Maybe<InstagramAccount>;
  interests: Array<Interest>;
  isLinkedToAgency: Scalars['Boolean'];
  minimumFeeCents: Scalars['Int'];
  offers?: Maybe<OfferConnection>;
  profilePictureUrl?: Maybe<Scalars['String']>;
  /** An ISO state code. (SISO) */
  state?: Maybe<Scalars['String']>;
};


/** A profile object */
export type ProfileAgencyLinkRequestsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** A profile object */
export type ProfileApplicationsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<ApplicationFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** A profile object */
export type ProfileAssociatedCampaignsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<CampaignRelationFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** A profile object */
export type ProfileOffersArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<OfferFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** The connection type for Profile. */
export type ProfileConnection = {
  __typename?: 'ProfileConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ProfileEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Profile>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ProfileEdge = {
  __typename?: 'ProfileEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Profile>;
};

/** Fields for which a list of profiles can be ordered. */
export enum ProfileFields {
  EngagementRate = 'engagement_rate',
  FollowerCount = 'follower_count',
  MinimumFee = 'minimum_fee'
}

export type ProfileFilter = {
  /** ID of city from countrystatecity.in */
  city?: Maybe<Scalars['Int']>;
  /** An ISO country code. (CISO) */
  country?: Maybe<Scalars['String']>;
  interestCodes?: Maybe<Array<Scalars['String']>>;
  matchAllInterests?: Maybe<Scalars['Boolean']>;
  maxEngagementRate?: Maybe<Scalars['Float']>;
  maxFollowerCount?: Maybe<Scalars['Int']>;
  minEngagementRate?: Maybe<Scalars['Float']>;
  minFollowerCount?: Maybe<Scalars['Int']>;
  prefix?: Maybe<Scalars['String']>;
  /** An ISO state code. (SISO) */
  state?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of ProfileInterestsUpdate */
export type ProfileInterestsUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  interestCodes: Array<Scalars['String']>;
  profileId: Scalars['String'];
};

/** Autogenerated return type of ProfileInterestsUpdate */
export type ProfileInterestsUpdatePayload = {
  __typename?: 'ProfileInterestsUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<UserError>;
  success: Scalars['Boolean'];
};

export type ProfileList = Node & {
  __typename?: 'ProfileList';
  /** UUID of the object. */
  id: Scalars['ID'];
  name: Scalars['String'];
  profiles: ProfileConnection;
};


export type ProfileListProfilesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** Autogenerated input type of ProfileListAdd */
export type ProfileListAddInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  profileIds: Array<Scalars['ID']>;
  profileListId: Scalars['ID'];
};

/** Autogenerated return type of ProfileListAdd */
export type ProfileListAddPayload = {
  __typename?: 'ProfileListAddPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<UserError>;
  profileList?: Maybe<ProfileList>;
};

/** The connection type for ProfileList. */
export type ProfileListConnection = {
  __typename?: 'ProfileListConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ProfileListEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<ProfileList>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** Autogenerated input type of ProfileListCreate */
export type ProfileListCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

/** Autogenerated return type of ProfileListCreate */
export type ProfileListCreatePayload = {
  __typename?: 'ProfileListCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<UserError>;
  profileList?: Maybe<ProfileList>;
};

/** Autogenerated input type of ProfileListDelete */
export type ProfileListDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of ProfileListDelete */
export type ProfileListDeletePayload = {
  __typename?: 'ProfileListDeletePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<UserError>;
  success?: Maybe<Scalars['Boolean']>;
};

/** An edge in a connection. */
export type ProfileListEdge = {
  __typename?: 'ProfileListEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<ProfileList>;
};

/** Autogenerated input type of ProfileListRemove */
export type ProfileListRemoveInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  profileIds: Array<Scalars['ID']>;
  profileListId: Scalars['ID'];
};

/** Autogenerated return type of ProfileListRemove */
export type ProfileListRemovePayload = {
  __typename?: 'ProfileListRemovePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<UserError>;
  profileList?: Maybe<ProfileList>;
};

/** Autogenerated input type of ProfileListRename */
export type ProfileListRenameInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

/** Autogenerated return type of ProfileListRename */
export type ProfileListRenamePayload = {
  __typename?: 'ProfileListRenamePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<UserError>;
  profileList?: Maybe<ProfileList>;
};

export type ProfileOrderBy = {
  direction: OrderDirection;
  field: ProfileFields;
};

/** Autogenerated input type of ProfileUpdate */
export type ProfileUpdateInput = {
  bio?: Maybe<Scalars['String']>;
  /** ID of city from countrystatecity.in */
  city?: Maybe<Scalars['Int']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An ISO code of a country. (CISO) */
  country?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  minimumFeeCents?: Maybe<Scalars['Int']>;
  profileId: Scalars['ID'];
  profilePicture?: Maybe<Scalars['File']>;
  /** An ISO code of a state. (SISO) */
  state?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of ProfileUpdate */
export type ProfileUpdatePayload = {
  __typename?: 'ProfileUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<UserError>;
  profile?: Maybe<Profile>;
};

export type Query = {
  __typename?: 'Query';
  /** Used to check if a user can be added as a client to an Agent's Agency */
  accountCanBeAdded: AccountCanBeAddedPayload;
  agency?: Maybe<Agency>;
  agent?: Maybe<Agent>;
  agents: AgentConnection;
  application?: Maybe<Application>;
  campaign?: Maybe<Campaign>;
  campaigns: CampaignConnection;
  currentUser?: Maybe<User>;
  influencer?: Maybe<Influencer>;
  influencers: InfluencerConnection;
  interests: Array<RecursiveInterest>;
  interestsFlat: Array<Interest>;
  invoice?: Maybe<Invoice>;
  offer?: Maybe<Offer>;
  organization?: Maybe<Organization>;
  organizations: OrganizationConnection;
  personnel?: Maybe<Personnel>;
  personnels: PersonnelConnection;
  profile?: Maybe<Profile>;
  profileList?: Maybe<ProfileList>;
  profiles?: Maybe<ProfileConnection>;
  usersToApprove: Array<User>;
};


export type QueryAccountCanBeAddedArgs = {
  handle: Scalars['String'];
};


export type QueryAgencyArgs = {
  id: Scalars['ID'];
};


export type QueryAgentArgs = {
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
};


export type QueryAgentsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryApplicationArgs = {
  id: Scalars['ID'];
};


export type QueryCampaignArgs = {
  id: Scalars['ID'];
};


export type QueryCampaignsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<CampaignFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryInfluencerArgs = {
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
};


export type QueryInfluencersArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryInvoiceArgs = {
  id: Scalars['ID'];
};


export type QueryOfferArgs = {
  id: Scalars['ID'];
};


export type QueryOrganizationArgs = {
  id: Scalars['ID'];
};


export type QueryOrganizationsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryPersonnelArgs = {
  id: Scalars['ID'];
};


export type QueryPersonnelsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryProfileArgs = {
  id: Scalars['ID'];
};


export type QueryProfileListArgs = {
  id: Scalars['ID'];
};


export type QueryProfilesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<ProfileFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ProfileOrderBy>;
};

/** Recursive interest type. A child means that it is a subcategory of the parent interest. Currently limited to depth 2 */
export type RecursiveInterest = {
  __typename?: 'RecursiveInterest';
  /** Subcategories of the parent interest */
  children: Array<RecursiveInterest>;
  /** Unique identifier for interests */
  code: Scalars['String'];
  name: Scalars['String'];
};

/** Autogenerated input type of RequestEmailVerification */
export type RequestEmailVerificationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of RequestEmailVerification */
export type RequestEmailVerificationPayload = {
  __typename?: 'RequestEmailVerificationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of SignIn */
export type SignInInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  credentials: Auth_Provider_Credentials;
};

/** Autogenerated return type of SignIn */
export type SignInPayload = {
  __typename?: 'SignInPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<UserError>;
  token?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

/** Autogenerated input type of SignOut */
export type SignOutInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of SignOut */
export type SignOutPayload = {
  __typename?: 'SignOutPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of SignUp */
export type SignUpInput = {
  accountType: Account;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  credentials: Auth_Provider_Credentials;
  fullName: Scalars['String'];
};

/** Autogenerated return type of SignUp */
export type SignUpPayload = {
  __typename?: 'SignUpPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<UserError>;
  user?: Maybe<User>;
};

export type Subscription = {
  __typename?: 'Subscription';
  /** Freshens the InstagramAccount object. The first subscription update will have the freshened InstagramAccount */
  instagramAccountUpdateRequest: InstagramAccountUpdateRequestPayload;
  userNotificationRead: UserNotificationReadPayload;
  userNotifications: UserNotificationsPayload;
};


export type SubscriptionInstagramAccountUpdateRequestArgs = {
  id: Scalars['ID'];
};


export type SubscriptionUserNotificationReadArgs = {
  userId: Scalars['ID'];
};


export type SubscriptionUserNotificationsArgs = {
  userId: Scalars['ID'];
};

/** A unique consumer of the API. */
export type User = {
  approved: Scalars['Boolean'];
  /** The email of the user. */
  email?: Maybe<Scalars['String']>;
  emailPreference: EmailPreference;
  /** The full name of the user. */
  fullName?: Maybe<Scalars['String']>;
  /** UUID of the object. */
  id: Scalars['ID'];
  notificationPreference: NotificationPreference;
  /** User notifications */
  notifications: NotificationConnection;
  preferences?: Maybe<UserPreference>;
  profileLists: ProfileListConnection;
  /** A list of the actions that need to be taken on the account. */
  requirements?: Maybe<Array<AccountAction>>;
  /** User notifications */
  unreadNotifications: NotificationConnection;
};


/** A unique consumer of the API. */
export type UserNotificationsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** A unique consumer of the API. */
export type UserProfileListsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** A unique consumer of the API. */
export type UserUnreadNotificationsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** Autogenerated input type of UserApprove */
export type UserApproveInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
};

/** Autogenerated return type of UserApprove */
export type UserApprovePayload = {
  __typename?: 'UserApprovePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<UserError>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of UserCreate */
export type UserCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  fullName: Scalars['String'];
  type: Account;
};

/** Autogenerated return type of UserCreate */
export type UserCreatePayload = {
  __typename?: 'UserCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<UserError>;
  password?: Maybe<Scalars['String']>;
};

/** A user-readable error */
export type UserError = {
  __typename?: 'UserError';
  /** A description of the error */
  message: Scalars['String'];
  /** Which input value this error came from */
  path?: Maybe<Array<Scalars['String']>>;
};

/** Autogenerated return type of UserNotificationRead */
export type UserNotificationReadPayload = {
  __typename?: 'UserNotificationReadPayload';
  errors: Array<UserError>;
  readNotification?: Maybe<Notification>;
};

/** Autogenerated return type of UserNotifications */
export type UserNotificationsPayload = {
  __typename?: 'UserNotificationsPayload';
  errors: Array<UserError>;
  newNotification?: Maybe<Notification>;
};

/** Autogenerated input type of UserPasswordChange */
export type UserPasswordChangeInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  currentPassword: Scalars['String'];
  newPassword: Scalars['String'];
};

/** Autogenerated return type of UserPasswordChange */
export type UserPasswordChangePayload = {
  __typename?: 'UserPasswordChangePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<UserError>;
  success: Scalars['Boolean'];
};

export type UserPreference = {
  __typename?: 'UserPreference';
  id: Scalars['ID'];
  language?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['ID']>;
};

/** Autogenerated input type of VerifyEmail */
export type VerifyEmailInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  code: Scalars['String'];
};

/** Autogenerated return type of VerifyEmail */
export type VerifyEmailPayload = {
  __typename?: 'VerifyEmailPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<UserError>;
  user?: Maybe<User>;
};

export type AgentsAdminQueryVariables = Exact<{
  input: Scalars['Int'];
  after: Scalars['String'];
}>;


export type AgentsAdminQuery = (
  { __typename?: 'Query' }
  & { agents: (
    { __typename?: 'AgentConnection' }
    & { edges?: Maybe<Array<Maybe<(
      { __typename?: 'AgentEdge' }
      & Pick<AgentEdge, 'cursor'>
      & { node?: Maybe<(
        { __typename?: 'Agent' }
        & Pick<Agent, 'email' | 'fullName'>
        & { agency?: Maybe<(
          { __typename?: 'Agency' }
          & Pick<Agency, 'about' | 'name' | 'id' | 'photoUrl'>
          & { profiles: (
            { __typename?: 'ProfileConnection' }
            & { edges?: Maybe<Array<Maybe<(
              { __typename?: 'ProfileEdge' }
              & { node?: Maybe<(
                { __typename?: 'Profile' }
                & Pick<Profile, 'id' | 'bio' | 'displayName' | 'cometchatId' | 'profilePictureUrl'>
                & { instagramAccount?: Maybe<(
                  { __typename?: 'InstagramAccount' }
                  & Pick<InstagramAccount, 'handle'>
                )>, applications?: Maybe<(
                  { __typename?: 'ApplicationConnection' }
                  & { nodes?: Maybe<Array<Maybe<(
                    { __typename?: 'Application' }
                    & Pick<Application, 'id' | 'state'>
                  )>>> }
                )>, offers?: Maybe<(
                  { __typename?: 'OfferConnection' }
                  & { nodes?: Maybe<Array<Maybe<(
                    { __typename?: 'Offer' }
                    & Pick<Offer, 'id' | 'state'>
                  )>>> }
                )> }
              )> }
            )>>>, pageInfo: (
              { __typename?: 'PageInfo' }
              & Pick<PageInfo, 'endCursor' | 'hasNextPage' | 'hasPreviousPage' | 'startCursor'>
            ) }
          ), applications?: Maybe<(
            { __typename?: 'ApplicationConnection' }
            & { nodes?: Maybe<Array<Maybe<(
              { __typename?: 'Application' }
              & Pick<Application, 'id'>
              & { profile: (
                { __typename?: 'Profile' }
                & Pick<Profile, 'displayName'>
              ) }
            )>>> }
          )>, invoices: (
            { __typename?: 'InvoiceConnection' }
            & { nodes?: Maybe<Array<Maybe<(
              { __typename?: 'Invoice' }
              & Pick<Invoice, 'id' | 'invoiceStatus'>
            )>>> }
          ), offers?: Maybe<(
            { __typename?: 'OfferConnection' }
            & { nodes?: Maybe<Array<Maybe<(
              { __typename?: 'Offer' }
              & Pick<Offer, 'id' | 'state'>
              & { profile: (
                { __typename?: 'Profile' }
                & Pick<Profile, 'displayName'>
              ) }
            )>>> }
          )> }
        )> }
      )> }
    )>>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'endCursor' | 'hasNextPage' | 'hasPreviousPage' | 'startCursor'>
    ) }
  ) }
);

export type ApproveUserMutationVariables = Exact<{
  input: UserApproveInput;
}>;


export type ApproveUserMutation = (
  { __typename?: 'Mutation' }
  & { userApprove?: Maybe<(
    { __typename?: 'UserApprovePayload' }
    & Pick<UserApprovePayload, 'success'>
    & { errors: Array<(
      { __typename?: 'UserError' }
      & Pick<UserError, 'message'>
    )> }
  )> }
);

export type BrandsAdminCampaignsQueryVariables = Exact<{
  input: Scalars['ID'];
}>;


export type BrandsAdminCampaignsQuery = (
  { __typename?: 'Query' }
  & { personnel?: Maybe<(
    { __typename?: 'Personnel' }
    & { brands?: Maybe<(
      { __typename?: 'BrandConnection' }
      & { nodes?: Maybe<Array<Maybe<(
        { __typename?: 'Brand' }
        & { campaigns: (
          { __typename?: 'CampaignConnection' }
          & { nodes?: Maybe<Array<Maybe<(
            { __typename?: 'Campaign' }
            & Pick<Campaign, 'id' | 'name' | 'isPublished' | 'dateStart' | 'dateEnd'>
            & { applications?: Maybe<(
              { __typename?: 'ApplicationConnection' }
              & { nodes?: Maybe<Array<Maybe<(
                { __typename?: 'Application' }
                & Pick<Application, 'id' | 'paymentEstimateCents' | 'acceptsPayByCash' | 'acceptsPayByProduct' | 'acceptsPayByStoreCredit' | 'state'>
                & { profile: (
                  { __typename?: 'Profile' }
                  & Pick<Profile, 'displayName'>
                  & { agent?: Maybe<(
                    { __typename?: 'Agent' }
                    & Pick<Agent, 'fullName' | 'id'>
                  )>, instagramAccount?: Maybe<(
                    { __typename?: 'InstagramAccount' }
                    & Pick<InstagramAccount, 'handle'>
                  )> }
                ) }
              )>>> }
            )>, invoices: (
              { __typename?: 'InvoiceConnection' }
              & { nodes?: Maybe<Array<Maybe<(
                { __typename?: 'Invoice' }
                & Pick<Invoice, 'id' | 'invoiceStatus' | 'payoutAmountCents'>
              )>>> }
            ) }
          )>>> }
        ) }
      )>>> }
    )> }
  )> }
);

export type BrandsAdminQueryVariables = Exact<{
  input: Scalars['Int'];
  after: Scalars['String'];
}>;


export type BrandsAdminQuery = (
  { __typename?: 'Query' }
  & { personnels: (
    { __typename?: 'PersonnelConnection' }
    & { edges?: Maybe<Array<Maybe<(
      { __typename?: 'PersonnelEdge' }
      & Pick<PersonnelEdge, 'cursor'>
      & { node?: Maybe<(
        { __typename?: 'Personnel' }
        & Pick<Personnel, 'email' | 'id' | 'fullName'>
        & { brands?: Maybe<(
          { __typename?: 'BrandConnection' }
          & { nodes?: Maybe<Array<Maybe<(
            { __typename?: 'Brand' }
            & Pick<Brand, 'id' | 'about' | 'name' | 'websiteUrl' | 'photoUrl'>
            & { applications?: Maybe<(
              { __typename?: 'ApplicationConnection' }
              & { nodes?: Maybe<Array<Maybe<(
                { __typename?: 'Application' }
                & Pick<Application, 'id' | 'state'>
              )>>> }
            )>, campaigns: (
              { __typename?: 'CampaignConnection' }
              & { nodes?: Maybe<Array<Maybe<(
                { __typename?: 'Campaign' }
                & Pick<Campaign, 'id' | 'isPublished'>
              )>>> }
            ), invoices: (
              { __typename?: 'InvoiceConnection' }
              & { nodes?: Maybe<Array<Maybe<(
                { __typename?: 'Invoice' }
                & Pick<Invoice, 'id' | 'invoiceStatus'>
              )>>> }
            ), offers?: Maybe<(
              { __typename?: 'OfferConnection' }
              & { nodes?: Maybe<Array<Maybe<(
                { __typename?: 'Offer' }
                & Pick<Offer, 'id' | 'state'>
              )>>> }
            )> }
          )>>> }
        )> }
      )> }
    )>>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'endCursor' | 'hasNextPage' | 'hasPreviousPage' | 'startCursor'>
    ) }
  ) }
);

export type BrandsAdminStatsQueryVariables = Exact<{
  input: Scalars['Int'];
  after: Scalars['String'];
}>;


export type BrandsAdminStatsQuery = (
  { __typename?: 'Query' }
  & { organizations: (
    { __typename?: 'OrganizationConnection' }
    & { edges?: Maybe<Array<Maybe<(
      { __typename?: 'OrganizationEdge' }
      & Pick<OrganizationEdge, 'cursor'>
      & { node?: Maybe<(
        { __typename?: 'Organization' }
        & { brands: (
          { __typename?: 'BrandConnection' }
          & { nodes?: Maybe<Array<Maybe<(
            { __typename?: 'Brand' }
            & { campaigns: (
              { __typename?: 'CampaignConnection' }
              & { nodes?: Maybe<Array<Maybe<(
                { __typename?: 'Campaign' }
                & Pick<Campaign, 'id' | 'isPublished'>
              )>>> }
            ), applications?: Maybe<(
              { __typename?: 'ApplicationConnection' }
              & { nodes?: Maybe<Array<Maybe<(
                { __typename?: 'Application' }
                & Pick<Application, 'id'>
              )>>> }
            )>, offers?: Maybe<(
              { __typename?: 'OfferConnection' }
              & { nodes?: Maybe<Array<Maybe<(
                { __typename?: 'Offer' }
                & Pick<Offer, 'id'>
              )>>> }
            )>, invoices: (
              { __typename?: 'InvoiceConnection' }
              & { nodes?: Maybe<Array<Maybe<(
                { __typename?: 'Invoice' }
                & Pick<Invoice, 'id' | 'invoiceStatus' | 'payoutAmountCents'>
                & { product: (
                  { __typename?: 'Offer' }
                  & Pick<Offer, 'paymentCashCents' | 'paymentStoreCreditCents' | 'paymentProductCents'>
                ) }
              )>>> }
            ) }
          )>>> }
        ) }
      )> }
    )>>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'endCursor' | 'hasNextPage' | 'hasPreviousPage' | 'startCursor'>
    ) }
  ) }
);

export type CampaignQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type CampaignQuery = (
  { __typename?: 'Query' }
  & { campaign?: Maybe<(
    { __typename?: 'Campaign' }
    & Pick<Campaign, 'id' | 'name' | 'photoUrl' | 'isPublished' | 'dateStart' | 'dateEnd' | 'description' | 'gender' | 'ageRangeMax' | 'ageRangeMin' | 'keyMessage' | 'paymentStoreProductCents' | 'paymentStoreCreditCents' | 'paymentCashCents'>
    & { deliverableDescriptions?: Maybe<Array<(
      { __typename?: 'DeliverableDescription' }
      & Pick<DeliverableDescription, 'description' | 'dueDate' | 'metadata' | 'type'>
    )>>, applications?: Maybe<(
      { __typename?: 'ApplicationConnection' }
      & { nodes?: Maybe<Array<Maybe<(
        { __typename?: 'Application' }
        & Pick<Application, 'acceptsPayByCash' | 'acceptsPayByProduct' | 'acceptsPayByStoreCredit' | 'paymentEstimateCents' | 'id' | 'pitch' | 'state'>
        & { applicant: (
          { __typename?: 'Agent' }
          & Pick<Agent, 'fullName' | 'id' | 'email'>
        ) | (
          { __typename?: 'Influencer' }
          & Pick<Influencer, 'fullName' | 'id' | 'email'>
        ) | (
          { __typename?: 'Personnel' }
          & Pick<Personnel, 'fullName' | 'id' | 'email'>
        ), profile: (
          { __typename?: 'Profile' }
          & Pick<Profile, 'bio' | 'displayName' | 'minimumFeeCents' | 'id' | 'profilePictureUrl'>
        ), campaign: (
          { __typename?: 'Campaign' }
          & Pick<Campaign, 'name' | 'id'>
          & { deliverableDescriptions?: Maybe<Array<(
            { __typename?: 'DeliverableDescription' }
            & Pick<DeliverableDescription, 'description' | 'dueDate' | 'metadata' | 'type'>
          )>> }
        ) }
      )>>> }
    )>, offers?: Maybe<(
      { __typename?: 'OfferConnection' }
      & { nodes?: Maybe<Array<Maybe<(
        { __typename?: 'Offer' }
        & Pick<Offer, 'state' | 'groupchatId' | 'id' | 'paymentCashCents' | 'paymentProductCents' | 'paymentStoreCreditCents' | 'exclusivityMonths' | 'usageRightsMonths'>
        & { profile: (
          { __typename?: 'Profile' }
          & Pick<Profile, 'bio' | 'displayName' | 'minimumFeeCents' | 'id' | 'profilePictureUrl'>
          & { instagramAccount?: Maybe<(
            { __typename?: 'InstagramAccount' }
            & Pick<InstagramAccount, 'handle'>
          )> }
        ), campaign: (
          { __typename?: 'Campaign' }
          & Pick<Campaign, 'name' | 'keyMessage' | 'id' | 'dateEnd' | 'dateStart'>
        ), deliverables?: Maybe<(
          { __typename?: 'DeliverableConnection' }
          & { nodes?: Maybe<Array<Maybe<(
            { __typename?: 'Deliverable' }
            & Pick<Deliverable, 'caption' | 'description' | 'id' | 'mediaUrls' | 'type' | 'state' | 'metadata'>
            & { offer: (
              { __typename?: 'Offer' }
              & { profile: (
                { __typename?: 'Profile' }
                & Pick<Profile, 'id' | 'displayName' | 'profilePictureUrl' | 'cometchatId'>
              ) }
            ) }
          )>>> }
        )>, deliverableDescriptions: Array<(
          { __typename?: 'DeliverableDescription' }
          & Pick<DeliverableDescription, 'description' | 'dueDate' | 'metadata' | 'type'>
        )> }
      )>>> }
    )>, invoices: (
      { __typename?: 'InvoiceConnection' }
      & { nodes?: Maybe<Array<Maybe<(
        { __typename?: 'Invoice' }
        & Pick<Invoice, 'id' | 'invoiceStatus'>
        & { product: (
          { __typename?: 'Offer' }
          & Pick<Offer, 'id' | 'paymentStoreCreditCents' | 'paymentProductCents' | 'paymentCashCents'>
          & { campaign: (
            { __typename?: 'Campaign' }
            & Pick<Campaign, 'id' | 'dateEnd'>
          ), profile: (
            { __typename?: 'Profile' }
            & Pick<Profile, 'id' | 'profilePictureUrl' | 'displayName'>
          ) }
        ) }
      )>>> }
    ) }
  )> }
);

export type CampaignUpdateIsPublishedMutationVariables = Exact<{
  input: CampaignUpdateInput;
}>;


export type CampaignUpdateIsPublishedMutation = (
  { __typename?: 'Mutation' }
  & { campaignUpdate?: Maybe<(
    { __typename?: 'CampaignUpdatePayload' }
    & { campaign?: Maybe<(
      { __typename?: 'Campaign' }
      & Pick<Campaign, 'isPublished'>
    )>, errors: Array<(
      { __typename?: 'UserError' }
      & Pick<UserError, 'message'>
    )> }
  )> }
);

export type CampaignUpdateMutationVariables = Exact<{
  input: CampaignUpdateInput;
}>;


export type CampaignUpdateMutation = (
  { __typename?: 'Mutation' }
  & { campaignUpdate?: Maybe<(
    { __typename?: 'CampaignUpdatePayload' }
    & { campaign?: Maybe<(
      { __typename?: 'Campaign' }
      & Pick<Campaign, 'id' | 'name' | 'photoUrl' | 'dateStart' | 'dateEnd' | 'description' | 'gender' | 'ageRangeMax' | 'ageRangeMin' | 'keyMessage' | 'paymentCashCents' | 'paymentStoreCreditCents' | 'paymentStoreProductCents'>
      & { deliverableDescriptions?: Maybe<Array<(
        { __typename?: 'DeliverableDescription' }
        & Pick<DeliverableDescription, 'description' | 'dueDate' | 'metadata' | 'type'>
      )>> }
    )>, errors: Array<(
      { __typename?: 'UserError' }
      & Pick<UserError, 'message'>
    )> }
  )> }
);

export type CampaignsOfferModalQueryVariables = Exact<{
  appProfileId?: Maybe<ApplicationFilter>;
  offProfileId?: Maybe<OfferFilter>;
}>;


export type CampaignsOfferModalQuery = (
  { __typename?: 'Query' }
  & { currentUser?: Maybe<{ __typename?: 'Agent' } | { __typename?: 'Influencer' } | (
    { __typename?: 'Personnel' }
    & { brands?: Maybe<(
      { __typename?: 'BrandConnection' }
      & { nodes?: Maybe<Array<Maybe<(
        { __typename?: 'Brand' }
        & { campaigns: (
          { __typename?: 'CampaignConnection' }
          & { nodes?: Maybe<Array<Maybe<(
            { __typename?: 'Campaign' }
            & Pick<Campaign, 'id' | 'name' | 'photoUrl' | 'isPublished' | 'dateStart' | 'dateEnd' | 'description' | 'gender' | 'ageRangeMax' | 'ageRangeMin' | 'keyMessage' | 'paymentStoreProductCents' | 'paymentStoreCreditCents' | 'paymentCashCents'>
            & { deliverableDescriptions?: Maybe<Array<(
              { __typename?: 'DeliverableDescription' }
              & Pick<DeliverableDescription, 'description' | 'dueDate' | 'metadata' | 'type'>
            )>>, applications?: Maybe<(
              { __typename?: 'ApplicationConnection' }
              & { nodes?: Maybe<Array<Maybe<(
                { __typename?: 'Application' }
                & Pick<Application, 'acceptsPayByCash' | 'acceptsPayByProduct' | 'acceptsPayByStoreCredit' | 'paymentEstimateCents' | 'id' | 'pitch' | 'state'>
                & { applicant: (
                  { __typename?: 'Agent' }
                  & Pick<Agent, 'fullName' | 'id' | 'email'>
                ) | (
                  { __typename?: 'Influencer' }
                  & Pick<Influencer, 'fullName' | 'id' | 'email'>
                ) | (
                  { __typename?: 'Personnel' }
                  & Pick<Personnel, 'fullName' | 'id' | 'email'>
                ), profile: (
                  { __typename?: 'Profile' }
                  & Pick<Profile, 'bio' | 'displayName' | 'minimumFeeCents' | 'id' | 'profilePictureUrl'>
                ), campaign: (
                  { __typename?: 'Campaign' }
                  & Pick<Campaign, 'name' | 'id'>
                  & { deliverableDescriptions?: Maybe<Array<(
                    { __typename?: 'DeliverableDescription' }
                    & Pick<DeliverableDescription, 'description' | 'dueDate' | 'metadata' | 'type'>
                  )>> }
                ) }
              )>>> }
            )>, offers?: Maybe<(
              { __typename?: 'OfferConnection' }
              & { nodes?: Maybe<Array<Maybe<(
                { __typename?: 'Offer' }
                & Pick<Offer, 'state' | 'groupchatId' | 'id' | 'paymentCashCents' | 'paymentProductCents' | 'paymentStoreCreditCents' | 'exclusivityMonths' | 'usageRightsMonths'>
                & { profile: (
                  { __typename?: 'Profile' }
                  & Pick<Profile, 'bio' | 'displayName' | 'minimumFeeCents' | 'id' | 'profilePictureUrl'>
                ), campaign: (
                  { __typename?: 'Campaign' }
                  & Pick<Campaign, 'name' | 'keyMessage' | 'id' | 'dateEnd' | 'dateStart'>
                  & { invoices: (
                    { __typename?: 'InvoiceConnection' }
                    & { nodes?: Maybe<Array<Maybe<(
                      { __typename?: 'Invoice' }
                      & Pick<Invoice, 'id' | 'invoiceStatus'>
                    )>>> }
                  ) }
                ), deliverableDescriptions: Array<(
                  { __typename?: 'DeliverableDescription' }
                  & Pick<DeliverableDescription, 'description' | 'dueDate' | 'metadata' | 'type'>
                )> }
              )>>> }
            )> }
          )>>> }
        ) }
      )>>> }
    )> }
  )> }
);

export type CampaignCreateMutationVariables = Exact<{
  id: CampaignCreateInput;
}>;


export type CampaignCreateMutation = (
  { __typename?: 'Mutation' }
  & { campaignCreate?: Maybe<(
    { __typename?: 'CampaignCreatePayload' }
    & { campaign?: Maybe<(
      { __typename?: 'Campaign' }
      & Pick<Campaign, 'id' | 'isPublished'>
    )>, errors: Array<(
      { __typename?: 'UserError' }
      & Pick<UserError, 'message'>
    )> }
  )> }
);

export type OrganizationCreateMutationVariables = Exact<{
  input: OrganizationCreateInput;
}>;


export type OrganizationCreateMutation = (
  { __typename?: 'Mutation' }
  & { organizationCreate?: Maybe<(
    { __typename?: 'OrganizationCreatePayload' }
    & { organization?: Maybe<(
      { __typename?: 'Organization' }
      & Pick<Organization, 'id' | 'about' | 'name' | 'photoUrl' | 'websiteUrl'>
    )>, errors: Array<(
      { __typename?: 'UserError' }
      & Pick<UserError, 'message'>
    )> }
  )> }
);

export type CurrentUserCampaignManageQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentUserCampaignManageQuery = (
  { __typename?: 'Query' }
  & { currentUser?: Maybe<{ __typename?: 'Agent' } | { __typename?: 'Influencer' } | (
    { __typename?: 'Personnel' }
    & Pick<Personnel, 'id' | 'fullName'>
    & { brands?: Maybe<(
      { __typename?: 'BrandConnection' }
      & { edges?: Maybe<Array<Maybe<(
        { __typename?: 'BrandEdge' }
        & { node?: Maybe<(
          { __typename?: 'Brand' }
          & Pick<Brand, 'name' | 'about' | 'id'>
          & { campaigns: (
            { __typename?: 'CampaignConnection' }
            & { edges?: Maybe<Array<Maybe<(
              { __typename?: 'CampaignEdge' }
              & { node?: Maybe<(
                { __typename?: 'Campaign' }
                & Pick<Campaign, 'dateEnd' | 'dateStart' | 'gender' | 'id' | 'name' | 'ageRangeMax' | 'ageRangeMin' | 'isPublished' | 'paymentCashCents' | 'paymentStoreProductCents' | 'paymentStoreCreditCents'>
                & { deliverableDescriptions?: Maybe<Array<(
                  { __typename?: 'DeliverableDescription' }
                  & Pick<DeliverableDescription, 'description' | 'dueDate' | 'metadata' | 'type'>
                )>> }
              )> }
            )>>> }
          ) }
        )> }
      )>>> }
    )> }
  )> }
);

export type CurrentUserHomeQueryVariables = Exact<{
  filter: ApplicationFilter;
}>;


export type CurrentUserHomeQuery = (
  { __typename?: 'Query' }
  & { currentUser?: Maybe<{ __typename?: 'Agent' } | { __typename?: 'Influencer' } | (
    { __typename?: 'Personnel' }
    & Pick<Personnel, 'id' | 'fullName'>
    & { brands?: Maybe<(
      { __typename?: 'BrandConnection' }
      & { edges?: Maybe<Array<Maybe<(
        { __typename?: 'BrandEdge' }
        & { node?: Maybe<(
          { __typename?: 'Brand' }
          & Pick<Brand, 'name' | 'about' | 'id'>
          & { campaigns: (
            { __typename?: 'CampaignConnection' }
            & { edges?: Maybe<Array<Maybe<(
              { __typename?: 'CampaignEdge' }
              & { node?: Maybe<(
                { __typename?: 'Campaign' }
                & Pick<Campaign, 'dateEnd' | 'dateStart' | 'gender' | 'id' | 'name' | 'paymentCashCents' | 'paymentStoreProductCents' | 'paymentStoreCreditCents' | 'ageRangeMax' | 'ageRangeMin' | 'isPublished'>
                & { deliverableDescriptions?: Maybe<Array<(
                  { __typename?: 'DeliverableDescription' }
                  & Pick<DeliverableDescription, 'description' | 'dueDate' | 'metadata' | 'type'>
                )>>, applications?: Maybe<(
                  { __typename?: 'ApplicationConnection' }
                  & { edges?: Maybe<Array<Maybe<(
                    { __typename?: 'ApplicationEdge' }
                    & { node?: Maybe<(
                      { __typename?: 'Application' }
                      & Pick<Application, 'id' | 'state' | 'pitch' | 'acceptsPayByCash' | 'acceptsPayByProduct' | 'acceptsPayByStoreCredit' | 'paymentEstimateCents'>
                      & { profile: (
                        { __typename?: 'Profile' }
                        & Pick<Profile, 'bio' | 'displayName' | 'id' | 'profilePictureUrl'>
                      ), applicant: (
                        { __typename?: 'Agent' }
                        & Pick<Agent, 'fullName' | 'id' | 'email'>
                      ) | (
                        { __typename?: 'Influencer' }
                        & Pick<Influencer, 'fullName' | 'id' | 'email'>
                      ) | (
                        { __typename?: 'Personnel' }
                        & Pick<Personnel, 'fullName' | 'id' | 'email'>
                      ), campaign: (
                        { __typename?: 'Campaign' }
                        & Pick<Campaign, 'name' | 'id'>
                        & { deliverableDescriptions?: Maybe<Array<(
                          { __typename?: 'DeliverableDescription' }
                          & Pick<DeliverableDescription, 'description' | 'dueDate' | 'metadata' | 'type'>
                        )>> }
                      ) }
                    )> }
                  )>>> }
                )>, offers?: Maybe<(
                  { __typename?: 'OfferConnection' }
                  & { nodes?: Maybe<Array<Maybe<(
                    { __typename?: 'Offer' }
                    & Pick<Offer, 'id'>
                  )>>> }
                )> }
              )> }
            )>>> }
          ) }
        )> }
      )>>> }
    )> }
  )> }
);

export type CurrentUserNotificationPreferencesQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentUserNotificationPreferencesQuery = (
  { __typename?: 'Query' }
  & { currentUser?: Maybe<(
    { __typename?: 'Agent' }
    & { notificationPreference: (
      { __typename?: 'NotificationPreference' }
      & Pick<NotificationPreference, 'applicationCreated' | 'campaignEnded' | 'campaignHalfway' | 'campaignOneDayLeft' | 'campaignStarted' | 'contentSubmitted' | 'contentUpdated' | 'dummyAction' | 'offerAccepted' | 'paymentSetupNeeded' | 'messageReceived'>
    ), emailPreference: (
      { __typename?: 'EmailPreference' }
      & Pick<EmailPreference, 'applicationCreated' | 'contentSubmitted' | 'contentUpdated' | 'dummyAction' | 'messageReceived' | 'passwordReset'>
    ) }
  ) | (
    { __typename?: 'Influencer' }
    & { notificationPreference: (
      { __typename?: 'NotificationPreference' }
      & Pick<NotificationPreference, 'applicationCreated' | 'campaignEnded' | 'campaignHalfway' | 'campaignOneDayLeft' | 'campaignStarted' | 'contentSubmitted' | 'contentUpdated' | 'dummyAction' | 'offerAccepted' | 'paymentSetupNeeded' | 'messageReceived'>
    ), emailPreference: (
      { __typename?: 'EmailPreference' }
      & Pick<EmailPreference, 'applicationCreated' | 'contentSubmitted' | 'contentUpdated' | 'dummyAction' | 'messageReceived' | 'passwordReset'>
    ) }
  ) | (
    { __typename?: 'Personnel' }
    & { notificationPreference: (
      { __typename?: 'NotificationPreference' }
      & Pick<NotificationPreference, 'applicationCreated' | 'campaignEnded' | 'campaignHalfway' | 'campaignOneDayLeft' | 'campaignStarted' | 'contentSubmitted' | 'contentUpdated' | 'dummyAction' | 'offerAccepted' | 'paymentSetupNeeded' | 'messageReceived'>
    ), emailPreference: (
      { __typename?: 'EmailPreference' }
      & Pick<EmailPreference, 'applicationCreated' | 'contentSubmitted' | 'contentUpdated' | 'dummyAction' | 'messageReceived' | 'passwordReset'>
    ) }
  )> }
);

export type CurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentUserQuery = (
  { __typename?: 'Query' }
  & { currentUser?: Maybe<{ __typename?: 'Agent' } | { __typename?: 'Influencer' } | (
    { __typename?: 'Personnel' }
    & Pick<Personnel, 'id' | 'fullName' | 'email' | 'requirements'>
    & { organization?: Maybe<(
      { __typename?: 'Organization' }
      & Pick<Organization, 'id' | 'name' | 'photoUrl' | 'websiteUrl' | 'about'>
      & { paymentMethods: Array<(
        { __typename?: 'PaymentMethod' }
        & Pick<PaymentMethod, 'brand' | 'id' | 'lastFour'>
      )>, brands: (
        { __typename?: 'BrandConnection' }
        & { nodes?: Maybe<Array<Maybe<(
          { __typename?: 'Brand' }
          & Pick<Brand, 'id'>
        )>>> }
      ) }
    )>, preferences?: Maybe<(
      { __typename?: 'UserPreference' }
      & Pick<UserPreference, 'language'>
    )> }
  )> }
);

export type CurrentUserSettingsQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentUserSettingsQuery = (
  { __typename?: 'Query' }
  & { currentUser?: Maybe<{ __typename?: 'Agent' } | { __typename?: 'Influencer' } | (
    { __typename?: 'Personnel' }
    & Pick<Personnel, 'email' | 'fullName' | 'id'>
    & { organization?: Maybe<(
      { __typename?: 'Organization' }
      & Pick<Organization, 'about' | 'name' | 'id' | 'photoUrl' | 'websiteUrl'>
      & { paymentMethods: Array<(
        { __typename?: 'PaymentMethod' }
        & Pick<PaymentMethod, 'brand' | 'id' | 'lastFour'>
      )> }
    )> }
  )> }
);

export type DeliverableAcceptMutationVariables = Exact<{
  input: DeliverableAcceptInput;
}>;


export type DeliverableAcceptMutation = (
  { __typename?: 'Mutation' }
  & { deliverableAccept?: Maybe<(
    { __typename?: 'DeliverableAcceptPayload' }
    & { deliverable?: Maybe<(
      { __typename?: 'Deliverable' }
      & Pick<Deliverable, 'id'>
    )>, errors: Array<(
      { __typename?: 'UserError' }
      & Pick<UserError, 'message'>
    )> }
  )> }
);

export type DeliverableRejectMutationVariables = Exact<{
  input: DeliverableRejectInput;
}>;


export type DeliverableRejectMutation = (
  { __typename?: 'Mutation' }
  & { deliverableReject?: Maybe<(
    { __typename?: 'DeliverableRejectPayload' }
    & { deliverable?: Maybe<(
      { __typename?: 'Deliverable' }
      & Pick<Deliverable, 'id'>
    )>, errors: Array<(
      { __typename?: 'UserError' }
      & Pick<UserError, 'message'>
    )> }
  )> }
);

export type EmailPreferencesUpdateMutationVariables = Exact<{
  emailType: EmailType;
  newValue: Scalars['Boolean'];
}>;


export type EmailPreferencesUpdateMutation = (
  { __typename?: 'Mutation' }
  & { emailPreferencesUpdate?: Maybe<(
    { __typename?: 'EmailPreferencesUpdatePayload' }
    & { user?: Maybe<(
      { __typename?: 'Agent' }
      & Pick<Agent, 'id'>
    ) | (
      { __typename?: 'Influencer' }
      & Pick<Influencer, 'id'>
    ) | (
      { __typename?: 'Personnel' }
      & Pick<Personnel, 'id'>
    )>, errors: Array<(
      { __typename?: 'UserError' }
      & Pick<UserError, 'message' | 'path'>
    )> }
  )> }
);

export type InfluencerFavListQueryVariables = Exact<{ [key: string]: never; }>;


export type InfluencerFavListQuery = (
  { __typename?: 'Query' }
  & { currentUser?: Maybe<(
    { __typename?: 'Agent' }
    & { profileLists: (
      { __typename?: 'ProfileListConnection' }
      & { nodes?: Maybe<Array<Maybe<(
        { __typename?: 'ProfileList' }
        & Pick<ProfileList, 'id' | 'name'>
        & { profiles: (
          { __typename?: 'ProfileConnection' }
          & { edges?: Maybe<Array<Maybe<(
            { __typename?: 'ProfileEdge' }
            & Pick<ProfileEdge, 'cursor'>
            & { node?: Maybe<(
              { __typename?: 'Profile' }
              & Pick<Profile, 'cometchatId' | 'displayName' | 'id' | 'profilePictureUrl' | 'minimumFeeCents'>
              & { interests: Array<(
                { __typename?: 'Interest' }
                & Pick<Interest, 'code' | 'name'>
              )>, instagramAccount?: Maybe<(
                { __typename?: 'InstagramAccount' }
                & Pick<InstagramAccount, 'followerCount' | 'handle' | 'profilePictureUrl' | 'name'>
                & { hypeauditorReport?: Maybe<(
                  { __typename?: 'HypeauditorReport' }
                  & Pick<HypeauditorReport, 'audienceAgesFemale' | 'audienceAgesMale' | 'audienceCities' | 'audienceCountries' | 'engagementRate' | 'country' | 'followCount' | 'followerCount' | 'fullName' | 'handle'>
                )> }
              )> }
            )> }
          )>>>, pageInfo: (
            { __typename?: 'PageInfo' }
            & Pick<PageInfo, 'endCursor' | 'hasNextPage' | 'hasPreviousPage' | 'startCursor'>
          ) }
        ) }
      )>>> }
    ) }
  ) | (
    { __typename?: 'Influencer' }
    & { profileLists: (
      { __typename?: 'ProfileListConnection' }
      & { nodes?: Maybe<Array<Maybe<(
        { __typename?: 'ProfileList' }
        & Pick<ProfileList, 'id' | 'name'>
        & { profiles: (
          { __typename?: 'ProfileConnection' }
          & { edges?: Maybe<Array<Maybe<(
            { __typename?: 'ProfileEdge' }
            & Pick<ProfileEdge, 'cursor'>
            & { node?: Maybe<(
              { __typename?: 'Profile' }
              & Pick<Profile, 'cometchatId' | 'displayName' | 'id' | 'profilePictureUrl' | 'minimumFeeCents'>
              & { interests: Array<(
                { __typename?: 'Interest' }
                & Pick<Interest, 'code' | 'name'>
              )>, instagramAccount?: Maybe<(
                { __typename?: 'InstagramAccount' }
                & Pick<InstagramAccount, 'followerCount' | 'handle' | 'profilePictureUrl' | 'name'>
                & { hypeauditorReport?: Maybe<(
                  { __typename?: 'HypeauditorReport' }
                  & Pick<HypeauditorReport, 'audienceAgesFemale' | 'audienceAgesMale' | 'audienceCities' | 'audienceCountries' | 'engagementRate' | 'country' | 'followCount' | 'followerCount' | 'fullName' | 'handle'>
                )> }
              )> }
            )> }
          )>>>, pageInfo: (
            { __typename?: 'PageInfo' }
            & Pick<PageInfo, 'endCursor' | 'hasNextPage' | 'hasPreviousPage' | 'startCursor'>
          ) }
        ) }
      )>>> }
    ) }
  ) | (
    { __typename?: 'Personnel' }
    & { profileLists: (
      { __typename?: 'ProfileListConnection' }
      & { nodes?: Maybe<Array<Maybe<(
        { __typename?: 'ProfileList' }
        & Pick<ProfileList, 'id' | 'name'>
        & { profiles: (
          { __typename?: 'ProfileConnection' }
          & { edges?: Maybe<Array<Maybe<(
            { __typename?: 'ProfileEdge' }
            & Pick<ProfileEdge, 'cursor'>
            & { node?: Maybe<(
              { __typename?: 'Profile' }
              & Pick<Profile, 'cometchatId' | 'displayName' | 'id' | 'profilePictureUrl' | 'minimumFeeCents'>
              & { interests: Array<(
                { __typename?: 'Interest' }
                & Pick<Interest, 'code' | 'name'>
              )>, instagramAccount?: Maybe<(
                { __typename?: 'InstagramAccount' }
                & Pick<InstagramAccount, 'followerCount' | 'handle' | 'profilePictureUrl' | 'name'>
                & { hypeauditorReport?: Maybe<(
                  { __typename?: 'HypeauditorReport' }
                  & Pick<HypeauditorReport, 'audienceAgesFemale' | 'audienceAgesMale' | 'audienceCities' | 'audienceCountries' | 'engagementRate' | 'country' | 'followCount' | 'followerCount' | 'fullName' | 'handle'>
                )> }
              )> }
            )> }
          )>>>, pageInfo: (
            { __typename?: 'PageInfo' }
            & Pick<PageInfo, 'endCursor' | 'hasNextPage' | 'hasPreviousPage' | 'startCursor'>
          ) }
        ) }
      )>>> }
    ) }
  )> }
);

export type InfluencerListQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  minFollow?: Maybe<Scalars['Int']>;
  maxFollow?: Maybe<Scalars['Int']>;
  minEr?: Maybe<Scalars['Float']>;
  maxEr?: Maybe<Scalars['Float']>;
  prefix?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  interests?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  order: ProfileOrderBy;
}>;


export type InfluencerListQuery = (
  { __typename?: 'Query' }
  & { profiles?: Maybe<(
    { __typename?: 'ProfileConnection' }
    & { edges?: Maybe<Array<Maybe<(
      { __typename?: 'ProfileEdge' }
      & Pick<ProfileEdge, 'cursor'>
      & { node?: Maybe<(
        { __typename?: 'Profile' }
        & Pick<Profile, 'cometchatId' | 'displayName' | 'id' | 'profilePictureUrl' | 'minimumFeeCents'>
        & { agent?: Maybe<(
          { __typename?: 'Agent' }
          & Pick<Agent, 'id'>
        )>, interests: Array<(
          { __typename?: 'Interest' }
          & Pick<Interest, 'code' | 'name'>
        )>, instagramAccount?: Maybe<(
          { __typename?: 'InstagramAccount' }
          & Pick<InstagramAccount, 'followerCount' | 'handle' | 'profilePictureUrl' | 'name'>
          & { hypeauditorReport?: Maybe<(
            { __typename?: 'HypeauditorReport' }
            & Pick<HypeauditorReport, 'audienceAgesFemale' | 'audienceAgesMale' | 'audienceCities' | 'audienceCountries' | 'engagementRate' | 'country' | 'followCount' | 'followerCount' | 'fullName' | 'handle'>
          )> }
        )> }
      )> }
    )>>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'endCursor' | 'hasNextPage' | 'hasPreviousPage' | 'startCursor'>
    ) }
  )> }
);

export type InfluencerProfileQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type InfluencerProfileQuery = (
  { __typename?: 'Query' }
  & { profile?: Maybe<(
    { __typename?: 'Profile' }
    & Pick<Profile, 'bio' | 'displayName' | 'id' | 'isLinkedToAgency' | 'profilePictureUrl' | 'minimumFeeCents' | 'cometchatId'>
    & { agency?: Maybe<(
      { __typename?: 'Agency' }
      & Pick<Agency, 'id' | 'name'>
    )>, applications?: Maybe<(
      { __typename?: 'ApplicationConnection' }
      & { nodes?: Maybe<Array<Maybe<(
        { __typename?: 'Application' }
        & Pick<Application, 'acceptsPayByCash' | 'acceptsPayByProduct' | 'acceptsPayByStoreCredit' | 'pitch' | 'paymentEstimateCents' | 'id' | 'state'>
        & { campaign: (
          { __typename?: 'Campaign' }
          & Pick<Campaign, 'name' | 'id'>
        ) }
      )>>> }
    )>, interests: Array<(
      { __typename?: 'Interest' }
      & Pick<Interest, 'code' | 'name'>
    )>, instagramAccount?: Maybe<(
      { __typename?: 'InstagramAccount' }
      & Pick<InstagramAccount, 'biography' | 'followCount' | 'followerCount' | 'handle' | 'id' | 'name' | 'postCount' | 'profilePictureUrl' | 'recentPosts'>
      & { hypeauditorReport?: Maybe<(
        { __typename?: 'HypeauditorReport' }
        & Pick<HypeauditorReport, 'accountQualityScore' | 'accountQualityScoreDescription' | 'accountQualityScoreName' | 'audienceAgesFemale' | 'audienceAgesMale' | 'audienceBrandAffinity' | 'audienceCities' | 'audienceCountries' | 'audienceEthnicity' | 'audienceInterests' | 'audienceLanguages' | 'audienceType' | 'audienceUsStates' | 'audienceIncome' | 'averageComments' | 'averageLikes' | 'engagementRate' | 'brandMentions' | 'country' | 'engagementRateDescription' | 'engagementRateForAds' | 'engagementRateForAdsName' | 'estimatedReachMax' | 'estimatedReachMin' | 'followCount' | 'followerCount' | 'fullName' | 'handle' | 'postFrequency'>
      )> }
    )> }
  )> }
);

export type InfluencersAdminQueryVariables = Exact<{
  input: Scalars['Int'];
  after: Scalars['String'];
}>;


export type InfluencersAdminQuery = (
  { __typename?: 'Query' }
  & { influencers: (
    { __typename?: 'InfluencerConnection' }
    & { edges?: Maybe<Array<Maybe<(
      { __typename?: 'InfluencerEdge' }
      & Pick<InfluencerEdge, 'cursor'>
      & { node?: Maybe<(
        { __typename?: 'Influencer' }
        & Pick<Influencer, 'fullName' | 'id' | 'email' | 'requirements'>
        & { applications?: Maybe<(
          { __typename?: 'ApplicationConnection' }
          & { nodes?: Maybe<Array<Maybe<(
            { __typename?: 'Application' }
            & Pick<Application, 'id' | 'state'>
          )>>> }
        )>, offers?: Maybe<(
          { __typename?: 'OfferConnection' }
          & { nodes?: Maybe<Array<Maybe<(
            { __typename?: 'Offer' }
            & Pick<Offer, 'id' | 'state'>
          )>>> }
        )>, profile?: Maybe<(
          { __typename?: 'Profile' }
          & Pick<Profile, 'id' | 'profilePictureUrl' | 'displayName'>
          & { instagramAccount?: Maybe<(
            { __typename?: 'InstagramAccount' }
            & Pick<InstagramAccount, 'followerCount' | 'handle'>
          )> }
        )>, invoices: (
          { __typename?: 'InvoiceConnection' }
          & { nodes?: Maybe<Array<Maybe<(
            { __typename?: 'Invoice' }
            & Pick<Invoice, 'invoiceStatus' | 'id'>
          )>>> }
        ) }
      )> }
    )>>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'endCursor' | 'hasNextPage' | 'hasPreviousPage' | 'startCursor'>
    ) }
  ) }
);

export type InfluencersAdminStatsQueryVariables = Exact<{
  input: Scalars['Int'];
  after: Scalars['String'];
}>;


export type InfluencersAdminStatsQuery = (
  { __typename?: 'Query' }
  & { influencers: (
    { __typename?: 'InfluencerConnection' }
    & { edges?: Maybe<Array<Maybe<(
      { __typename?: 'InfluencerEdge' }
      & Pick<InfluencerEdge, 'cursor'>
      & { node?: Maybe<(
        { __typename?: 'Influencer' }
        & { profile?: Maybe<(
          { __typename?: 'Profile' }
          & { agency?: Maybe<(
            { __typename?: 'Agency' }
            & Pick<Agency, 'id'>
          )> }
        )>, applications?: Maybe<(
          { __typename?: 'ApplicationConnection' }
          & { nodes?: Maybe<Array<Maybe<(
            { __typename?: 'Application' }
            & Pick<Application, 'id'>
          )>>> }
        )>, offers?: Maybe<(
          { __typename?: 'OfferConnection' }
          & { nodes?: Maybe<Array<Maybe<(
            { __typename?: 'Offer' }
            & Pick<Offer, 'id'>
            & { deliverables?: Maybe<(
              { __typename?: 'DeliverableConnection' }
              & { nodes?: Maybe<Array<Maybe<(
                { __typename?: 'Deliverable' }
                & Pick<Deliverable, 'mediaUrls'>
              )>>> }
            )> }
          )>>> }
        )>, invoices: (
          { __typename?: 'InvoiceConnection' }
          & { nodes?: Maybe<Array<Maybe<(
            { __typename?: 'Invoice' }
            & Pick<Invoice, 'id' | 'invoiceStatus'>
            & { product: (
              { __typename?: 'Offer' }
              & Pick<Offer, 'paymentCashCents' | 'paymentProductCents' | 'paymentStoreCreditCents'>
            ) }
          )>>> }
        ) }
      )> }
    )>>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'endCursor' | 'hasNextPage' | 'hasPreviousPage' | 'startCursor'>
    ) }
  ) }
);

export type InfluencersQueryVariables = Exact<{
  input?: Maybe<Scalars['Int']>;
}>;


export type InfluencersQuery = (
  { __typename?: 'Query' }
  & { influencers: (
    { __typename?: 'InfluencerConnection' }
    & { edges?: Maybe<Array<Maybe<(
      { __typename?: 'InfluencerEdge' }
      & Pick<InfluencerEdge, 'cursor'>
      & { node?: Maybe<(
        { __typename?: 'Influencer' }
        & Pick<Influencer, 'fullName'>
        & { profile?: Maybe<(
          { __typename?: 'Profile' }
          & Pick<Profile, 'id' | 'profilePictureUrl' | 'minimumFeeCents'>
          & { instagramAccount?: Maybe<(
            { __typename?: 'InstagramAccount' }
            & Pick<InstagramAccount, 'followerCount' | 'handle'>
            & { hypeauditorReport?: Maybe<(
              { __typename?: 'HypeauditorReport' }
              & Pick<HypeauditorReport, 'audienceAgesFemale' | 'audienceAgesMale' | 'audienceCities' | 'audienceCountries' | 'engagementRate' | 'country' | 'followCount' | 'followerCount' | 'fullName' | 'handle'>
            )> }
          )> }
        )> }
      )> }
    )>>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'endCursor' | 'hasNextPage' | 'hasPreviousPage' | 'startCursor'>
    ) }
  ) }
);

export type InfluencersRepresentedAdminQueryVariables = Exact<{
  input: Scalars['Int'];
  after: Scalars['String'];
}>;


export type InfluencersRepresentedAdminQuery = (
  { __typename?: 'Query' }
  & { profiles?: Maybe<(
    { __typename?: 'ProfileConnection' }
    & { edges?: Maybe<Array<Maybe<(
      { __typename?: 'ProfileEdge' }
      & Pick<ProfileEdge, 'cursor'>
      & { node?: Maybe<(
        { __typename?: 'Profile' }
        & Pick<Profile, 'id' | 'displayName' | 'cometchatId' | 'profilePictureUrl'>
        & { instagramAccount?: Maybe<(
          { __typename?: 'InstagramAccount' }
          & Pick<InstagramAccount, 'handle' | 'name'>
        )>, applications?: Maybe<(
          { __typename?: 'ApplicationConnection' }
          & { nodes?: Maybe<Array<Maybe<(
            { __typename?: 'Application' }
            & Pick<Application, 'id'>
          )>>> }
        )>, offers?: Maybe<(
          { __typename?: 'OfferConnection' }
          & { nodes?: Maybe<Array<Maybe<(
            { __typename?: 'Offer' }
            & Pick<Offer, 'id' | 'state'>
            & { deliverables?: Maybe<(
              { __typename?: 'DeliverableConnection' }
              & { nodes?: Maybe<Array<Maybe<(
                { __typename?: 'Deliverable' }
                & Pick<Deliverable, 'mediaUrls'>
              )>>> }
            )> }
          )>>> }
        )>, agency?: Maybe<(
          { __typename?: 'Agency' }
          & Pick<Agency, 'id' | 'name'>
          & { agents: (
            { __typename?: 'AgentConnection' }
            & { nodes?: Maybe<Array<Maybe<(
              { __typename?: 'Agent' }
              & Pick<Agent, 'id' | 'fullName' | 'email' | 'requirements'>
            )>>> }
          ), invoices: (
            { __typename?: 'InvoiceConnection' }
            & { nodes?: Maybe<Array<Maybe<(
              { __typename?: 'Invoice' }
              & Pick<Invoice, 'id' | 'invoiceStatus'>
              & { product: (
                { __typename?: 'Offer' }
                & Pick<Offer, 'paymentCashCents' | 'paymentProductCents' | 'paymentStoreCreditCents'>
                & { profile: (
                  { __typename?: 'Profile' }
                  & Pick<Profile, 'id'>
                ) }
              ) }
            )>>> }
          ) }
        )> }
      )> }
    )>>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'endCursor' | 'hasNextPage' | 'hasPreviousPage' | 'startCursor'>
    ) }
  )> }
);

export type NotificationDataFragment = (
  { __typename?: 'Notification' }
  & Pick<Notification, 'id' | 'readAt' | 'createdAt' | 'type'>
  & { application?: Maybe<(
    { __typename?: 'Application' }
    & Pick<Application, 'id' | 'state'>
  )>, campaign?: Maybe<(
    { __typename?: 'Campaign' }
    & Pick<Campaign, 'id' | 'name' | 'photoUrl' | 'dateStart' | 'dateEnd'>
  )>, profile?: Maybe<(
    { __typename?: 'Profile' }
    & Pick<Profile, 'id' | 'displayName' | 'profilePictureUrl'>
    & { instagramAccount?: Maybe<(
      { __typename?: 'InstagramAccount' }
      & Pick<InstagramAccount, 'profilePictureUrl'>
    )> }
  )>, deliverable?: Maybe<(
    { __typename?: 'Deliverable' }
    & Pick<Deliverable, 'id' | 'state' | 'mediaUrls'>
  )>, offer?: Maybe<(
    { __typename?: 'Offer' }
    & Pick<Offer, 'id'>
  )> }
);

export type NotificationMarkAsReadMutationVariables = Exact<{
  notificationId: Scalars['ID'];
}>;


export type NotificationMarkAsReadMutation = (
  { __typename?: 'Mutation' }
  & { notificationMarkAsRead?: Maybe<(
    { __typename?: 'NotificationMarkAsReadPayload' }
    & { notification?: Maybe<(
      { __typename?: 'Notification' }
      & NotificationDataFragment
    )>, errors: Array<(
      { __typename?: 'UserError' }
      & Pick<UserError, 'message' | 'path'>
    )> }
  )> }
);

export type NotificationPreferencesUpdateMutationVariables = Exact<{
  notificationType: NotificationType;
  newValue: Scalars['Boolean'];
}>;


export type NotificationPreferencesUpdateMutation = (
  { __typename?: 'Mutation' }
  & { notificationPreferencesUpdate?: Maybe<(
    { __typename?: 'NotificationPreferencesUpdatePayload' }
    & { user?: Maybe<(
      { __typename?: 'Agent' }
      & Pick<Agent, 'id'>
    ) | (
      { __typename?: 'Influencer' }
      & Pick<Influencer, 'id'>
    ) | (
      { __typename?: 'Personnel' }
      & Pick<Personnel, 'id'>
    )>, errors: Array<(
      { __typename?: 'UserError' }
      & Pick<UserError, 'message' | 'path'>
    )> }
  )> }
);

export type OfferCreateMutationVariables = Exact<{
  input: OfferCreateInput;
}>;


export type OfferCreateMutation = (
  { __typename?: 'Mutation' }
  & { offerCreate?: Maybe<(
    { __typename?: 'OfferCreatePayload' }
    & { errors: Array<(
      { __typename?: 'UserError' }
      & Pick<UserError, 'message'>
    )>, offer?: Maybe<(
      { __typename?: 'Offer' }
      & { campaign: (
        { __typename?: 'Campaign' }
        & Pick<Campaign, 'id' | 'name'>
      ) }
    )> }
  )> }
);

export type OrganizationQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type OrganizationQuery = (
  { __typename?: 'Query' }
  & { organization?: Maybe<(
    { __typename?: 'Organization' }
    & Pick<Organization, 'id' | 'name' | 'about' | 'photoUrl' | 'websiteUrl'>
    & { brands: (
      { __typename?: 'BrandConnection' }
      & { nodes?: Maybe<Array<Maybe<(
        { __typename?: 'Brand' }
        & Pick<Brand, 'name' | 'about' | 'id'>
      )>>> }
    ) }
  )> }
);

export type PaymentInvoicesQueryVariables = Exact<{
  first: Scalars['Int'];
  after: Scalars['String'];
}>;


export type PaymentInvoicesQuery = (
  { __typename?: 'Query' }
  & { currentUser?: Maybe<{ __typename?: 'Agent' } | { __typename?: 'Influencer' } | (
    { __typename?: 'Personnel' }
    & { organization?: Maybe<(
      { __typename?: 'Organization' }
      & { brands: (
        { __typename?: 'BrandConnection' }
        & { nodes?: Maybe<Array<Maybe<(
          { __typename?: 'Brand' }
          & { invoices: (
            { __typename?: 'InvoiceConnection' }
            & { edges?: Maybe<Array<Maybe<(
              { __typename?: 'InvoiceEdge' }
              & Pick<InvoiceEdge, 'cursor'>
              & { node?: Maybe<(
                { __typename?: 'Invoice' }
                & Pick<Invoice, 'id' | 'invoiceStatus' | 'paidAt' | 'payoutAmountCents'>
                & { product: (
                  { __typename?: 'Offer' }
                  & Pick<Offer, 'paymentCashCents' | 'paymentProductCents' | 'paymentStoreCreditCents'>
                  & { profile: (
                    { __typename?: 'Profile' }
                    & Pick<Profile, 'displayName' | 'id' | 'profilePictureUrl'>
                    & { instagramAccount?: Maybe<(
                      { __typename?: 'InstagramAccount' }
                      & Pick<InstagramAccount, 'profilePictureUrl' | 'name' | 'handle'>
                    )>, agent?: Maybe<(
                      { __typename?: 'Agent' }
                      & Pick<Agent, 'agencyId'>
                      & { agency?: Maybe<(
                        { __typename?: 'Agency' }
                        & Pick<Agency, 'about' | 'name'>
                        & { agents: (
                          { __typename?: 'AgentConnection' }
                          & { nodes?: Maybe<Array<Maybe<(
                            { __typename?: 'Agent' }
                            & Pick<Agent, 'fullName' | 'id'>
                          )>>> }
                        ) }
                      )> }
                    )> }
                  ) }
                ) }
              )> }
            )>>>, pageInfo: (
              { __typename?: 'PageInfo' }
              & Pick<PageInfo, 'endCursor' | 'hasNextPage' | 'hasPreviousPage' | 'startCursor'>
            ) }
          ) }
        )>>> }
      ) }
    )> }
  )> }
);

export type DetachPaymentMethodMutationVariables = Exact<{
  input: PaymentPaymentMethodDetachInput;
}>;


export type DetachPaymentMethodMutation = (
  { __typename?: 'Mutation' }
  & { paymentPaymentMethodDetach?: Maybe<(
    { __typename?: 'PaymentPaymentMethodDetachPayload' }
    & Pick<PaymentPaymentMethodDetachPayload, 'success'>
    & { errors: Array<(
      { __typename?: 'UserError' }
      & Pick<UserError, 'message'>
    )> }
  )> }
);

export type PaymentPayInvoiceMutationVariables = Exact<{
  input: PaymentPayInvoiceInput;
}>;


export type PaymentPayInvoiceMutation = (
  { __typename?: 'Mutation' }
  & { paymentPayInvoice?: Maybe<(
    { __typename?: 'PaymentPayInvoicePayload' }
    & Pick<PaymentPayInvoicePayload, 'clientSecret'>
    & { errors: Array<(
      { __typename?: 'UserError' }
      & Pick<UserError, 'message'>
    )> }
  )> }
);

export type PaymentSetupIntentCreateMutationVariables = Exact<{
  input: PaymentSetupIntentCreateInput;
}>;


export type PaymentSetupIntentCreateMutation = (
  { __typename?: 'Mutation' }
  & { paymentSetupIntentCreate?: Maybe<(
    { __typename?: 'PaymentSetupIntentCreatePayload' }
    & Pick<PaymentSetupIntentCreatePayload, 'clientSecret'>
    & { errors: Array<(
      { __typename?: 'UserError' }
      & Pick<UserError, 'message'>
    )> }
  )> }
);

export type ProfileListAddMutationVariables = Exact<{
  input: ProfileListAddInput;
}>;


export type ProfileListAddMutation = (
  { __typename?: 'Mutation' }
  & { profileListAdd?: Maybe<(
    { __typename?: 'ProfileListAddPayload' }
    & { errors: Array<(
      { __typename?: 'UserError' }
      & Pick<UserError, 'message'>
    )>, profileList?: Maybe<(
      { __typename?: 'ProfileList' }
      & Pick<ProfileList, 'id'>
    )> }
  )> }
);

export type CreateFavoriteProfileListMutationVariables = Exact<{
  input: ProfileListCreateInput;
}>;


export type CreateFavoriteProfileListMutation = (
  { __typename?: 'Mutation' }
  & { profileListCreate?: Maybe<(
    { __typename?: 'ProfileListCreatePayload' }
    & { errors: Array<(
      { __typename?: 'UserError' }
      & Pick<UserError, 'message'>
    )>, profileList?: Maybe<(
      { __typename?: 'ProfileList' }
      & Pick<ProfileList, 'id'>
    )> }
  )> }
);

export type ProfileListRemoveMutationVariables = Exact<{
  input: ProfileListRemoveInput;
}>;


export type ProfileListRemoveMutation = (
  { __typename?: 'Mutation' }
  & { profileListRemove?: Maybe<(
    { __typename?: 'ProfileListRemovePayload' }
    & { errors: Array<(
      { __typename?: 'UserError' }
      & Pick<UserError, 'message'>
    )>, profileList?: Maybe<(
      { __typename?: 'ProfileList' }
      & Pick<ProfileList, 'id'>
    )> }
  )> }
);

export type OrganizationUpdateMutationVariables = Exact<{
  input: OrganizationUpdateInput;
}>;


export type OrganizationUpdateMutation = (
  { __typename?: 'Mutation' }
  & { organizationUpdate?: Maybe<(
    { __typename?: 'OrganizationUpdatePayload' }
    & { organization?: Maybe<(
      { __typename?: 'Organization' }
      & Pick<Organization, 'id' | 'about' | 'name' | 'photoUrl'>
    )>, errors: Array<(
      { __typename?: 'UserError' }
      & Pick<UserError, 'message'>
    )> }
  )> }
);

export type UpdatePasswordMutationVariables = Exact<{
  input: UserPasswordChangeInput;
}>;


export type UpdatePasswordMutation = (
  { __typename?: 'Mutation' }
  & { userPasswordChange?: Maybe<(
    { __typename?: 'UserPasswordChangePayload' }
    & Pick<UserPasswordChangePayload, 'success'>
    & { errors: Array<(
      { __typename?: 'UserError' }
      & Pick<UserError, 'message'>
    )> }
  )> }
);

export type UserCreateMutationVariables = Exact<{
  input: UserCreateInput;
}>;


export type UserCreateMutation = (
  { __typename?: 'Mutation' }
  & { userCreate?: Maybe<(
    { __typename?: 'UserCreatePayload' }
    & Pick<UserCreatePayload, 'password'>
    & { errors: Array<(
      { __typename?: 'UserError' }
      & Pick<UserError, 'message'>
    )> }
  )> }
);

export type UserNotificationReadSubscriptionVariables = Exact<{
  userId: Scalars['ID'];
}>;


export type UserNotificationReadSubscription = (
  { __typename?: 'Subscription' }
  & { userNotificationRead: (
    { __typename?: 'UserNotificationReadPayload' }
    & { readNotification?: Maybe<(
      { __typename?: 'Notification' }
      & Pick<Notification, 'id' | 'readAt'>
    )>, errors: Array<(
      { __typename?: 'UserError' }
      & Pick<UserError, 'message' | 'path'>
    )> }
  ) }
);

export type UserNotificationsQueryVariables = Exact<{
  after?: Maybe<Scalars['String']>;
}>;


export type UserNotificationsQuery = (
  { __typename?: 'Query' }
  & { currentUser?: Maybe<(
    { __typename?: 'Agent' }
    & { notifications: (
      { __typename?: 'NotificationConnection' }
      & { nodes?: Maybe<Array<Maybe<(
        { __typename?: 'Notification' }
        & NotificationDataFragment
      )>>>, pageInfo: (
        { __typename?: 'PageInfo' }
        & Pick<PageInfo, 'endCursor' | 'hasNextPage' | 'hasPreviousPage'>
      ) }
    ) }
  ) | (
    { __typename?: 'Influencer' }
    & { notifications: (
      { __typename?: 'NotificationConnection' }
      & { nodes?: Maybe<Array<Maybe<(
        { __typename?: 'Notification' }
        & NotificationDataFragment
      )>>>, pageInfo: (
        { __typename?: 'PageInfo' }
        & Pick<PageInfo, 'endCursor' | 'hasNextPage' | 'hasPreviousPage'>
      ) }
    ) }
  ) | (
    { __typename?: 'Personnel' }
    & { notifications: (
      { __typename?: 'NotificationConnection' }
      & { nodes?: Maybe<Array<Maybe<(
        { __typename?: 'Notification' }
        & NotificationDataFragment
      )>>>, pageInfo: (
        { __typename?: 'PageInfo' }
        & Pick<PageInfo, 'endCursor' | 'hasNextPage' | 'hasPreviousPage'>
      ) }
    ) }
  )> }
);

export type UserNotificationSubscriptionVariables = Exact<{
  userId: Scalars['ID'];
}>;


export type UserNotificationSubscription = (
  { __typename?: 'Subscription' }
  & { userNotifications: (
    { __typename?: 'UserNotificationsPayload' }
    & { newNotification?: Maybe<(
      { __typename?: 'Notification' }
      & NotificationDataFragment
    )>, errors: Array<(
      { __typename?: 'UserError' }
      & Pick<UserError, 'message' | 'path'>
    )> }
  ) }
);

export type UserUnreadNotificationsQueryVariables = Exact<{ [key: string]: never; }>;


export type UserUnreadNotificationsQuery = (
  { __typename?: 'Query' }
  & { currentUser?: Maybe<(
    { __typename?: 'Agent' }
    & { unreadNotifications: (
      { __typename?: 'NotificationConnection' }
      & { nodes?: Maybe<Array<Maybe<(
        { __typename?: 'Notification' }
        & NotificationDataFragment
      )>>> }
    ) }
  ) | (
    { __typename?: 'Influencer' }
    & { unreadNotifications: (
      { __typename?: 'NotificationConnection' }
      & { nodes?: Maybe<Array<Maybe<(
        { __typename?: 'Notification' }
        & NotificationDataFragment
      )>>> }
    ) }
  ) | (
    { __typename?: 'Personnel' }
    & { unreadNotifications: (
      { __typename?: 'NotificationConnection' }
      & { nodes?: Maybe<Array<Maybe<(
        { __typename?: 'Notification' }
        & NotificationDataFragment
      )>>> }
    ) }
  )> }
);

export type UsersToApproveQueryVariables = Exact<{ [key: string]: never; }>;


export type UsersToApproveQuery = (
  { __typename?: 'Query' }
  & { usersToApprove: Array<(
    { __typename?: 'Agent' }
    & Pick<Agent, 'email' | 'fullName' | 'id' | 'requirements'>
  ) | (
    { __typename?: 'Influencer' }
    & Pick<Influencer, 'email' | 'fullName' | 'id' | 'requirements'>
  ) | (
    { __typename?: 'Personnel' }
    & Pick<Personnel, 'email' | 'fullName' | 'id' | 'requirements'>
  )> }
);

export type VerifyEmailMutationVariables = Exact<{
  input: VerifyEmailInput;
}>;


export type VerifyEmailMutation = (
  { __typename?: 'Mutation' }
  & { verifyEmail?: Maybe<(
    { __typename?: 'VerifyEmailPayload' }
    & { errors: Array<(
      { __typename?: 'UserError' }
      & Pick<UserError, 'message'>
    )>, user?: Maybe<(
      { __typename?: 'Agent' }
      & Pick<Agent, 'email' | 'fullName' | 'id'>
    ) | (
      { __typename?: 'Influencer' }
      & Pick<Influencer, 'email' | 'fullName' | 'id'>
    ) | (
      { __typename?: 'Personnel' }
      & Pick<Personnel, 'email' | 'fullName' | 'id'>
    )> }
  )> }
);

export const NotificationDataFragmentDoc = gql`
    fragment NotificationData on Notification {
  id
  readAt
  createdAt
  type
  application {
    id
    state
  }
  campaign {
    id
    name
    photoUrl
    dateStart
    dateEnd
  }
  profile {
    id
    displayName
    profilePictureUrl
    instagramAccount {
      profilePictureUrl
    }
  }
  deliverable {
    id
    state
    mediaUrls
  }
  offer {
    id
  }
  readAt
}
    `;
export const AgentsAdminDocument = gql`
    query AgentsAdmin($input: Int!, $after: String!) {
  agents(first: $input, after: $after) {
    edges {
      cursor
      node {
        email
        fullName
        agency {
          about
          name
          id
          photoUrl
          profiles {
            edges {
              node {
                instagramAccount {
                  handle
                }
                id
                bio
                displayName
                cometchatId
                profilePictureUrl
                applications {
                  nodes {
                    id
                    state
                  }
                }
                offers {
                  nodes {
                    id
                    state
                  }
                }
              }
            }
            pageInfo {
              endCursor
              hasNextPage
              hasPreviousPage
              startCursor
            }
          }
          applications {
            nodes {
              id
              profile {
                displayName
              }
            }
          }
          invoices {
            nodes {
              id
              invoiceStatus
            }
          }
          offers {
            nodes {
              id
              state
              profile {
                displayName
              }
            }
          }
        }
      }
    }
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
  }
}
    `;

/**
 * __useAgentsAdminQuery__
 *
 * To run a query within a React component, call `useAgentsAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useAgentsAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAgentsAdminQuery({
 *   variables: {
 *      input: // value for 'input'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useAgentsAdminQuery(baseOptions: Apollo.QueryHookOptions<AgentsAdminQuery, AgentsAdminQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AgentsAdminQuery, AgentsAdminQueryVariables>(AgentsAdminDocument, options);
      }
export function useAgentsAdminLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AgentsAdminQuery, AgentsAdminQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AgentsAdminQuery, AgentsAdminQueryVariables>(AgentsAdminDocument, options);
        }
export type AgentsAdminQueryHookResult = ReturnType<typeof useAgentsAdminQuery>;
export type AgentsAdminLazyQueryHookResult = ReturnType<typeof useAgentsAdminLazyQuery>;
export type AgentsAdminQueryResult = Apollo.QueryResult<AgentsAdminQuery, AgentsAdminQueryVariables>;
export const ApproveUserDocument = gql`
    mutation ApproveUser($input: UserApproveInput!) {
  userApprove(input: $input) {
    errors {
      message
    }
    success
  }
}
    `;
export type ApproveUserMutationFn = Apollo.MutationFunction<ApproveUserMutation, ApproveUserMutationVariables>;

/**
 * __useApproveUserMutation__
 *
 * To run a mutation, you first call `useApproveUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveUserMutation, { data, loading, error }] = useApproveUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useApproveUserMutation(baseOptions?: Apollo.MutationHookOptions<ApproveUserMutation, ApproveUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApproveUserMutation, ApproveUserMutationVariables>(ApproveUserDocument, options);
      }
export type ApproveUserMutationHookResult = ReturnType<typeof useApproveUserMutation>;
export type ApproveUserMutationResult = Apollo.MutationResult<ApproveUserMutation>;
export type ApproveUserMutationOptions = Apollo.BaseMutationOptions<ApproveUserMutation, ApproveUserMutationVariables>;
export const BrandsAdminCampaignsDocument = gql`
    query BrandsAdminCampaigns($input: ID!) {
  personnel(id: $input) {
    brands {
      nodes {
        campaigns {
          nodes {
            id
            name
            isPublished
            dateStart
            dateEnd
            applications {
              nodes {
                id
                paymentEstimateCents
                acceptsPayByCash
                acceptsPayByProduct
                acceptsPayByStoreCredit
                state
                profile {
                  displayName
                  agent {
                    fullName
                    id
                  }
                  instagramAccount {
                    handle
                  }
                }
              }
            }
            invoices {
              nodes {
                id
                invoiceStatus
                payoutAmountCents
              }
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useBrandsAdminCampaignsQuery__
 *
 * To run a query within a React component, call `useBrandsAdminCampaignsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBrandsAdminCampaignsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBrandsAdminCampaignsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBrandsAdminCampaignsQuery(baseOptions: Apollo.QueryHookOptions<BrandsAdminCampaignsQuery, BrandsAdminCampaignsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BrandsAdminCampaignsQuery, BrandsAdminCampaignsQueryVariables>(BrandsAdminCampaignsDocument, options);
      }
export function useBrandsAdminCampaignsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BrandsAdminCampaignsQuery, BrandsAdminCampaignsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BrandsAdminCampaignsQuery, BrandsAdminCampaignsQueryVariables>(BrandsAdminCampaignsDocument, options);
        }
export type BrandsAdminCampaignsQueryHookResult = ReturnType<typeof useBrandsAdminCampaignsQuery>;
export type BrandsAdminCampaignsLazyQueryHookResult = ReturnType<typeof useBrandsAdminCampaignsLazyQuery>;
export type BrandsAdminCampaignsQueryResult = Apollo.QueryResult<BrandsAdminCampaignsQuery, BrandsAdminCampaignsQueryVariables>;
export const BrandsAdminDocument = gql`
    query BrandsAdmin($input: Int!, $after: String!) {
  personnels(first: $input, after: $after) {
    edges {
      cursor
      node {
        email
        id
        fullName
        brands {
          nodes {
            id
            about
            name
            websiteUrl
            photoUrl
            applications {
              nodes {
                id
                state
              }
            }
            campaigns {
              nodes {
                id
                isPublished
              }
            }
            invoices {
              nodes {
                id
                invoiceStatus
              }
            }
            offers {
              nodes {
                id
                state
              }
            }
          }
        }
      }
    }
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
  }
}
    `;

/**
 * __useBrandsAdminQuery__
 *
 * To run a query within a React component, call `useBrandsAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useBrandsAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBrandsAdminQuery({
 *   variables: {
 *      input: // value for 'input'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useBrandsAdminQuery(baseOptions: Apollo.QueryHookOptions<BrandsAdminQuery, BrandsAdminQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BrandsAdminQuery, BrandsAdminQueryVariables>(BrandsAdminDocument, options);
      }
export function useBrandsAdminLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BrandsAdminQuery, BrandsAdminQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BrandsAdminQuery, BrandsAdminQueryVariables>(BrandsAdminDocument, options);
        }
export type BrandsAdminQueryHookResult = ReturnType<typeof useBrandsAdminQuery>;
export type BrandsAdminLazyQueryHookResult = ReturnType<typeof useBrandsAdminLazyQuery>;
export type BrandsAdminQueryResult = Apollo.QueryResult<BrandsAdminQuery, BrandsAdminQueryVariables>;
export const BrandsAdminStatsDocument = gql`
    query BrandsAdminStats($input: Int!, $after: String!) {
  organizations(first: $input, after: $after) {
    edges {
      cursor
      node {
        brands {
          nodes {
            campaigns {
              nodes {
                id
                isPublished
              }
            }
            applications {
              nodes {
                id
              }
            }
            offers {
              nodes {
                id
              }
            }
            invoices {
              nodes {
                id
                invoiceStatus
                payoutAmountCents
                product {
                  paymentCashCents
                  paymentStoreCreditCents
                  paymentProductCents
                }
              }
            }
          }
        }
      }
    }
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
  }
}
    `;

/**
 * __useBrandsAdminStatsQuery__
 *
 * To run a query within a React component, call `useBrandsAdminStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBrandsAdminStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBrandsAdminStatsQuery({
 *   variables: {
 *      input: // value for 'input'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useBrandsAdminStatsQuery(baseOptions: Apollo.QueryHookOptions<BrandsAdminStatsQuery, BrandsAdminStatsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BrandsAdminStatsQuery, BrandsAdminStatsQueryVariables>(BrandsAdminStatsDocument, options);
      }
export function useBrandsAdminStatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BrandsAdminStatsQuery, BrandsAdminStatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BrandsAdminStatsQuery, BrandsAdminStatsQueryVariables>(BrandsAdminStatsDocument, options);
        }
export type BrandsAdminStatsQueryHookResult = ReturnType<typeof useBrandsAdminStatsQuery>;
export type BrandsAdminStatsLazyQueryHookResult = ReturnType<typeof useBrandsAdminStatsLazyQuery>;
export type BrandsAdminStatsQueryResult = Apollo.QueryResult<BrandsAdminStatsQuery, BrandsAdminStatsQueryVariables>;
export const CampaignDocument = gql`
    query Campaign($id: ID!) {
  campaign(id: $id) {
    id
    name
    photoUrl
    isPublished
    dateStart
    dateEnd
    description
    gender
    ageRangeMax
    ageRangeMin
    keyMessage
    paymentStoreProductCents
    paymentStoreCreditCents
    paymentCashCents
    deliverableDescriptions {
      description
      dueDate
      metadata
      type
    }
    applications {
      nodes {
        acceptsPayByCash
        acceptsPayByProduct
        acceptsPayByStoreCredit
        paymentEstimateCents
        applicant {
          fullName
          id
          email
        }
        profile {
          bio
          displayName
          minimumFeeCents
          id
          profilePictureUrl
        }
        campaign {
          name
          id
          deliverableDescriptions {
            description
            dueDate
            metadata
            type
          }
        }
        id
        pitch
        state
      }
    }
    offers {
      nodes {
        state
        groupchatId
        id
        paymentCashCents
        paymentProductCents
        paymentStoreCreditCents
        exclusivityMonths
        usageRightsMonths
        profile {
          bio
          displayName
          minimumFeeCents
          id
          profilePictureUrl
          instagramAccount {
            handle
          }
        }
        campaign {
          name
          keyMessage
          id
          dateEnd
          dateStart
        }
        deliverables {
          nodes {
            caption
            description
            id
            mediaUrls
            type
            state
            metadata
            offer {
              profile {
                id
                displayName
                profilePictureUrl
                cometchatId
              }
            }
          }
        }
        deliverableDescriptions {
          description
          dueDate
          metadata
          type
        }
      }
    }
    invoices {
      nodes {
        id
        invoiceStatus
        product {
          id
          paymentStoreCreditCents
          paymentProductCents
          paymentCashCents
          campaign {
            id
            dateEnd
          }
          profile {
            id
            profilePictureUrl
            displayName
          }
        }
      }
    }
  }
}
    `;

/**
 * __useCampaignQuery__
 *
 * To run a query within a React component, call `useCampaignQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampaignQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampaignQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCampaignQuery(baseOptions: Apollo.QueryHookOptions<CampaignQuery, CampaignQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CampaignQuery, CampaignQueryVariables>(CampaignDocument, options);
      }
export function useCampaignLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CampaignQuery, CampaignQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CampaignQuery, CampaignQueryVariables>(CampaignDocument, options);
        }
export type CampaignQueryHookResult = ReturnType<typeof useCampaignQuery>;
export type CampaignLazyQueryHookResult = ReturnType<typeof useCampaignLazyQuery>;
export type CampaignQueryResult = Apollo.QueryResult<CampaignQuery, CampaignQueryVariables>;
export const CampaignUpdateIsPublishedDocument = gql`
    mutation CampaignUpdateIsPublished($input: CampaignUpdateInput!) {
  campaignUpdate(input: $input) {
    campaign {
      isPublished
    }
    errors {
      message
    }
  }
}
    `;
export type CampaignUpdateIsPublishedMutationFn = Apollo.MutationFunction<CampaignUpdateIsPublishedMutation, CampaignUpdateIsPublishedMutationVariables>;

/**
 * __useCampaignUpdateIsPublishedMutation__
 *
 * To run a mutation, you first call `useCampaignUpdateIsPublishedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCampaignUpdateIsPublishedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [campaignUpdateIsPublishedMutation, { data, loading, error }] = useCampaignUpdateIsPublishedMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCampaignUpdateIsPublishedMutation(baseOptions?: Apollo.MutationHookOptions<CampaignUpdateIsPublishedMutation, CampaignUpdateIsPublishedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CampaignUpdateIsPublishedMutation, CampaignUpdateIsPublishedMutationVariables>(CampaignUpdateIsPublishedDocument, options);
      }
export type CampaignUpdateIsPublishedMutationHookResult = ReturnType<typeof useCampaignUpdateIsPublishedMutation>;
export type CampaignUpdateIsPublishedMutationResult = Apollo.MutationResult<CampaignUpdateIsPublishedMutation>;
export type CampaignUpdateIsPublishedMutationOptions = Apollo.BaseMutationOptions<CampaignUpdateIsPublishedMutation, CampaignUpdateIsPublishedMutationVariables>;
export const CampaignUpdateDocument = gql`
    mutation CampaignUpdate($input: CampaignUpdateInput!) {
  campaignUpdate(input: $input) {
    campaign {
      id
      name
      photoUrl
      dateStart
      dateEnd
      deliverableDescriptions {
        description
        dueDate
        metadata
        type
      }
      description
      gender
      ageRangeMax
      ageRangeMin
      keyMessage
      paymentCashCents
      paymentStoreCreditCents
      paymentStoreProductCents
    }
    errors {
      message
    }
  }
}
    `;
export type CampaignUpdateMutationFn = Apollo.MutationFunction<CampaignUpdateMutation, CampaignUpdateMutationVariables>;

/**
 * __useCampaignUpdateMutation__
 *
 * To run a mutation, you first call `useCampaignUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCampaignUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [campaignUpdateMutation, { data, loading, error }] = useCampaignUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCampaignUpdateMutation(baseOptions?: Apollo.MutationHookOptions<CampaignUpdateMutation, CampaignUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CampaignUpdateMutation, CampaignUpdateMutationVariables>(CampaignUpdateDocument, options);
      }
export type CampaignUpdateMutationHookResult = ReturnType<typeof useCampaignUpdateMutation>;
export type CampaignUpdateMutationResult = Apollo.MutationResult<CampaignUpdateMutation>;
export type CampaignUpdateMutationOptions = Apollo.BaseMutationOptions<CampaignUpdateMutation, CampaignUpdateMutationVariables>;
export const CampaignsOfferModalDocument = gql`
    query CampaignsOfferModal($appProfileId: ApplicationFilter, $offProfileId: OfferFilter) {
  currentUser {
    ... on Personnel {
      brands {
        nodes {
          campaigns {
            nodes {
              id
              name
              photoUrl
              isPublished
              dateStart
              dateEnd
              description
              gender
              ageRangeMax
              ageRangeMin
              keyMessage
              paymentStoreProductCents
              paymentStoreCreditCents
              paymentCashCents
              deliverableDescriptions {
                description
                dueDate
                metadata
                type
              }
              applications(filter: $appProfileId) {
                nodes {
                  acceptsPayByCash
                  acceptsPayByProduct
                  acceptsPayByStoreCredit
                  paymentEstimateCents
                  id
                  pitch
                  state
                  applicant {
                    fullName
                    id
                    email
                  }
                  profile {
                    bio
                    displayName
                    minimumFeeCents
                    id
                    profilePictureUrl
                  }
                  campaign {
                    name
                    id
                    deliverableDescriptions {
                      description
                      dueDate
                      metadata
                      type
                    }
                  }
                }
              }
              offers(filter: $offProfileId) {
                nodes {
                  state
                  groupchatId
                  id
                  profile {
                    bio
                    displayName
                    minimumFeeCents
                    id
                    profilePictureUrl
                  }
                  campaign {
                    name
                    keyMessage
                    id
                    dateEnd
                    dateStart
                    invoices {
                      nodes {
                        id
                        invoiceStatus
                      }
                    }
                  }
                  paymentCashCents
                  paymentProductCents
                  paymentStoreCreditCents
                  exclusivityMonths
                  usageRightsMonths
                  deliverableDescriptions {
                    description
                    dueDate
                    metadata
                    type
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useCampaignsOfferModalQuery__
 *
 * To run a query within a React component, call `useCampaignsOfferModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampaignsOfferModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampaignsOfferModalQuery({
 *   variables: {
 *      appProfileId: // value for 'appProfileId'
 *      offProfileId: // value for 'offProfileId'
 *   },
 * });
 */
export function useCampaignsOfferModalQuery(baseOptions?: Apollo.QueryHookOptions<CampaignsOfferModalQuery, CampaignsOfferModalQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CampaignsOfferModalQuery, CampaignsOfferModalQueryVariables>(CampaignsOfferModalDocument, options);
      }
export function useCampaignsOfferModalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CampaignsOfferModalQuery, CampaignsOfferModalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CampaignsOfferModalQuery, CampaignsOfferModalQueryVariables>(CampaignsOfferModalDocument, options);
        }
export type CampaignsOfferModalQueryHookResult = ReturnType<typeof useCampaignsOfferModalQuery>;
export type CampaignsOfferModalLazyQueryHookResult = ReturnType<typeof useCampaignsOfferModalLazyQuery>;
export type CampaignsOfferModalQueryResult = Apollo.QueryResult<CampaignsOfferModalQuery, CampaignsOfferModalQueryVariables>;
export const CampaignCreateDocument = gql`
    mutation campaignCreate($id: CampaignCreateInput!) {
  campaignCreate(input: $id) {
    campaign {
      id
      isPublished
    }
    errors {
      message
    }
  }
}
    `;
export type CampaignCreateMutationFn = Apollo.MutationFunction<CampaignCreateMutation, CampaignCreateMutationVariables>;

/**
 * __useCampaignCreateMutation__
 *
 * To run a mutation, you first call `useCampaignCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCampaignCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [campaignCreateMutation, { data, loading, error }] = useCampaignCreateMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCampaignCreateMutation(baseOptions?: Apollo.MutationHookOptions<CampaignCreateMutation, CampaignCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CampaignCreateMutation, CampaignCreateMutationVariables>(CampaignCreateDocument, options);
      }
export type CampaignCreateMutationHookResult = ReturnType<typeof useCampaignCreateMutation>;
export type CampaignCreateMutationResult = Apollo.MutationResult<CampaignCreateMutation>;
export type CampaignCreateMutationOptions = Apollo.BaseMutationOptions<CampaignCreateMutation, CampaignCreateMutationVariables>;
export const OrganizationCreateDocument = gql`
    mutation OrganizationCreate($input: OrganizationCreateInput!) {
  organizationCreate(input: $input) {
    organization {
      id
      about
      name
      photoUrl
      websiteUrl
    }
    errors {
      message
    }
  }
}
    `;
export type OrganizationCreateMutationFn = Apollo.MutationFunction<OrganizationCreateMutation, OrganizationCreateMutationVariables>;

/**
 * __useOrganizationCreateMutation__
 *
 * To run a mutation, you first call `useOrganizationCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrganizationCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [organizationCreateMutation, { data, loading, error }] = useOrganizationCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOrganizationCreateMutation(baseOptions?: Apollo.MutationHookOptions<OrganizationCreateMutation, OrganizationCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OrganizationCreateMutation, OrganizationCreateMutationVariables>(OrganizationCreateDocument, options);
      }
export type OrganizationCreateMutationHookResult = ReturnType<typeof useOrganizationCreateMutation>;
export type OrganizationCreateMutationResult = Apollo.MutationResult<OrganizationCreateMutation>;
export type OrganizationCreateMutationOptions = Apollo.BaseMutationOptions<OrganizationCreateMutation, OrganizationCreateMutationVariables>;
export const CurrentUserCampaignManageDocument = gql`
    query CurrentUserCampaignManage {
  currentUser {
    ... on Personnel {
      id
      fullName
      brands {
        edges {
          node {
            name
            about
            id
            campaigns {
              edges {
                node {
                  dateEnd
                  dateStart
                  gender
                  id
                  name
                  ageRangeMax
                  ageRangeMin
                  isPublished
                  paymentCashCents
                  paymentStoreProductCents
                  paymentStoreCreditCents
                  deliverableDescriptions {
                    description
                    dueDate
                    metadata
                    type
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useCurrentUserCampaignManageQuery__
 *
 * To run a query within a React component, call `useCurrentUserCampaignManageQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserCampaignManageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserCampaignManageQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserCampaignManageQuery(baseOptions?: Apollo.QueryHookOptions<CurrentUserCampaignManageQuery, CurrentUserCampaignManageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CurrentUserCampaignManageQuery, CurrentUserCampaignManageQueryVariables>(CurrentUserCampaignManageDocument, options);
      }
export function useCurrentUserCampaignManageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrentUserCampaignManageQuery, CurrentUserCampaignManageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CurrentUserCampaignManageQuery, CurrentUserCampaignManageQueryVariables>(CurrentUserCampaignManageDocument, options);
        }
export type CurrentUserCampaignManageQueryHookResult = ReturnType<typeof useCurrentUserCampaignManageQuery>;
export type CurrentUserCampaignManageLazyQueryHookResult = ReturnType<typeof useCurrentUserCampaignManageLazyQuery>;
export type CurrentUserCampaignManageQueryResult = Apollo.QueryResult<CurrentUserCampaignManageQuery, CurrentUserCampaignManageQueryVariables>;
export const CurrentUserHomeDocument = gql`
    query CurrentUserHome($filter: ApplicationFilter!) {
  currentUser {
    ... on Personnel {
      id
      fullName
      brands {
        edges {
          node {
            name
            about
            id
            campaigns {
              edges {
                node {
                  dateEnd
                  dateStart
                  gender
                  id
                  name
                  paymentCashCents
                  paymentStoreProductCents
                  paymentStoreCreditCents
                  ageRangeMax
                  ageRangeMin
                  isPublished
                  deliverableDescriptions {
                    description
                    dueDate
                    metadata
                    type
                  }
                  applications(filter: $filter) {
                    edges {
                      node {
                        id
                        state
                        pitch
                        acceptsPayByCash
                        acceptsPayByProduct
                        acceptsPayByStoreCredit
                        paymentEstimateCents
                        profile {
                          bio
                          displayName
                          id
                          profilePictureUrl
                        }
                        applicant {
                          fullName
                          id
                          email
                        }
                        campaign {
                          name
                          id
                          deliverableDescriptions {
                            description
                            dueDate
                            metadata
                            type
                          }
                        }
                      }
                    }
                  }
                  offers {
                    nodes {
                      id
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useCurrentUserHomeQuery__
 *
 * To run a query within a React component, call `useCurrentUserHomeQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserHomeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserHomeQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCurrentUserHomeQuery(baseOptions: Apollo.QueryHookOptions<CurrentUserHomeQuery, CurrentUserHomeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CurrentUserHomeQuery, CurrentUserHomeQueryVariables>(CurrentUserHomeDocument, options);
      }
export function useCurrentUserHomeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrentUserHomeQuery, CurrentUserHomeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CurrentUserHomeQuery, CurrentUserHomeQueryVariables>(CurrentUserHomeDocument, options);
        }
export type CurrentUserHomeQueryHookResult = ReturnType<typeof useCurrentUserHomeQuery>;
export type CurrentUserHomeLazyQueryHookResult = ReturnType<typeof useCurrentUserHomeLazyQuery>;
export type CurrentUserHomeQueryResult = Apollo.QueryResult<CurrentUserHomeQuery, CurrentUserHomeQueryVariables>;
export const CurrentUserNotificationPreferencesDocument = gql`
    query CurrentUserNotificationPreferences {
  currentUser {
    notificationPreference {
      applicationCreated
      campaignEnded
      campaignHalfway
      campaignOneDayLeft
      campaignStarted
      contentSubmitted
      contentUpdated
      dummyAction
      offerAccepted
      paymentSetupNeeded
      messageReceived
    }
    emailPreference {
      applicationCreated
      contentSubmitted
      contentUpdated
      dummyAction
      messageReceived
      passwordReset
    }
  }
}
    `;

/**
 * __useCurrentUserNotificationPreferencesQuery__
 *
 * To run a query within a React component, call `useCurrentUserNotificationPreferencesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserNotificationPreferencesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserNotificationPreferencesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserNotificationPreferencesQuery(baseOptions?: Apollo.QueryHookOptions<CurrentUserNotificationPreferencesQuery, CurrentUserNotificationPreferencesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CurrentUserNotificationPreferencesQuery, CurrentUserNotificationPreferencesQueryVariables>(CurrentUserNotificationPreferencesDocument, options);
      }
export function useCurrentUserNotificationPreferencesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrentUserNotificationPreferencesQuery, CurrentUserNotificationPreferencesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CurrentUserNotificationPreferencesQuery, CurrentUserNotificationPreferencesQueryVariables>(CurrentUserNotificationPreferencesDocument, options);
        }
export type CurrentUserNotificationPreferencesQueryHookResult = ReturnType<typeof useCurrentUserNotificationPreferencesQuery>;
export type CurrentUserNotificationPreferencesLazyQueryHookResult = ReturnType<typeof useCurrentUserNotificationPreferencesLazyQuery>;
export type CurrentUserNotificationPreferencesQueryResult = Apollo.QueryResult<CurrentUserNotificationPreferencesQuery, CurrentUserNotificationPreferencesQueryVariables>;
export const CurrentUserDocument = gql`
    query CurrentUser {
  currentUser {
    ... on Personnel {
      id
      fullName
      email
      organization {
        paymentMethods {
          brand
          id
          lastFour
        }
        brands {
          nodes {
            id
          }
        }
        id
        name
        photoUrl
        websiteUrl
        about
      }
      preferences {
        language
      }
      requirements
    }
  }
}
    `;

/**
 * __useCurrentUserQuery__
 *
 * To run a query within a React component, call `useCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserQuery(baseOptions?: Apollo.QueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, options);
      }
export function useCurrentUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, options);
        }
export type CurrentUserQueryHookResult = ReturnType<typeof useCurrentUserQuery>;
export type CurrentUserLazyQueryHookResult = ReturnType<typeof useCurrentUserLazyQuery>;
export type CurrentUserQueryResult = Apollo.QueryResult<CurrentUserQuery, CurrentUserQueryVariables>;
export const CurrentUserSettingsDocument = gql`
    query CurrentUserSettings {
  currentUser {
    ... on Personnel {
      email
      fullName
      id
      organization {
        paymentMethods {
          brand
          id
          lastFour
        }
        about
        name
        id
        photoUrl
        websiteUrl
      }
    }
  }
}
    `;

/**
 * __useCurrentUserSettingsQuery__
 *
 * To run a query within a React component, call `useCurrentUserSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserSettingsQuery(baseOptions?: Apollo.QueryHookOptions<CurrentUserSettingsQuery, CurrentUserSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CurrentUserSettingsQuery, CurrentUserSettingsQueryVariables>(CurrentUserSettingsDocument, options);
      }
export function useCurrentUserSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrentUserSettingsQuery, CurrentUserSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CurrentUserSettingsQuery, CurrentUserSettingsQueryVariables>(CurrentUserSettingsDocument, options);
        }
export type CurrentUserSettingsQueryHookResult = ReturnType<typeof useCurrentUserSettingsQuery>;
export type CurrentUserSettingsLazyQueryHookResult = ReturnType<typeof useCurrentUserSettingsLazyQuery>;
export type CurrentUserSettingsQueryResult = Apollo.QueryResult<CurrentUserSettingsQuery, CurrentUserSettingsQueryVariables>;
export const DeliverableAcceptDocument = gql`
    mutation DeliverableAccept($input: DeliverableAcceptInput!) {
  deliverableAccept(input: $input) {
    deliverable {
      id
    }
    errors {
      message
    }
  }
}
    `;
export type DeliverableAcceptMutationFn = Apollo.MutationFunction<DeliverableAcceptMutation, DeliverableAcceptMutationVariables>;

/**
 * __useDeliverableAcceptMutation__
 *
 * To run a mutation, you first call `useDeliverableAcceptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeliverableAcceptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deliverableAcceptMutation, { data, loading, error }] = useDeliverableAcceptMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeliverableAcceptMutation(baseOptions?: Apollo.MutationHookOptions<DeliverableAcceptMutation, DeliverableAcceptMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeliverableAcceptMutation, DeliverableAcceptMutationVariables>(DeliverableAcceptDocument, options);
      }
export type DeliverableAcceptMutationHookResult = ReturnType<typeof useDeliverableAcceptMutation>;
export type DeliverableAcceptMutationResult = Apollo.MutationResult<DeliverableAcceptMutation>;
export type DeliverableAcceptMutationOptions = Apollo.BaseMutationOptions<DeliverableAcceptMutation, DeliverableAcceptMutationVariables>;
export const DeliverableRejectDocument = gql`
    mutation DeliverableReject($input: DeliverableRejectInput!) {
  deliverableReject(input: $input) {
    deliverable {
      id
    }
    errors {
      message
    }
  }
}
    `;
export type DeliverableRejectMutationFn = Apollo.MutationFunction<DeliverableRejectMutation, DeliverableRejectMutationVariables>;

/**
 * __useDeliverableRejectMutation__
 *
 * To run a mutation, you first call `useDeliverableRejectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeliverableRejectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deliverableRejectMutation, { data, loading, error }] = useDeliverableRejectMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeliverableRejectMutation(baseOptions?: Apollo.MutationHookOptions<DeliverableRejectMutation, DeliverableRejectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeliverableRejectMutation, DeliverableRejectMutationVariables>(DeliverableRejectDocument, options);
      }
export type DeliverableRejectMutationHookResult = ReturnType<typeof useDeliverableRejectMutation>;
export type DeliverableRejectMutationResult = Apollo.MutationResult<DeliverableRejectMutation>;
export type DeliverableRejectMutationOptions = Apollo.BaseMutationOptions<DeliverableRejectMutation, DeliverableRejectMutationVariables>;
export const EmailPreferencesUpdateDocument = gql`
    mutation EmailPreferencesUpdate($emailType: EmailType!, $newValue: Boolean!) {
  emailPreferencesUpdate(input: {emailType: $emailType, newValue: $newValue}) {
    user {
      id
    }
    errors {
      message
      path
    }
  }
}
    `;
export type EmailPreferencesUpdateMutationFn = Apollo.MutationFunction<EmailPreferencesUpdateMutation, EmailPreferencesUpdateMutationVariables>;

/**
 * __useEmailPreferencesUpdateMutation__
 *
 * To run a mutation, you first call `useEmailPreferencesUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEmailPreferencesUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [emailPreferencesUpdateMutation, { data, loading, error }] = useEmailPreferencesUpdateMutation({
 *   variables: {
 *      emailType: // value for 'emailType'
 *      newValue: // value for 'newValue'
 *   },
 * });
 */
export function useEmailPreferencesUpdateMutation(baseOptions?: Apollo.MutationHookOptions<EmailPreferencesUpdateMutation, EmailPreferencesUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EmailPreferencesUpdateMutation, EmailPreferencesUpdateMutationVariables>(EmailPreferencesUpdateDocument, options);
      }
export type EmailPreferencesUpdateMutationHookResult = ReturnType<typeof useEmailPreferencesUpdateMutation>;
export type EmailPreferencesUpdateMutationResult = Apollo.MutationResult<EmailPreferencesUpdateMutation>;
export type EmailPreferencesUpdateMutationOptions = Apollo.BaseMutationOptions<EmailPreferencesUpdateMutation, EmailPreferencesUpdateMutationVariables>;
export const InfluencerFavListDocument = gql`
    query InfluencerFavList {
  currentUser {
    profileLists {
      nodes {
        id
        name
        profiles {
          edges {
            cursor
            node {
              cometchatId
              displayName
              id
              profilePictureUrl
              minimumFeeCents
              interests {
                code
                name
              }
              instagramAccount {
                followerCount
                handle
                profilePictureUrl
                name
                hypeauditorReport {
                  audienceAgesFemale
                  audienceAgesMale
                  audienceCities
                  audienceCountries
                  engagementRate
                  country
                  followCount
                  followerCount
                  fullName
                  handle
                }
              }
            }
          }
          pageInfo {
            endCursor
            hasNextPage
            hasPreviousPage
            startCursor
          }
        }
      }
    }
  }
}
    `;

/**
 * __useInfluencerFavListQuery__
 *
 * To run a query within a React component, call `useInfluencerFavListQuery` and pass it any options that fit your needs.
 * When your component renders, `useInfluencerFavListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInfluencerFavListQuery({
 *   variables: {
 *   },
 * });
 */
export function useInfluencerFavListQuery(baseOptions?: Apollo.QueryHookOptions<InfluencerFavListQuery, InfluencerFavListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InfluencerFavListQuery, InfluencerFavListQueryVariables>(InfluencerFavListDocument, options);
      }
export function useInfluencerFavListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InfluencerFavListQuery, InfluencerFavListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InfluencerFavListQuery, InfluencerFavListQueryVariables>(InfluencerFavListDocument, options);
        }
export type InfluencerFavListQueryHookResult = ReturnType<typeof useInfluencerFavListQuery>;
export type InfluencerFavListLazyQueryHookResult = ReturnType<typeof useInfluencerFavListLazyQuery>;
export type InfluencerFavListQueryResult = Apollo.QueryResult<InfluencerFavListQuery, InfluencerFavListQueryVariables>;
export const InfluencerListDocument = gql`
    query InfluencerList($first: Int, $minFollow: Int, $maxFollow: Int, $minEr: Float, $maxEr: Float, $prefix: String, $after: String, $interests: [String!], $order: ProfileOrderBy!) {
  profiles(
    first: $first
    after: $after
    orderBy: $order
    filter: {minFollowerCount: $minFollow, maxFollowerCount: $maxFollow, minEngagementRate: $minEr, maxEngagementRate: $maxEr, prefix: $prefix, interestCodes: $interests}
  ) {
    edges {
      cursor
      node {
        cometchatId
        displayName
        id
        profilePictureUrl
        minimumFeeCents
        agent {
          id
        }
        interests {
          code
          name
        }
        instagramAccount {
          followerCount
          handle
          profilePictureUrl
          name
          hypeauditorReport {
            audienceAgesFemale
            audienceAgesMale
            audienceCities
            audienceCountries
            engagementRate
            country
            followCount
            followerCount
            fullName
            handle
          }
        }
      }
    }
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
  }
}
    `;

/**
 * __useInfluencerListQuery__
 *
 * To run a query within a React component, call `useInfluencerListQuery` and pass it any options that fit your needs.
 * When your component renders, `useInfluencerListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInfluencerListQuery({
 *   variables: {
 *      first: // value for 'first'
 *      minFollow: // value for 'minFollow'
 *      maxFollow: // value for 'maxFollow'
 *      minEr: // value for 'minEr'
 *      maxEr: // value for 'maxEr'
 *      prefix: // value for 'prefix'
 *      after: // value for 'after'
 *      interests: // value for 'interests'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useInfluencerListQuery(baseOptions: Apollo.QueryHookOptions<InfluencerListQuery, InfluencerListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InfluencerListQuery, InfluencerListQueryVariables>(InfluencerListDocument, options);
      }
export function useInfluencerListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InfluencerListQuery, InfluencerListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InfluencerListQuery, InfluencerListQueryVariables>(InfluencerListDocument, options);
        }
export type InfluencerListQueryHookResult = ReturnType<typeof useInfluencerListQuery>;
export type InfluencerListLazyQueryHookResult = ReturnType<typeof useInfluencerListLazyQuery>;
export type InfluencerListQueryResult = Apollo.QueryResult<InfluencerListQuery, InfluencerListQueryVariables>;
export const InfluencerProfileDocument = gql`
    query InfluencerProfile($id: ID!) {
  profile(id: $id) {
    bio
    displayName
    id
    isLinkedToAgency
    profilePictureUrl
    minimumFeeCents
    cometchatId
    agency {
      id
      name
    }
    applications {
      nodes {
        acceptsPayByCash
        acceptsPayByProduct
        acceptsPayByStoreCredit
        pitch
        paymentEstimateCents
        id
        state
        campaign {
          name
          id
        }
      }
    }
    interests {
      code
      name
    }
    instagramAccount {
      biography
      followCount
      followerCount
      followCount
      handle
      id
      name
      postCount
      profilePictureUrl
      recentPosts
      hypeauditorReport {
        accountQualityScore
        accountQualityScoreDescription
        accountQualityScoreName
        audienceAgesFemale
        audienceAgesMale
        audienceBrandAffinity
        audienceCities
        audienceCountries
        audienceEthnicity
        audienceEthnicity
        audienceInterests
        audienceLanguages
        audienceType
        audienceUsStates
        audienceIncome
        audienceInterests
        averageComments
        averageLikes
        engagementRate
        brandMentions
        country
        engagementRateDescription
        engagementRateForAds
        engagementRateForAdsName
        estimatedReachMax
        estimatedReachMin
        followCount
        followerCount
        fullName
        handle
        postFrequency
      }
    }
  }
}
    `;

/**
 * __useInfluencerProfileQuery__
 *
 * To run a query within a React component, call `useInfluencerProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useInfluencerProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInfluencerProfileQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useInfluencerProfileQuery(baseOptions: Apollo.QueryHookOptions<InfluencerProfileQuery, InfluencerProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InfluencerProfileQuery, InfluencerProfileQueryVariables>(InfluencerProfileDocument, options);
      }
export function useInfluencerProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InfluencerProfileQuery, InfluencerProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InfluencerProfileQuery, InfluencerProfileQueryVariables>(InfluencerProfileDocument, options);
        }
export type InfluencerProfileQueryHookResult = ReturnType<typeof useInfluencerProfileQuery>;
export type InfluencerProfileLazyQueryHookResult = ReturnType<typeof useInfluencerProfileLazyQuery>;
export type InfluencerProfileQueryResult = Apollo.QueryResult<InfluencerProfileQuery, InfluencerProfileQueryVariables>;
export const InfluencersAdminDocument = gql`
    query InfluencersAdmin($input: Int!, $after: String!) {
  influencers(first: $input, after: $after) {
    edges {
      cursor
      node {
        fullName
        id
        email
        requirements
        applications {
          nodes {
            id
            state
          }
        }
        offers {
          nodes {
            id
            state
          }
        }
        profile {
          id
          profilePictureUrl
          displayName
          instagramAccount {
            followerCount
            handle
          }
        }
        invoices {
          nodes {
            invoiceStatus
            id
          }
        }
      }
    }
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
  }
}
    `;

/**
 * __useInfluencersAdminQuery__
 *
 * To run a query within a React component, call `useInfluencersAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useInfluencersAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInfluencersAdminQuery({
 *   variables: {
 *      input: // value for 'input'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useInfluencersAdminQuery(baseOptions: Apollo.QueryHookOptions<InfluencersAdminQuery, InfluencersAdminQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InfluencersAdminQuery, InfluencersAdminQueryVariables>(InfluencersAdminDocument, options);
      }
export function useInfluencersAdminLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InfluencersAdminQuery, InfluencersAdminQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InfluencersAdminQuery, InfluencersAdminQueryVariables>(InfluencersAdminDocument, options);
        }
export type InfluencersAdminQueryHookResult = ReturnType<typeof useInfluencersAdminQuery>;
export type InfluencersAdminLazyQueryHookResult = ReturnType<typeof useInfluencersAdminLazyQuery>;
export type InfluencersAdminQueryResult = Apollo.QueryResult<InfluencersAdminQuery, InfluencersAdminQueryVariables>;
export const InfluencersAdminStatsDocument = gql`
    query InfluencersAdminStats($input: Int!, $after: String!) {
  influencers(first: $input, after: $after) {
    edges {
      cursor
      node {
        profile {
          agency {
            id
          }
        }
        applications {
          nodes {
            id
          }
        }
        offers {
          nodes {
            id
            deliverables {
              nodes {
                mediaUrls
              }
            }
          }
        }
        invoices {
          nodes {
            id
            invoiceStatus
            product {
              paymentCashCents
              paymentProductCents
              paymentStoreCreditCents
            }
          }
        }
      }
    }
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
  }
}
    `;

/**
 * __useInfluencersAdminStatsQuery__
 *
 * To run a query within a React component, call `useInfluencersAdminStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInfluencersAdminStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInfluencersAdminStatsQuery({
 *   variables: {
 *      input: // value for 'input'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useInfluencersAdminStatsQuery(baseOptions: Apollo.QueryHookOptions<InfluencersAdminStatsQuery, InfluencersAdminStatsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InfluencersAdminStatsQuery, InfluencersAdminStatsQueryVariables>(InfluencersAdminStatsDocument, options);
      }
export function useInfluencersAdminStatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InfluencersAdminStatsQuery, InfluencersAdminStatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InfluencersAdminStatsQuery, InfluencersAdminStatsQueryVariables>(InfluencersAdminStatsDocument, options);
        }
export type InfluencersAdminStatsQueryHookResult = ReturnType<typeof useInfluencersAdminStatsQuery>;
export type InfluencersAdminStatsLazyQueryHookResult = ReturnType<typeof useInfluencersAdminStatsLazyQuery>;
export type InfluencersAdminStatsQueryResult = Apollo.QueryResult<InfluencersAdminStatsQuery, InfluencersAdminStatsQueryVariables>;
export const InfluencersDocument = gql`
    query Influencers($input: Int) {
  influencers(first: $input) {
    edges {
      cursor
      node {
        fullName
        profile {
          id
          profilePictureUrl
          minimumFeeCents
          instagramAccount {
            followerCount
            handle
            hypeauditorReport {
              audienceAgesFemale
              audienceAgesMale
              audienceCities
              audienceCountries
              engagementRate
              country
              followCount
              followerCount
              fullName
              handle
            }
          }
        }
      }
    }
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
  }
}
    `;

/**
 * __useInfluencersQuery__
 *
 * To run a query within a React component, call `useInfluencersQuery` and pass it any options that fit your needs.
 * When your component renders, `useInfluencersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInfluencersQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInfluencersQuery(baseOptions?: Apollo.QueryHookOptions<InfluencersQuery, InfluencersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InfluencersQuery, InfluencersQueryVariables>(InfluencersDocument, options);
      }
export function useInfluencersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InfluencersQuery, InfluencersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InfluencersQuery, InfluencersQueryVariables>(InfluencersDocument, options);
        }
export type InfluencersQueryHookResult = ReturnType<typeof useInfluencersQuery>;
export type InfluencersLazyQueryHookResult = ReturnType<typeof useInfluencersLazyQuery>;
export type InfluencersQueryResult = Apollo.QueryResult<InfluencersQuery, InfluencersQueryVariables>;
export const InfluencersRepresentedAdminDocument = gql`
    query InfluencersRepresentedAdmin($input: Int!, $after: String!) {
  profiles(first: $input, after: $after) {
    edges {
      cursor
      node {
        id
        displayName
        cometchatId
        profilePictureUrl
        instagramAccount {
          handle
          name
        }
        applications {
          nodes {
            id
          }
        }
        offers {
          nodes {
            id
            state
            deliverables {
              nodes {
                mediaUrls
              }
            }
          }
        }
        agency {
          id
          name
          agents {
            nodes {
              id
              fullName
              email
              requirements
            }
          }
          invoices {
            nodes {
              id
              invoiceStatus
              product {
                profile {
                  id
                }
                paymentCashCents
                paymentProductCents
                paymentStoreCreditCents
              }
              product {
                paymentCashCents
                paymentProductCents
                paymentStoreCreditCents
              }
            }
          }
        }
      }
    }
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
  }
}
    `;

/**
 * __useInfluencersRepresentedAdminQuery__
 *
 * To run a query within a React component, call `useInfluencersRepresentedAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useInfluencersRepresentedAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInfluencersRepresentedAdminQuery({
 *   variables: {
 *      input: // value for 'input'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useInfluencersRepresentedAdminQuery(baseOptions: Apollo.QueryHookOptions<InfluencersRepresentedAdminQuery, InfluencersRepresentedAdminQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InfluencersRepresentedAdminQuery, InfluencersRepresentedAdminQueryVariables>(InfluencersRepresentedAdminDocument, options);
      }
export function useInfluencersRepresentedAdminLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InfluencersRepresentedAdminQuery, InfluencersRepresentedAdminQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InfluencersRepresentedAdminQuery, InfluencersRepresentedAdminQueryVariables>(InfluencersRepresentedAdminDocument, options);
        }
export type InfluencersRepresentedAdminQueryHookResult = ReturnType<typeof useInfluencersRepresentedAdminQuery>;
export type InfluencersRepresentedAdminLazyQueryHookResult = ReturnType<typeof useInfluencersRepresentedAdminLazyQuery>;
export type InfluencersRepresentedAdminQueryResult = Apollo.QueryResult<InfluencersRepresentedAdminQuery, InfluencersRepresentedAdminQueryVariables>;
export const NotificationMarkAsReadDocument = gql`
    mutation NotificationMarkAsRead($notificationId: ID!) {
  notificationMarkAsRead(input: {notificationId: $notificationId}) {
    notification {
      ...NotificationData
    }
    errors {
      message
      path
    }
  }
}
    ${NotificationDataFragmentDoc}`;
export type NotificationMarkAsReadMutationFn = Apollo.MutationFunction<NotificationMarkAsReadMutation, NotificationMarkAsReadMutationVariables>;

/**
 * __useNotificationMarkAsReadMutation__
 *
 * To run a mutation, you first call `useNotificationMarkAsReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNotificationMarkAsReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [notificationMarkAsReadMutation, { data, loading, error }] = useNotificationMarkAsReadMutation({
 *   variables: {
 *      notificationId: // value for 'notificationId'
 *   },
 * });
 */
export function useNotificationMarkAsReadMutation(baseOptions?: Apollo.MutationHookOptions<NotificationMarkAsReadMutation, NotificationMarkAsReadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<NotificationMarkAsReadMutation, NotificationMarkAsReadMutationVariables>(NotificationMarkAsReadDocument, options);
      }
export type NotificationMarkAsReadMutationHookResult = ReturnType<typeof useNotificationMarkAsReadMutation>;
export type NotificationMarkAsReadMutationResult = Apollo.MutationResult<NotificationMarkAsReadMutation>;
export type NotificationMarkAsReadMutationOptions = Apollo.BaseMutationOptions<NotificationMarkAsReadMutation, NotificationMarkAsReadMutationVariables>;
export const NotificationPreferencesUpdateDocument = gql`
    mutation NotificationPreferencesUpdate($notificationType: NotificationType!, $newValue: Boolean!) {
  notificationPreferencesUpdate(
    input: {notificationType: $notificationType, newValue: $newValue}
  ) {
    user {
      id
    }
    errors {
      message
      path
    }
  }
}
    `;
export type NotificationPreferencesUpdateMutationFn = Apollo.MutationFunction<NotificationPreferencesUpdateMutation, NotificationPreferencesUpdateMutationVariables>;

/**
 * __useNotificationPreferencesUpdateMutation__
 *
 * To run a mutation, you first call `useNotificationPreferencesUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNotificationPreferencesUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [notificationPreferencesUpdateMutation, { data, loading, error }] = useNotificationPreferencesUpdateMutation({
 *   variables: {
 *      notificationType: // value for 'notificationType'
 *      newValue: // value for 'newValue'
 *   },
 * });
 */
export function useNotificationPreferencesUpdateMutation(baseOptions?: Apollo.MutationHookOptions<NotificationPreferencesUpdateMutation, NotificationPreferencesUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<NotificationPreferencesUpdateMutation, NotificationPreferencesUpdateMutationVariables>(NotificationPreferencesUpdateDocument, options);
      }
export type NotificationPreferencesUpdateMutationHookResult = ReturnType<typeof useNotificationPreferencesUpdateMutation>;
export type NotificationPreferencesUpdateMutationResult = Apollo.MutationResult<NotificationPreferencesUpdateMutation>;
export type NotificationPreferencesUpdateMutationOptions = Apollo.BaseMutationOptions<NotificationPreferencesUpdateMutation, NotificationPreferencesUpdateMutationVariables>;
export const OfferCreateDocument = gql`
    mutation OfferCreate($input: OfferCreateInput!) {
  offerCreate(input: $input) {
    errors {
      message
    }
    offer {
      campaign {
        id
        name
      }
    }
  }
}
    `;
export type OfferCreateMutationFn = Apollo.MutationFunction<OfferCreateMutation, OfferCreateMutationVariables>;

/**
 * __useOfferCreateMutation__
 *
 * To run a mutation, you first call `useOfferCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOfferCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [offerCreateMutation, { data, loading, error }] = useOfferCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOfferCreateMutation(baseOptions?: Apollo.MutationHookOptions<OfferCreateMutation, OfferCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OfferCreateMutation, OfferCreateMutationVariables>(OfferCreateDocument, options);
      }
export type OfferCreateMutationHookResult = ReturnType<typeof useOfferCreateMutation>;
export type OfferCreateMutationResult = Apollo.MutationResult<OfferCreateMutation>;
export type OfferCreateMutationOptions = Apollo.BaseMutationOptions<OfferCreateMutation, OfferCreateMutationVariables>;
export const OrganizationDocument = gql`
    query Organization($id: ID!) {
  organization(id: $id) {
    id
    name
    about
    photoUrl
    websiteUrl
    brands {
      nodes {
        name
        about
        id
      }
    }
  }
}
    `;

/**
 * __useOrganizationQuery__
 *
 * To run a query within a React component, call `useOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrganizationQuery(baseOptions: Apollo.QueryHookOptions<OrganizationQuery, OrganizationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrganizationQuery, OrganizationQueryVariables>(OrganizationDocument, options);
      }
export function useOrganizationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganizationQuery, OrganizationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrganizationQuery, OrganizationQueryVariables>(OrganizationDocument, options);
        }
export type OrganizationQueryHookResult = ReturnType<typeof useOrganizationQuery>;
export type OrganizationLazyQueryHookResult = ReturnType<typeof useOrganizationLazyQuery>;
export type OrganizationQueryResult = Apollo.QueryResult<OrganizationQuery, OrganizationQueryVariables>;
export const PaymentInvoicesDocument = gql`
    query PaymentInvoices($first: Int!, $after: String!) {
  currentUser {
    ... on Personnel {
      organization {
        brands {
          nodes {
            invoices(first: $first, after: $after) {
              edges {
                cursor
                node {
                  id
                  invoiceStatus
                  paidAt
                  payoutAmountCents
                  product {
                    paymentCashCents
                    paymentProductCents
                    paymentStoreCreditCents
                    profile {
                      displayName
                      id
                      profilePictureUrl
                      instagramAccount {
                        profilePictureUrl
                        name
                        handle
                      }
                      agent {
                        agencyId
                        agency {
                          about
                          name
                          agents {
                            nodes {
                              fullName
                              id
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              pageInfo {
                endCursor
                hasNextPage
                hasPreviousPage
                startCursor
              }
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __usePaymentInvoicesQuery__
 *
 * To run a query within a React component, call `usePaymentInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentInvoicesQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function usePaymentInvoicesQuery(baseOptions: Apollo.QueryHookOptions<PaymentInvoicesQuery, PaymentInvoicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PaymentInvoicesQuery, PaymentInvoicesQueryVariables>(PaymentInvoicesDocument, options);
      }
export function usePaymentInvoicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PaymentInvoicesQuery, PaymentInvoicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PaymentInvoicesQuery, PaymentInvoicesQueryVariables>(PaymentInvoicesDocument, options);
        }
export type PaymentInvoicesQueryHookResult = ReturnType<typeof usePaymentInvoicesQuery>;
export type PaymentInvoicesLazyQueryHookResult = ReturnType<typeof usePaymentInvoicesLazyQuery>;
export type PaymentInvoicesQueryResult = Apollo.QueryResult<PaymentInvoicesQuery, PaymentInvoicesQueryVariables>;
export const DetachPaymentMethodDocument = gql`
    mutation DetachPaymentMethod($input: PaymentPaymentMethodDetachInput!) {
  paymentPaymentMethodDetach(input: $input) {
    errors {
      message
    }
    success
  }
}
    `;
export type DetachPaymentMethodMutationFn = Apollo.MutationFunction<DetachPaymentMethodMutation, DetachPaymentMethodMutationVariables>;

/**
 * __useDetachPaymentMethodMutation__
 *
 * To run a mutation, you first call `useDetachPaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDetachPaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [detachPaymentMethodMutation, { data, loading, error }] = useDetachPaymentMethodMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDetachPaymentMethodMutation(baseOptions?: Apollo.MutationHookOptions<DetachPaymentMethodMutation, DetachPaymentMethodMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DetachPaymentMethodMutation, DetachPaymentMethodMutationVariables>(DetachPaymentMethodDocument, options);
      }
export type DetachPaymentMethodMutationHookResult = ReturnType<typeof useDetachPaymentMethodMutation>;
export type DetachPaymentMethodMutationResult = Apollo.MutationResult<DetachPaymentMethodMutation>;
export type DetachPaymentMethodMutationOptions = Apollo.BaseMutationOptions<DetachPaymentMethodMutation, DetachPaymentMethodMutationVariables>;
export const PaymentPayInvoiceDocument = gql`
    mutation PaymentPayInvoice($input: PaymentPayInvoiceInput!) {
  paymentPayInvoice(input: $input) {
    clientSecret
    errors {
      message
    }
  }
}
    `;
export type PaymentPayInvoiceMutationFn = Apollo.MutationFunction<PaymentPayInvoiceMutation, PaymentPayInvoiceMutationVariables>;

/**
 * __usePaymentPayInvoiceMutation__
 *
 * To run a mutation, you first call `usePaymentPayInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePaymentPayInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [paymentPayInvoiceMutation, { data, loading, error }] = usePaymentPayInvoiceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePaymentPayInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<PaymentPayInvoiceMutation, PaymentPayInvoiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PaymentPayInvoiceMutation, PaymentPayInvoiceMutationVariables>(PaymentPayInvoiceDocument, options);
      }
export type PaymentPayInvoiceMutationHookResult = ReturnType<typeof usePaymentPayInvoiceMutation>;
export type PaymentPayInvoiceMutationResult = Apollo.MutationResult<PaymentPayInvoiceMutation>;
export type PaymentPayInvoiceMutationOptions = Apollo.BaseMutationOptions<PaymentPayInvoiceMutation, PaymentPayInvoiceMutationVariables>;
export const PaymentSetupIntentCreateDocument = gql`
    mutation PaymentSetupIntentCreate($input: PaymentSetupIntentCreateInput!) {
  paymentSetupIntentCreate(input: $input) {
    clientSecret
    errors {
      message
    }
  }
}
    `;
export type PaymentSetupIntentCreateMutationFn = Apollo.MutationFunction<PaymentSetupIntentCreateMutation, PaymentSetupIntentCreateMutationVariables>;

/**
 * __usePaymentSetupIntentCreateMutation__
 *
 * To run a mutation, you first call `usePaymentSetupIntentCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePaymentSetupIntentCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [paymentSetupIntentCreateMutation, { data, loading, error }] = usePaymentSetupIntentCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePaymentSetupIntentCreateMutation(baseOptions?: Apollo.MutationHookOptions<PaymentSetupIntentCreateMutation, PaymentSetupIntentCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PaymentSetupIntentCreateMutation, PaymentSetupIntentCreateMutationVariables>(PaymentSetupIntentCreateDocument, options);
      }
export type PaymentSetupIntentCreateMutationHookResult = ReturnType<typeof usePaymentSetupIntentCreateMutation>;
export type PaymentSetupIntentCreateMutationResult = Apollo.MutationResult<PaymentSetupIntentCreateMutation>;
export type PaymentSetupIntentCreateMutationOptions = Apollo.BaseMutationOptions<PaymentSetupIntentCreateMutation, PaymentSetupIntentCreateMutationVariables>;
export const ProfileListAddDocument = gql`
    mutation ProfileListAdd($input: ProfileListAddInput!) {
  profileListAdd(input: $input) {
    errors {
      message
    }
    profileList {
      id
    }
  }
}
    `;
export type ProfileListAddMutationFn = Apollo.MutationFunction<ProfileListAddMutation, ProfileListAddMutationVariables>;

/**
 * __useProfileListAddMutation__
 *
 * To run a mutation, you first call `useProfileListAddMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProfileListAddMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [profileListAddMutation, { data, loading, error }] = useProfileListAddMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProfileListAddMutation(baseOptions?: Apollo.MutationHookOptions<ProfileListAddMutation, ProfileListAddMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProfileListAddMutation, ProfileListAddMutationVariables>(ProfileListAddDocument, options);
      }
export type ProfileListAddMutationHookResult = ReturnType<typeof useProfileListAddMutation>;
export type ProfileListAddMutationResult = Apollo.MutationResult<ProfileListAddMutation>;
export type ProfileListAddMutationOptions = Apollo.BaseMutationOptions<ProfileListAddMutation, ProfileListAddMutationVariables>;
export const CreateFavoriteProfileListDocument = gql`
    mutation CreateFavoriteProfileList($input: ProfileListCreateInput!) {
  profileListCreate(input: $input) {
    errors {
      message
    }
    profileList {
      id
    }
  }
}
    `;
export type CreateFavoriteProfileListMutationFn = Apollo.MutationFunction<CreateFavoriteProfileListMutation, CreateFavoriteProfileListMutationVariables>;

/**
 * __useCreateFavoriteProfileListMutation__
 *
 * To run a mutation, you first call `useCreateFavoriteProfileListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFavoriteProfileListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFavoriteProfileListMutation, { data, loading, error }] = useCreateFavoriteProfileListMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateFavoriteProfileListMutation(baseOptions?: Apollo.MutationHookOptions<CreateFavoriteProfileListMutation, CreateFavoriteProfileListMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateFavoriteProfileListMutation, CreateFavoriteProfileListMutationVariables>(CreateFavoriteProfileListDocument, options);
      }
export type CreateFavoriteProfileListMutationHookResult = ReturnType<typeof useCreateFavoriteProfileListMutation>;
export type CreateFavoriteProfileListMutationResult = Apollo.MutationResult<CreateFavoriteProfileListMutation>;
export type CreateFavoriteProfileListMutationOptions = Apollo.BaseMutationOptions<CreateFavoriteProfileListMutation, CreateFavoriteProfileListMutationVariables>;
export const ProfileListRemoveDocument = gql`
    mutation ProfileListRemove($input: ProfileListRemoveInput!) {
  profileListRemove(input: $input) {
    errors {
      message
    }
    profileList {
      id
    }
  }
}
    `;
export type ProfileListRemoveMutationFn = Apollo.MutationFunction<ProfileListRemoveMutation, ProfileListRemoveMutationVariables>;

/**
 * __useProfileListRemoveMutation__
 *
 * To run a mutation, you first call `useProfileListRemoveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProfileListRemoveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [profileListRemoveMutation, { data, loading, error }] = useProfileListRemoveMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProfileListRemoveMutation(baseOptions?: Apollo.MutationHookOptions<ProfileListRemoveMutation, ProfileListRemoveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProfileListRemoveMutation, ProfileListRemoveMutationVariables>(ProfileListRemoveDocument, options);
      }
export type ProfileListRemoveMutationHookResult = ReturnType<typeof useProfileListRemoveMutation>;
export type ProfileListRemoveMutationResult = Apollo.MutationResult<ProfileListRemoveMutation>;
export type ProfileListRemoveMutationOptions = Apollo.BaseMutationOptions<ProfileListRemoveMutation, ProfileListRemoveMutationVariables>;
export const OrganizationUpdateDocument = gql`
    mutation OrganizationUpdate($input: OrganizationUpdateInput!) {
  organizationUpdate(input: $input) {
    organization {
      id
      about
      name
      photoUrl
    }
    errors {
      message
    }
  }
}
    `;
export type OrganizationUpdateMutationFn = Apollo.MutationFunction<OrganizationUpdateMutation, OrganizationUpdateMutationVariables>;

/**
 * __useOrganizationUpdateMutation__
 *
 * To run a mutation, you first call `useOrganizationUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrganizationUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [organizationUpdateMutation, { data, loading, error }] = useOrganizationUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOrganizationUpdateMutation(baseOptions?: Apollo.MutationHookOptions<OrganizationUpdateMutation, OrganizationUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OrganizationUpdateMutation, OrganizationUpdateMutationVariables>(OrganizationUpdateDocument, options);
      }
export type OrganizationUpdateMutationHookResult = ReturnType<typeof useOrganizationUpdateMutation>;
export type OrganizationUpdateMutationResult = Apollo.MutationResult<OrganizationUpdateMutation>;
export type OrganizationUpdateMutationOptions = Apollo.BaseMutationOptions<OrganizationUpdateMutation, OrganizationUpdateMutationVariables>;
export const UpdatePasswordDocument = gql`
    mutation UpdatePassword($input: UserPasswordChangeInput!) {
  userPasswordChange(input: $input) {
    errors {
      message
    }
    success
  }
}
    `;
export type UpdatePasswordMutationFn = Apollo.MutationFunction<UpdatePasswordMutation, UpdatePasswordMutationVariables>;

/**
 * __useUpdatePasswordMutation__
 *
 * To run a mutation, you first call `useUpdatePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePasswordMutation, { data, loading, error }] = useUpdatePasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePasswordMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePasswordMutation, UpdatePasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePasswordMutation, UpdatePasswordMutationVariables>(UpdatePasswordDocument, options);
      }
export type UpdatePasswordMutationHookResult = ReturnType<typeof useUpdatePasswordMutation>;
export type UpdatePasswordMutationResult = Apollo.MutationResult<UpdatePasswordMutation>;
export type UpdatePasswordMutationOptions = Apollo.BaseMutationOptions<UpdatePasswordMutation, UpdatePasswordMutationVariables>;
export const UserCreateDocument = gql`
    mutation userCreate($input: UserCreateInput!) {
  userCreate(input: $input) {
    password
    errors {
      message
    }
  }
}
    `;
export type UserCreateMutationFn = Apollo.MutationFunction<UserCreateMutation, UserCreateMutationVariables>;

/**
 * __useUserCreateMutation__
 *
 * To run a mutation, you first call `useUserCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userCreateMutation, { data, loading, error }] = useUserCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserCreateMutation(baseOptions?: Apollo.MutationHookOptions<UserCreateMutation, UserCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserCreateMutation, UserCreateMutationVariables>(UserCreateDocument, options);
      }
export type UserCreateMutationHookResult = ReturnType<typeof useUserCreateMutation>;
export type UserCreateMutationResult = Apollo.MutationResult<UserCreateMutation>;
export type UserCreateMutationOptions = Apollo.BaseMutationOptions<UserCreateMutation, UserCreateMutationVariables>;
export const UserNotificationReadDocument = gql`
    subscription UserNotificationRead($userId: ID!) {
  userNotificationRead(userId: $userId) {
    readNotification {
      id
      readAt
    }
    errors {
      message
      path
    }
  }
}
    `;

/**
 * __useUserNotificationReadSubscription__
 *
 * To run a query within a React component, call `useUserNotificationReadSubscription` and pass it any options that fit your needs.
 * When your component renders, `useUserNotificationReadSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserNotificationReadSubscription({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserNotificationReadSubscription(baseOptions: Apollo.SubscriptionHookOptions<UserNotificationReadSubscription, UserNotificationReadSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<UserNotificationReadSubscription, UserNotificationReadSubscriptionVariables>(UserNotificationReadDocument, options);
      }
export type UserNotificationReadSubscriptionHookResult = ReturnType<typeof useUserNotificationReadSubscription>;
export type UserNotificationReadSubscriptionResult = Apollo.SubscriptionResult<UserNotificationReadSubscription>;
export const UserNotificationsDocument = gql`
    query UserNotifications($after: String) {
  currentUser {
    notifications(first: 10, after: $after) {
      nodes {
        ...NotificationData
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
}
    ${NotificationDataFragmentDoc}`;

/**
 * __useUserNotificationsQuery__
 *
 * To run a query within a React component, call `useUserNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserNotificationsQuery({
 *   variables: {
 *      after: // value for 'after'
 *   },
 * });
 */
export function useUserNotificationsQuery(baseOptions?: Apollo.QueryHookOptions<UserNotificationsQuery, UserNotificationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserNotificationsQuery, UserNotificationsQueryVariables>(UserNotificationsDocument, options);
      }
export function useUserNotificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserNotificationsQuery, UserNotificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserNotificationsQuery, UserNotificationsQueryVariables>(UserNotificationsDocument, options);
        }
export type UserNotificationsQueryHookResult = ReturnType<typeof useUserNotificationsQuery>;
export type UserNotificationsLazyQueryHookResult = ReturnType<typeof useUserNotificationsLazyQuery>;
export type UserNotificationsQueryResult = Apollo.QueryResult<UserNotificationsQuery, UserNotificationsQueryVariables>;
export const UserNotificationDocument = gql`
    subscription UserNotification($userId: ID!) {
  userNotifications(userId: $userId) {
    newNotification {
      ...NotificationData
    }
    errors {
      message
      path
    }
  }
}
    ${NotificationDataFragmentDoc}`;

/**
 * __useUserNotificationSubscription__
 *
 * To run a query within a React component, call `useUserNotificationSubscription` and pass it any options that fit your needs.
 * When your component renders, `useUserNotificationSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserNotificationSubscription({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserNotificationSubscription(baseOptions: Apollo.SubscriptionHookOptions<UserNotificationSubscription, UserNotificationSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<UserNotificationSubscription, UserNotificationSubscriptionVariables>(UserNotificationDocument, options);
      }
export type UserNotificationSubscriptionHookResult = ReturnType<typeof useUserNotificationSubscription>;
export type UserNotificationSubscriptionResult = Apollo.SubscriptionResult<UserNotificationSubscription>;
export const UserUnreadNotificationsDocument = gql`
    query UserUnreadNotifications {
  currentUser {
    unreadNotifications {
      nodes {
        ...NotificationData
      }
    }
  }
}
    ${NotificationDataFragmentDoc}`;

/**
 * __useUserUnreadNotificationsQuery__
 *
 * To run a query within a React component, call `useUserUnreadNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserUnreadNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserUnreadNotificationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserUnreadNotificationsQuery(baseOptions?: Apollo.QueryHookOptions<UserUnreadNotificationsQuery, UserUnreadNotificationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserUnreadNotificationsQuery, UserUnreadNotificationsQueryVariables>(UserUnreadNotificationsDocument, options);
      }
export function useUserUnreadNotificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserUnreadNotificationsQuery, UserUnreadNotificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserUnreadNotificationsQuery, UserUnreadNotificationsQueryVariables>(UserUnreadNotificationsDocument, options);
        }
export type UserUnreadNotificationsQueryHookResult = ReturnType<typeof useUserUnreadNotificationsQuery>;
export type UserUnreadNotificationsLazyQueryHookResult = ReturnType<typeof useUserUnreadNotificationsLazyQuery>;
export type UserUnreadNotificationsQueryResult = Apollo.QueryResult<UserUnreadNotificationsQuery, UserUnreadNotificationsQueryVariables>;
export const UsersToApproveDocument = gql`
    query UsersToApprove {
  usersToApprove {
    email
    fullName
    id
    requirements
  }
}
    `;

/**
 * __useUsersToApproveQuery__
 *
 * To run a query within a React component, call `useUsersToApproveQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersToApproveQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersToApproveQuery({
 *   variables: {
 *   },
 * });
 */
export function useUsersToApproveQuery(baseOptions?: Apollo.QueryHookOptions<UsersToApproveQuery, UsersToApproveQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsersToApproveQuery, UsersToApproveQueryVariables>(UsersToApproveDocument, options);
      }
export function useUsersToApproveLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersToApproveQuery, UsersToApproveQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsersToApproveQuery, UsersToApproveQueryVariables>(UsersToApproveDocument, options);
        }
export type UsersToApproveQueryHookResult = ReturnType<typeof useUsersToApproveQuery>;
export type UsersToApproveLazyQueryHookResult = ReturnType<typeof useUsersToApproveLazyQuery>;
export type UsersToApproveQueryResult = Apollo.QueryResult<UsersToApproveQuery, UsersToApproveQueryVariables>;
export const VerifyEmailDocument = gql`
    mutation VerifyEmail($input: VerifyEmailInput!) {
  verifyEmail(input: $input) {
    errors {
      message
    }
    user {
      email
      fullName
      id
    }
  }
}
    `;
export type VerifyEmailMutationFn = Apollo.MutationFunction<VerifyEmailMutation, VerifyEmailMutationVariables>;

/**
 * __useVerifyEmailMutation__
 *
 * To run a mutation, you first call `useVerifyEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyEmailMutation, { data, loading, error }] = useVerifyEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVerifyEmailMutation(baseOptions?: Apollo.MutationHookOptions<VerifyEmailMutation, VerifyEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VerifyEmailMutation, VerifyEmailMutationVariables>(VerifyEmailDocument, options);
      }
export type VerifyEmailMutationHookResult = ReturnType<typeof useVerifyEmailMutation>;
export type VerifyEmailMutationResult = Apollo.MutationResult<VerifyEmailMutation>;
export type VerifyEmailMutationOptions = Apollo.BaseMutationOptions<VerifyEmailMutation, VerifyEmailMutationVariables>;