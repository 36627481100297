import React from "react"
import { useHistory } from "react-router-dom";

export function NotificationLink({ notification, children }) {
    // Define defaults
    const { campaign } = notification

    const history = useHistory()

    let link = null;

    if (campaign && notification.type === "CONTENT_SUBMITTED") {
        link = `/campaign/${campaign.id}?=2`
    } else if (campaign && (notification.type === "APPLICATION_CREATED" || notification.type === "OFFER_ACCEPTED")  ){
        link = `/campaign/${campaign.id}?=1`
    } else {
        link = `/campaign/${campaign.id}`
    }

    return (
        <div className="link-wrapper" onClick={() => link ? history.push(link) : null}>
            {children}
        </div>
    )
}