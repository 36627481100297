import React, { useRef } from 'react';
import { Link } from "react-router-dom";
import { NotificationComponent } from "../../Notifications/NotificationComponent"
import { MessageNotificationBadge } from '../../Notifications/badges';
import { NotificationBadgeWrapper } from '../../Notifications/NotificationBadgeWrapper';
import { MessageController } from '../../Notifications/controllers/MessageController';
import { useSettingsStore } from '../../../hooks';
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom';

const rootclass = 'header'

const Header = ({ me, isAuth, isAdminAuth, windowWidth, setRecoverPassword, recoverPassword }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const yourInfoStore = useSettingsStore(state => state.yourInfo);

    const handleLogout = async (e) => {
        e.preventDefault();
        localStorage.removeItem('token');
        window.location = '/login'
    }

    const handleAdminLogout = async (e) => {
        e.preventDefault();
        localStorage.removeItem('token');
        sessionStorage.removeItem('adminToken');
        window.location = '/login'
    }

    const handleHomeClick = () => {
        setRecoverPassword(false);
        if(isAdminAuth){
            history.push('/admin')
        }
        history.push('/')
        localStorage.setItem('currentPath', "/");
    }

    const handleMessaging = () => {
        history.push('/messages')
    }
    
    /**
     * It seems like some hooks (like UseMe) are triggering rerendering of the entire page
     * So, I'll just use useRef for the NotificationComponent
     */
    const not = useRef(<NotificationComponent />)

    if(!isAdminAuth){
        // Brand
        return (
            isAuth && me && me?.__typename !== "Influencer" ?
            <>
            {/* Desktop */}
            {windowWidth >=760 && 
                <header className={rootclass}>
                    <div className={`${rootclass}-content`}>
                        <Link to ='/' onClick={handleHomeClick}>
                            <img src='/firework-logo.png' alt="Firework logo" />
                            <div>
                                <span>{t("nav.beta")}</span>
                            </div>
                        </Link>
                        <nav role="navigation">
                            <ul>
        
                                <li ><Link to='/influencer'>{t("nav.influencers")}</Link></li>
                                
                                <MessageController >
                                    <li>
                                        <NotificationBadgeWrapper badge={<MessageNotificationBadge />}>
                                            <Link to='/messages'>{t("nav.messages")}</Link>
                                        </NotificationBadgeWrapper>
                                    </li>
                                </MessageController>
                                
                                <li >{not.current}</li>
                            </ul>
                            <div tabIndex="0" className="header-dropdown">
                                <div className="header-userIcon">
                                    {yourInfoStore?.image ? 
                                        <img src={yourInfoStore.image} alt={`${yourInfoStore?.name} logo`} />
                                        :
                                        me?.organization?.photoUrl
                                        ? 
                                        <img src={me.organization.photoUrl} alt={`${me.organization?.name} logo`} />
                                        : 
                                        <img src='/placeholder-profile.png' alt='placeholder' />
                                    }
                                </div>
                                <div className="header-dropdown-content">
                                    <Link tabIndex="0" to="/settings">{t("nav.settings")}</Link>
                                    <div tabIndex="0" onClick={(e) => handleLogout(e)}>{t("nav.logout")}</div>
                                </div>
                            </div>
                        </nav>
                    </div>
                </header>
            }

            {/* Mobile */}
            {windowWidth <500 &&
                <header className={rootclass}>
                    <div className={`${rootclass}-content`}>
                        <img src='/firework-logo.png' alt="Firework logo" />
                        <div className={`${rootclass}-content-tag`}>
                            <span>{t("nav.beta")}</span>
                        </div>
                        <nav role="navigation">
                            <ul>
        
                                {/* <li ><Link to='/influencer'>{t("nav.influencers")}</Link></li> */}
                                
                                {/* <MessageController >
                                    <li>
                                        <NotificationBadgeWrapper badge={<MessageNotificationBadge />}>
                                            <Link to='/messages'>{t("nav.messages")}</Link>
                                        </NotificationBadgeWrapper>
                                    </li>
                                </MessageController> */}
                                
                                {/* <li >{not.current}</li> */}
                            </ul>
                            <div tabIndex="0" className="header-dropdown">
                                <div className="header-userIcon">
                                    {
                                        me?.organization?.photoUrl
                                            ? <img src={me?.organization?.photoUrl} alt={`${me?.organization?.name} logo`} />
                                            : <img src='/placeholder-profile.png' alt='placeholder' />
                                    }
                                </div>
                                <div className="header-dropdown-content">
                                    <div tabIndex="0" onClick={(e) => handleMessaging(e)}>{t("nav.messages")}</div>
                                    <div tabIndex="0" onClick={(e) => handleLogout(e)}>{t("nav.logout")}</div>
                                </div>
                            </div>
                        </nav>
                    </div>

                </header>
            }
            </>
            :

            // Influencer or Sign in
            <header className={rootclass}>
                <div className={`${rootclass}-content`}>
                <div className="header-logo">
                    {recoverPassword 
                    ?
                        <Link to ='/' onClick={handleHomeClick}>
                            <img src='/firework-logo.png' alt="Firework logo" />
                            <div>
                                <span>{t("nav.beta")}</span>

                            </div>
                        </Link>
                    : <>
                        <img src='/firework-logo.png' alt="Firework logo" />
                        <div>
                            <span>{t("nav.beta")}</span>
                        </div>
                    </>}

                    {/* {isAdminAuth && isAdminRoute ?
                        <div className="header-admin">
                            <p onClick={handleAdminHome}>Home</p>
                            <p onClick={handleAdminMessages}>Messages</p>
                            <p className="header-admin-logout" onClick={handleAdminLogout}>Logout</p>
                        </div>
                    : null} */}
                </div>
                </div>
            </header>
        )
    } else {
        //Admin
        return(
            <header className={rootclass}>
                <div className={`${rootclass}-content`}>
                    <Link to="/admin">
                        <img src='/firework-logo.png' alt="Firework logo" />
                        <div>
                            <span>{t("nav.beta")}</span>
                        </div>
                    </Link>

                    <div tabIndex="0" className="header-dropdown">
                            <div className="header-userIcon">
                                <img src='/firework-admin.png' alt='FireWork Logo' />
                            </div>
                            <div className="header-dropdown-content">
                                <Link tabIndex="0" to="/admin/messages">Messaging</Link>
                                <div tabIndex="0" onClick={(e) => handleAdminLogout(e)}>Logout</div>
                            </div>
                        </div>
                </div>
            </header>
        )  
    }
}

export default Header;