import React, { useState,useEffect } from 'react';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import { useTranslation } from 'react-i18next'
import Loading from '../../../../../../Global/Loading/Icon'

const PayInvoice = ({
	values,
	setValues,
	setCardError,
	rootclass,
	setUpdate,
	paying,
	setPaying,
    step,
    setStep,
	paymentMethods,
	paymentIntentClientSecret,
}) => {
	const { t } = useTranslation();
	const stripe = useStripe();
	const elements = useElements();
	const [cleared, setCleared]=useState(false);

	useEffect(() => {
		if(paymentIntentClientSecret){
			handlePaymentSubmit();
		}
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paymentIntentClientSecret, paying]);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const handlePaymentSubmit = async () => {
		setPaying(true);
		if (!stripe || !elements) {
			// Stripe.js has not yet loaded.
			// Make sure to disable form submission until Stripe.js has loaded.
			return;
		}

		const result = await stripe.confirmCardPayment(`${paymentIntentClientSecret}`);
			if (result.error) {
				// Show error to your customer (e.g., insufficient funds)
				setPaying(false);
				setCardError({
					errorState: true,
					errorMessage: result.error.message
				})
			} else {
				// The payment has been processed!
				setPaying(false);
				if (result.paymentIntent.status === 'succeeded') {
					setStep(3);
					window.setTimeout(function () {
					setUpdate(true);
					}, 3000);
				}
			}
	
	};

	const handleSelectCC = (CC) => {
        setCleared(false);
        setValues({
             ...values, 
             ccId: CC
        }) 
    }

	return (
		step === 0 && !paying ?
		<>	
			<strong>{t("modal.pay.payOffer.cards")}</strong>
			<div className={`${rootclass}-cards-row`}>
				{paymentMethods.map((card,i)=> {
					switch (card.brand) {
						case 'amex': 
							return(
								<div key={i} className={`${rootclass}-cards-row-item`}>
								<label className="radio" >
									<span className="radio__input" >
										<input type="radio" id={i} name='ER' value={card.id} onClick={(e) => handleSelectCC(e.target.value)}></input>
										<span className={!cleared ? "radio__custom": "radio__cleared"}></span>
									</span>
									<span className={"radio__label"}>
									<img src="/cc/amex.png" alt="Amex Png - Transparent Background Amex Logo"></img>
										<p><span>American Express</span> {t("settings.payment.ending")}{card.lastFour}</p>
									</span>
								</label> 
							</div>
							);
						case 'visa':
							return(
								<div key={i} className={`${rootclass}-cards-row-item`}>
									<label className="radio" >
										<span className="radio__input" >
											<input type="radio" id={i} name='ER' value={card.id} onClick={(e) => handleSelectCC(e.target.value)}></input>
											<span className={!cleared ? "radio__custom": "radio__cleared"}></span>
										</span>
										<span className={"radio__label"}>
											<img src="/cc/visa.png" alt="Visa Png - Transparent Background Visa Logo"></img>
											<p><span>Visa</span> {t("settings.payment.ending")}{card.lastFour}</p>
										</span>
									</label> 
								</div>
							);
						case 'mastercard':
							return(
								<div key={i} className={`${rootclass}-cards-row-item`}>
									<label className="radio" >
										<span className="radio__input" >
											<input type="radio" id={i} name='ER' value={card.id} onClick={(e) => handleSelectCC(e.target.value)}></input>
											<span className={!cleared ? "radio__custom": "radio__cleared"}></span>
										</span>
										<span className={"radio__label"}>
											<img src="/cc/mc.png" alt="MasterCard Png - Transparent Background Mastercard Logo"></img>
											<p><span>Mastercard</span> {t("settings.payment.ending")}{card.lastFour}</p>
										</span>
									</label> 
								</div>
							);
						default:
							return(
								<div key={i} className={`${rootclass}-cards-row-item`}>
									<label className="radio" >
										<span className="radio__input" >
											<input type="radio" id={i} name='ER' value={card.id} onClick={(e) => handleSelectCC(e.target.value)}></input>
											<span className={!cleared ? "radio__custom": "radio__cleared"}></span>
										</span>
										<span className={"radio__label"}>
											<img src="/cc/generic.png" alt="Generic Png - Transparent Background Generic Logo"></img>
											<p><span>Credit Card</span> {t("settings.payment.ending")}{card.lastFour}</p>
										</span>
									</label> 
								</div>
							);
					};
				})}
			</div>
		</>
		:
		<>
			<Loading rootclass={rootclass} />
		</>
	)


};

export default PayInvoice;
